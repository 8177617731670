import { ThemeProvider } from '@emotion/react';
import { LocalShipping, LocalShippingOutlined, Storefront } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatNumberLocale } from '../../utils/data-utils';

interface TokenRedeemButtonProps {
	selected: boolean;
	onClick: () => void;
	tokenAmount: number;
	redeemLocation: string;
	redeemType: 'shipping' | 'withdrawal';
}

function roundToDecimals(num: number, decimals: number) {
	let factor = Math.pow(10, decimals);
	return Math.round(num * factor) / factor;
}

const TokenRedeemButton: React.FC<TokenRedeemButtonProps> = ({
	selected,
	onClick,
	tokenAmount,
	redeemLocation,
	redeemType,
}) => {
	const [isSelected, setIsSelected] = useState(selected);
	const intl = useIntl();

	useEffect(() => {
		setIsSelected(selected);
	}, [selected]);

	return (
		<Paper
			onClick={() => onClick()}
			elevation={0}
			sx={{
				padding: 0,
				border: isSelected ? '1px solid #8F8F8F' : null,
				backgroundColor: '#F9F9F9',
				minHeight: '75px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'&:hover': {
					cursor: 'pointer',
				},
			}}
		>
			<Box padding={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
				<Box display={'flex'} flexDirection={'column'} textAlign={'center'}>
					<Typography fontWeight={700} color={'primary.main'}>
						{`${formatNumberLocale(tokenAmount)} tokens`}
					</Typography>
					<Box alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
						{redeemType === 'shipping' ? <LocalShippingOutlined></LocalShippingOutlined> : <Storefront></Storefront>}
						<Typography fontSize={'12px'} fontWeight={600} variant="inherit">
							{redeemLocation}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
};

export default TokenRedeemButton;
