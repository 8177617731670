import React, { useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Fade,
	Grid,
	InputLabel,
	LinearProgress,
	Link,
	MenuItem,
	Modal,
	Paper,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import TextFieldWithPlaceholder from '../../text-field-with-placeholder';
import SelectWithPlaceholder from '../../select-with-placeholder';
import SelectMultiWithPlaceholder from '../../select-multiple-with-placeholder';
import FileUpload from 'react-material-file-upload';
import NuqleaFileUpload from '../../nuqlea-file-uploader';
import { BusinessDetails, BusinessFile, BusinessName, DocumentationTypes, User } from '../../../types';
import { fileToBase64 } from '../../../utils/data-utils';
import { string } from 'is';
import client from '../../../clients/client';
import CreditProfileTerms from './credit-profile-terms';

interface CreditProfileEditionTabProps {
	callback: (isEditionMode: boolean) => void;
	showRequiredError: (show: boolean) => void;
	updateBusinessDetailsList: () => void;
	businessNamesList?: BusinessName[];
	businessDetailToEdit?: BusinessDetails;
	creditProfiles: BusinessDetails[] | undefined;
	organizationId: string;
	token: string;
	creator: User;
}

const CreditProfileEditionTab: React.FC<CreditProfileEditionTabProps> = ({
	callback,
	showRequiredError,
	updateBusinessDetailsList,
	businessNamesList,
	businessDetailToEdit,
	creditProfiles,
	organizationId,
	token,
	creator,
}) => {
	const isMobile = useMobile();
	const intl = useIntl();

	const [errorPayload, setErrorPayload] = useState<string>('');
	const [businessNameCuit, setBusinessNameCuit] = useState(
		businessNamesList?.length ? Number(businessNamesList[0]?.cuit) : 0
	);
	const [commercialReferencesText, setCommercialReferencesText] = useState(
		businessDetailToEdit?.commercial_reference ?? ''
	);
	const [mainSuppliersText, setMainSuppliersText] = useState(businessDetailToEdit?.supplier ?? '');
	const [mainCustomersText, setMainCustomersText] = useState(businessDetailToEdit?.customer ?? '');

	const [lastBalancesDoc1, setLastBalancesDoc1] = useState<File[]>([]);
	const [lastBalancesDoc2, setLastBalancesDoc2] = useState<File[]>([]);
	const [balancePostSalesDoc, setBalancePostSalesDoc] = useState<File[]>([]);
	const [commercialReferencesDoc, setCommercialReferencesDoc] = useState<File[]>([]);
	const [mainSuppliersDoc, setMainSuppliersDoc] = useState<File[]>([]);
	const [mainCustomersDoc, setMainCustomersDoc] = useState<File[]>([]);

	const [toggleCommerciarReferences, setToggleCommerciarReferences] = useState(
		businessDetailToEdit?.files?.find((i: any) => Number(i.type) === DocumentationTypes.COMMERCIAL_REF) ? true : false
	);
	const [toggleMainSuppliers, setToggleMainSuppliers] = useState(
		businessDetailToEdit?.files?.find((i: any) => Number(i.type) === DocumentationTypes.MAIN_SUPPLIERS) ? true : false
	);
	const [toggleMainCustomers, setToggleMainCustomers] = useState(
		businessDetailToEdit?.files?.find((i: any) => Number(i.type) === DocumentationTypes.MAIN_CUSTOMERS) ? true : false
	);

	const [firstSubmit, setFirstSubmit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [showSecondBalance, setShowSecondBalance] = useState(false);
	const [showNoDataMessage, setShowNoDataMessage] = useState(false);
	const [showTermsModal, setShowTermsModal] = useState(false);

	const handleSave = async () => {
		setFirstSubmit(true);

		if (checkDataPresent() === false) {
			setShowNoDataMessage(true);
		} else {
			showRequiredError(false);
			businessDetailToEdit ? updateBusinessDetails() : createBusinessDetails();
		}
	};

	const createBusinessDetails = async () => {
		const newBusinessDetails: BusinessDetails = {
			bussiness_name_id: businessNamesList?.filter((item) => item.cuit === businessNameCuit)[0].id ?? 'none',
			commercial_reference: commercialReferencesText ?? null,
			supplier: mainSuppliersText ?? null,
			customer: mainCustomersText ?? null,
			created_by: creator.email,
			files: await makeFilesArray(),
		};
		try {
			const data = newBusinessDetails as any;
			data.userId = creator.id;
			setIsSaving(true);
			await client.createBusinessDetails({ data: data, token: token });
			updateBusinessDetailsList();
			callback(false);
		} catch (error) {
			console.error(error);
		} finally {
			setIsSaving(false);
		}
	};

	const updateBusinessDetails = async () => {
		//Get current files loaded in front
		const unfilteredFiles = await makeFilesArray();
		let filesToDelete: string[] = [];

		//Check if original files are present in current files, if not, mark to delete
		businessDetailToEdit?.files?.forEach((bf: BusinessFile) => {
			let keep = unfilteredFiles?.find((uf) => uf.format === bf.id);
			if (keep == undefined) {
				filesToDelete.push(String(bf.id));
			}
		});

		//Remove files that don't need to be updated
		const filteredFiles = unfilteredFiles?.filter((item) => item.doc !== '');

		const updatedBusinessDetails: BusinessDetails = {
			id: businessDetailToEdit?.id,
			bussiness_name_id: businessDetailToEdit?.bussiness_name_id ?? null,
			commercial_reference: commercialReferencesText ?? null,
			supplier: mainSuppliersText ?? null,
			customer: mainCustomersText ?? null,
			created_by: creator.email,
			files: filteredFiles,
			removedFilesIds: filesToDelete,
		};
		try {
			const data = updatedBusinessDetails as any;
			data.userId = creator.id;
			setIsSaving(true);
			await client.updateBusinessDetails({ data: data, token: token });
			updateBusinessDetailsList();
			callback(false);
		} catch (error: any) {
			console.error(error);
			if (error.response?.status === 413) {
				setErrorPayload(intl.formatMessage({ id: 'common.payloadTooLarge' }));
			}
		} finally {
			setIsSaving(false);
		}
	};

	const makeFilesArray = async () => {
		let files: { type: number; doc: string; format: string; name: string }[] = [];
		lastBalancesDoc1.length &&
			files.push({
				type: DocumentationTypes.BALANCE,
				doc: (await fileToBase64(lastBalancesDoc1[0])).base64 ?? '',
				format: lastBalancesDoc1[0].type,
				name: getFileName(DocumentationTypes.BALANCE),
			});

		lastBalancesDoc2.length &&
			files.push({
				type: DocumentationTypes.BALANCE,
				doc: (await fileToBase64(lastBalancesDoc2[0])).base64 ?? '',
				format: lastBalancesDoc2[0].type,
				name: getFileName(DocumentationTypes.BALANCE, 1),
			});

		balancePostSalesDoc.length &&
			files.push({
				type: DocumentationTypes.BALANCE_POST_SALES,
				doc: (await fileToBase64(balancePostSalesDoc[0])).base64 ?? '',
				format: balancePostSalesDoc[0].type,
				name: getFileName(DocumentationTypes.BALANCE_POST_SALES),
			});

		commercialReferencesDoc.length &&
			files.push({
				type: DocumentationTypes.COMMERCIAL_REF,
				doc: (await fileToBase64(commercialReferencesDoc[0])).base64 ?? '',
				format: commercialReferencesDoc[0].type,
				name: getFileName(DocumentationTypes.COMMERCIAL_REF),
			});

		mainSuppliersDoc.length &&
			files.push({
				type: DocumentationTypes.MAIN_SUPPLIERS,
				doc: (await fileToBase64(mainSuppliersDoc[0])).base64 ?? '',
				format: mainSuppliersDoc[0].type,
				name: getFileName(DocumentationTypes.MAIN_SUPPLIERS),
			});

		mainCustomersDoc.length &&
			files.push({
				type: DocumentationTypes.MAIN_CUSTOMERS,
				doc: (await fileToBase64(mainCustomersDoc[0])).base64 ?? '',
				format: mainCustomersDoc[0].type,
				name: getFileName(DocumentationTypes.MAIN_CUSTOMERS),
			});

		return files.length ? files : null;
	};

	const checkDataPresent = (): boolean => {
		if (
			lastBalancesDoc1.length > 0 ||
			balancePostSalesDoc.length > 0 ||
			commercialReferencesDoc.length > 0 ||
			mainSuppliersDoc.length > 0 ||
			mainCustomersDoc.length > 0 ||
			commercialReferencesText !== '' ||
			mainCustomersText !== '' ||
			mainSuppliersText !== ''
		) {
			return true;
		}
		return false;
	};

	const onSnackBarClose = () => {
		setShowNoDataMessage(false);
	};

	const getFileName = (type: Number, index?: Number): string => {
		let name = Object.keys(DocumentationTypes).find(
			(item) => DocumentationTypes[item as keyof typeof DocumentationTypes] === type
		);

		return index ? String(name) + `_${index}` : String(name);
	};

	useEffect(() => {
		const getStoragedFiles = async () => {
			if (businessDetailToEdit?.files?.length > 0) {
				for (const file of businessDetailToEdit?.files) {
					const blob = new Blob();
					const filename = file.path.substring(file.path.lastIndexOf('/') + 1);
					const newFile = new File([blob], filename, { type: file.id });

					switch (Number(file.type)) {
						case DocumentationTypes.BALANCE:
							if (filename.includes('_1')) {
								setLastBalancesDoc2([newFile]);
								setShowSecondBalance(true);
								break;
							}
							setLastBalancesDoc1([newFile]);
							break;
						case DocumentationTypes.BALANCE_POST_SALES:
							setBalancePostSalesDoc([newFile]);
							break;
						case DocumentationTypes.COMMERCIAL_REF:
							setCommercialReferencesDoc([newFile]);
							break;
						case DocumentationTypes.MAIN_SUPPLIERS:
							setMainSuppliersDoc([newFile]);
							break;
						case DocumentationTypes.MAIN_CUSTOMERS:
							setMainCustomersDoc([newFile]);
							break;
						default:
							break;
					}
				}
			}
		};
		getStoragedFiles();
	}, []);

	return (
		<Paper elevation={0} sx={{ minHeight: isMobile ? null : '100%', maxHeight: isMobile ? null : 2000 }}>
			<Grid pl={'15px'} minHeight={'100%'} display={'flex'} container spacing={2}>
				{!isMobile && (
					<Grid item xs={12} md={7} lg={6} xl={6}>
						<Box padding={'10px 130px 0 60px'}>
							<Typography variant="h6" fontWeight={700}>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.title' })}
							</Typography>
							<Typography fontWeight={400} fontSize={'14px'} color={'#72727E'}>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.subtitle' })}
							</Typography>
						</Box>
					</Grid>
				)}

				<Grid
					minHeight={'450px'}
					mt={isMobile ? 0 : '10px'}
					mb={'10px'}
					px={isMobile ? '15px' : '40px'}
					item
					xs={12}
					md={5}
					lg={6}
					xl={6}
				>
					<Stack justifyContent={'space-between'} width={'100%'} minHeight={'550px'}>
						{!businessDetailToEdit && (
							<Box pb={'20px'}>
								<SelectWithPlaceholder
									label={intl.formatMessage({ id: 'settings.company.choose_business_name' })}
									placeholder="ninguno"
									fullWidth
									value={businessNameCuit}
									onChange={(event) => setBusinessNameCuit(Number(event.target.value))}
									required
								>
									{businessNamesList?.map((item) => {
										return <MenuItem value={Number(item.cuit)}>{item.business_social + ' - ' + item.cuit}</MenuItem>;
									})}
								</SelectWithPlaceholder>
							</Box>
						)}
						<Box pb={'20px'}>
							<InputLabel>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.last_balance_sheets_input' })}
							</InputLabel>
							<NuqleaFileUpload value={lastBalancesDoc1} onChange={setLastBalancesDoc1} />
							{lastBalancesDoc1.length > 0 && showSecondBalance === false && (
								<Button onClick={() => setShowSecondBalance(true)}>Añadir archivo</Button>
							)}
						</Box>

						{showSecondBalance && (
							<Box pb={'20px'}>
								<NuqleaFileUpload
									value={lastBalancesDoc2}
									onChange={(files: File[]) => {
										setLastBalancesDoc2(files);
										files.length === 0 && setShowSecondBalance(false);
									}}
								/>
							</Box>
						)}

						<Box pb={'20px'}>
							<InputLabel>
								{intl.formatMessage({ id: 'settings.company.credit_profile_tab.sales_after_balance_input' })}
							</InputLabel>
							<NuqleaFileUpload value={balancePostSalesDoc} onChange={setBalancePostSalesDoc} />
						</Box>

						<Box pb={'20px'}>
							{toggleCommerciarReferences ? (
								<Box>
									<InputLabel>
										{intl.formatMessage({ id: 'settings.company.credit_profile_tab.commercial_reference' })}
									</InputLabel>
									<NuqleaFileUpload value={commercialReferencesDoc} onChange={setCommercialReferencesDoc} />
								</Box>
							) : (
								<TextFieldWithPlaceholder
									sx={{ width: '100%' }}
									label={intl.formatMessage({ id: 'settings.company.credit_profile_tab.commercial_reference' })}
									multiline
									value={commercialReferencesText}
									placeholder={intl.formatMessage({
										id: 'settings.company.credit_profile_tab.commercial_reference_placeholder',
									})}
									onChange={(event) => setCommercialReferencesText(event.target.value)}
								></TextFieldWithPlaceholder>
							)}
							<Button onClick={() => setToggleCommerciarReferences(!toggleCommerciarReferences)} variant="text">
								{toggleCommerciarReferences
									? intl.formatMessage({
											id: 'settings.company.credit_profile_tab.complete_as_text',
									  })
									: intl.formatMessage({
											id: 'settings.company.credit_profile_tab.attach_file',
									  })}
							</Button>
						</Box>

						<Box pb={'20px'}>
							{toggleMainSuppliers ? (
								<Box>
									<InputLabel>
										{intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_suppliers' })}
									</InputLabel>
									<NuqleaFileUpload value={mainSuppliersDoc} onChange={setMainSuppliersDoc} />
								</Box>
							) : (
								<TextFieldWithPlaceholder
									sx={{ width: '100%' }}
									label={intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_suppliers' })}
									multiline
									placeholder={intl.formatMessage({
										id: 'settings.company.credit_profile_tab.main_suppliers_placeholder',
									})}
									value={mainSuppliersText}
									onChange={(event) => setMainSuppliersText(event.target.value)}
								></TextFieldWithPlaceholder>
							)}
							<Button onClick={() => setToggleMainSuppliers(!toggleMainSuppliers)} variant="text">
								{toggleMainSuppliers
									? intl.formatMessage({
											id: 'settings.company.credit_profile_tab.complete_as_text',
									  })
									: intl.formatMessage({
											id: 'settings.company.credit_profile_tab.attach_file',
									  })}
							</Button>
						</Box>

						<Box pb={'20px'}>
							{toggleMainCustomers ? (
								<Box>
									<InputLabel>
										{intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_customers' })}
									</InputLabel>
									<NuqleaFileUpload value={mainCustomersDoc} onChange={setMainCustomersDoc} />
								</Box>
							) : (
								<TextFieldWithPlaceholder
									sx={{ width: '100%' }}
									label={intl.formatMessage({ id: 'settings.company.credit_profile_tab.main_customers' })}
									multiline
									placeholder={intl.formatMessage({
										id: 'settings.company.credit_profile_tab.main_customers_placeholder',
									})}
									value={mainCustomersText}
									onChange={(event) => setMainCustomersText(event.target.value)}
								></TextFieldWithPlaceholder>
							)}
							<Button onClick={() => setToggleMainCustomers(!toggleMainCustomers)} variant="text">
								{toggleMainCustomers
									? intl.formatMessage({
											id: 'settings.company.credit_profile_tab.complete_as_text',
									  })
									: intl.formatMessage({
											id: 'settings.company.credit_profile_tab.attach_file',
									  })}
							</Button>
						</Box>
					</Stack>
					{isSaving && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
				</Grid>
				<Grid item xs={isMobile ? 12 : 6}></Grid>
				<Grid item xl={6} lg={6} xs={12} height={'10%'}>
					<Button disabled={isSaving || !creditProfiles?.length} onClick={() => callback(false)} variant="outlined">
						{intl.formatMessage({ id: 'common.cancel' })}
					</Button>
					<Button disabled={isSaving} onClick={handleSave} variant="contained" sx={{ ml: '10px' }}>
						{intl.formatMessage({ id: 'common.save' })}
					</Button>

					<Box sx={{ my: '1rem' }}>
						<Typography>
							{intl.formatMessage({ id: 'settings.company.credit_profile.termns_conditions_link' })}
						</Typography>
						<Link href="#" onClick={() => setShowTermsModal(true)} underline="always" color="inherit">
							{intl.formatMessage({ id: 'buy_tokens.agreement_details.link_terms_and_conditions' })}
						</Link>
					</Box>
				</Grid>
			</Grid>
			<Snackbar
				open={!!errorPayload}
				onClose={() => setErrorPayload('')}
				autoHideDuration={8000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{errorPayload}
				</Alert>
			</Snackbar>
			<Snackbar
				open={showNoDataMessage}
				autoHideDuration={4000}
				onClose={onSnackBarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				message="No hay datos que guardar"
				ContentProps={{ sx: { display: 'flex', justifyContent: 'center' } }}
			/>
			<Modal open={showTermsModal} onKeyDown={(event) => event.key === 'Escape' && setShowTermsModal(false)}>
				<Fade in={showTermsModal}>
					<Box>
						<CreditProfileTerms onclick={() => setShowTermsModal(false)}></CreditProfileTerms>
					</Box>
				</Fade>
			</Modal>
		</Paper>
	);
};

export default CreditProfileEditionTab;
