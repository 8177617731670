import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Chip, InputLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { string } from 'is';

interface SelectMultiWithPlaceholderProps extends SelectProps {
	label: string;
	children: ReactNode;
	placeholder: string;
	maxItems?: number;
	onValueChangeCallback: (value: string[]) => void;
}

const SelectMultiWithPlaceholder: React.FC<SelectMultiWithPlaceholderProps> = ({
	label,
	children,
	placeholder,
	maxItems = 1000,
	onValueChangeCallback,
	...props
}) => {
	const [selectedValues, setSelectedValues] = useState<string[]>(props.value as string[]);

	const handleDropDownChange = (event: any) => {
		let value: string[] = event.target.value;
		value.length > maxItems && value.pop();
		value.includes('0') && value.shift();
		setSelectedValues(value);
	};

	const handleChipDelete = (valueToDelete: string) => {
		let newValues: string[] = [];
		setSelectedValues((prevSelected) => {
			newValues = prevSelected.filter((value) => value !== valueToDelete);
			newValues.length === 0 && newValues.push('0');
			return newValues;
		});
	};

	useEffect(() => {
		onValueChangeCallback(selectedValues);
	}, [selectedValues]);
	return (
		<Box>
			<InputLabel>{props.required ? label + '*' : label}</InputLabel>
			<Select
				{...props}
				sx={{
					...props.sx,
					color: placeholder && selectedValues.length === 1 ? '#81818181' : 'inherit',
					overflowY: 'visible',
					maxHeight: 'none',
				}}
				multiple
				MenuProps={{
					sx: {
						'&& .Mui-selected': {
							backgroundColor: '#eaeaea',
						},
						'&& .Mui-selected:hover': {
							backgroundColor: '#a5a5a5',
						},
					},
				}}
				onChange={handleDropDownChange}
				value={selectedValues}
				renderValue={(selected: any) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selected.map((value: string) =>
							value === '0' ? (
								<Typography key={value} sx={{ mt: '5px', color: '#81818181' }}>
									{placeholder}
								</Typography>
							) : (
								<Chip
									onDelete={(event) => {
										handleChipDelete(value);
									}}
									onMouseDown={(event) => {
										event.stopPropagation();
									}}
									key={value}
									label={value}
								/>
							)
						)}
					</Box>
				)}
			>
				<MenuItem value="" disabled>
					{placeholder}
				</MenuItem>
				{children}
			</Select>
		</Box>
	);
};

export default SelectMultiWithPlaceholder;
