import { useIntl } from 'react-intl';
import { StackedData, User } from '../../../types';
import { purchaseBehavior } from './main-settings';
import StackDataDisplay from '../../base-components/stack-data-display';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import EditButton from '../../nuqlea-components/edit-button';

interface purchaseTabProps {
	callback: (isEditionMode: boolean) => void;
	user: User;
	purchase: purchaseBehavior;
}

const PurchasesTab: React.FC<purchaseTabProps> = ({ callback, user, purchase }) => {
	const intl = useIntl();

	const data: StackedData[] = [
		{
			title: intl.formatMessage({ id: 'settings.company.purchases_tab.items_of_interest_input' }),
			description: purchase.activities?.replaceAll(',', '\n'),
		},
		{
			title: intl.formatMessage({ id: 'settings.company.purchases_tab.usual_provider_input' }),
			description: purchase.vendor_type,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.purchases_tab.usual_pay_mode_input' }),
			description: purchase.payment_type,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.purchases_tab.usual_gathering_input' }),
			description: purchase.stockpilling_type,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.purchases_tab.logistic_input' }),
			description: purchase.shipping_type,
		},
	];

	return (
		<GenericTabFrame
			title={intl.formatMessage({ id: 'settings.company.purchases_tab.title' })}
			subtitle={intl.formatMessage({ id: 'settings.company.purchases_tab.subtitle' })}
			buttons={[
				<EditButton
					text={intl.formatMessage({ id: 'settings.company.purchases_tab.edit' })}
					onClick={() => callback(true)}
				></EditButton>,
			]}
		>
			<StackDataDisplay minHeight={'31rem'} data={data}></StackDataDisplay>
		</GenericTabFrame>
	);
};

export default PurchasesTab;
