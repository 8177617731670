import { IntlProvider } from 'react-intl';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import messages from '../i18n';
import Language from '../i18n/language';
import NavBar from './navbar';
import { CollaboratorRole, CollaboratorStatus, Role, Theme, User } from '../types';
import ReferencePrices from './reference-prices/reference-prices';
import Quotations from './quotations/quotations';
import MainSettings from './settings/organization/main-settings';
import UserMainSettings from './settings/my-account/main-settings';
import Projects from './projects/projects';
import SignUp from './auth/sign-up';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import useStorage from '../hooks/use-storage';
import { ThemeProvider } from '@emotion/react';
import themes from '../themes';
import { CssBaseline, debounce } from '@mui/material';
import Details from './details/details';
import useLanguage from '../hooks/use-language';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuthorization from '../hooks/use-authorization';
import DetailsSeller from './details/details-seller';
import { useAuth0 } from '@auth0/auth0-react';
import PaymentCalendar from './payment-calendar';
import FullScreenLoading from './full-screen-loading';
import { Wrapper } from '@googlemaps/react-wrapper';
import Maintenance from './maintenance';
import client from '../clients/client';
import Collaborators from './collaborators';
import Validation, { ValidationStatus } from './validation';
import BuyTokens from './my-tokens/buy-tokens/buy-tokens';
import QuantitySelectStep from './my-tokens/buy-tokens/quantity-select-step';
import ConfirmationStep from './my-tokens/buy-tokens/confirmation-step';
import ConfirmedStep from './my-tokens/buy-tokens/confirmed-step';
import TokenDetailView from './my-tokens/detail/DetailStepper';
import TokenDetailViewConstructor from './my-tokens/detail/DetailStepperConstructor';
import TokensSeller from './my-tokens/dashboard/tokens-seller';
import Redeemview from './my-tokens/redeem-view';
import useFirebase from '../hooks/use-firebase';
import Help from './help/help';
import Plan from './plan/plan';
import NotificationsInbox from './notifications/notifications-inbox';
import Home from '../pages/home/Home';
import NewQuotationsEmptyState from './new-quotation/new-quotations-empty-state';
import MyTokensEmptyState from './my-tokens/my-tokens-empty-state';
import ProjectDetail from './projects/detail/projectDetail';
import Hotjar from '@hotjar/browser';
import RedeemTokensView from './my-tokens/dashboard/RedeemTokensView';
import Audiences from './audiences/Audiences';
import OneTimeDialog from './ont-time-dialog';
import { useRecoilValue } from 'recoil';
import notificationState from '../atoms/one-time-notification-state';
import disableRootElementScrollState from '../atoms/disable-root-element-scroll-state';
import useOneTimeNotification from '../hooks/use-one-time-notifications';
import useAgreements from '../hooks/use-agreements';
import NewQuotationConfirmed from './new-quotation/new-quotation-confirmed';

const siteId = 2846021;
const hotjarVersion = 6;

const Application = () => {
	const baseTheme = useTheme();
	const matchesXs = useMediaQuery(baseTheme.breakpoints.down('md'));
	const matchesXl = useMediaQuery(baseTheme.breakpoints.up('xl'));

	const { user: auth0user, isLoading, error, loginWithRedirect, isAuthenticated, logout } = useAuth0();
	const [user, setUser] = useAuthorization(!isLoading);
	const [maintenance, setMaintenance] = useState(false);
	const [language, setLanguage] = useLanguage(() => Language.ES_AR);
	const [theme, setTheme] = useStorage('theme', () => Theme.LIGHT);
	const [remoteConfig] = useFirebase();
	useOneTimeNotification();
	useAgreements();
	const notifications = useRecoilValue(notificationState);
	const disableRootElementScroll = useRecoilValue(disableRootElementScrollState);		

	const fetchData = debounce(() => {
		client
			.checkVersion({ fields: { user } })
			.then((data) => {
				if ('version' in user && user?.version !== data?.version) {
					Object.keys(localStorage).forEach((key) => {
						localStorage.removeItem(key);
					});
					localStorage.clear();
					logout({ logoutParams: { returnTo: window.location.origin } });
				} else if (data.maintenance_mode) {
					setTimeout(() => {
						setMaintenance(true);
					}, 350);
				}
			})
			.catch((error) => console.log({ error }));
	}, 250);

	useEffect(() => {
		setMaintenance(remoteConfig?.MANTENIMIENTO);
	}, [remoteConfig]);
	

	useEffect(() => {
		if (Object.keys(user).length !== 0) {
			fetchData();
			if ('email' in user) {
				if(!user.email.split('@')[1].startsWith('nuqlea.com')) {
					Hotjar.init(siteId, hotjarVersion);
					Hotjar.identify(String(user.id), {
						name: user.name,
						email: user.email,
						company: user.name_external,
						id_external: user.id_external,
						permissions: user.admin ? 'admin' : 'regular',
						type_external: user.type_external,
					});
				}
			}
			
			(window as any)?.$crisp?.push(['config', 'container:index', [2]]);
			if ('email' in user) {
				(window as any)?.$crisp?.push(['set', 'user:nickname', [user.name]]);
				(window as any)?.$crisp?.push(['set', 'user:email', [user.email]]);
				(window as any)?.$crisp?.push([
					'set',
					'user:company',
					[
						user.name_external,
						{
							id_external: user.id_external,
							name_external: user.name_external,
							type_external: user.type_external,
						},
					],
				]);
			}

			if('status' in user && user.status === CollaboratorStatus.CONFIRMED) {
				// @ts-ignore
				window.LOU.identify(user.id, {
					name: 'name' in user && user.name,
					email: 'email' in user && user.email,
					company: 'name_external' in user && user.name_external,
					id_external: 'id_external' in user && user.id_external,
					permissions: 'admin' in user && user.admin ? 'admin' : 'regular',
					type_external: 'type_external' in user &&user.type_external,
				});
			}
			
		}
	}, [user]);

	useEffect(() => {
		if (disableRootElementScroll) {
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.documentElement.style.overflow = 'auto';
		}
	}, [disableRootElementScroll])
	

	const type = 'type_external' in user && user?.type_external;

	const routes: any = [];

	if (user && type === Role.CONSTRUCTOR) {
		routes.push(
			// {
			// 	path: '/payment-calendar',
			// 	element: <PaymentCalendar />,
			// },
			{
				path: '/',
				element: <Navigate replace to="/home" />,
			},
			{
				path: '/home',
				element: <Home />,
			},
			{
				path: '/quotations',
				element: <Quotations />,
			},
			{
				path: '/list/:listId',
				element: <Details />,
			},
			{
				path: '/list/:listId/:orderId',
				element: <Details />,
			},
			{
				path: '/new-quotation',
				element: <NewQuotationsEmptyState checkPageVisualization />,
			},
			{
				path: '/new-quotation-confirmed',
				element: <NewQuotationConfirmed />,
			},
			{
				path: '/new-quotation-empty-state',
				element: <NewQuotationsEmptyState checkPageVisualization={false} />,
			},
			{
				path: '/notifications',
				element: <NotificationsInbox />,
			},
			{
				path: '/profile',
				element: <MainSettings />,
			},
			{
				path: '/my-account',
				element: <UserMainSettings />,
			},
			{
				path: '/help',
				element: <Help />,
			},
			{
				path: '/plan',
				element: <Plan />,
			}
		);

		if (
			'email' in user &&
			(remoteConfig?.PROYECTOS?.allowed_users?.includes(user?.email) ||
			remoteConfig?.PROYECTOS?.allowed_users?.includes('*'))
		) {
			routes.push(
				{
					path: '/projects',
					element: <Projects />,
				},
				{
					path: '/projects/new',
					element: <ProjectDetail />,
				},
				{
					path: '/projects/edit/:id',
					element: <ProjectDetail />,
				}
			);
		}

		if (
			'email' in user &&
			(remoteConfig?.PRECIOS?.allowed_users?.includes(user?.email) ||
			remoteConfig?.PRECIOS?.allowed_users?.includes('*'))
		) {
			routes.push({
				path: '/reference-prices',
				element: <ReferencePrices />,
			});
		}

		if (
			'email' in user &&
			(remoteConfig?.ACOPIO?.allowed_users?.includes(user?.email) ||
			remoteConfig?.ACOPIO?.allowed_users?.includes('*'))
		) {
			routes.push(
				{
					path: '/my-tokens',
					element: <MyTokensEmptyState checkPageVisualization />,
				},
				{
					path: '/empty-state-my-tokens',
					element: <MyTokensEmptyState checkPageVisualization={false} />,
				},
				{
					path: '/my-tokens/:id',
					element: <TokenDetailViewConstructor />,
				},
				{
					path: '/my-tokens/buy',
					element: <BuyTokens />,
					children: [
						{
							path: '/my-tokens/buy/quantity-select',
							element: <QuantitySelectStep />,
						},
						{
							path: '/my-tokens/buy/confirmation-step',
							element: <ConfirmationStep />,
						},
						{
							path: '/my-tokens/buy/confirmed-step',
							element: <ConfirmedStep />,
						},
					],
				},
				{
					path: '/my-tokens/new-redeem',
					element: <RedeemTokensView />,
				},
				{
					path: '/my-tokens/redeem-request/:id',
					element: <Redeemview />,
				}
			);
		}
	} else if (user && type === Role.SELLER) {
		routes.push(
			{
				path: '/',
				element: <Navigate replace to="/quotations"></Navigate>,
			},
			{
				path: '/quotations',
				element: <Quotations />,
			},
			{
				path: '/list/:listId',
				element: <TokenDetailView />,
			},
			{
				path: '/list/:listId/:quoteId',
				element: <DetailsSeller />,
			},
			{
				path: '/notifications',
				element: <NotificationsInbox />,
			}
		);

		if ('email' in user && remoteConfig?.ACOPIO?.allowed_users?.includes(user?.email)) {
			routes.push(
				{
					path: '/my-tokens',
					element: <TokensSeller />,
				},
				{
					path: '/my-tokens/:id',
					element: <TokenDetailView />,
				},
				{
					path: '/my-tokens/redeem/:id',
					element: <RedeemTokensView />,
				},
				{
					path: '/my-tokens/redeem-request/:id',
					element: <Redeemview />,
				}
			);
		}

		if ('email' in user && remoteConfig?.AUDIENCIAS?.allowed_users?.includes(user?.email)) {
			routes.push(
				{
					path: '/audiences',
					element: <Audiences />,
				});
		}
	}

	if ('role' in user && user.role !== CollaboratorRole.USER) {
		routes.push({
			path: '/collaborators',
			element: <Collaborators />,
		});
	}

	const goToLogin = () => {
		// Force clear browser storage before login
		Object.keys(localStorage).map((key) => {
			localStorage.removeItem(key);
		});
		localStorage.clear();

		loginWithRedirect();
		return <FullScreenLoading />;
	};

	const isUnprotectedRoute = (pathname: string) => {
		return ['/register'].includes(pathname);
	};

	const getRouteUnprotectedPage = (pathname: string) => {
		let routeContent = null;

		if (pathname === '/register') {
			routeContent = <Route key="/register" path="/register" element={<SignUp />} />;
		}

		return routeContent ? (
			<ThemeProvider theme={themes[theme]}>
				<IntlProvider locale={language} messages={messages[language]}>
					<CssBaseline enableColorScheme>
						<BrowserRouter>
							<Routes>{routeContent}</Routes>
						</BrowserRouter>
					</CssBaseline>
				</IntlProvider>
			</ThemeProvider>
		) : (
			<></>
		);
	};
	
	return (
		<ThemeProvider theme={themes[theme]}>
			<IntlProvider locale={language} messages={messages[language]}>
				<Wrapper apiKey="AIzaSyB3YOWVgUXRyCpTbcfvmuPz4LSOqgt6Sps" libraries={['places']} language={language}>
					<CssBaseline enableColorScheme>
						{isAuthenticated && !maintenance ? (
							<BrowserRouter>
								<Box sx={{ display: 'flex' }}>
									{'status' in user ? (
										<>
											{notifications.length > 0 && <OneTimeDialog />}
											{auth0user?.email_verified && CollaboratorStatus.CONFIRMED === user.status && (
												<NavBar matchesXs={matchesXs} matchesXl={matchesXl} remoteConfig={remoteConfig} />
											)}
											<Routes>
												{auth0user?.email_verified &&
													CollaboratorStatus.CONFIRMED === user.status &&
													routes.map((route: any) => {
														const { path, element, children } = route;
														if (!children) {
															return <Route key={path} {...route} />;
														} else {
															return (
																<Route key={path} path={path} element={element}>
																	{children.map((child: any) => (
																		<Route key={child.path} path={child.path} element={child.element} />
																	))}
																</Route>
															);
														}
													})}
												{auth0user?.email_verified && CollaboratorStatus.DELETED === user.status && (
													<>
														<Route path={'/'} element={<Navigate replace to='/deleted-user' />} />
														<Route key={'/deleted-user'} path={'/deleted-user'} element={<Validation status={ValidationStatus.DELETED} />} />
													</>
												)}
												{auth0user?.email_verified && CollaboratorStatus.PENDING === user.status && (
													<>
														<Route path={'/'} element={<Navigate replace to='/pending-user-approval' />} />
														<Route
															key={'/pending-user-approval'}
															path={'/pending-user-approval'}
															element={<Validation status={ValidationStatus.PENDING_APPROVAL} />}
														/>
													</>
												)}
												{!auth0user?.email_verified && (
													<>
														<Route path={'/'} element={<Navigate replace to='/pending-email-validation' />} />
														<Route
															key={'/pending-email-validation'}
															path={'/pending-email-validation'}
															element={
																<Validation status={ValidationStatus.PENDING_VALIDATION} userId={auth0user?.sub} />
															}
														/>
													</>
												)}
											</Routes>
										</>
									) : (
										<FullScreenLoading />
									)}
								</Box>
							</BrowserRouter>
						) : isUnprotectedRoute(window.location.pathname) ? (
							getRouteUnprotectedPage(window.location.pathname)
						) : (
							<>
								{isLoading && <FullScreenLoading />}
								{maintenance && <Maintenance />}
								{!isLoading && !maintenance && goToLogin()}
							</>
						)}
					</CssBaseline>
				</Wrapper>
			</IntlProvider>
		</ThemeProvider>
	);
};

export default Application;
