import { Box, BoxProps, Paper, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { ReactNode } from 'react';

interface tabContainerProps extends BoxProps {
	children: ReactNode;
	buttons?: ReactNode[];
	title?: string;
	subtitle?: string;
	variant?: 'card' | 'edition';
}

const GenericTabFrame: React.FC<tabContainerProps> = ({ children, buttons, title, subtitle, variant, ...props }) => {
	const intl = useIntl();
	return (
		<Paper elevation={0} className="h-4/5">
			<Box
				display="grid"
				gridTemplateColumns={{ xs: '1fr', md: '35% 58%', lg: '35% 58%' }}
				gap={{ xs: '1rem', sm: '0' }}
				padding={{
					xs: variant === 'card' ? '0 0.5rem 0.5rem 0.5rem' : '0.5rem',
					sm: variant === 'card' ? '0 1.5rem 1.5rem 1.5rem' : '2rem 2.5rem 2rem 5rem',
				}}
				width={'100%'}
				justifyContent={'space-between'}
				{...props}
			>
				<Box className={'pb-4'}>
					<Box>
						<Typography color={'text.primary'} fontWeight={700} fontSize={'16px'}>
							{title}
						</Typography>
						<Typography variant="body2">{subtitle}</Typography>
					</Box>
				</Box>
				<Box sx={{ gridRowEnd: { xs: 'auto', md: 'span 2', lg: 'span 2' }, boxSizing: 'border-box' }}>{children}</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'end',
						gridColumn: variant === 'edition' ? { xs: 'auto', md: 2, lg: 2 } : 'auto',
						gridRow: variant === 'edition' ? { xs: 'auto', md: 3, lg: 3 } : 'auto',
					}}
				>
					<Box className="pt-8 pr-[2rem] pb-0">
						<Stack direction={'row'} spacing={3}>
							{buttons}
						</Stack>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
};

export default GenericTabFrame;
