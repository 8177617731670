import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { Alert, Typography, styled } from '@mui/material';
import client from '../../../clients/client';
import { Action, User } from '../../../types';
import FullScreenLoading from '../../full-screen-loading';

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	backgroundColor: theme.palette.background.default,
	padding: '10px',
	borderRadius: '10px',
	color: theme.palette.text.secondary,
	margin: '10px 0',
}));

const SeparatedTextField = styled(TextField)`
	margin-top: 15px;
`;

interface Props {
	open: boolean;
	quotationId: string;
	listId: string;
	environment: string;
	outdated: boolean;
	user: User;
	onClose: (event?: object | null, reason?: string) => void;
}

const ReQuotationDialog = (props: Props) => {
	const intl = useIntl();
	const { open, quotationId, listId, environment, outdated, user, onClose } = props;

	const [comments, setComments] = useState<string>();
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();

	const handleSubmit = () => {
		setLoading(true);
		setError(undefined);

		client
			.executeAction({ listId, action: Action.RE_QUOTE, environment, outdated, comments, user })
			.then((response) => onClose(null, 'success'))
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			{loading && <FullScreenLoading />}
			<DialogTitle>
				<Typography variant="h5" textAlign="center">
					{intl.formatMessage({ id: 'list.details.re_quotation_dialog.title' })}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1" textAlign="center">
					{intl.formatMessage({ id: 'list.details.re_quotation_dialog.subtitle' }, { quotationId })}
				</Typography>
				<StyledAlert severity="info">
					{intl.formatMessage({ id: 'list.details.re_quotation_dialog.details' })}
				</StyledAlert>
				{error && <Alert severity="error">{intl.formatMessage({ id: 'common.loading_error_message.details' })}</Alert>}
				<SeparatedTextField
					label={intl.formatMessage({ id: 'list.details.re_quotation_dialog.comments_field_name' })}
					fullWidth
					multiline
					rows={4}
					placeholder={intl.formatMessage({ id: 'list.details.re_quotation_dialog.comments_field_placeholder' })}
					value={comments}
					onChange={(event) => setComments(event.target.value)}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1, mb:1 }}>
				<Button
					onClick={() => onClose(null, 'cancelButtonClick')}
					variant="contained"
					sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button onClick={handleSubmit} sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }} variant="contained">
					{intl.formatMessage({ id: 'common.send' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReQuotationDialog;
