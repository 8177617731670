import { Box, Grid, Skeleton, Tab, ThemeProvider, Typography } from '@mui/material';
import PageContainer from '../../page-container';
import { useIntl } from 'react-intl';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import InformationBar from '../../information-bar';
import { useState } from 'react';
import UserInfoTab from './user-info-tab';
import UserInfoEditionTab from './user-info-edition-tab';
import UserSecurityEditionTab from './user-security-edition-tab';
import userState from '../../../atoms/user-state';
import { useRecoilState } from 'recoil';

const MainSettings: React.FC = () => {
	const [user, setUser] = useRecoilState(userState);
	const [value, setValue] = useState('1');
	const [generalEditMode, setGeneralEditMode] = useState(false);
	const [securityDataLoading, setSecurityDataLoading] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const intl = useIntl();

	const menuTabs = [
		{ value: '1', label: intl.formatMessage({ id: 'settings.company.general_tab' }) },
		{ value: '2', label: intl.formatMessage({ id: 'settings.my-account.security_tab' }) },
	];

	const toggleUserInfoEdit = async (isEditionMode: boolean) => {
		setShowError(false);
		setGeneralEditMode(isEditionMode);
	};

	const showRequiredError = (show: boolean, message?: string) => {
		message ? setErrorMessage(message) : setErrorMessage(intl.formatMessage({ id: 'common.error_required_fields' }));
		setShowError(show);
	};

	const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
		setShowError(false);
		setValue(newValue);
	};

	const tabsContent = [
		{
			value: '1',
			content: !generalEditMode ? (
				<UserInfoTab user={user} callback={toggleUserInfoEdit}></UserInfoTab>
			) : (
				<UserInfoEditionTab
					showRequiredError={showRequiredError}
					callback={toggleUserInfoEdit}
					updateUserData={(userData: any): void => {
						setUser({ ...user, ...userData });
					}}
					user={user}
				></UserInfoEditionTab>
			),
		},
		{
			value: '2',
			content: <UserSecurityEditionTab user={user} showRequiredError={showRequiredError}></UserSecurityEditionTab>,
			isLoading: securityDataLoading,
		},
	];

	return (
		<PageContainer>
			<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h3" fontSize={'32px'} fontWeight={700} lineHeight={1.2} mb={'25px'}>
							{intl.formatMessage({ id: 'common.my_account' })}
						</Typography>
					</Grid>
				</Grid>

				<Grid container sx={{ mt: '10px', flex: 1 }}>
					<Grid item xs={12}>
						<Box sx={{ width: '100%', height: '100%' }}>
							<TabContext value={value}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleChange} indicatorColor="primary" textColor="primary">
										{menuTabs.map((item) => (
											<Tab
												sx={{ fontSize: '16px', mr: '35px' }}
												key={'menu' + item.value}
												value={item.value}
												label={item.label}
											></Tab>
										))}
									</TabList>
								</Box>
								{showError && (
									<InformationBar
										sx={{ mt: '15px' }}
										icon="error"
										message={errorMessage}
										color="#FF3D00"
									></InformationBar>
								)}

								{tabsContent.map((item) => (
									<TabPanel key={item.value} value={item.value} sx={{ padding: '36px 0 0 0 ', height: '100%' }}>
										{item.isLoading ? (
											<Skeleton sx={{ mt: '10px' }} variant="rectangular" height={'50vh'} animation="wave" />
										) : (
											item.content
										)}
									</TabPanel>
								))}
							</TabContext>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</PageContainer>
	);
};

export default MainSettings;
