import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
    children: React.ReactNode | string,
    disabled?: boolean,
    path: string,
    state?: object
}

const RouterLinkWithDisabled = (props: Props) => {
    const { children, disabled, path } = props    

    if (disabled) {
        return <span>{children}</span>
    } else {
        return (
            <Link
                to={path}
                style={{ textDecoration: 'none' }}
            >
                {children}
            </Link>
        )
    }
}

export default RouterLinkWithDisabled