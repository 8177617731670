import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	open: boolean;
	conditions: any;
	onClose: (event?: object | null, reason?: string) => void;
}

const QuotingConditions = (props: Props) => {
	const intl = useIntl();
	const { open, conditions, onClose } = props;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" sx={{ '& .MuiPaper-root': { maxWidth: '472px', borderRadius: 3, pb:2 } }}>
			<DialogTitle>
				<Box sx={{ display:'flex', justifyContent:'space-between' }}>
					<Typography variant="h5" textAlign="start">
						{intl.formatMessage({ id: 'list.details.quotation_conditions_dialog.title' })}
					</Typography>

					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ mt: 1, display:'flex', flexDirection: 'column', gap:2 }}>
				<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Grid item xs={12} md={5}>
						<Typography variant="subtitle1">
							{intl.formatMessage({ id: 'list.details.quotation_conditions_dialog.shipping' })}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} sx={{ textAlign: 'start' }}>
						<Typography variant="subtitle1" color="#777">
							{conditions?.shipping_request === null? '-': conditions?.shipping_request ? 'Sí' : 'No'}
						</Typography>
					</Grid>
				</Grid>
				
				<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Grid item xs={12} md={5}>
						<Typography variant="subtitle1">
							{intl.formatMessage({ id: 'list.details.quotation_conditions_dialog.payment' })}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} sx={{ textAlign: 'start' }}>
						<Typography variant="subtitle1" color="#777">
							{conditions?.payment ? conditions?.payment.replaceAll('echeq ', '').replaceAll(',', ', ') : 'No especificado'}
						</Typography>
					</Grid>
				</Grid>

				<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Grid item xs={12} md={5}>
						<Typography variant="subtitle1">
							{intl.formatMessage({ id: 'list.details.quotation_conditions_dialog.availability' })}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} sx={{ textAlign: 'start' }}>
						<Typography variant="subtitle1" color="#777">
							{conditions?.availability ?? 'Entrega total'}
						</Typography>
					</Grid>
				</Grid>

				<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Grid item xs={12} md={5}>
						<Typography variant="subtitle1">
							{intl.formatMessage({ id: 'list.details.quotation_conditions_dialog.comments' })}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} sx={{ textAlign: 'start' }}>
						<Typography variant="subtitle1" color="#777">
							{conditions?.quote_comment ?? '-'}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default QuotingConditions;
