import { useEffect, useState } from 'react';

interface Props extends google.maps.MarkerOptions {
	onDragend?: (event: google.maps.MapMouseEvent) => void;
	onClick?: (event: google.maps.MapMouseEvent) => void;
	url?: string;
}

const MapMarker = (props: Props) => {
	const { onDragend, onClick } = props;

	const [marker, setMarker] = useState<google.maps.Marker>();

	useEffect(() => {
		if (!marker) {
			setMarker(new google.maps.Marker());
		}

		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);

	useEffect(() => {
		if (marker) {
			marker.setOptions(props);

			google.maps.event.clearListeners(marker, 'dragend');

			if (onDragend) {
				marker.addListener('dragend', onDragend);
			}

			if(onClick) {
				marker.addListener('click', onClick);
			}
		}
	}, [marker, props]);

	return null;
};

export default MapMarker;
