import {
	Grid,
	Typography,
	Box,
	InputLabel,
	Button,
	Stack,
	LinearProgress,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Paper,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { useMobile } from '../hooks/use-mobile';
import TextFieldWithPlaceholder from './text-field-with-placeholder';
import LocationPicker from './location-picker';
import { ConstructionAddress, ConstructionContact, Location } from '../types';

interface addressesEditionTabProps {
	columnLayout?: boolean;
	showRequiredError?: (show: boolean) => void;
	addressToEdit?: ConstructionAddress;
	noContact?: boolean;
	retrieveAddress: (address: ConstructionAddress) => void;
	onCancel: () => void;
}

interface splittedAddress {
	street: string;
	city: string;
	province: string;
	country: string;
	placeUrl: string;
	latitude: number;
	longitude: number;
	gpId: string;
}

const AddressForm: React.FC<addressesEditionTabProps> = ({
	columnLayout,
	showRequiredError,
	addressToEdit,
	noContact,
	retrieveAddress,
	onCancel,
}) => {
	const intl = useIntl();
	const isMobile = useMobile();
	const [currentSplittedAddress, setCurrentSplittedAddress] = useState<splittedAddress>();
	const [referenceName, setReferenceName] = useState(addressToEdit?.alias ?? '');
	const [address, setAddress] = useState(addressToEdit?.address ?? '');
	const [city, setCity] = useState(addressToEdit?.city ?? '');
	const [province, setProvince] = useState(addressToEdit?.province ?? '');
	const [country, setCountry] = useState(addressToEdit?.country ?? '');
	const [availability, setAvailability] = useState(addressToEdit?.availability ?? '');
	const [gathering, setGathering] = useState(addressToEdit?.gathering ?? false);
	const [comments, setComments] = useState(addressToEdit?.comments ?? '');
	const [contacts, setContacts] = useState<ConstructionContact[]>(
		addressToEdit?.contacts ?? [{ id: '1', name: '', phone: '', addressId: addressToEdit?.id ?? '' }]
	);
	const [isSaving, setIsSaving] = useState(false);
	const [firstSubmit, setFirstSubmit] = useState(false);
	const currentLocation: Location | undefined = addressToEdit
		? {
				address: addressToEdit.address,
				coordinates: { lat: addressToEdit.latitude, lng: addressToEdit.longitude },
				google_place_id: addressToEdit.googlePlaceId,
		  }
		: undefined;
	const [location, setLocation] = useState<Location | undefined>(currentLocation);
	const [hasNewLocation, setHasNewLocation] = useState(false);
	const [showColumnLayout, setShowColumnLayout] = useState(columnLayout);

	const handleSave = async () => {
		setFirstSubmit(true);
		let items = document.querySelectorAll('.MuiInputBase-input');
		let arrayItems = Array.from(items);
		await new Promise((resolve) => setTimeout(resolve, 100));
		const anyInvalid = arrayItems?.some((item) => item.ariaInvalid === 'true');

		if (anyInvalid) {
			showRequiredError && showRequiredError(true);
		} else {
			showRequiredError && showRequiredError(false);
			addressToEdit ? updateAddress() : createAddress();
		}
	};

	const addContact = () => {
		const newId = String(contacts.length + 1);

		setContacts((prev) => {
			return [...prev, { id: newId, name: '', phone: '', addressId: addressToEdit?.id ?? '' }];
		});
	};

	const removeContact = () => {
		const updatedContacts = contacts.slice(0, -1);
		setContacts(updatedContacts);
	};

	const onMapChange = (location: Location | undefined) => {
		setLocation(location);
		let addressResult = location && splitLocation(location);
		setAddress(addressResult?.street ?? '');
		setCity(addressResult?.city ?? '');
		setProvince(addressResult?.province ?? '');
		setCountry(addressResult?.country ?? '');
		setCurrentSplittedAddress(addressResult);
	};

	const updateContacts = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let val = event.target.value;
		let data = event.target.id.split('-');
		let attribute = data[0].includes('Name') ? 'name' : 'phone';
		setContacts((prevContacts) =>
			prevContacts.map((contact) => (contact.id === data[1] ? { ...contact, [attribute]: val } : contact))
		);
	};

	const splitLocation = (addressData: Location): splittedAddress => {
		const addressComponents = addressData.full_response.address_components;
		let streetName =
			addressComponents.find(
				(comp: any) =>
					comp.types.includes('route') || comp.types.includes('establishment') || comp.types.includes('plus_code')
			).short_name ?? '';

		let streetNumber = addressComponents.find((comp: any) => comp.types.includes('street_number'))?.short_name ?? '';
		let sublocality = addressComponents.find((comp: any) => comp.types.includes('sublocality'))?.short_name;

		const newSplitted: splittedAddress = {
			street: streetName + ' ' + streetNumber,
			city: sublocality
				? sublocality
				: addressComponents.find((comp: any) => comp.types.includes('administrative_area_level_2')).short_name ?? '',
			province:
				addressComponents.find((comp: any) => comp.types.includes('administrative_area_level_1')).short_name ?? '',
			country: addressComponents.find((comp: any) => comp.types.includes('country')).long_name ?? '',
			placeUrl: `https://google.com/maps/?q=${addressData?.coordinates?.lat},${addressData?.coordinates?.lng}`,
			latitude: addressData.coordinates?.lat ?? 0,
			longitude: addressData.coordinates?.lng ?? 0,
			gpId: addressData.google_place_id ?? '',
		};

		return newSplitted;
	};

	const createAddress = () => {
		if (currentSplittedAddress) {
			const newAddress: ConstructionAddress = {
				id: addressToEdit?.id ?? '',
				organizationId: addressToEdit?.organizationId ?? '',
				alias: referenceName,
				address: currentSplittedAddress?.street,
				city: currentSplittedAddress.city,
				province: currentSplittedAddress.province,
				country: currentSplittedAddress.country,
				placeUrl: currentSplittedAddress.placeUrl,
				latitude: currentSplittedAddress.latitude,
				longitude: currentSplittedAddress.longitude,
				googlePlaceId: currentSplittedAddress.gpId,
				availability: availability,
				gathering: gathering,
				comments: comments,
				contacts: contacts,
			};
			retrieveAddress(newAddress);
		}
	};

	const updateAddress = () => {
		if (addressToEdit) {
			const updatedAddress: ConstructionAddress = {
				id: addressToEdit.id,
				organizationId: addressToEdit.organizationId,
				alias: referenceName,
				address: currentSplittedAddress?.street ?? addressToEdit.address,
				city: currentSplittedAddress?.city ?? addressToEdit.city,
				province: currentSplittedAddress?.province ?? addressToEdit.province,
				country: currentSplittedAddress?.country ?? addressToEdit.province,
				placeUrl: currentSplittedAddress?.placeUrl ?? addressToEdit.placeUrl,
				latitude: currentSplittedAddress?.latitude ?? addressToEdit.latitude,
				longitude: currentSplittedAddress?.longitude ?? addressToEdit.longitude,
				googlePlaceId: currentSplittedAddress?.gpId ?? addressToEdit.googlePlaceId,
				availability: availability,
				gathering: gathering,
				comments: comments,
				contacts: contacts,
			};
			retrieveAddress(updatedAddress);
		}
	};

	useEffect(() => {
		isMobile && setShowColumnLayout(true);
	});

	return (
		<Paper
			elevation={0}
			sx={{
				width: '100%',
				pt: '16px',
				minHeight: isMobile ? null : '100%',
				//maxHeight: isMobile ? null : 1000,
			}}
		>
			<Grid padding={'0 15px 15px 15px'} minHeight={'100%'} display={'flex'} container spacing={2}>
				<Grid item xs={12}>
					<Stack justifyContent={'space-between'} width={'100%'} minHeight={showColumnLayout ? '530px' : '430px'}>
						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.addresses_tab.reference_name' })}
							placeholder={intl.formatMessage({ id: 'settings.company.addresses_tab.reference_name_placeholder' })}
							value={referenceName}
							sx={{ width: '100%' }}
							required
							error={firstSubmit && referenceName === '' ? true : false}
							onChange={(event) => setReferenceName(event.target.value)}
						></TextFieldWithPlaceholder>
						<Box>
							<InputLabel variant="standard">
								{intl.formatMessage({ id: 'settings.company.addresses_tab.street_and_number_input' }) + '*'}
							</InputLabel>
							<LocationPicker variant="compact" country="AR" value={location} onChange={onMapChange} disabled />
						</Box>
						<Box>
							<Grid spacing={showColumnLayout ? 1 : 3} flexDirection={showColumnLayout ? 'column' : 'row'} container>
								<Grid item xs={showColumnLayout ? 12 : 6}>
									<TextFieldWithPlaceholder
										label={intl.formatMessage({ id: 'settings.company.addresses_tab.city_input' })}
										placeholder={intl.formatMessage({ id: 'common.fill' })}
										value={city}
										sx={{ width: '100%' }}
										required
										error={firstSubmit && city === '' ? true : false}
										onChange={(event) => setCity(event.target.value)}
										disabled
									></TextFieldWithPlaceholder>
								</Grid>
								<Grid item xs={showColumnLayout ? 12 : 6}>
									<TextFieldWithPlaceholder
										label={intl.formatMessage({ id: 'settings.company.addresses_tab.province_input' })}
										placeholder={intl.formatMessage({ id: 'common.fill' })}
										value={province}
										sx={{ width: '100%' }}
										required
										error={firstSubmit && province === '' ? true : false}
										onChange={(event) => setProvince(event.target.value)}
										disabled
									></TextFieldWithPlaceholder>
								</Grid>
							</Grid>
						</Box>

						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.addresses_tab.availability_input' })}
							placeholder={intl.formatMessage({
								id: 'settings.company.addresses_tab.availability_input_placeholder',
							})}
							value={availability}
							sx={{ width: '100%' }}
							required
							error={firstSubmit && availability === '' ? true : false}
							onChange={(event) => setAvailability(event.target.value)}
						></TextFieldWithPlaceholder>

						<Box>
							<FormControl>
								<FormLabel id="demo-controlled-radio-buttons-group">
									{intl.formatMessage({ id: 'settings.company.addresses_tab.gathering_input' })}
								</FormLabel>
								<RadioGroup
									aria-labelledby="demo-controlled-radio-buttons-group"
									name="controlled-radio-buttons-group"
									value={gathering}
									onChange={(event) => setGathering(event.target.value === 'true' ? true : false)}
									row
								>
									<FormControlLabel
										value={'true'}
										control={<Radio />}
										label={intl.formatMessage({ id: 'common.yes' })}
									/>
									<FormControlLabel
										value={'false'}
										control={<Radio sx={{ ml: '50px' }} />}
										label={intl.formatMessage({ id: 'common.no' })}
									/>
								</RadioGroup>
							</FormControl>
						</Box>

						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.addresses_tab.comments_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.addresses_tab.comments_input_placeholder' })}
							value={comments}
							sx={{ width: '100%' }}
							onChange={(event) => setComments(event.target.value)}
							multiline
							rows={2}
						></TextFieldWithPlaceholder>
					</Stack>
					{isSaving && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
				</Grid>

				{noContact ?? (
					<Grid item xs={12} mb={'5x'}>
						<Box>
							<Typography sx={{ pb: '30px' }} variant="h6" fontWeight={700}>
								{intl.formatMessage({ id: 'settings.company.addresses_tab.contact_subtitle' })}
							</Typography>
							{contacts &&
								contacts.map((contact) => {
									return (
										<Grid
											spacing={showColumnLayout ? 1 : 3}
											flexDirection={showColumnLayout ? 'column' : 'row'}
											container
										>
											<Grid item xs={showColumnLayout ? 12 : 6}>
												<TextFieldWithPlaceholder
													id={'contactName-' + contact.id}
													label={intl.formatMessage({ id: 'settings.company.addresses_tab.contact_name' })}
													placeholder={intl.formatMessage({ id: 'common.fill' })}
													value={contact.name}
													sx={{ width: '100%' }}
													onChange={(event) => updateContacts(event)}
													required
													error={firstSubmit && contact.name === '' ? true : false}
												></TextFieldWithPlaceholder>
											</Grid>
											<Grid item xs={showColumnLayout ? 12 : 6}>
												<TextFieldWithPlaceholder
													id={'contactPhone-' + contact.id}
													label={intl.formatMessage({ id: 'settings.company.addresses_tab.contact_phone' })}
													placeholder={intl.formatMessage({ id: 'common.fill' })}
													value={contact.phone}
													sx={{ width: '100%' }}
													onChange={(event) => updateContacts(event)}
													required
													error={firstSubmit && contact.phone === '' ? true : false}
												></TextFieldWithPlaceholder>
											</Grid>
										</Grid>
									);
								})}
							<Box display={'flex'} justifyContent={'space-between'}>
								<Button
									disabled={contacts.length >= 4}
									onClick={addContact}
									sx={{ textTransform: 'none', fontSize: '12px', mt: '5px' }}
									size="small"
								>
									{intl.formatMessage({ id: 'common.add_another' })}
								</Button>
								<Button
									disabled={
										contacts.length === 1 || (addressToEdit && contacts.length <= addressToEdit?.contacts.length)
									}
									onClick={removeContact}
									sx={{ textTransform: 'none', fontSize: '12px', mt: '5px' }}
									size="small"
								>
									{intl.formatMessage({ id: 'common.delete' })}
								</Button>
							</Box>
						</Box>
					</Grid>
				)}

				<Grid item xs={12} height={'10%'}>
					<Button
						sx={{ width: showColumnLayout ? '48%' : 'auto' }}
						disabled={isSaving}
						onClick={onCancel}
						variant="outlined"
					>
						{intl.formatMessage({ id: 'common.cancel' })}
					</Button>
					<Button
						sx={{ width: showColumnLayout ? '48%' : 'auto', ml: '10px' }}
						disabled={isSaving}
						onClick={handleSave}
						variant="contained"
					>
						{showColumnLayout
							? intl.formatMessage({ id: 'common.continue' })
							: intl.formatMessage({ id: 'common.save' })}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default AddressForm;
