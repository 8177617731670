import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export interface Option {
	value: string;
	label: string;
}

interface selectWithCheckProps {
	options: Option[];
	defaultValue?: string;
	onValueChangeCallback: (value: string) => void;
}

const SelectWithCheck = (props: selectWithCheckProps) => {
	const [dropDownSelected, setDropDownSelected] = useState<string>(props.defaultValue ?? '');

	const handleDropDownChange = (event: SelectChangeEvent) => {
		setDropDownSelected(event.target.value as string);
		props.onValueChangeCallback(event.target.value as string);
	};

	return (
		<Select
			value={dropDownSelected}
			onChange={handleDropDownChange}
			displayEmpty
			inputProps={{ 'aria-label': 'Without label' }}
			renderValue={(selected) => selected}
		>
			{props.options.map((option) => (
				<MenuItem
					key={option.value}
					value={option.value}
					sx={{ fontWeight: 500, marginLeft: option.value !== dropDownSelected ? '37px' : undefined }}
				>
					{option.value === dropDownSelected && (
						<ListItemIcon>
							<CheckIcon />
						</ListItemIcon>
					)}
					{option.label}
				</MenuItem>
			))}
		</Select>
	);
};

export default SelectWithCheck;
