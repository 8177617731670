import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { RedeemTokenViewDataProduct } from '../../../../types';
type EquivalenceDialogProps = {
	open: boolean;
	data: RedeemTokenViewDataProduct[];
	loading: boolean;
	onClose: () => void;
};

const EquivalenceDialog = ({ open, data, loading, onClose }: EquivalenceDialogProps) => {
	const intl = useIntl();
	return (
		<Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
			<DialogContent sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 3, marginBottom: 4, alignItems: 'start' }}>
				<Box sx={{ flex: 1 }}>
					<Typography variant="h5" color="#72727E">
						{intl.formatMessage({ id: 'mytokens.constructor.redeem.dialog.equivalences.title' })}
					</Typography>
					<Typography variant="body1" sx={{ marginTop: 2 }}>
						{intl.formatMessage({ id: 'mytokens.constructor.redeem.dialog.equivalences.subtitle' })}
					</Typography>
				</Box>
				<Box sx={{ flex: 1 }}>
					<Typography variant="body1" sx={{ fontWeight: 600, marginBottom: 4 }}>
						{intl.formatMessage({ id: 'mytokens.constructor.redeem.dialog.equivalences.legend' })}
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
						{data.map((product, i) => {
							return (
								<Grid container key={i}>
									<Grid item xs={2} md={2}>
										<img src={product.image} alt={product.name} width="60px" />
									</Grid>
									<Grid item xs={3} md={3} justifySelf="center" alignSelf="center">
										<Typography variant="h5" textAlign="center">
											{product.equivalence}
										</Typography>
									</Grid>
									<Grid item xs={7} md={7}>
										<Typography variant="body1">{product.name}</Typography>
									</Grid>
								</Grid>
							);
						})}
					</Box>
				</Box>
			</DialogContent>
			<Button
				onClick={onClose}
				sx={{
					bgcolor: 'white',
					borderRadius: '5px',
					color: '#3445F2',
					border: '1px solid #3445F2',
					fontWeight: 500,
					width: '160px',
					justifyContent: 'center',
					margin: 'auto',
					cursor: 'pointer',
				}}
			>
				Cerrar
			</Button>
		</Dialog>
	);
};

export default EquivalenceDialog;
