import { useEffect } from 'react';
import client from '../clients/client';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import userState from '../atoms/user-state';
import notificationState from '../atoms/one-time-notification-state';

const useOneTimeNotification = () => {
	const user = useRecoilValue(userState);
	const setNotification = useSetRecoilState(notificationState);
    
	useEffect(() => {
		if (Object.keys(user).length !== 0) {

            client
                .oneTimeNotification({ token: user.token })
                .then((res: any) => {
                    if (res.data.notifications.length > 0) {
                        
                        setNotification((prevNotif) => []);
                        res.data.notifications.forEach((oneNotification: any) => {
                            if(!!oneNotification?.metadata && !!JSON.parse(oneNotification.metadata)?.routes){
                                const route = JSON.parse(oneNotification.metadata).routes;
                                
                                if(route === window.location.pathname){
                                    setNotification((prevNotif) => [...prevNotif, { id: oneNotification.id, message: oneNotification.message, metadata: JSON.parse(oneNotification.metadata) }]);
                                }
                            }else {
                                setNotification((prevNotif) => [...prevNotif, { id: oneNotification.id, message: oneNotification.message }]);
                            }
                        });
                    }
                })
                .catch((err) => {
                    setNotification([]);
                    console.log(err);
                });
        }
	}, [user]);

};

export default useOneTimeNotification;
