
import React, { useLayoutEffect, useMemo, useState } from 'react';
import {
	Box,
    Grid,
	styled,
	Typography,
	Button,
	MenuItem,
	InputLabel,
	Stack,
	Input,
	Divider,
	Select,
	Tooltip,
    RadioGroup,
    FormControlLabel,
    Radio,
    LinearProgress,
    Link
} from '@mui/material';
import emotionStyled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useMobile } from '../../../hooks/use-mobile';
import { QuestionMark } from '@mui/icons-material';
import PurchaseConfirmDialog from './purchase-confirm';
import { formatNumberWithCurrency } from '../../../utils/data-utils';
import PurchaseInProgressDialog from './purchase-in-progress'
import { useContextBuyTokens } from './buy-tokens';
import { SET_SELECTED_COMPANY, SET_SELECTED_FINANCING_METHOD, SET_SELECTED_DELIVERY, SET_STOCK_SAFEKEEPING_ID, TOGGLE_IS_LOADING_RESPONSE, SET_ERROR_FROM_PURCHASE, SET_COMPANIES, SET_ERROR_FROM_FETCH_TRUE } from './reducer/actionNames';
import { useNavigate } from 'react-router-dom';
import { AgreementCondition, ShippingZone } from './reducer';
import giftSVG from '../../../assets/black-gift.svg';
import client from '../../../clients/client';
import PurchaseErrorDialog from './purchase-error';
import MapsDialog from './maps-dialog';
import AcceptTokenTermsDialog from './accept-token-terms-dialog';
import { AgreementConditionTypes } from '../../../types';

const GiftIcon = emotionStyled.img`
    width: 35px;
    height: 35px;
`

export const TooltipQuestionMarkIcon = styled(QuestionMark)`
	box-sizing: content-box;
	padding: 2px;
	width: 16px;
	height: unset;
	margin-left: 14px;
	margin-bottom: -3px;
	border-radius: 10px;
	background-color: #72727e;
	color: #ffffff;
`;

export const SelectInputConfirmation = styled(Select)(({ theme }) => ({
	'label + &': {
		marginTop: 0,
        width: '100%',
	},
	'&::before': {
	  visibility: 'hidden',
	},
	'& .MuiInput-input': {
	  borderRadius: 4,
	  position: 'relative',
	  border: '1px solid',
	  borderColor: '#E0E3E7',
	  fontSize: 16,
	  minWidth: '208px !important',
	  padding: '10px 12px'
	}
}));

export const TextFieldConfirmation = styled(Input)(({ theme }) => {
    return ({
    'label + &': {
        marginTop: 0,
        width: '100%',
	},
    '& .MuiInput-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid',
      borderColor: '#E0E3E7',
      fontSize: 16,
      padding: '10px 12px',
    },
	'& .Mui-disabled': {
		textFillColor: '#000000 !important',
	},
})});

const ConfirmationStep = () => {
	const intl = useIntl();
	const isMobile = useMobile();
    const navigate = useNavigate();

	const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false);
	const [purchaseConfirmDialogOpen, setPurchaseConfirmDialogOpen] = useState(false);
    const [purchaseInProgressDialogOpen, setPurchaseInProgressDialogOpen] = useState(false);
    const [mapsDialogOpen, setMapsDialogOpen] = useState(false);

    const [shippingZones, setShippingZones] = useState<ShippingZone[]>([])    
    const [selectedZone, setSelectedZone] = useState<ShippingZone | undefined>();    

    const [state, dispatch, user, totalsToRender] = useContextBuyTokens();
    const {
        companies,
        errorOnPurchase,
        isLoadingResponse,
        quantity,
        quantityString,
        selectedCompany,
        selectedFinancingMethod,
        selectedDelivery,
        agreement,
        collection,
        seller,
        pricing,
        benefit,
        financing,
        shippings,
        withdrawal
    } = state;

    useLayoutEffect(() => {
        const newShippingZones: ShippingZone[] = []
        if (selectedDelivery?.condition_type_id === AgreementConditionTypes.WITHDRAWAL) {
            withdrawal?.zones?.forEach((zone: ShippingZone) => {
                newShippingZones.push({ ...zone, agreement_condition_id: withdrawal.id })
            })
        } else {
            shippings.forEach((shipping: AgreementCondition) => {
                if (shipping.zones) {
                    shipping.zones.forEach((zone: ShippingZone) => {
                        newShippingZones.push({ ...zone, agreement_condition_id: shipping.id })
                    })
                }
            })
        }
        // sort again zones, if there are agreements with more than one zone
        newShippingZones.sort((a, b) => a.zone_label.localeCompare(b.zone_label));

        if (newShippingZones.length > 1) {
            if (selectedDelivery?.condition_type_id === AgreementConditionTypes.WITHDRAWAL) {
                newShippingZones.unshift({
                    zone_id: '',
                    zone_label: intl.formatMessage({ id: 'buy_tokens.agreement_details.select_withdrawal_addres' }),
                    agreement_condition_id: ''
                })
            } else {
                newShippingZones.unshift({
                    zone_id: '',
                    zone_label: intl.formatMessage({ id: 'buy_tokens.agreement_details.select_zone' }),
                    agreement_condition_id: ''
                })
            }
        }

        setShippingZones(newShippingZones)
        if (selectedZone?.agreement_condition_id !== selectedDelivery?.id) {
            setSelectedZone(newShippingZones[0])         
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDelivery, shippings, withdrawal])

    const financingMap = useMemo(() => 
        new Map(financing ? financing.sort((a:any,b:any) => {
            if (
                ((a.name < b.name) && (a.name.length < b.name.length)) ||
                ((a.name < b.name) && (a.name.length === b.name.length))
            ) {
                return -1;
            }
            if (
                ((a.name > b.name) && (a.name.length > b.name.length)) ||
                ((a.name > b.name) && (a.name.length === b.name.length))
            ) {
                return 1;
            }
            return 0;
        }).map((financeMethod: AgreementCondition) => [financeMethod.id, financeMethod]) : [])
    , [financing])

    const { firstSubtotal, discount, deliveryPrice, interest, finalTotal, timeValidity } = totalsToRender;
    const benefitIsApplied = benefit && discount > 0;

    const handleReloadCompanies = (reload: boolean) => {
        if (reload) {
            setPurchaseConfirmDialogOpen(false);
            loadCompanies();
        }
    };

    const loadCompanies = () => {
        dispatch({ type: TOGGLE_IS_LOADING_RESPONSE });
        client.getConstructorBusinessNames({ token: user.token })
            .then((response) => {
                dispatch({ type: SET_COMPANIES, payload: response.businessNames });
                dispatch({ type: TOGGLE_IS_LOADING_RESPONSE });
            })
            .catch((err) => {
                dispatch({ type: TOGGLE_IS_LOADING_RESPONSE });
                dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
            });
    };

    const handleConfirmPurchaseDialog = async () => {
        if(globalThis.location.hostname === 'studio.nuqlea.com') {
			(globalThis as any).dataLayer?.push({
				"event": "click_tokens_purchase_button_buy",
				"value": {
					"user": {
                        "user_id": user?.id,
                        "user_email": user?.email,
                        "type_external": user?.type_external,
                        "id_external": user?.id_external,
                        "name_external": user?.name_external,
                        "role": user?.role,
                        "environment": user?.enviroment
                    },
                    "agreement": {
                        "id": agreement?.id,
                        "name": agreement?.name,
                        "validity": agreement?.validity?.value,
                    },
                    "company": {
                        "company_id": selectedCompany?.id,
                        "company_name": selectedCompany?.business_social,
                        "company_cuit": selectedCompany?.cuit
                    },
                    "seller": {
                        "id": seller?.iddistribuidores,
                        "name": seller?.seller,
                    },
                    "quantity": quantity,
                    "pricing": {
                        "id": pricing?.id,
                        "price": pricing?.price,
                    },
                    "benefit": {
                        "id": benefit?.id,
                        "name": benefit?.name,
                        "code": benefit?.code,
                        "description": benefit?.description,
                        "value": benefit?.value,
                        "value_type": benefit?.value_type,
                        "value_sign": benefit?.value_sign
                    },
                    "selectedDelivery": {
                        "id": selectedDelivery?.id,
                        "name": selectedDelivery?.name,
                        "code": selectedDelivery?.code,
                        "description": selectedDelivery?.description,
                        "condition_type_id": selectedDelivery?.condition_type_id,
                        "condition_type_label": selectedDelivery?.condition_type_label,
                        "value": selectedDelivery?.value,
                        "value_type": selectedDelivery?.value_type,
                        "value_sign": selectedDelivery?.value_sign
                    },
                    "selectedZone": {
                        "zone_id": selectedZone?.zone_id,
                        "zone_label": selectedZone?.zone_label,
                    },
                    "selectedFinancingMethod": {
                        "id": selectedFinancingMethod?.id,
                        "name": selectedFinancingMethod?.name,
                        "code": selectedFinancingMethod?.code,
                        "description": selectedFinancingMethod?.description,
                        "value": selectedFinancingMethod?.value,
                        "value_type": selectedFinancingMethod?.value_type,
                        "value_sign": selectedFinancingMethod?.value_sign,
                    },
                    "finalTotal": finalTotal,
                    "discount": discount,
                    "deliveryPrice": deliveryPrice,
                    "interest": interest,
				}
			})
		}

        setPurchaseConfirmDialogOpen(true);
    };

    const handleConfirmPurchase = async () => {
        if (!selectedCompany || !selectedFinancingMethod || !selectedDelivery || !selectedZone?.zone_id) {
            return;
        }

        if(globalThis.location.hostname === 'studio.nuqlea.com') {
			(globalThis as any).dataLayer?.push({
				"event": "click_tokens_purchase_button_confirm",
				"value": {
                    "user": {
                        "user_id": user?.id,
                        "user_email": user?.email,
                        "type_external": user?.type_external,
                        "id_external": user?.id_external,
                        "name_external": user?.name_external,
                        "role": user?.role,
                        "environment": user?.enviroment
                    },
                    "agreement": {
                        "id": agreement?.id,
                        "name": agreement?.name,
                        "validity": agreement?.validity?.value,
                    },
                    "company": {
                        "company_id": selectedCompany?.id,
                        "company_name": selectedCompany?.business_social,
                        "company_cuit": selectedCompany?.cuit
                    },
                    "seller": {
                        "id": seller?.iddistribuidores,
                        "name": seller?.seller,
                    },
                    "quantity": quantity,
                    "pricing": {
                        "id": pricing?.id,
                        "price": pricing?.price,
                    },
                    "benefit": {
                        "id": benefit?.id,
                        "name": benefit?.name,
                        "code": benefit?.code,
                        "description": benefit?.description,
                        "value": benefit?.value,
                        "value_type": benefit?.value_type,
                        "value_sign": benefit?.value_sign
                    },
                    "selectedDelivery": {
                        "id": selectedDelivery?.id,
                        "name": selectedDelivery?.name,
                        "code": selectedDelivery?.code,
                        "description": selectedDelivery?.description,
                        "condition_type_id": selectedDelivery?.condition_type_id,
                        "condition_type_label": selectedDelivery?.condition_type_label,
                        "value": selectedDelivery?.value,
                        "value_type": selectedDelivery?.value_type,
                        "value_sign": selectedDelivery?.value_sign
                    },
                    "selectedZone": {
                        "zone_id": selectedZone?.zone_id,
                        "zone_label": selectedZone?.zone_label,
                    },
                    "selectedFinancingMethod": {
                        "id": selectedFinancingMethod?.id,
                        "name": selectedFinancingMethod?.name,
                        "code": selectedFinancingMethod?.code,
                        "description": selectedFinancingMethod?.description,
                        "value": selectedFinancingMethod?.value,
                        "value_type": selectedFinancingMethod?.value_type,
                        "value_sign": selectedFinancingMethod?.value_sign,
                    },
                    "finalTotal": finalTotal,
                    "discount": discount,
                    "deliveryPrice": deliveryPrice,
                    "interest": interest,
				}
			})
		}
        
        setPurchaseConfirmDialogOpen(false);
        setPurchaseInProgressDialogOpen(true);
        
        const agreements_conditions_used = [];
        if (selectedFinancingMethod) {
            agreements_conditions_used.push({ id: selectedFinancingMethod.id });
        }
        if (selectedDelivery) {
            agreements_conditions_used.push({ id: selectedDelivery.id, zone_id: selectedZone?.zone_id });
        }
        if (benefitIsApplied) {
            agreements_conditions_used.push({ id: benefit.id });
        }

        const fields = {
            agreementId: agreement?.id ?? '',
			agreementValidity: agreement?.validity?.value ?? 0,
			collection,
			companyId: selectedCompany?.id,
			seller: seller?.seller,
			quantity,
            pricing,
            benefitCondition: benefit,
            selectedDeliveryCondition: selectedDelivery,
            selectedFinancingCondition: selectedFinancingMethod,
            finalTotal,
            agreements_conditions: agreements_conditions_used
        };

        let buyResponse;
        try {
            buyResponse = await client.buyTokens({token: user.token, fields})
        } catch (error) {
            dispatch({ 
                type: SET_ERROR_FROM_PURCHASE,
                payload: intl.formatMessage({ id: 'buy_tokens.error_on_purchase' })
            });
            setPurchaseInProgressDialogOpen(false);
        }
            
        if (buyResponse.success) {
            dispatch({ type: SET_STOCK_SAFEKEEPING_ID, payload: buyResponse.sskId });
            navigate(`/my-tokens/buy/confirmed-step?agreement=${agreement?.id}`)
            setPurchaseInProgressDialogOpen(false);
        } else {
            dispatch({ 
                type: SET_ERROR_FROM_PURCHASE,
                payload: intl.formatMessage({ id: 'buy_tokens.error_on_purchase' })
            });
            setPurchaseInProgressDialogOpen(false);
            throw new Error(buyResponse.message);
        }
        
    };

	const handlePaymentConditionsLinkClick = (event: any) => {
		event.preventDefault();
		setTermsAndConditionsDialogOpen(true);
	};

    return (
        <Grid container>
            {!isLoadingResponse ?
                <>
                    <Grid item xs={12} sm={8}>
                        <Stack spacing={2}>
                            <Typography
                                variant="h5"
                                sx={{ marginTop: {xs: '20px', sm: '0px'} }}
                            >
                                {intl.formatMessage({ id: 'buy_tokens.agreement_details.title' })}
                            </Typography>
                            <Stack
                                spacing={2}
                                divider={isMobile ? null :<Divider orientation="horizontal" flexItem/>}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <InputLabel htmlFor='quantity-confirmation' sx={{ color: '#000', fontWeight: 500, position: 'relative', mb: 1 }}>
                                            {intl.formatMessage({id: 'buy_tokens.agreement_details.label_units_requests'})}
                                        </InputLabel>
                                        <TextFieldConfirmation value={quantityString} id='quantity-confirmation' disabled />
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                        <Box 
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '1.6em',
                                                color: '#000',
                                                marginTop: 2,
                                                maxWidth: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'end',
                                                alignItems: 'end',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            {benefitIsApplied &&
                                                <Box sx={{
                                                    textDecoration: 'line-through',
                                                    fontSize: '.82em',
                                                    opacity: .4
                                                }}>
                                                    {formatNumberWithCurrency(firstSubtotal, 2)}
                                                </Box>
                                            }
                                            <span>{formatNumberWithCurrency(firstSubtotal - discount, 2)}</span>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {financing &&
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="financing-days" sx={{ color: '#000', fontWeight: 500, position: 'relative', mb: 1 }}>
                                                {intl.formatMessage({id: 'buy_tokens.agreement_details.label_payment_method'})}
                                                {!isMobile && (
                                                    <Tooltip
                                                        title={
                                                            <Typography sx={{ color: '#FFFFFF' }}>
                                                                {intl.formatMessage({id: 'buy_tokens.agreement_details.tooltip_financing'})}
                                                            </Typography>
                                                        }
                                                    >
                                                        <TooltipQuestionMarkIcon/>
                                                    </Tooltip>
                                                )}
                                            </InputLabel>
                                            {financing.length === 1 ?
                                                <TextFieldConfirmation value={selectedFinancingMethod?.name} id='financing-days' disabled />
                                                :
                                                <SelectInputConfirmation
                                                    labelId="financing-days"
                                                    value={selectedFinancingMethod?.id}
                                                    onChange={(event) => {
                                                        const selected = financingMap.get(event.target.value as string) ?? financing.sort((a:any,b:any) => {
                                                            if (
                                                                ((a.name < b.name) && (a.name.length < b.name.length)) ||
                                                                ((a.name < b.name) && (a.name.length === b.name.length))
                                                            ) {
                                                                return -1;
                                                            }
                                                            if (
                                                                ((a.name > b.name) && (a.name.length > b.name.length)) ||
                                                                ((a.name > b.name) && (a.name.length === b.name.length))
                                                            ) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        })[0];

                                                        dispatch({ 
                                                            type: SET_SELECTED_FINANCING_METHOD,
                                                            payload: selected
                                                        })

                                                        if(globalThis.location.hostname === 'studio.nuqlea.com') {
                                                            (globalThis as any).dataLayer?.push({
                                                                "event": "click_tokens_purchase_picker_financing",
                                                                "value": {
                                                                    "user": {
                                                                        "user_id": user?.id,
                                                                        "user_email": user?.email,
                                                                        "type_external": user?.type_external,
                                                                        "id_external": user?.id_external,
                                                                        "name_external": user?.name_external,
                                                                        "role": user?.role,
                                                                        "environment": user?.enviroment
                                                                    },
                                                                    "agreement": {
                                                                        "id": agreement?.id,
                                                                        "name": agreement?.name,
                                                                        "validity": agreement?.validity?.value,
                                                                    },
                                                                    "seller": {
                                                                        "id": seller?.iddistribuidores,
                                                                        "name": seller?.seller,
                                                                    },
                                                                    "quantity": quantity,
                                                                    "pricing": {
                                                                        "id": pricing?.id,
                                                                        "price": pricing?.price,
                                                                    },
                                                                    "selectedFinancingMethod": {
                                                                        "id": selected?.id,
                                                                        "name": selected?.name,
                                                                        "code": selected?.code,
                                                                        "description": selected?.description,
                                                                        "value": selected?.value,
                                                                        "value_type": selected?.value_type,
                                                                        "value_sign": selected?.value_sign,
                                                                    },
                                                                    "finalTotal": finalTotal,
                                                                    "discount": discount,
                                                                    "deliveryPrice": deliveryPrice,
                                                                    "interest": interest,
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {financing.sort((a:any,b:any) => {
                                                        if (
                                                            ((a.name < b.name) && (a.name.length < b.name.length)) ||
                                                            ((a.name < b.name) && (a.name.length === b.name.length))
                                                        ) {
                                                            return -1;
                                                        }
                                                        if (
                                                            ((a.name > b.name) && (a.name.length > b.name.length)) ||
                                                            ((a.name > b.name) && (a.name.length === b.name.length))
                                                        ) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }).map((financeMethod: AgreementCondition) =>
                                                        <MenuItem key={financeMethod.id} value={financeMethod.id}>
                                                            {financeMethod.name}
                                                        </MenuItem>
                                                    )}
                                                </SelectInputConfirmation>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: interest === 0 ? 500 : 700,
                                                    fontSize: '1.6em',
                                                    color: interest === 0 ? '#47D27F' : 'inherit',
                                                    marginTop: 2
                                                }}
                                            >
                                                { interest === 0 ? 'Sin interés' : formatNumberWithCurrency(interest, 2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="input-delivery" sx={{ color: '#000', fontWeight: 500, position: 'relative', mb: 1 }}>
                                            {intl.formatMessage({id: 'buy_tokens.agreement_details.delivery'})}
                                            {!isMobile && (
                                                <Tooltip
                                                    title={
                                                        <Typography sx={{ color: '#FFFFFF' }}>
                                                            {intl.formatMessage({id: 'buy_tokens.agreement_details.tooltip_sent'})}
                                                        </Typography>
                                                    }
                                                >
                                                    <TooltipQuestionMarkIcon/>
                                                </Tooltip>
                                            )}
                                        </InputLabel>
                                        {shippings.length > 0 && withdrawal && (
                                            <RadioGroup
                                                row
                                                aria-labelledby="input-delivery"
                                                value={selectedDelivery?.condition_type_id}
                                                name="radio-buttons-group"
                                                onChange={(event) => {
                                                    if (event.target.value === withdrawal.condition_type_id) {
                                                        dispatch?.({
                                                            type: SET_SELECTED_DELIVERY,
                                                            payload: withdrawal
                                                        })
                                                        setSelectedZone({
                                                            zone_id: '',
                                                            zone_label: intl.formatMessage({ id: 'buy_tokens.agreement_details.select_withdrawal_addres' }),
                                                            agreement_condition_id: ''
                                                        });
                                                    } else {
                                                        dispatch?.({
                                                            type: SET_SELECTED_DELIVERY,
                                                            payload: shippings[0]
                                                        })
                                                        setSelectedZone({
                                                            zone_id: '',
                                                            zone_label: intl.formatMessage({ id: 'buy_tokens.agreement_details.select_zone' }),
                                                            agreement_condition_id: ''
                                                        });
                                                    }

                                                    if(globalThis.location.hostname === 'studio.nuqlea.com') {
                                                        (globalThis as any).dataLayer?.push({
                                                            "event": "click_tokens_purchase_picker_delivery",
                                                            "value": {
                                                                "user": {
                                                                    "user_id": user?.id,
                                                                    "user_email": user?.email,
                                                                    "type_external": user?.type_external,
                                                                    "id_external": user?.id_external,
                                                                    "name_external": user?.name_external,
                                                                    "role": user?.role,
                                                                    "environment": user?.enviroment
                                                                },
                                                                "agreement": {
                                                                    "id": agreement?.id,
                                                                    "name": agreement?.name,
                                                                    "validity": agreement?.validity?.value,
                                                                },
                                                                "seller": {
                                                                    "id": seller?.iddistribuidores,
                                                                    "name": seller?.seller,
                                                                },
                                                                "quantity": quantity,
                                                                "pricing": {
                                                                    "id": pricing?.id,
                                                                    "price": pricing?.price,
                                                                },
                                                                "selectedDelivery": {
                                                                    "id": event.target.value === withdrawal.condition_type_id ? withdrawal?.id : shippings[0]?.id,
                                                                    "name": event.target.value === withdrawal.condition_type_id ? withdrawal?.name : shippings[0]?.name,
                                                                    "code": event.target.value === withdrawal.condition_type_id ? withdrawal?.code : shippings[0]?.code,
                                                                    "description": event.target.value === withdrawal.condition_type_id ? withdrawal?.description : shippings[0]?.description,
                                                                    "condition_type_id": event.target.value === withdrawal.condition_type_id ? withdrawal?.condition_type_id : shippings[0]?.condition_type_id,
                                                                    "condition_type_label": event.target.value === withdrawal.condition_type_id ? withdrawal?.condition_type_label : shippings[0]?.condition_type_label,
                                                                    "value": event.target.value === withdrawal.condition_type_id ? withdrawal?.value : shippings[0]?.value,
                                                                    "value_type": event.target.value === withdrawal.condition_type_id ? withdrawal?.value_type : shippings[0]?.value_type,
                                                                    "value_sign": event.target.value === withdrawal.condition_type_id ? withdrawal?.value_sign : shippings[0]?.value_sign
                                                                },
                                                                "finalTotal": finalTotal,
                                                                "discount": discount,
                                                                "deliveryPrice": deliveryPrice,
                                                                "interest": interest,
                                                            }
                                                        })
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiButtonBase-root.Mui-checked': {
                                                        color: '#3B74FB',
                                                        '& .MuiSvgIcon-root:nth-child(2)': {
                                                            fill: '#3B74FB',
                                                        },
                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={shippings[0].condition_type_id}
                                                    control={<Radio />}
                                                    label={shippings[0].condition_type_label}
                                                />
                                                <FormControlLabel
                                                    value={withdrawal.condition_type_id}
                                                    control={<Radio />}
                                                    label={withdrawal.condition_type_label}
                                                />
                                            </RadioGroup>
                                        )}
                                        {shippingZones.length > 1 ?
                                            <SelectInputConfirmation
                                                labelId="input-delivery"
                                                value={selectedZone?.zone_label ?? ''}
                                                onChange={(event) => {
                                                    const newSelectedShippingZone = shippingZones.find((zone: ShippingZone) => zone.zone_label === event.target.value)
                                                    
                                                    setSelectedZone(newSelectedShippingZone)
                                                    dispatch?.({
                                                        type: SET_SELECTED_DELIVERY,
                                                        payload: newSelectedShippingZone?.agreement_condition_id === withdrawal?.id
                                                                ? withdrawal
                                                                : shippings.find((shipping: AgreementCondition) => shipping.id === newSelectedShippingZone?.agreement_condition_id)
                                                    })
                                                    
                                                    if(globalThis.location.hostname === 'studio.nuqlea.com') {
                                                        (globalThis as any).dataLayer?.push({
                                                            "event": "click_tokens_purchase_picker_zona",
                                                            "value": {
                                                                "user": {
                                                                    "user_id": user?.id,
                                                                    "user_email": user?.email,
                                                                    "type_external": user?.type_external,
                                                                    "id_external": user?.id_external,
                                                                    "name_external": user?.name_external,
                                                                    "role": user?.role,
                                                                    "environment": user?.enviroment
                                                                },
                                                                "agreement": {
                                                                    "id": agreement?.id,
                                                                    "name": agreement?.name,
                                                                    "validity": agreement?.validity?.value,
                                                                },
                                                                "seller": {
                                                                    "id": seller?.iddistribuidores,
                                                                    "name": seller?.seller,
                                                                },
                                                                "quantity": quantity,
                                                                "pricing": {
                                                                    "id": pricing?.id,
                                                                    "price": pricing?.price,
                                                                },
                                                                "selectedZone": {
                                                                    "zone_id": selectedZone?.zone_id,
                                                                    "zone_label": selectedZone?.zone_label,
                                                                },
                                                                "finalTotal": finalTotal,
                                                                "discount": discount,
                                                                "deliveryPrice": deliveryPrice,
                                                                "interest": interest,
                                                            }
                                                        })
                                                    }
                                                }}
                                                sx={{ width: '100%' }}
                                            >
                                                {shippingZones.map((zone: ShippingZone) => (
                                                    <MenuItem key={zone.zone_id} value={zone.zone_label}>
                                                        {zone.zone_label}
                                                    </MenuItem>
                                                ))}
                                            </SelectInputConfirmation>
                                            :
                                            <TextFieldConfirmation
                                                id="input-delivery"
                                                value={selectedDelivery?.zones?.[0]?.zone_label ?? ''}
                                                disabled
                                                sx={{ width: '100%' }}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                        <Typography sx={{ fontWeight: 700, fontSize: '1.6em', color: '#000000', marginTop: 2 }}>
                                            {selectedZone?.zone_id
                                                ? deliveryPrice === 0 
                                                    ? formatNumberWithCurrency(deliveryPrice)
                                                    : formatNumberWithCurrency(deliveryPrice, 2)
                                                : ' '
                                            }
                                        </Typography>
                                    </Grid>
                                    {selectedDelivery?.condition_type_id === AgreementConditionTypes.WITHDRAWAL ? // withdrawal at the factory
                                        <Grid item xs={12} sx={{marginTop: '4px'}}>
                                            <Link  sx={{fontWeight: '500', textDecoration: 'none', '& :visited': { color: 'blue' }}} href={selectedZone?.zone_url ?? ''} target='_blank'>
                                                Ver mapa de puntos de retiro
                                            </Link >
                                        </Grid>
                                        :
                                        <Grid item xs={12} sx={{marginTop: '4px'}}>
                                            <Box onClick={() => setMapsDialogOpen(true)} sx={{fontWeight: '500', color: 'blue', cursor: 'pointer', display: 'inline-block' }}>
                                                Ver mapa de zonas de entrega
                                            </Box>
                                        </Grid> 
                                    }
                                </Grid>
                                {isMobile && benefitIsApplied && (
                                    <>
                                        <Divider orientation="horizontal" flexItem sx={{ marginX: '16% !important' }}/>
                                        <Grid
                                            container
                                            sx={{
                                                backgroundColor: '#EFF8F3',
                                                borderBottom: '4px solid #47D27F',
                                                borderRadius: '5px',
                                                padding: '10px 20px',
                                            }}
                                            rowSpacing={3}
                                        >
                                            <Grid item xs={2}>
                                                <GiftIcon src={giftSVG} alt="gift-icon" />
                                            </Grid>
                                            <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography
                                                    sx={{ fontSize: 16, fontWeight: 500, color: '#000000', marginLeft: '10px' }}
                                                >
                                                    {benefit.value_type === 'percentage' ?
                                                        intl.formatMessage({ id: 'buy_tokens.agreement_details.benefit_percentage' }, { value: benefit.value })
                                                        : intl.formatMessage({ id: 'buy_tokens.agreement_details.benefit_fixed' }, { value: formatNumberWithCurrency(discount, 2) })
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{ fontSize: 16, fontWeight: 500, color: '#069C54' }}
                                                >
                                                    {intl.formatMessage({ id: 'buy_tokens.agreement_details.saved_money' }, { value: formatNumberWithCurrency(discount, 2) })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="horizontal" flexItem sx={{ marginX: '16% !important' }}/>
                                    </>
                                )}
                                <Grid container>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                        <Typography sx={{ fontWeight: 700, fontSize: '1.6em', color: '#000', marginTop: 2 }}>
                                            {intl.formatMessage({id: 'common.total'})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                        <Typography sx={{ fontWeight: 700, fontSize: '1.6em', color: '#000', marginTop: 2 }}>
                                            {selectedZone?.zone_id ? formatNumberWithCurrency(finalTotal, 2) : formatNumberWithCurrency(finalTotal - deliveryPrice, 2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                        <Typography sx={{ fontSize: 13, marginTop: 2, "& > a": { color: 'currentColor' }  }}>
                                            {intl.formatMessage(
                                                { id: 'buy_tokens.agreement_details.phrase_terms_and_conditions' },
                                                {
                                                    link: (
                                                        <a href="#" onClick={handlePaymentConditionsLinkClick} style={{ textDecoration: 'underline' }}>
                                                            {intl.formatMessage({ id: 'buy_tokens.agreement_details.link_terms_and_conditions' })}
                                                        </a>
                                                    ),
                                                }
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: { xs: 'center', sm: 'end' },
                                            alignItems: 'center',
                                            marginTop: 2
                                        }}
                                    >
                                        <Button
                                            variant='outlined'
                                            sx={{ borderColor: '#B1B1B8', borderRadius: 1, color: '#406AFF', minWidth: '94px',
                                                ':hover': { borderColor: '#B1B1B8' }
                                            }}
                                            onClick={() => navigate(`/my-tokens/buy/quantity-select?agreement=${agreement?.id}`)}
                                        >
                                            {intl.formatMessage({ id: 'common.back' })}
                                        </Button>
                                        <Tooltip
                                            title={selectedDelivery?.condition_type_id === AgreementConditionTypes.WITHDRAWAL 
                                                ? intl.formatMessage({ id: 'buy_tokens.agreement_details.select_withdrawal_addres' })
                                                : intl.formatMessage({ id: 'buy_tokens.agreement_details.select_zone' })}
                                            disableHoverListener={Boolean(selectedZone?.zone_id)}
                                            disableFocusListener={Boolean(selectedZone?.zone_id)}
                                            disableTouchListener={Boolean(selectedZone?.zone_id)}
                                        >
                                            <span>{/* span wrapper to render tooltip when button is disabled */}
                                                <Button
                                                    disabled={!companies.length || !seller || !collection || !selectedZone?.zone_id}
                                                    variant='contained'
                                                    sx={{ marginLeft: 2, borderRadius: 1, backgroundColor: '#406AFF', color: '#FFFFFF', minWidth: '94px',
                                                        ':hover': { backgroundColor: '#406AFF' }
                                                    }}
                                                    onClick={handleConfirmPurchaseDialog}
                                                >
                                                    {intl.formatMessage({ id: 'common.buy' })}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                    {isMobile && (
                                        <Grid item xs={12} sx={{ marginTop: '30px' }}>
                                            <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.titleMobile' })}</Typography>
                                            <Typography sx={{ fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.subtitle3' }, { days: agreement?.min_time_to_ship })}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Grid>
                    {!isMobile && (
                        <Grid
                            item
                            sm={4}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                                alignContent: 'space-between',
                                mb: { xs: 1, sm: 2, md: 8.2 }
                            }}
                        >
                            <Stack spacing={6} sx={{ alignItems: 'center', justifyContent: benefitIsApplied ? 'space-between' : 'end', flex: 'auto' }}>
                                {benefitIsApplied && (
                                    <Grid
                                        container
                                        sx={{
                                            backgroundColor: '#EFF8F3',
                                            borderBottom: '4px solid #47D27F',
                                            maxWidth: '70%',
                                            borderRadius: 2,
                                            padding: { sm: '10px 15px', lg: '10px 20px'},
                                            mt: 1,
                                        }}
                                        rowSpacing={3}
                                    >
                                        <Grid item xs={2}>
                                            <GiftIcon src={giftSVG} alt="gift-icon" style={{ marginTop: 4, width: '30px' }} />
                                        </Grid>
                                        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography
                                                sx={{ fontSize: 16, fontWeight: 500, color: '#000000', marginLeft: {sm: '15px', lg: '10px' } }}
                                            >
                                                {benefit.value_type === 'percentage' ?
                                                    intl.formatMessage({ id: 'buy_tokens.agreement_details.benefit_percentage' }, { value: benefit.value })
                                                    : intl.formatMessage({ id: 'buy_tokens.agreement_details.benefit_fixed' }, { value: formatNumberWithCurrency(discount, 2) })
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{ fontSize: 16, fontWeight: 500, color: '#069C54' }}
                                            >
                                                {intl.formatMessage({ id: 'buy_tokens.agreement_details.saved_money' }, { value: formatNumberWithCurrency(discount, 2) })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Stack
                                    spacing={1}
                                    sx={{
                                        maxWidth: '70%'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 700, fontSize: 12 }}>
                                        {intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.title' })}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>{timeValidity}</Typography>
                                    <Typography sx={{ fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.subtitle2' })}</Typography>
                                    <Typography sx={{ fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.subtitle3' }, { days: agreement?.min_time_to_ship })}</Typography>
                                    <Typography sx={{ fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.subtitle4' })}</Typography>
                                    <Typography sx={{ fontSize: 12 }}>{intl.formatMessage({ id: 'buy_tokens.agreement_details.clarifications.subtitle5' }, {
                                        value: agreement?.min_redeem,
                                        units: agreement?.unity + 's'
                                    })}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    )}
                </>
                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="inherit" />
                </Box>
            }
            <MapsDialog
                deliveriesConditions={[...shippings, withdrawal]}
                dispatch={dispatch}
                open={mapsDialogOpen}
                onClose={() => setMapsDialogOpen(false)}
                zones={shippingZones.filter(zone => Boolean(zone.localities))}
                setZone={setSelectedZone}
                initialZone={!selectedZone?.zone_id ? undefined : selectedZone}
            />
            <AcceptTokenTermsDialog
                open={termsAndConditionsDialogOpen}
                onClose={() => setTermsAndConditionsDialogOpen(false)}
                agreement={agreement?.id ?? ''}
            />
            <PurchaseConfirmDialog
                user={user}
                confirm={handleConfirmPurchase}
                companies={companies ?? []}
                open={purchaseConfirmDialogOpen}
                onClose={() => setPurchaseConfirmDialogOpen(false)}
                selectedCompany={selectedCompany}
                handleSetSelectedCompany={(newCompany) => dispatch?.({ type: SET_SELECTED_COMPANY, payload: newCompany })}
                reloadCompanies={ (reload) => handleReloadCompanies(reload) }
            />
            <PurchaseInProgressDialog
                open={purchaseInProgressDialogOpen}
                onClose={() => setPurchaseInProgressDialogOpen(false)}
            />
            <PurchaseErrorDialog
                open={Boolean(errorOnPurchase)}
                onClose={() => dispatch?.({ type: SET_ERROR_FROM_PURCHASE, payload: null })}
                errorMessage={errorOnPurchase}
            />
        </Grid>
    )
}

export default ConfirmationStep;