import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import File from './file';
import DataList, { Entry } from './data-list';
import { useMobile } from '../hooks/use-mobile';
import client from '../clients/client';
import { User } from '../types';
import { useIntl } from 'react-intl';
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileViewer from '../components/file-viewer';

interface Props {
	title: string;
	details?: Entry[];
	user: User;
	driveFileId: string;
	listId: string;
	plan?: boolean | false;
	mt?: number;
	gcsFilePath?: string;
}

const FileWithDetails = (props: Props) => {
	const { title, details, user, driveFileId, listId, plan, mt, gcsFilePath } = props;

	const mobile = useMobile();
	const intl = useIntl();

	const [downloading, setDownloading] = useState(false);
	const [estimateForcedState, setEstimateForcedState] = useState<any>({ open: false, nameData: null, fileData: null });

	return (
		<Grid container spacing={2} sx={{ alignItems: 'center', gap: 1, flexFlow: { md: 'initial', xs: 'column' }, mt: 0 }}>
			{!plan && (
				<>
					<Grid item xs={12} sm={6} textAlign={mobile ? 'center' : 'right'}>
						<File
							title={title}
							actions={
								driveFileId
									? [
											{
												title: intl.formatMessage({ id: 'common.view' }),
												icon: <VisibilityIcon />,
												loading: downloading,
												onClick: () => {
													setDownloading(true);
													Promise.all([
														client.getDriveFileName({ driveFileId, user }),
														client.getDriveFile({
															driveFileId,
															fileId: title,
															listId,
															user,
														}),
													])
														.then(([nameData, fileData]) => {
															setEstimateForcedState({
																open: true,
																nameData,
																fileData,
															});
														})
														.finally(() => setDownloading(false));
												},
											},
									  ]
									: gcsFilePath
										? [
											{
												title: intl.formatMessage({ id: 'common.view' }),
												icon: <VisibilityIcon />,
												loading: downloading,
												onClick: () => {
													setDownloading(true);
													Promise.all([
														client.downloadGCSFile(gcsFilePath, "base64", user.id_external, user.token),
														client.downloadGCSBinaryFile(gcsFilePath,  user.id_external, user.token)
													])
														.then(([nameData, fileData]) => {
															setEstimateForcedState({
																open: true,
																nameData,
																fileData,
															});
														})
														.finally(() => setDownloading(false));
												},
											},
										]
										: [
											{
												title: intl.formatMessage({ id: 'common.not_available' }),
												loading: false,
												onClick: () => 0,
											},
										]

								
							}
						/>
						{(driveFileId || gcsFilePath) && (
							<FileViewer
								driveFileId={driveFileId}
								fileId={title}
								listId={listId}
								user={user}
								forcedState={estimateForcedState}
								gcsFilePath={gcsFilePath}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} textAlign={mobile ? 'center' : 'start'}>
						<DataList entries={details} />
					</Grid>
				</>
			)}
			{plan && (
				<>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							my: 2,
							px: downloading ? 0 : 1
						}}
					>
						<Typography>{title}</Typography>
						{(driveFileId || gcsFilePath) && (
							<LoadingButton
								variant="text"
								loading={downloading}
								loadingPosition="start"
								sx={{
									width: '100%',
									maxWidth: downloading ? '140px' : '100px',
									color: '#1737B7',
								}}
								onClick={() => {
									setDownloading(true);

									if( gcsFilePath ){
										Promise.all([
											client.downloadGCSFile(gcsFilePath, "base64", user.id_external, user.token),
											client.downloadGCSBinaryFile(gcsFilePath,  user.id_external, user.token)
										])
										.then(([nameData, fileData]) => {
											setEstimateForcedState({
												open: true,
												nameData,
												fileData,
											});
										})
										.finally(() => setDownloading(false));
									}
									
									if( driveFileId ){
										Promise.all([
											client.getDriveFileName({ driveFileId, user }),
											client.getDriveFile({
												driveFileId,
												fileId: title,
												listId,
												user,
											}),
										])
										.then(([nameData, fileData]) => {
											setEstimateForcedState({
												open: true,
												nameData,
												fileData,
											});
										})
										.finally(() => setDownloading(false));
									}
								}}
							>
								{downloading ? 'Procesando...' : 'Descargar'}
							</LoadingButton>
						)}
						{!(driveFileId || gcsFilePath) && intl.formatMessage({ id: 'common.not_available' })}
						{(driveFileId || gcsFilePath) && (
							<FileViewer
								driveFileId={driveFileId}
								fileId={title}
								listId={listId}
								user={user}
								forcedState={estimateForcedState}
								gcsFilePath={gcsFilePath}
							/>
						)}
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default FileWithDetails;
