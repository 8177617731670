import { useMemo } from 'react';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { GridBox, NextButton, SeparatedLabel, StyledTextField, StyledNumberField, TypographyTitle } from '../styles';
import { useIntl } from 'react-intl';
import InformationBar from '../../information-bar';
import { SignUpStep } from '../interfaces';
import { areErrorsPresent } from '../utils';
import { accountTypes } from '../constants';

const AccountTypeComponent = ({ formData, formErrors, handleInputChange, loading, handleChangeStep }: any) => {
	const intl = useIntl();
	const accountTypesWithPlaceholder = useMemo(() => {
		return [
			{ id: 0, label: intl.formatMessage({ id: 'auth.signup.constructor_or_seller.placeholder' }), value: '' },
			...accountTypes,
		];
	}, [intl]);

	return (
		<>
			<TypographyTitle variant="h5">{intl.formatMessage({ id: 'auth.signup.account_creation' })}</TypographyTitle>
			{areErrorsPresent(SignUpStep.ACCOUNT_CHECK, formErrors) && (
				<InformationBar
					sx={{ mb: '15px' }}
					icon="error"
					message={intl.formatMessage({ id: 'common.generic_error_fields' })}
					color="#FF3D00"
				></InformationBar>
			)}
			<GridBox>
				<Grid container spacing={16}>
					<Grid item xs={12} md={5}>
						<Box>
							<Typography color={'black'} fontWeight={700} fontSize={'16px'} marginBottom={1}>
								{intl.formatMessage({ id: 'auth.signup.organizational_data' })}
							</Typography>
							<Typography fontWeight={400} fontSize={'14px'} color={'#72727E'}>
								{intl.formatMessage({ id: 'common.required_fields' })}
							</Typography>
						</Box>
					</Grid>
					<Grid id="noPaddingGrid" item xs={12} md={7}>
						<SeparatedLabel sx={{ marginTop: 0 }}>
							{intl.formatMessage({ id: 'auth.signup.constructor_or_seller' })}
						</SeparatedLabel>
						<StyledTextField
							select
							name="accountType"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({ id: 'auth.signup.constructor_or_seller.placeholder' })}
							value={formData.accountType}
							onChange={handleInputChange}
							required
							error={!!formErrors.accountType}
							helperText={formErrors.accountType}
						>
							{
								// exclude for now the option to create a seller account from signup
								accountTypes
									.filter((accType) => accType.value !== 'seller')
									.map((accType) => (
										<MenuItem key={accType.id} value={accType.value}>
											{accType.label}
										</MenuItem>
									))
							}
						</StyledTextField>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.cuit' })}</SeparatedLabel>
						<StyledNumberField
							name="cuit"
							size="small"
							type="text"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.cuit.placeholder',
							})}
							value={formData.cuit}
							onChange={handleInputChange}
							required
							error={!!formErrors.cuit}
							helperText={formErrors.cuit}
						/>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.business_name' })}</SeparatedLabel>
						<StyledTextField
							name="businessName"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.business_name.placeholder',
							})}
							value={formData.businessName}
							onChange={handleInputChange}
							required
							error={!!formErrors.businessName}
							helperText={formErrors.businessName}
						/>
					</Grid>
					<Grid id="noPaddingDownGrid" item xs={12}>
						<Box sx={{ borderTop: 'solid 1px #D9D9D9' }} />
						<Grid container spacing={16}>
							<Grid item xs={0} md={5}></Grid>
							<Grid id="noPaddingGrid" item xs={12} md={7}>
								<Box sx={{ paddingTop: { xs: '0px', md: '22px' } }}>
									<Button
										onClick={() => (window.location.href = window.location.origin)}
										variant="outlined"
										sx={{
											width: { xs: '40%', md: '120px' },
											mr: '10px',
										}}
									>
										{intl.formatMessage({ id: 'common.back_to_login' })}
									</Button>
									<NextButton loading={loading} loadingPosition="start" onClick={handleChangeStep} variant="contained">
										{intl.formatMessage({ id: 'common.continue' })}
									</NextButton>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</GridBox>
		</>
	);
};

export default AccountTypeComponent;
