import { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Alert,
	Typography,
	InputLabel,
	Box,
	Stack,
	Select,
	MenuItem,
	IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import { Action, BusinessName, User } from '../../../types';
import client from '../../../clients/client';
import FullScreenLoading from '../../full-screen-loading';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import userState from '../../../atoms/user-state';
import { formatCUIT } from '../../../utils/data-utils';
import NuqleaAlert from '../../nuqlea-alert';
import SideModalContainer from '../../modals/side-modal-container';
import TaxProfileForm from '../../settings/organization/shared-components/tax-profile-form';
import { useMobile } from '../../../hooks/use-mobile';

interface Props {
	companies: BusinessName[];
	open: boolean;
	winnerQuotations?: string[];
	quoteVersion?: number;
	listId: string;
	orderId: string;
	quotationDriveFileId: string;
	gcsFilePath?: string;
	onClose: (event?: object | null, reason?: string) => void;
	reloadCompanies?: () => void;
}

const ConfirmationDialog = (props: Props) => {
	const intl = useIntl();
	const {
		companies,
		open,
		winnerQuotations,
		quoteVersion,
		quotationDriveFileId,
		gcsFilePath,
		listId,
		orderId,
		onClose,
		reloadCompanies
	} = props;
	
	const isMobile = useMobile();
	const user = useRecoilValue<User>(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);

	const companiesMap = useMemo<Map<string | null | undefined, BusinessName>>(
		() => new Map(companies.map((company: BusinessName) => [company.id, company])),
		[companies]
	);

	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();
	const [downloading, setDownloading] = useState(false);
	const [taxProfileForm, setTaxProfileForm] = useState<boolean>(false);
	const [selectedCompany, setSelectedCompany] = useState<BusinessName | undefined>(companies[0]);

	useEffect(() => {
		setDisableRootElementScroll(taxProfileForm);
	}, [setDisableRootElementScroll, taxProfileForm]);

	useEffect(() => {
		if (companies.length > 0 && !selectedCompany) {
			setSelectedCompany(companies[0]);
		}
	}, [companies, selectedCompany]);	

	const handleSubmit = () => {
		setLoading(true);
		setError(undefined);

		if (selectedCompany?.id) {
			client
				.executeAction({
					listId,
					action: Action.CONFIRM,
					user,
					companyId: selectedCompany.id,
					winner_quotations: winnerQuotations
				})
				.then((response:any) => {
	
					onClose(null, 'success')
				})
				.catch((error) => setError(error.message))
				.finally(() => setLoading(false));
		} else {
			setError(intl.formatMessage({ id: 'list.details.confirmation_dialog.empty_business_name_error' }));
			setLoading(false);
		}
	};

	const EstimateDownloadButton = () => {
		return (
			<Box
				component='span'
				fontWeight={600}
				color={downloading ? 'grey' : 'blue'}
				className={`cursor-pointer ${!downloading && 'underline'}`}
				onClick={() => {
					setDownloading(true);
						if (quotationDriveFileId) {
							Promise.all([
								client.getDriveFileName({ driveFileId: quotationDriveFileId, user }),
								client.getDriveFile({
									driveFileId: quotationDriveFileId,
									fileId: 'Cotización Nuqlea',
									listId,
									user,
								}),
							])
							.then(([nameData, fileData]) => {
								const blob = new Blob([fileData], { type: 'application/octet-stream' });
								const blobURL = URL.createObjectURL(blob);
								const a = document.createElement('a');
								a.setAttribute('target', '_blank');
								a.setAttribute('download', nameData.filename);
								a.setAttribute('href', blobURL);
								a.click();
							})
							.finally(() => setDownloading(false));
						}
						
						if (gcsFilePath) {
							Promise.all([
								client.downloadGCSFile(gcsFilePath, "base64", user.id_external, user.token),
								client.downloadGCSBinaryFile(gcsFilePath,  user.id_external, user.token)
							])
							.then(([nameData, fileData]) => {
								const blob = new Blob([fileData], { type: 'application/octet-stream' });
								const blobURL = URL.createObjectURL(blob);
								const a = document.createElement('a');
								a.setAttribute('target', '_blank');
								a.setAttribute('download', nameData.filename);
								a.setAttribute('href', blobURL);
								a.click();
							})
							.finally(() => setDownloading(false));
						}
				}}
			>
				{quotationDriveFileId
					? downloading
						? intl.formatMessage({ id: 'common.in_progress' })
						: intl.formatMessage({ id: 'list.details.confirmation_dialog.quote_version' }, { quoteVersion })
					: `(${intl.formatMessage({ id: 'common.not_available' })})`
				}
			</Box>
		);
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				fullWidth
				sx={{
					'& .MuiPaper-root': {
						maxWidth: '600px',
						p: 3,
						borderRadius: 4
					}
				}}
			>
				{loading && <FullScreenLoading />}
				<DialogTitle>
					<Typography variant="h5" color='black'>
						{intl.formatMessage({ id: 'list.details.confirmation_dialog.title' })}
					</Typography>
					<IconButton
						aria-label="close"
						onClick={() => onClose(null, 'backdropClick')}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Stack spacing={4} width={'100%'}>
						<Box
							className="flex items-center w-full p-4 rounded-md bg-blue-100"
						>
							<InfoOutlined sx={{ mr: 1.5, color: 'blue' }} />
							<Typography variant="body1" color="blue">
								{intl.formatMessage(
									{ id: 'list.details.confirmation_dialog.reminder' },
									{ quotationLink: <EstimateDownloadButton /> }
								)}
							</Typography>
						</Box>
						<Stack>
							<InputLabel shrink htmlFor="company" sx={{ fontSize: 18, color: '#000', fontWeight: 500 }}>
								{intl.formatMessage({ id: 'common.organization_name' })}
							</InputLabel>
							<Select
								labelId="company"
								value={selectedCompany?.id ?? ''}
								onChange={(event) => {
									setSelectedCompany(companiesMap.get(event.target.value as string))
									setError(undefined);
								}}
							>
								{companies.map((company: BusinessName) => (
									<MenuItem key={company.id} value={company.id ?? ''} sx={{ color: 'grey.600' }}>
										{company.business_social}
									</MenuItem>
								))}
							</Select>
						</Stack>
						<Stack>
							<InputLabel
								shrink
								htmlFor="tax-id"
								sx={{
									fontSize: 18,
									color: '#000',
									fontWeight: 500,
									position: 'relative',
								}}
							>
								{intl.formatMessage({ id: 'header.tax_id_label' })}
							</InputLabel>
							<TextField
								id="tax-id"
								value={formatCUIT(selectedCompany?.cuit ?? '')}
								disabled
								sx={{
									'& .Mui-disabled': {
										textFillColor: '#000000 !important',
									},
								}}
							/>
						</Stack>
						{selectedCompany?.validInvoiceProfile === false && (
							<NuqleaAlert
								action={() => {
									setTaxProfileForm(true);
								}}
								actionTitle={intl.formatMessage({ id: 'common.complete_action' })}
								minHeight="60px"
							>
								{intl.formatMessage({ id: 'buy_tokens.purchase_confirm.invalid_invoice_profile' })}
							</NuqleaAlert>
						)}
						{error && (
							<Alert severity="error" sx={{ mt: 2 }}>
								{error ?? 'Ocurrió un error.'}
							</Alert>
						)}
					</Stack>
				</DialogContent>
				<DialogActions className='justify-center mb-1'>
					<Button
						onClick={handleSubmit}
						variant="contained"
						disabled={!selectedCompany}
					>
						{intl.formatMessage({ id: 'common.buy' })}
					</Button>
				</DialogActions>
			</Dialog>
			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={taxProfileForm}
				onClose={() => setTaxProfileForm(false)}
			>
				<TaxProfileForm
					callback={(_, changed) => {
						setTaxProfileForm(false);
						if (!changed) {
							return;
						}
						reloadCompanies?.();
						onClose(null, 'backdropClick');
					}}
					businessNameToEdit={selectedCompany}
					organizationId={user.id_external}
					token={user.token}
					creator={user}
					isModal={true}
				/>
			</SideModalContainer>
		</>
	);
};

export default ConfirmationDialog;