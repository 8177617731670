import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Circle = styled('div')((props: { backgroundColor?: string }) => ({
	width: '55px',
	height: '55px',
	padding: '16px',
	textAlign: 'center',
	backgroundColor: props.backgroundColor || '#000',
	borderRadius: '50%',
	color: '#fff',
}));

interface Props {
	value: number;
	color?: string;
}

const Counter = (props: Props) => (
	<Circle backgroundColor={props.color}>
		<Typography variant="h5" textAlign="center">
			{props.value}
		</Typography>
	</Circle>
);

export default Counter;
