import {
	Box,
	Button,
	Grid,
	Dialog,
	DialogContent,
	DialogTitle,
	Paper,
	Stack,
	ThemeProvider,
	Typography,
} from '@mui/material';
import { User } from '../../../types';
import { construct } from '../organization/main-settings';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { LockReset, Visibility, VisibilityOff } from '@mui/icons-material';
import client from '../../../clients/client';
import GenericTabFrame from '../../base-components/generic-tab-frame';

interface userSecurityEditionTabProps {
	showRequiredError: (showError: boolean, message?: string) => void;
	user: User;
}

const UserSecurityEditionTab: React.FC<userSecurityEditionTabProps> = ({ showRequiredError, user }) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const [isSaving, setIsSaving] = useState(false);
	const [showMessage, setShowMessage] = useState(false);

	const onPasswordResetClick = async () => {
		try {
			setIsSaving(true);
			const userData = { userId: user.id, email: user.email, name: user.name };
			const response = await client.resetUserPassword({ data: userData, token: user.token });

			if (response.status === 200) {
				setShowMessage(true);
			}
		} catch (error) {
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<>
			<GenericTabFrame title={intl.formatMessage({ id: 'settings.my-account.security.password' })}>
				<Stack className="text-center">
					<Typography variant="h6">
						{intl.formatMessage({ id: 'settings.my-account.security.resetPassword' })}
					</Typography>
					<LoadingButton loading={isSaving} className="mt-3" onClick={onPasswordResetClick} variant="contained">
						<LockReset fontSize="large"></LockReset>
					</LoadingButton>
				</Stack>
			</GenericTabFrame>
			<Dialog
				onClose={setShowMessage}
				open={showMessage}
				onKeyDown={(event) => event.key === 'Escape' && setShowMessage(false)}
			>
				<DialogTitle>{intl.formatMessage({ id: 'common.email_sent' })}</DialogTitle>
				<DialogContent>
					<Grid container justifyContent={'space-between'} spacing={3}>
						<Grid item xs={12}>
							<Typography>
								{intl.formatMessage({ id: 'settings.my-account.security.password_reset_message' })}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Box className="flex justify-end">
								<Button
									sx={{ fontSize: '14px', width: '115px' }}
									onClick={() => setShowMessage(false)}
									variant="outlined"
								>
									OK
								</Button>
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default UserSecurityEditionTab;
