import { createContext, useContext } from 'react';

export type UpdateTokensType = {
  refresh: boolean;
  setRefresh: (refresh:boolean) => void
}

export const UpdateTokensContext = createContext<UpdateTokensType>({
    refresh: true,
    setRefresh: (refresh:boolean) => {},
});

export const useUpdateTokensContext = () => useContext(UpdateTokensContext)