import * as React from 'react';
import { Container, Box, debounce, styled, Button, Typography,  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import client from '../clients/client';
import { useEffect } from 'react';

const NextButton = styled(LoadingButton)(({ theme }) => ({
	backgroundColor: '#000',
	color: '#FFF',
	border: '1px solid #000',
	borderRadius: '30px',
	paddingLeft: '40px',
    paddingRight: '40px',
    textDecoration: 'none',
	"&:hover": {
		color: '#FFF',
		backgroundColor: '#000'
	}
}));

const StyledAlert2 = styled(MuiAlert)(({ theme }) => ({
    border: 'none',
    backgroundColor: '#D8DCED',
    borderRadius: '10px',
    color: '#222',
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export enum ValidationStatus {
	PENDING_VALIDATION = 'pending_validation',
	PENDING_APPROVAL = 'pending_approval',
    DELETED = 'deleted'
}
interface Props {
	status: ValidationStatus;
    userId?: string;
}

const Validation = (props: Props) => {
    const intl = useIntl();
	const { logout } = useAuth0();
    const { status, userId } = props;
    const [snackBar, setSnackbar] = React.useState({message: '', error: false});
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        // Add the beforeunload event listener to clear localStorage on page refresh
        window.addEventListener('beforeunload', () => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
        });
    
        // Remove the event listener when the component is unmounted
        return () => {
          window.removeEventListener('beforeunload', () => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
          });
        };
    }, []);

    const logoutFunction = () => {
        localStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    
    const resendEmail = debounce(() => {
        userId && client
			.resendVerificationEmail({ userId })
			.then((data) => {
                setSnackbar({ message: intl.formatMessage({ id: 'auth.signup.validation.sent_mail' }), error: false})
				setLoading(false);
			})
			.catch(() => {
                setSnackbar({ message: intl.formatMessage({ id: 'auth.signup.default_error_message' }), error: true});
                setLoading(false)
            })
	}, 250);

    const statusProps = {
        [ValidationStatus.PENDING_VALIDATION]: {
            title: intl.formatMessage({ id: 'auth.signup.validation.validate_mail' }),
            alert: intl.formatMessage({ id: 'auth.signup.validation.validate' }),
            buttonTextId: intl.formatMessage({ id: 'auth.signup.validation.send_mail' }),
            onPress: () => {setLoading(true); resendEmail()}
        },
        [ValidationStatus.PENDING_APPROVAL]: {
            title: intl.formatMessage({ id: 'auth.signup.validation.create_user' }),
            alert: intl.formatMessage({ id: 'auth.signup.validation.create' }),
            buttonTextId: intl.formatMessage({ id: 'common.go_to_start' }),
            onPress: logoutFunction
        },
        [ValidationStatus.DELETED]: {
            title: intl.formatMessage({ id: 'auth.signup.validation.deleted_user' }),
            alert: intl.formatMessage({ id: 'auth.signup.validation.deleted' }),
            buttonTextId: intl.formatMessage({ id: 'common.go_to_start' }),
            onPress: logoutFunction
        }
    };

    return(
        <Container sx={{ px: { xs: 0, sm: 5, md: 10, lg: 20 }}}>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', px:{xs: 7, sm: 7}, mb: 4, mt: 8}}>{statusProps[status].title}</Typography>
            <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', my: 3, mx: 7 }}>
                <StyledAlert2 severity="info" sx={{  mb: 5, px: {xs: 3, sm: 4, md: 7}, color: '#222', borderRadius:3, '& .MuiAlert-icon': { display: 'none' } }}>
                    {intl.formatMessage({ id: statusProps[status].alert })}
                </StyledAlert2>
                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: {xs: 'column', md: 'row'}}}>
                    {status === ValidationStatus.PENDING_VALIDATION && <Button onClick={logoutFunction} sx={{ mb: {xs:3, md: 0}, mr:{ xs:0, md: 5}, textTransform: 'none', background: '#000 !important', color: '#FFF', border: '1px solid #000', borderRadius: 10, px: '40px' }} variant="contained">
                        {intl.formatMessage({ id: 'header.menu.logout_button' })}
                    </Button>}
                    <NextButton onClick={statusProps[status].onPress} variant="contained" loading={loading} loadingPosition="start">
                        {intl.formatMessage({ id: statusProps[status].buttonTextId })}
                    </NextButton>
                </Box>
            </Box>
            <Snackbar open={!!snackBar.message} onClose={() => setSnackbar({message: '', error: false})} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert severity={snackBar.error ? "error" : "success"} sx={{ width: '100%' }} >
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </Container>
        
    )
}

export default Validation;

