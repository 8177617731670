import { QuoteStatus, QuoteStatusForSeller } from './types';

export const colorsByStatus: { [x: string]: string } = {
	[QuoteStatus.EN_PROCESO_DE_COTIZACION]: '#182039',
	[QuoteStatus.COTIZACION_RECIBIDA]: '#0E2160',
	[QuoteStatus.EN_PROCESO_DE_COMPRA]: '#112984',
	[QuoteStatus.EN_PROCESO_DE_ENTREGA]: '#1737B7',
	[QuoteStatus.COMPRA_FINALIZADA]: '#556AC3',
	[QuoteStatusForSeller.PENDING_QUOTATION]: '#182039',
	[QuoteStatusForSeller.AWAITING_FOR_RESPONSE]: '#0E2160',
	[QuoteStatusForSeller.IN_PURCHASE_PROCESS]: '#112984',
	[QuoteStatusForSeller.FINISHED_PURCHASE]: '#556AC3',
};


export const showOlderListsConstructors = ['50'];
export const olderStartDateConstructores = '2022-11-01'
export const startDateConstructor = '2023-01-01';
export const startDateSeller = '2023-03-01';

export const ID_COLUMN_STYLE = {
	width: '48px',
};


export const ID_EXTENDED_COLUMN_STYLE = {
	width: '80px',
};

export const ACTION_COLUMN_STYLE = {
	width: '50px',
};

export const DeliveryStatus: any = {
	seller: {
		1: 'deliveries.status.seller.1',
		2: 'deliveries.status.seller.2',
		3: 'deliveries.status.seller.3'
	},
	constructor: {
		1: 'deliveries.status.constructor.1',
		2: 'deliveries.status.constructor.2',
		3: 'deliveries.status.constructor.3'
	}
}
