import { useCallback, useEffect, useState } from 'react';
import {
	Grid,
	Typography,
	Button,
	Box,
	Paper,
	Stack,
	Divider,
	MenuItem,
	LinearProgress,
	InputLabel,
} from '@mui/material';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import { IProjectDetailData } from '../projectTypes';
import TextFieldWithPlaceholder from '../../text-field-with-placeholder';
import SelectWithPlaceholder from '../../select-with-placeholder';
import { BaseFile, ConstructionAddress, ConstructionAddressResponse, User } from '../../../types';
import ProjectsService from '../projectsService';
import ProjectFilesService from '../projectFilesService';
import SkeletonInput from '../../skeleton-input';
import client from '../../../clients/client';
import AddressForm from '../../address-form';
import SideModalContainer from '../../modals/side-modal-container';
import InformationBar from '../../information-bar';
import NuqleaFileUpload from '../../nuqlea-file-uploader';
import { fileToBase64 } from '../../../utils/data-utils';
import { useSetRecoilState } from 'recoil';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';

export interface Props {
	user: User;
	project: IProjectDetailData | null;
	projectFiles: BaseFile[] | null;
	handleCancel: () => void;
	callback?: () => void;
}

interface SimpleAddress {
	id: string;
	address: string;
}

const acceptedFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', '.dwg'];

const ProjectForm = ({ user, project, projectFiles, handleCancel, callback }: Props) => {
	const [isLoading, setIsLoading] = useState(true);
	const isMobile: boolean = useMobile();
	const intl = useIntl();
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);

	const [firstSubmit, setFirstSubmit] = useState(false);

	const id = project?.id ?? undefined;
	const [name, setName] = useState<string | undefined>(project?.name ?? '');
	const [constructionResponsible, setConstructionResponsible] = useState<string>(
		project?.ns_construction_responsible_id ?? ''
	);
	const [purchaseResponsible, setPurchaseResponsible] = useState<string>(project?.ns_purchase_responsible_id ?? '');
	const [address, setAddress] = useState<string | undefined>(project?.address ?? '');
	const [type, setType] = useState<string>(project?.type_id ?? '');
	const [financingType, setFinancingType] = useState<string>(project?.financing_type_id ?? '0');
	const [description, setDescription] = useState<string>(project?.description ?? '');

	const [projectResponsibles, setProjectResponsibles] = useState<any[]>([]);
	const [projectTypes, setProjectTypes] = useState<any[]>([]);
	const [finaningTypes, setFinancingTypes] = useState<any[]>([]);
	const [addresses, setAddresses] = useState<ConstructionAddress[]>([]);
	const [addressModal, setAddressModal] = useState<boolean>(false);

	const [projectCurrentFiles, setProjectCurrentFiles] = useState<File[]>([]);
	const [projectNewFiles, setProjectNewFiles] = useState<File[]>([]);
	const [projectRemovedFiles, setProjectRemovedFiles] = useState<string[]>([]);

	const [projectCurrentFilesMap, setProjectCurrentFilesMap] = useState<Map<string, string>>(new Map());
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isBusy, setIsBusy] = useState(false);

	let uploadedFile: File[] = [];

	const isFormValid = () => {
		// TODO: Falta definir que campos son opcionales y cuales obligatorios.
		if (name === '') {
			setShowError(true);
			setErrorMessage(intl.formatMessage({ id: 'common.error_required_fields' }));
			return false;
		}
		if (description === '') {
			setShowError(true);
			setErrorMessage(intl.formatMessage({ id: 'common.error_required_fields' }));
			return false;
		}

		return true;
	};

	const convertFilesToBase64 = async (filesArray: File[]) => {
		let files: {
			name: string;
			content: string;
			format: string;
		}[] = [];

		if (filesArray.length > 0) {
			for (let file of filesArray) {
				file.size > 0 &&
					files.push({
						name: file.name,
						content: (await fileToBase64(file)).base64 ?? '',
						format: file.type,
					});
			}
		}

		return files.length ? files : null;
	};

	const uploadNewFiles = async (projectId: number) => {
		const files = await convertFilesToBase64(projectNewFiles);
		files?.map(async (file) => {
			try {
				await ProjectFilesService.uploadFile(user, projectId, file.content, file.name);
			} catch (error) {
				console.error(error);
			}
		});
	};

	const removeFiles = async (projectId: number) => {
		for (let filename of projectRemovedFiles) {
			try {
				let filepath = projectCurrentFilesMap.get(filename);
				if (filepath) {
					await ProjectFilesService.removeFile(user, filepath);
				}
			} catch (error) {
				console.error(error, filename);
			}
		}
	};

	const handleSubmit = async () => {
		try {
			setFirstSubmit(true);

			if (!isFormValid()) {
				setShowError(true);
				return;
			}

			setIsBusy(true);

			let response: any;
			if (id) {
				response = await updateProject(id);
			} else {
				response = await createProject();
			}

			if (response.project) {
				await uploadNewFiles(response.project.id);
				await removeFiles(response.project.id);

				if (callback) {
					callback();
				}
			}

			setIsBusy(false);
		} catch (error) {
			setErrorMessage(intl.formatMessage({ id: 'common.error_message' }));
			console.error(error);
			setIsBusy(false);
		}
	};

	const updateProject = async (id: number) => {
		const constructionResponsibleValue: string | undefined = projectResponsibles.find(
			(responsable) => responsable.id === constructionResponsible
		)?.value;
		const purchaseResponsibleValue: string | undefined = projectResponsibles.find(
			(responsable) => responsable.id === purchaseResponsible
		)?.value;
		const projectTypeValue: string | undefined = projectTypes.find((param) => param.id === type)?.value;
		const financingTypeValue: string | undefined = finaningTypes.find((param) => param.id === financingType)?.value;

		const projectData: IProjectDetailData = {};

		if (name !== project?.name && name !== '') {
			projectData.name = name ?? null;
		}

		if (
			constructionResponsibleValue !== project?.construction_responsible &&
			constructionResponsibleValue !== undefined
		) {
			projectData.construction_responsible = constructionResponsibleValue ?? null;
		}

		if (constructionResponsible !== project?.ns_construction_responsible_id && constructionResponsible !== '') {
			projectData.ns_construction_responsible_id = constructionResponsible ?? null;
		}

		if (purchaseResponsibleValue !== project?.purchase_responsible && purchaseResponsibleValue !== undefined) {
			projectData.purchase_responsible = purchaseResponsibleValue ?? null;
		}

		if (purchaseResponsible !== project?.ns_purchase_responsible_id && purchaseResponsible !== '') {
			projectData.ns_purchase_responsible_id = purchaseResponsible ?? null;
		}

		if (address !== project?.address && address !== '') {
			projectData.address = address ?? null;
		}

		if (projectTypeValue !== project?.type && projectTypeValue !== undefined) {
			projectData.type = projectTypeValue ?? null;
		}

		if (type !== project?.type_id && type !== '') {
			projectData.type_id = type ?? null;
		}

		if (financingTypeValue !== project?.financing_type && financingTypeValue !== undefined) {
			projectData.financing_type = financingTypeValue ?? null;
			projectData.ns_profile = financingTypeValue ?? null;
		}

		if (financingType !== project?.financing_type_id && financingType !== '') {
			projectData.financing_type_id = financingType ?? null;
		}

		if (description !== project?.description) {
			projectData.description = description ?? null;
		}

		try {
			return await ProjectsService.editProject(user, id, projectData);
		} catch (error) {
			console.error(error);
		}
	};

	const createProject = async () => {
		const projectData: IProjectDetailData = {
			name,
			construction_responsible:
				projectResponsibles.find((responsable) => responsable.id === constructionResponsible)?.value ?? null,
			ns_construction_responsible_id: constructionResponsible,
			purchase_responsible:
				projectResponsibles.find((responsable) => responsable.id === purchaseResponsible)?.value ?? null,
			ns_purchase_responsible_id: purchaseResponsible,
			address,
			type: projectTypes.find((param) => param.id === type)?.value ?? null,
			type_id: type,
			financing_type: finaningTypes.find((param) => param.id === financingType)?.value ?? null,
			financing_type_id: financingType,
			description,
			cover_surface: null,
			semicover_surface: null,
			start: null,
			due: null,
			common_cover_surface: null,
			budget_original: null,
			budget_spent: null,
			place: null,
			geo: null,
			external_app_id: null,
			common_semicover_surface: null,
			ns_profile: finaningTypes.find((param) => param.id === financingType)?.value ?? null,
			ns_construction_type: null,
			ns_delivery_date: null,
			ns_amount: null,
			ns_indexing_type: null,
			ns_address: null,
			ns_address_formatted: null,
		};

		try {
			return await ProjectsService.createProject(user, projectData);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchConstructorAddresses = useCallback(async (latest: string | undefined = undefined) => {
		const responseAddresses: ConstructionAddressResponse[] | any = await client.getOrganizationAddresses({
			token: user.token,
			id: user.id_external,
		});

		const clientAddresses = responseAddresses.data
			.sort((a: ConstructionAddressResponse, b: ConstructionAddressResponse) => {
				const dateA = a.created_at.value ? new Date(a.created_at.value) : null;
				const dateB = b.created_at.value ? new Date(b.created_at.value) : null;

				if (dateA === null && dateB === null) {
					return 0;
				} else if (dateA === null) {
					return 1;
				} else if (dateB === null) {
					return -1;
				} else {
					return dateA.getTime() - dateB.getTime();
				}
			})
			.map((item: ConstructionAddressResponse) => {
				let adapted: SimpleAddress = {
					id: item.id,
					address: item.address,
				};
				return adapted;
			});

		clientAddresses.push({
			id: -1,
			address: 'Nueva Dirección',
		});

		setAddresses(clientAddresses);

		if (latest) {
			setAddress(latest);
		}
	}, []);

	const closeAddressModal = () => {
		setAddressModal(false);
	};

	const handleCreateAddress = (address: ConstructionAddress) => {
		if (address) {
			address.organizationId = user.id_external;
		}

		const saveAddress = async () => {
			try {
				setIsLoading(true);
				const { data } = await client.createOrganizationAddress({ data: address, token: user.token });
				await fetchConstructorAddresses(data.address_id);

				setIsLoading(false);
			} catch (error) {
				console.error(error);
			}
		};

		saveAddress();
		setAddressModal(false);
	};

	const handleAddressChanged = (event: any) => {
		if ((event.target.value as number) === -1) {
			setAddressModal(true);
		} else {
			setAddress(event.target.value as string);
		}
	};

	const castProjectFilesToFile = (projectFiles: BaseFile[] | null) => {
		let files: File[] = [];

		if (projectFiles) {
			for (const file of projectFiles) {
				const blob = new Blob();
				const filename = file.name ?? 'file';
				const newFile = new File([blob], filename);

				files.push(newFile);
			}
		}
		return files;
	};

	const castProjectFilesToMap = (projectFiles: BaseFile[] | null) => {
		let map: Map<string, string> = new Map();

		if (projectFiles) {
			for (const file of projectFiles) {
				if (file.path && file.name) {
					map.set(file.name, file.path);
				}
			}
		}
		return map;
	};

	const handleRemoveFile = (filename: string) => {
		projectCurrentFiles.splice(
			projectCurrentFiles.findIndex((f) => f.name === filename),
			1
		);
		setProjectCurrentFiles([...projectCurrentFiles]);
		setProjectRemovedFiles([...projectRemovedFiles, filename]);
	};

	const handleNewFiles = (files: File[]) => {
		projectCurrentFiles.push(...files);
		setProjectNewFiles([...projectNewFiles, ...files]);
	};

	useEffect(() => {
		try {
			let mounted = true;

			const fetchProjectResponsibles = async () => {
				if (mounted) {
					const response = await ProjectsService.getResponsibles(user);

					if (response.responsibles) {
						const projectResponsibles = response.responsibles.map((responsable: any) => ({
							id: responsable.user_id,
							value: responsable.user_name,
						}));

						setProjectResponsibles(projectResponsibles);
					}
				}
			};

			const fetchProjectTypes = async () => {
				if (mounted) {
					const response = await ProjectsService.getProjectTypes(user);

					if (response.types) {
						const projectTypes = response.types.map((type: any) => ({
							id: type.id,
							value: type.label,
						}));

						setProjectTypes(projectTypes);
					}
				}
			};

			const fetchFinancingTypes = () => {
				if (mounted) {
					const response = ProjectsService.getFinancingTypes(user);

					setFinancingTypes(response);
				}
			};

			const files = castProjectFilesToFile(projectFiles);
			setProjectCurrentFiles(files);
			setProjectCurrentFilesMap(castProjectFilesToMap(projectFiles));

			fetchFinancingTypes();

			Promise.all([fetchProjectResponsibles(), fetchProjectTypes(), fetchConstructorAddresses()]).finally(() => {
				setIsLoading(false);
			});

			return () => {
				mounted = false;
			};
		} catch (error) {
			console.error(error);
		}
	}, []);
 
	useEffect(() => {
		setDisableRootElementScroll(addressModal);
	}, [addressModal]);

	return (
		<>
			<Paper elevation={0} sx={{ pt: '30px', minHeight: '600px' }}>
				<Grid
					container
					pl={'30px'}
					pt={'5px'}
					pb={'30px'}
					pr={isMobile ? '15px' : '45px'}
					alignItems={'stretch'}
					spacing={2}
				>
					<Grid item xs={12} md={6} lg={5} xl={5}>
						<Typography color={'black'} fontWeight={700} fontSize={'16px'}>
							{intl.formatMessage({ id: 'projects.general.title' })}
						</Typography>
						{!isMobile && (
							<Typography sx={{ color: '#797979' }} variant="subtitle1">
								{intl.formatMessage({ id: `projects.general.edit_subtitle` })}
							</Typography>
						)}
					</Grid>

					<Grid item mt={isMobile ? 0 : '10px'} xs={12} md={6} lg={7} xl={7}>
						<Box component="form">
							<Stack justifyContent={'space-between'} width={'100%'} minHeight={'600px'}>
								{!isLoading ? (
									<TextFieldWithPlaceholder
										required
										label={intl.formatMessage({ id: 'projects.general.data.name' })}
										placeholder={intl.formatMessage({ id: 'projects.general.data.name.placeholder' })}
										value={name}
										sx={{ width: '100%' }}
										error={firstSubmit && name === '' ? true : false}
										onChange={(e) => setName(e.target.value)}
									></TextFieldWithPlaceholder>
								) : (
									<SkeletonInput />
								)}

								{!isLoading ? (
									<Box>
										<SelectWithPlaceholder
											fullWidth
											label={intl.formatMessage({ id: 'projects.general.data.construction_responsible' })}
											placeholder={intl.formatMessage({
												id: 'projects.general.data.construction_responsible.placeholder',
											})}
											size="small"
											value={projectResponsibles.length > 0 ? constructionResponsible : ''}
											onChange={(event) => setConstructionResponsible(event.target.value as string)}
										>
											{projectResponsibles.map((item: { id: number; value: string }) => {
												return (
													<MenuItem key={item.id} value={item.id}>
														{item.value}
													</MenuItem>
												);
											})}
										</SelectWithPlaceholder>
									</Box>
								) : (
									<SkeletonInput />
								)}

								{!isLoading ? (
									<Box>
										<SelectWithPlaceholder
											fullWidth
											label={intl.formatMessage({ id: 'projects.general.data.purchasing_manager' })}
											placeholder={intl.formatMessage({ id: 'projects.general.data.purchasing_manager.placeholder' })}
											size="small"
											value={projectResponsibles.length > 0 ? purchaseResponsible : ''}
											onChange={(event) => setPurchaseResponsible(event.target.value as string)}
										>
											{projectResponsibles.map((item) => {
												return (
													<MenuItem key={item.id} value={item.id}>
														{item.value}
													</MenuItem>
												);
											})}
										</SelectWithPlaceholder>
									</Box>
								) : (
									<SkeletonInput />
								)}

								{!isLoading ? (
									<Box>
										<SelectWithPlaceholder
											fullWidth
											label={intl.formatMessage({ id: 'projects.general.data.address' })}
											placeholder="Seleccione una dirección"
											size="small"
											value={addresses.length > 0 ? address : ''}
											onChange={(event) => handleAddressChanged(event)}
										>
											{addresses.map((item) => {
												return (
													<MenuItem key={item.id} value={item.id}>
														{item.address}
													</MenuItem>
												);
											})}
										</SelectWithPlaceholder>
									</Box>
								) : (
									<SkeletonInput />
								)}

								{!isLoading ? (
									<Box>
										<SelectWithPlaceholder
											fullWidth
											label={intl.formatMessage({ id: 'projects.general.data.project_type' })}
											placeholder={intl.formatMessage({ id: 'projects.general.data.project_type.placeholder' })}
											size="small"
											value={projectTypes.length > 0 ? type : ''}
											onChange={(event) => setType(event.target.value as string)}
										>
											{projectTypes.map((item) => {
												return (
													<MenuItem key={item.id} value={item.id}>
														{item.value}
													</MenuItem>
												);
											})}
										</SelectWithPlaceholder>
									</Box>
								) : (
									<SkeletonInput />
								)}

								{!isLoading ? (
									<Box>
										<SelectWithPlaceholder
											fullWidth
											label={intl.formatMessage({ id: 'projects.general.data.financing_type' })}
											placeholder={intl.formatMessage({ id: 'projects.general.data.financing_type.placeholder' })}
											size="small"
											value={finaningTypes.length > 0 ? financingType : ''}
											onChange={(event) => setFinancingType(event.target.value as string)}
										>
											{finaningTypes.map((item) => {
												return (
													<MenuItem key={item.id} value={item.id}>
														{item.value}
													</MenuItem>
												);
											})}
										</SelectWithPlaceholder>
									</Box>
								) : (
									<SkeletonInput />
								)}

								{!isLoading ? (
									<TextFieldWithPlaceholder
										required
										multiline
										rows={4}
										label={intl.formatMessage({ id: 'projects.general.data.description' })}
										placeholder={intl.formatMessage({ id: 'projects.general.data.description.placeholder' })}
										sx={{ width: '100%' }}
										value={description}
										error={firstSubmit && description === '' ? true : false}
										onChange={(event) => setDescription(event.target.value as string)}
									></TextFieldWithPlaceholder>
								) : (
									<SkeletonInput />
								)}
							</Stack>
						</Box>
					</Grid>
					<Grid item xs={12}>
						{isBusy && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
						{showError && (
							<InformationBar sx={{ mb: 2 }} icon="error" message={errorMessage} color="#FF3D00"></InformationBar>
						)}
						<Divider className="my-4" orientation="horizontal"></Divider>
					</Grid>
					<Grid item xs={12} md={6} lg={5} xl={5}>
						<Typography color={'black'} fontWeight={700} fontSize={'16px'}>
							{intl.formatMessage({ id: 'common.docs' })}
						</Typography>
						{!isMobile && (
							<Typography sx={{ color: '#797979' }} variant="subtitle1">
								{intl.formatMessage({ id: `projects.general.edit_subtitle` })}
							</Typography>
						)}
					</Grid>

					<Grid item xs={12} md={6} lg={5} xl={5}>
						{!isLoading ? (
							<>
								<InputLabel>{intl.formatMessage({ id: 'projects.general.edit.files_input' })}</InputLabel>
								{projectCurrentFiles &&
									projectCurrentFiles.length > 0 &&
									projectCurrentFiles.map((item, index) => {
										let array: File[] = [];
										array.push(item);

										return (
											<Box pb={'10px'} key={index}>
												<NuqleaFileUpload value={array} key={index} onRemove={handleRemoveFile} />
											</Box>
										);
									})}
								<Box pb={'10px'}>
									<NuqleaFileUpload
										value={uploadedFile}
										onChange={handleNewFiles}
										specText={intl.formatMessage({ id: 'projects.general.file_uploader_limitations' })}
										acceptedFormats={acceptedFormats}
									/>
								</Box>
							</>
						) : (
							<SkeletonInput />
						)}
					</Grid>

					{/* offset */}
					{!isMobile && <Grid item xs={12} md={6} lg={5} xl={5}></Grid>}
					<Grid item xs={12} md={6} lg={5} xl={5}>
						<Stack direction="row" justifyContent={isMobile ? 'space-around' : 'left'} spacing={2}>
							<Button variant="outlined" onClick={handleCancel}>
								{intl.formatMessage({ id: `common.cancel` })}
							</Button>
							<Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
								{intl.formatMessage({ id: `common.save` })}
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Paper>

			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={addressModal}
				onClose={closeAddressModal}
			>
				<AddressForm columnLayout retrieveAddress={handleCreateAddress} onCancel={closeAddressModal}></AddressForm>
			</SideModalContainer>
		</>
	);
};

export default ProjectForm;
