import { Close, Launch } from '@mui/icons-material';
import { Box, BoxProps, Fade, IconButton, LinearProgress, Modal, Paper, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { ThemeProvider } from '@emotion/react';
import { useEffect, useState } from 'react';
import { ConstructionAddress, User, Location, Role } from '../types';
import client from '../clients/client';
import ModalContainer from './modals/modal-container';
import AddressForm from './address-form';
import AddressDetails from './settings/organization/address-details';
import { getAddressesWitContacts } from './settings/organization/addressHelper';
import { useMobile } from '../hooks/use-mobile';

interface deliveryAddressProps extends BoxProps {
	address: string;
	locality?: string;
	user: User;
	withdrawal?: boolean;
}

const DeliveryAddress: React.FC<deliveryAddressProps> = ({ address, user, withdrawal = false, locality, ...props }) => {
	const [fullAddress, setFullAddress] = useState<ConstructionAddress>();
	const [formattedAddress, setFormattedAddress] = useState<string>();
	const [showAddressModal, setShowAddressModal] = useState(false);
	const [location, setLocation] = useState<Location>();
	const [loading, setLoading] = useState(false);
	const intl = useIntl();
	const isMobile = useMobile();
	const checkAddress = (address: string): boolean => /^[a-z0-9]{8,32}$/.test(address.replace(/-/g, ''));

	/* const getAddressFromUrl = (addressUrl: string) => {
		const geocoder = new google.maps.Geocoder();
		const url = new URL(addressUrl);
		const qParam = url.searchParams.get('q') ?? ' ';
		const [lat, lng] = qParam.split(',').map(Number);

		geocoder
			.geocode({
				location: { lat, lng },
			})
			.then((res): void => {
				const address = res.results[0].formatted_address;
				const location = {
					google_place_id: res.results[0].place_id,
					address,
					coordinates: {
						lat: lat,
						lng: lng,
					},
					full_response: res.results[0],
				};
				console.log(res.results[0]);
			});
	}; */

	let isMapAddress = checkAddress(address);

	useEffect(() => {
		const fetchData = async () => {
			if (isMapAddress && !withdrawal) {
				setLoading(true);
				const response = await client.getAddress({ token: user.token, id: address });

				let completeAddress = await getAddressesWitContacts(response.data);
				setFullAddress(completeAddress[0]);

				setLoading(false);
			} else if (withdrawal) {
				setFormattedAddress(address);
			}
		};
		fetchData();
	}, [isMapAddress]);

	useEffect(() => {
		const currentLocation: Location | undefined = fullAddress
			? {
					address: fullAddress.address,
					coordinates: { lat: fullAddress.latitude, lng: fullAddress.longitude },
					google_place_id: fullAddress.googlePlaceId,
			  }
			: undefined;
		setLocation(currentLocation);
	}, [fullAddress]);

	return (
		<Box sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'end',
			...props.sx
		}}>
			<Typography variant="subtitle1" display="inline" color="#7E7E82">
				<Box component="span" fontWeight={700} color="black" mr={.6}>
					{intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.deliver_address' })}
				</Box>
				{loading ? (
					<LinearProgress sx={{ backgroundColor: '#406AFF', height: '2px' }} />
				) : formattedAddress ? (
					formattedAddress
				) : isMapAddress ? (
					fullAddress ? (
						fullAddress?.address
					) : (
						' -'
					)
				) : locality ? (
					locality
				) : (
					' -'
				)}
			</Typography>
			{!withdrawal && (
				<Box>
					{loading ? (
						<></>
					) : (
						isMapAddress &&
						!!fullAddress && (
							<IconButton onClick={() => setShowAddressModal(true)} className="p-0 ml-1" color="primary">
								<Launch fontSize={'small'}></Launch>
							</IconButton>
						)
					)}

					<Modal
						sx={{ overflow: 'scroll' }}
						open={showAddressModal}
						onKeyDown={(event) => event.key === 'Escape' && setShowAddressModal(false)}
					>
						<Fade in={showAddressModal}>
							<Box>
								<ModalContainer sx={{ padding: 0, height: isMobile ? '95%' : null }}>
									{fullAddress && (
										<Paper className="p-8">
											<Box className="flex justify-between items-center mt-[-10px]">
												<Typography fontSize="1.5em" fontWeight={700}>
													{intl.formatMessage({ id: 'mytokens.seller.dashboard.redeem_request.deliver_address_modal' })}
												</Typography>
												<IconButton onClick={() => setShowAddressModal(false)}>
													<Close></Close>
												</IconButton>
											</Box>

											<AddressDetails
												constAddress={fullAddress}
												handleDelete={() => {}}
												switchEditMode={() => {}}
												showMap
												location={location}
												readOnly
											></AddressDetails>
										</Paper>
									)}
								</ModalContainer>
							</Box>
						</Fade>
					</Modal>
				</Box>
			)}
		</Box>
	);
};

export default DeliveryAddress;
