import { Box, Button, Grid, Paper, PaperProps, Typography } from '@mui/material';
import ModalContainer from './modal-container';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';

interface GenericConfirmationModalProps extends PaperProps {
	title?: string;
	isLoading?: boolean;
	message: string;
	primaryButtonText: string;
	primaryButtonCallback: () => void;
	secondaryButtonText: string;
	secondaryButtonCallback: () => void;
}

const GenericConfirmationModal: React.FC<GenericConfirmationModalProps> = ({
	title,
	isLoading,
	message,
	primaryButtonText,
	secondaryButtonText,
	primaryButtonCallback,
	secondaryButtonCallback,
	...props
}) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		isLoading && setLoading(isLoading);
	}, [isLoading]);

	return (
		<ModalContainer>
			<Paper sx={{ height: '250px', width: '425px', py: '35px', px: '90px', ...props.sx }}>
				<Grid container display={'flex'} justifyContent={'space-between'} spacing={3}>
					{title && (
						<Grid textAlign={'center'} item xs={12}>
							<Typography variant="h6" fontWeight={700}>
								{title}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography>{message}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box display={'flex'} justifyContent={'space-between'}>
							<Button sx={{ fontSize: '14px', width: '115px' }} onClick={secondaryButtonCallback} variant="outlined">
								{secondaryButtonText}
							</Button>
							<LoadingButton
								loading={loading}
								sx={{ fontSize: '14px', width: '115px' }}
								onClick={primaryButtonCallback}
								variant="contained"
							>
								{primaryButtonText}
							</LoadingButton>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</ModalContainer>
	);
};

export default GenericConfirmationModal;
