import axios, { AxiosResponse } from 'axios';
import { Response, User } from '../../types';

const customAxios = (user: User) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            config.headers['Authorization'] = `Bearer ${user.token}`;

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const service = {

    getAudiences: async (user: User): Promise<Audience[]> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/audiences`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    getAudienceById: async (user: User, audienceId: string): Promise<Audience> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/audiences/${audienceId}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    searchAudiences: async (user: User, searchTerm: string): Promise<Audience[]> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(`/audiences/search`, { query: searchTerm });

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    getAudienceUsers: async (user: User, audienceId: string): Promise<AudienceUser[]> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/audiences/${audienceId}/users`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    getAudienceConditions: async (user: User, audienceId: string): Promise<AudienceCondition[]> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/audiences/${audienceId}/conditions`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    }
}

export interface Audience {
    id: string,
    name: string,
    seller_name: string,
    bonification_value: number,
    bonification_value_sign: string,
    bonification_value_type: string,
    token_value_without_iva: number,
    members_count: string,
    status: string,
    updated_at: string
}

export interface AudienceUser {
    id_constructora: string,
    name_constructora: string,
    logo_constructora: string,
    origin: string,
    seller_name?: string
}

export interface AudienceCondition {
    name: string,
    description: string,
    value: number,
    value_type: string,
    value_sign: string,
    condition_type_id: string,
    condition_type_label: string,
    condition_type_code: string
}

export default service;