import { useEffect, useState } from 'react';
import { User } from '../types';
import client from '../clients/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import userState from '../atoms/user-state';

const useAuthorization = (canGetToken:boolean): [User | {}, Function] => {
	const [theUser, setTheUser] = useRecoilState(userState);
	const resetUser = useResetRecoilState(userState);
	const { getAccessTokenSilently } = useAuth0();

	const savedUser = localStorage.getItem('user');
	const parsed = !!savedUser && JSON.parse(savedUser);
	const newUser = parsed?.error === false && parsed?.user;
	const newToken = parsed?.error === false && parsed?.user?.token;

	useEffect(() => {
		if (Object.keys(theUser).length === 0 && canGetToken) {
			getAccessTokenSilently()
				.then((tokk: string) => {
					client
						.silentMe({ token: tokk })
						.then((res) => {

							let finalUser = newUser || res.data.user;
							let finalToken = newToken || res.data.token;

							if(newUser && newUser.status !== res.data.user?.status) {
								newUser.status = res.data.user?.status
							}

							if(newUser && newUser.role !== res.data.user?.role) {
								newUser.role = res.data.user?.role
							}
							localStorage.setItem('user', JSON.stringify({ user: {...finalUser, token: finalToken}, error: false }));
							localStorage.setItem('token', JSON.stringify(finalToken));

							setTheUser({ ...finalUser, token: finalToken });
						})
						.catch((err) => {
							resetUser();
							console.log(err);
						});
				})
				.catch((error: any) => {
					resetUser();
				});
		}
	}, [canGetToken]);

	return [theUser, setTheUser];
};

export default useAuthorization;
