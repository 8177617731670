import React from 'react';
import { styled } from '@mui/system';

interface CustomSwitchProps {
	checked: boolean;
	onChange: () => void;
}

const StyledSwitch = styled('div')(({ theme }) => ({
	position: 'relative',
	display: 'inline-block',
	width: '1.75rem',
	height: '1rem',
	backgroundColor: '#fff',
	borderRadius: '1rem',
	border: `1px solid ${theme.palette.grey[400]}`,
	cursor: 'pointer',
	transition: 'background-color 0.3s ease-in-out',
	padding: '2px',
	'& input': {
		display: 'none',
	},
	'& .dot': {
		position: 'absolute',
		top: '50%',
		left: '3px',
		width: '0.6rem',
		height: '0.6rem',
		borderRadius: '50%',
		backgroundColor: theme.palette.grey[400],
		transform: 'translateY(-50%)',
		transition: 'transform 0.15s ease-in-out',
	},
	'& input:checked + .dot': {
		transform: 'translateX(0.7rem) translateY(-50%)',
		backgroundColor: theme.palette.primary.main,
	},
}));

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange }) => {
	return (
		<StyledSwitch>
			<input type="checkbox" checked={checked} onChange={onChange} />
			<div className="dot"></div>
		</StyledSwitch>
	);
};

export default CustomSwitch;
