import { Box, MenuItem, Button, Stack, LinearProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import SelectWithPlaceholder from '../../select-with-placeholder';
import SelectMultiWithPlaceholder from '../../select-multiple-with-placeholder';
import { purchaseBehavior } from './main-settings';
import client from '../../../clients/client';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import logoPlaceholder from '../../../assets/logoPlaceholder.png';
import { useMobile } from '../../../hooks/use-mobile';

interface purchaseEditionTabProps {
	callback: (isEditionMode: boolean) => void;
	purchaseBehavior?: purchaseBehavior;
	constructorId: string;
	showRequiredError: (show: boolean) => void;
	updateDataShown: (data: object) => void;
	token: string;
	user: string;
}

const PurchasesEditionTab: React.FC<purchaseEditionTabProps> = ({
	callback,
	purchaseBehavior,
	constructorId,
	showRequiredError,
	updateDataShown,
	token,
	user,
}) => {
	const intl = useIntl();
	const getActivities = () => {
		let items = purchaseBehavior?.activities;
		if (items) {
			return items.includes(',') ? items.split(',') : [items];
		}
		return ['0'];
	};

	const [usualProviderSelected, setUsualProviderSelected] = useState(purchaseBehavior?.vendor_type ?? '0');
	const [itemsSelected, setItemsSelected] = useState<string[]>(getActivities());
	const [usualPayMode, setUsualPayMode] = useState(purchaseBehavior?.payment_type ?? '0');
	const [insuranceCaution, setInsuranceCaution] = useState(purchaseBehavior?.cautions ?? '0');
	const [gathering, setGathering] = useState(purchaseBehavior?.stockpilling_type ?? '0');
	const [usualLogistic, setUsualLogistic] = useState(purchaseBehavior?.shipping_type ?? '0');
	const [isSaving, setIsSaving] = useState(false);

	const itemsOptions = [
		{ value: 'Estructura resistente' },
		{ value: 'Mampostería' },
		{ value: 'Cubiertas' },
		{ value: 'Revoque' },
		{ value: 'Pisos y revestimientos' },
		{ value: 'Construcción en seco' },
		{ value: 'Electricidad' },
		{ value: 'Puertas/Aberturas' },
		{ value: 'Grifería y Sanitarios' },
		{ value: 'Pintura' },
		{ value: 'Marmolería' },
		{ value: 'Amoblamientos' },
	];

	const providerOptions = [
		{ value: 'Distribuidor local' },
		{ value: 'Distribuidor nacional' },
		{ value: 'Fabricantes' },
		{ value: 'Otros' },
	];

	const payModeOptions = [
		{ value: 'Contado' },
		{ value: 'Pago a plazo hasta 30 días' },
		{ value: 'Pago a plazo hasta 60 días' },
		{ value: 'Canje de metros' },
	];

	const insuranceOptions = [
		{ value: 'Si, para todos los requerimientos' },
		{ value: 'Solo para algunas compras' },
		{ value: 'No' },
	];

	const logisticOptions = [
		{ value: 'Cotizo siempre puesto en obra' },
		{ value: 'Puedo retirar materiales en todo el país' },
		{ value: 'Puedo retirar materiales en la ciudad de la obra' },
		{ value: 'Puedo retirar materiales liviano y/o terminaciones' },
	];

	const gatheringOptions = [
		{ value: 'Si, obra gruesa' },
		{ value: 'Si, terminaciones' },
		{ value: 'Si, ambas' },
		{ value: 'No' },
	];

	function sanitizeData(data: any) {
		const newObj: Record<string, string | string[] | null> = {};

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key];

				if (value === '0' || (Array.isArray(value) && value.length === 1 && value[0] === '0')) {
					newObj[key] = null;
				} else {
					newObj[key] = value;
				}
			}
		}

		return newObj;
	}

	const handleSave = async () => {
		const data = {
			constructorId: constructorId,
			activities: itemsSelected.join(','),
			provider: usualProviderSelected,
			payMode: usualPayMode,
			gathering: gathering,
			insurance: insuranceCaution,
			logistic: usualLogistic,
			creator: user,
		};

		try {
			setIsSaving(true);
			const sanitizedData = sanitizeData(data);
			updateDataShown(sanitizedData);
			purchaseBehavior
				? await client.updatePurchaseBehavior({ data: sanitizedData, token: token })
				: await client.createPurchaseBehavior({ data: sanitizedData, token: token });
			callback(false);
		} catch (error) {
			console.log(error);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<GenericTabFrame
			title={intl.formatMessage({ id: 'settings.company.purchases_tab.title' })}
			subtitle={intl.formatMessage({ id: 'settings.company.purchases_tab.subtitle' })}
			buttons={[
				<Button disabled={isSaving} onClick={() => callback(false)} variant="outlined">
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>,
				<Button disabled={isSaving} onClick={handleSave} variant="contained" sx={{ ml: '10px' }}>
					{intl.formatMessage({ id: 'common.save' })}
				</Button>,
			]}
			variant="edition"
		>
			<Stack spacing={2}>
				<Box>
					<SelectMultiWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.purchases_tab.items_of_interest_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_multi_placeholder' })}
						onValueChangeCallback={setItemsSelected}
						value={itemsSelected}
						fullWidth
					>
						{itemsOptions.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectMultiWithPlaceholder>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.purchases_tab.usual_provider_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
						onChange={(event) => setUsualProviderSelected(event.target.value as string)}
						fullWidth
						value={usualProviderSelected}
					>
						{providerOptions.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.purchases_tab.usual_pay_mode_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
						onChange={(event) => setUsualPayMode(event.target.value as string)}
						size="small"
						fullWidth
						value={usualPayMode}
					>
						{payModeOptions.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.purchases_tab.usual_gathering_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
						onChange={(event) => setGathering(event.target.value as string)}
						size="small"
						fullWidth
						value={gathering}
					>
						{gatheringOptions.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.purchases_tab.logistic_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
						onChange={(event) => setUsualLogistic(event.target.value as string)}
						size="small"
						fullWidth
						value={usualLogistic}
					>
						{logisticOptions.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
			</Stack>
			{isSaving && <LinearProgress className="mt-3"></LinearProgress>}
		</GenericTabFrame>
	);
};

export default PurchasesEditionTab;
