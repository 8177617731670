import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { Dialog, Box, Grid, Checkbox, debounce, useMediaQuery, useTheme, LinearProgress, styled } from '@mui/material';
import { FormControl, FormControlLabel } from '@mui/material';
import FileUpload from 'react-material-file-upload';
import { FileError, ErrorCode } from 'react-dropzone';
import { fileToBase64 } from '../../utils/data-utils';
import InputContainer from './dashboard/InputContainer';
import FilterTextField from '../FormTextField';
import { User } from '../../types';
import { useState } from 'react';
import client from '../../clients/client';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	redeemRequestId?: string;
	agreementId: string;
	user: User;
	open: boolean;
	onClose: () => void;
	onAccept?: () => void;
	onReject?: () => void;
}

const FileUploadWrapper = styled('div')<{ errorColor?: string }>(({ theme, errorColor }) => ({
	width: '100%',
	'& > *': {
		border: `2px dashed ${!!errorColor ? errorColor : theme.palette.divider} !important`,
		backgroundColor: theme.palette.background.default,
		borderRadius: 3,
	},
	'& .MuiFormControl-root': {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
		'& .MuiTypography-root': {
			fontSize: '1em',
			paddingTop: 20,
			paddingBottom: 20,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0d6efd',
			fontSize: 30,
			marginBottom: 4,
			marginLeft: 30,
		},
		'& .MuiButtonBase-root': {
			position: 'absolute',
			background: 'transparent',
			color: 'transparent',
			width: '100%',
			height: '14vh',
			borderRadius: 0,
			marginBottom: -8,
			marginLeft: 0,
		},
	},
}));

const FILES_MAX_SIZE = 4;

const filesAllowedTypes = [
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
];

const RedeemShippingQuotationDialog = (props: Props) => {
	const { open, onClose, onReject, redeemRequestId, user } = props;
	const intl = useIntl();
	const theme = useTheme();
	const lessThanTablet = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();

	const [loadingData, setLoadingData] = useState<boolean>(false);
	const [cost, setCost] = useState<number>(0);
	const [freeShipping, setFreeShipping] = useState<boolean>(false);
	const [files, setFiles] = useState<File[]>([]);

	const isFileNedeed = !(cost === 0 || freeShipping);

	const validateFilesLoad = (file: File): FileError | null => {
		if (!filesAllowedTypes.includes(file.type)) {
			const message = intl.formatMessage({ id: 'mytokens.error_message.invalid_file_type' });
			return {
				message,
				code: ErrorCode.FileInvalidType,
			};
		}

		if (file.size / 1024 / 1024 >= FILES_MAX_SIZE) {
			const message = intl.formatMessage(
				{ id: 'mytokens.error_message.invalid_size' },
				{ maxFileSize: FILES_MAX_SIZE }
			);
			return {
				message,
				code: ErrorCode.FileTooLarge,
			};
		}

		return null;
	};

	const closeDialog = () => {
		setLoadingData(false);
		onClose();
	};

	const postRedeemAction = debounce((fields) => {
		client
			.redeemAction({
				fields,
				token: user.token,
			})
			.then((data) => {
				navigate(`/my-tokens/redeem-request/${redeemRequestId}`);
				onClose();
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoadingData(false);
			});
	}, 250);

	const handleClick = () => {
		const baseData = {
			redeem_request_id: redeemRequestId,
			action_type: 2,
			comments: '',
			result: 3,
			shipping_price: freeShipping ? 0 : cost,
		};
		if (!!files.length) {
			fileToBase64(files[0])
				.then((file) => {
					const formattedData = {
						...baseData,
						fileName: file.name,
						fileType: file.type,
						fileData: file.base64,
						where: 'Presupuesto de envio',
					};

					setLoadingData(true);
					postRedeemAction(formattedData);
				})
				.catch((error) => console.log(error));
		} else {
			setLoadingData(true);
			postRedeemAction(baseData);
		}
	};

	return (
		<Dialog
			onClose={!!loadingData ? () => {} : closeDialog}
			aria-labelledby="customized-dialog-title"
			open={open}
			sx={{
				'& .MuiPaper-root': { borderRadius: 1, pb: 0 },
			}}
			maxWidth="md"
		>
			{loadingData && (
				<Box className="linearprogress" sx={{ width: '100%', position: 'absolute', left: 0, top: 0.9 }}>
					<LinearProgress color="inherit" />
				</Box>
			)}
			<DialogTitle sx={{ borderBottom: 'none' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<IconButton
						aria-label="close"
						onClick={!!loadingData ? () => {} : closeDialog}
						disabled={!!loadingData}
						sx={{
							position: 'absolute',
							right: 20,
							top: 12,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				HOLAAAAA
			</DialogTitle>

			<DialogContent sx={{ mt: 0, overflow: 'hidden', '& div, p, span': !!loadingData ? { color: '#00000036' } : {} }}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Box sx={{ width: '75%' }}>
							<Typography variant="h5" sx={{ marginBottom: '4px' }}>
								{intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.title' })}
							</Typography>
							<Typography sx={{ marginBottom: '4px', color: '#72727E' }}>
								{intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.subtitle' })}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container xs={12} rowGap={2}>
							<Grid item xs={12}>
								<FormControl sx={{ xs: '100%', md: '94%' }}>
									<InputContainer title={intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.cost' })}>
										<FilterTextField
											InputProps={{ startAdornment: '$' }}
											value={freeShipping ? 0 : cost}
											placeholder={intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.cost_placeholder' })}
											onChange={(e) => (Number(e.target.value) >= 0 ? setCost(Number(e.target.value)) : null)}
											disabled={freeShipping || loadingData}
											sx={{ width: '100%' }}
										/>
									</InputContainer>
									<FormControlLabel
										disabled={loadingData}
										control={
											<Checkbox
												disabled={loadingData}
												value={freeShipping}
												onChange={(e) => setFreeShipping(e.target.checked)}
												size="small"
											/>
										}
										label={intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.no_cost' })}
										sx={{ fontSize: '0.8em' }}
									/>
								</FormControl>
							</Grid>
							{isFileNedeed && (
								<Grid item xs={12}>
									<FormControl>
										<InputContainer title={intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.file' })}>
											<FileUploadWrapper>
												<FileUpload
													value={files}
													onChange={setFiles}
													validator={(file: File) => validateFilesLoad(file)}
													multiple={false}
													disabled={loadingData}
													buttonText={intl.formatMessage({ id: 'common.upload' })}
													title={intl.formatMessage({ id: 'mytokens.dashboard.upload_file_field_placeholder' })}
													sx={{
														borderRadius: 4,
														mt: 1,
														mb: 0.5,
														width: '100%',
														'& .MuiFormControl-root': {
															display: files && files.length ? 'none' : 'flex',
															minWidth: '97%',
														},
														'& .MuiSvgIcon-root': {
															fill: loadingData ? '#00000036!important' : '#0d6efd',
														},
													}}
												/>
											</FileUploadWrapper>
										</InputContainer>
									</FormControl>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 4 }}>
				<Button
					onClick={onReject}
					sx={{
						background: '#FFF',
						color: '#406AFF',
						border: loadingData ? 'none' : '1px solid #406AFF',
						borderRadius: 1,
						width: lessThanTablet ? '100%' : '92px',
					}}
					disabled={loadingData}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
				<Button
					onClick={handleClick}
					sx={{
						background: '#406AFF',
						color: '#FFF',
						borderRadius: 1,
						width: lessThanTablet ? '100%' : '92px',
					}}
					disabled={loadingData || (isFileNedeed && !files.length)}
					variant="contained"
				>
					{intl.formatMessage({ id: 'mytokens.redeem.shipping.dialog.send_button' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RedeemShippingQuotationDialog;
