import { Grid, Skeleton } from '@mui/material';
import { IProjectData } from './projectTypes';
import ProjectCard from './projectCard';
import { IntlShape } from 'react-intl';

export interface Props {
	intl: IntlShape;
	projects: IProjectData[];
	handleRedirect: (id?: number, isEditing?: boolean) => void;
	isLoading: boolean;
}

const ProjectList = (props: Props) => {
	const { intl, projects, handleRedirect, isLoading } = props;

	return (
		<Grid container spacing={5} justifyContent="space-between">
			{isLoading
				? Array.from(Array(6)).map((_, index) => (
						<Grid item key={index} xs={12} md={6}>
							<Skeleton
								variant="rectangular"
								sx={{ minWidth: '315px', maxWidth: '100%', height: '119px' }}
								animation="wave"
							/>
						</Grid>
				  ))
				: projects.map((project, index) => (
						<Grid item key={index} xs={12} md={6}>
							<ProjectCard
								intl={intl}
								projectData={project}
								onViewClick={() => handleRedirect(project.id)}
								onEditClick={() => handleRedirect(project.id, true)}
							></ProjectCard>
						</Grid>
				  ))}
		</Grid>
	);
};

export default ProjectList;
