import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { Alert, Typography, styled } from '@mui/material';
import client from '../../../clients/client';
import { User } from '../../../types';
import FullScreenLoading from '../../full-screen-loading';
import DatePicker from '../../date-picker';

const SeparatedDateField = styled(DatePicker)`
	margin-top: 15px;
`;

interface Props {
	open: boolean;
	quotationId?: string;
	environment: string;
	user: User;
	onClose: (event?: object | null, reason?: string) => void;
}

const SellerExtensionDialog = (props: Props) => {
	const intl = useIntl();
	const { open, quotationId, environment, user, onClose } = props;

	const [date, setDate] = useState<Date>();
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();

	const handleSubmit = () => {
		setLoading(true);
		setError(undefined)
		date?.setHours(24)
		client
			.extendQuotation({ quotationId, environment, date, user })
			.then((response) => onClose(null, 'success'))
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			{loading && <FullScreenLoading />}
			<DialogTitle>
				<Typography variant="h5" textAlign="center">
					{intl.formatMessage({ id: 'list.seller.details.extension_dialog.title' })}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{error && <Alert severity="error">{intl.formatMessage({ id: 'common.loading_error_message.details' })}</Alert>}
				<SeparatedDateField
					label={intl.formatMessage({ id: 'list.seller.details.extension_dialog.date_field_name' })}
					value={date}
					onChange={(value) => setDate(value)}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1, mb:1 }}>
				<Button onClick={() => onClose(null, 'cancelButtonClick')} color="secondary" variant="contained">
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button onClick={handleSubmit} color="success" variant="contained" disabled={!date}>
					{intl.formatMessage({ id: 'common.send' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SellerExtensionDialog;
