import styled from '@emotion/styled';
import { useEffect } from 'react';

const Container = styled('div')`
	width: 100%;
	text-align: start;
`;

const Label = styled('label')`
	position: relative;
	z-index: 1001;
	top: -65px;
	background-color: white;
	left: 20px;
	padding: 0 5px;
	font-weight: 400;
	font-size: 12px;
`;

const Input = styled('input')((props: { variant?: 'filter' | 'form' | 'dialog', error?: boolean }) => ({
	padding: props.variant !== 'dialog' ? '10px 14px' : '16.5px 14px',
	borderRadius: props.variant === 'filter' ? '30px' : props.variant === 'form' ? '4px' : '10px',
	border: props.error ? '1px solid #FF3D00' : props.variant === 'filter' ? '1px solid rgba(0, 0, 0, 0.87)' : '1px solid rgba(0, 0, 0, 0.23)',
	fontWeight: '400',
	fontSize: '14px',
	width: props.variant === 'filter' ? 'calc(100% - 33px)' : '100%',
	fontFamily: 'inherit',
	color: props.error ? '#FF3D00' : 'inherit',
	cursor: 'pointer'
}));

interface Props {
	identifier?: string;
	label?: string;
	value?: Date;
	variant?: 'filter' | 'form' | 'dialog';
	required?: boolean;
	disabled?: boolean;
	min?: Date;
	readOnly?: boolean;
	onChange: (value?: Date) => void;
	onClick?: () => void;
	error?: boolean;
}

const DatePicker = (props: Props) => {
	const { identifier, label, value, variant, required, disabled, min, onChange, onClick, readOnly, error } = props;

	return (
		<Container>
			<Input
				onClick={(event) => {
					onClick && onClick();
					!readOnly && (document.getElementById(`datepicker-${identifier ?? ''}`) as HTMLInputElement)?.showPicker()
				}}
				id={`datepicker-${identifier ?? ''}`}
				type="date"
				readOnly={readOnly}
				value={value?.toISOString()?.substring(0, 10)}
				disabled={disabled}
				min={min?.toISOString()?.substring(0, 10)}
				onChange={(event) => {
					if(event.target.value) {
						let date = new Date(event.target.value);
						onChange(date);
					} else {
						onChange(undefined);
					}
				}}
				variant={variant}
				error={error}
			/>
			{label && (
				<Label>
					{label}
					{required ? '*' : ''}
				</Label>
			)}
		</Container>
	);
};

export default DatePicker;
