import { useIntl } from 'react-intl';
import { useMobile } from '../../hooks/use-mobile';
import { User } from '../../types';
import { Box, Button, Divider, Paper, Skeleton, Stack, Typography, debounce, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import service, { Audience, AudienceCondition, AudienceUser } from './AudiencesService';
import AudienceConditionsComponent from './components/AudienceConditionsComponent';
import AudienceUsersComponent from './components/AudienceUsersComponent';

interface Props {
	user: User;
	audience?: Audience;
	isModal?: boolean;
	onClose?: () => void;
}

export const StatusBox = styled(Box)`
	border-radius: 4px;
	padding: 0 10px;
	height: 25px;
	width: 100px;
	justify-content: center;
	align-items: center;
	display: flex;
	background-color: #3dbe001a;
	color: #3dbe00;
`;

export const backgroundColor = '#3DBE001A';
export const color = '#3DBE00';

const AudienceDetail: React.FC<Props> = ({ user, audience, isModal = false, onClose }) => {
	const isMobile = useMobile();
	const intl = useIntl();
	const [isLoadingUsers, setIsLoadingUsers] = useState(true);
	const [isLoadingConditions, setIsLoadingConditions] = useState(true);
	const [audienceConditions, setAudienceConditions] = useState<AudienceCondition[]>([]);
	const [audienceUsers, setAudienceUsers] = useState<AudienceUser[]>([]);
	const [excludedUsers, setExcludedUsers] = useState<AudienceUser[]>([]);

	const fetchAudienceConditions = useMemo(
		() =>
			debounce(() => {
				if (!audience) return;

				service.getAudienceConditions(user, audience.id).then((audienceConditions) => {
					setAudienceConditions(audienceConditions ?? []);
					setIsLoadingConditions(false);
				});
			}, 250),
		[audience, service, user]
	);

	const fetchAudienceUsers = useMemo(
		() =>
			debounce(() => {
				if (!audience) return;

				service.getAudienceUsers(user, audience.id).then((audienceUsers:any) => {
					if(audienceUsers?.users && audienceUsers?.excluded_users) {
						setAudienceUsers(audienceUsers?.users ?? []);
						setExcludedUsers(audienceUsers?.excluded_users ?? []);
					} else {
						setAudienceUsers(audienceUsers ?? []);
					}

					setIsLoadingUsers(false);
				});
			}, 250),
		[audience, service, user]
	);

	useEffect(() => {
		if (!audience) return;

		setIsLoadingUsers(true);
		setIsLoadingConditions(true);

		Promise.all([fetchAudienceConditions(), fetchAudienceUsers()]).catch(() => {
			setIsLoadingUsers(false);
			setIsLoadingConditions(false);
		});

		return () => {
			fetchAudienceConditions.clear();
			fetchAudienceUsers.clear();
		};
	}, [audience, fetchAudienceConditions, fetchAudienceUsers]);

	return (
		<Stack direction={'column'} alignItems="center">
			<Stack width={'100%'} mt={-6.6} pl={'20px'} pr={isMobile ? '15px' : '20px'} alignItems={'stretch'} spacing={3}>
				<Typography fontWeight={700} fontSize={'1.6em'}>
					{audience?.name}
				</Typography>
				<Box className="flex items-center gap-2 mt-3">
					Estado: <StatusBox>{audience?.status === 'active' ?
						intl.formatMessage({ id: 'audiences.statuses.active' }) :
						intl.formatMessage({ id: 'audiences.statuses.inactive' })
					}</StatusBox>
				</Box>
				{isLoadingConditions ? (
					<Skeleton variant="rectangular" height={250} />
				) : (
					audienceConditions?.length > 0 && <AudienceConditionsComponent conditions={audienceConditions}></AudienceConditionsComponent>
				)}
				<Divider />
				{isLoadingUsers ? (
					<Skeleton variant="rectangular" height={400} />
				) : (
					audienceUsers?.length > 0 && <AudienceUsersComponent users={audienceUsers}></AudienceUsersComponent>
				)}
				<Divider />
				{isLoadingUsers ? (
					<Skeleton variant="rectangular" height={400} />
				) : (
					excludedUsers?.length > 0 && <AudienceUsersComponent users={excludedUsers} excludeds={true}></AudienceUsersComponent>
				)}
			</Stack>
			<Box pt={'40px'}>
				<Button variant="outlined" onClick={() => onClose && onClose()}>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
			</Box>
		</Stack>
	);
};

export default AudienceDetail;
