import { Link, Typography, styled } from '@mui/material';

const Wrapper = styled('div')((props: { paddingTop?: string }) => ({
	'& > div:not(:first-child)': {
		paddingTop: props.paddingTop || '20px'
	}
}));

const DataWrapper = styled('div')`
	display: inline-block;
`;

const ActionWrapper = styled('div')`
	display: inline-block;
	float: right;
`;

const Key = styled(Typography)`
	font-weight: 600;
	display: block;
`;

export interface Action {
	displayName: string;
	icon?: React.ReactElement;
	onClick: () => void;
}

export interface Entry {
	key: string;
	value: React.ReactNode;
	action?: Action;
}

interface Props {
	entries?: Entry[];
	paddingTop?: string;
}

const DataList = (props: Props) => {
	const { entries, paddingTop } = props;

	return (
		<Wrapper paddingTop={paddingTop}>
			{entries &&
				entries.map((entry, index) => (
					<div key={`entry-${index}`}>
						<DataWrapper>
							<Key variant="subtitle1">{entry.key}</Key>
							<Typography color="#7E7E82">{entry.value}</Typography>
						</DataWrapper>
						{entry.action && (
							<ActionWrapper>
								<Link onClick={entry.action.onClick} sx={{ cursor: 'pointer' }}>
									<Typography>{entry.action.displayName}</Typography>
								</Link>
							</ActionWrapper>
						)}
					</div>
				))}
		</Wrapper>
	);
};

export default DataList;
