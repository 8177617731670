import { Box, List, ListItem, Typography } from '@mui/material';
import { AudienceCondition } from '../AudiencesService';
import { useIntl } from 'react-intl';

export interface AudienceConditionsComponentProps {
	conditions: AudienceCondition[];
}

const AudienceConditionsComponent: React.FC<AudienceConditionsComponentProps> = ({ conditions }) => {
	const intl = useIntl();

	return (
		<Box>
			<Typography variant="h6" fontWeight={500} color={'#000'}>
				{intl.formatMessage({ id: 'audiences.details.conditions' })}
			</Typography>
			<List sx={{ listStyleType: 'disc', pl: 4 }}>
				{conditions.map((condition, index) => (
					<ListItem sx={{ display: 'list-item' }} key={index}>
						<Typography variant="body1">{condition.description}</Typography>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default AudienceConditionsComponent;
