import { useState, useEffect } from 'react';

const getStorageValue = <T>(key: string, defaultValueSupplier: () => T): T => {
	let stored = null;
	try {
		stored = localStorage.getItem(key);
	} catch (e) {
		console.warn('Local storage not accessible, no changes will be stored.');
	}

	if (!stored) {
		return defaultValueSupplier();
	}

	return JSON.parse(stored);
};

const setStorageValue = <T>(key: string, value: T): void => {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (e) {
		console.warn('Local storage not accessible, no changes will be stored.');
	}
};

const useStorage = <T>(key: string, defaultValueSupplier: () => T): [T, (t: T) => void] => {
	const [value, setValue] = useState(() => getStorageValue<T>(key, defaultValueSupplier));

	useEffect(() => setStorageValue(key, value), [key, value]);

	return [value, setValue];
};

export default useStorage;
