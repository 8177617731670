export interface FormData {
	accountType: string;
	cuit: string;
	businessName: string;
	address: string;
	logoImage: File[];
	cuitProof: File[];
	fullName: string;
	phone: string;
	password: string;
	mail: string;
	confirmedMail: string;
	workArea: string;
	passwordRepeat: string;
	category: string;
	role: string;
}

export interface SellerCreation {
	cuit: number;
	business_name: string;
	category: string;
	full_name: string | null;
	phone: number;
	mail: string;
	role: string | null;
}

export interface ConstructorCreation {
	business_name: string;
	business_address: string | null;
}

export interface ConstructorUserCreation {
	name: string;
	phone: number;
	email: string;
	password: string;
	area: string;
	user_type: string;
}

export enum SignUpStep {
	ACCOUNT_CHECK = 'account_check',
	CONSTRUCTOR_CREATION = 'constructor_creation',
	SELLER_CREATION = 'seller_creation',
}

export interface FormDataQueryParamConstructor {
	id_external: string;
	type_external: string;
	cuit: string;
	business_name_id: string;
	user_email: string;
}
