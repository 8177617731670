import { Role } from '../../../types';

export const StateColors = [
	{
		id: 'Aguardando confirmación',
		color: '#888',
	},
	{
		id: 'Aguardando pago',
		color: '#F22222',
	},
	{
		id: 'Aguardando factura',
		color: '#EEF222',
	},
	{
		id: 'Solicitud enviada',
		color: '#EEF222',
	},
	{
		id: 'Solicitud aprobada',
		color: '#2250F2',
	},
	{
		id: 'Finalizado',
		color: '#09C210',
	},
	{
		id: 'active',
		color: '#09C210',
		label: 'Disponible',
	},
	{
		id: 'expired',
		color: '#CC1A1A',
		label: 'Vencido',
	},
	{
		id: 'paused',
		color: '#FF7A00',
		label: 'Pausado',
	},
	{
		id: 'not available',
		color: '#FFE500',
		label: 'No disponible',
	},

	// REDEEM REQUESTS STATUSES
	// shipping
	{
		id: 'redeem_request|awaiting_response|shipping',
		color: '#EEF222',
		label: {
			[Role.CONSTRUCTOR]: {
				label: 'Aguardando factura',
			},
			[Role.SELLER]: {
				label: 'Emision de factura',
			},
		},
	},
	{
		id: 'redeem_request|awaiting_invoice|shipping',
		color: '#2250F2',
		label: 'Aguardando pago'
	},
	{
		id: ['redeem_request|accepted|shipping'],
		color: '#EEF222',
		label: {
			[Role.CONSTRUCTOR]: {
				label: 'Presupuesto recibido',
			},
			[Role.SELLER]: {
				label: 'Aguardando respuesta',
			},
		},
	},
	{
		id: 'redeem_request|awaiting_payment|shipping',
		color: '#2250F2',
		label: 'Envío pendiente',
	},
	{
		id: 'redeem_request|awaiting_payment|shipping|no',
		color: '#2250F2',
		label: 'Envío pendiente',
	},
	{
		id: 'redeem_request|awaiting_shipping|shipping',
		color: '#2250F2',
		label: 'Pendiente de entrega',
	},
	{
		id: ['redeem_request|rejected|withdrawal', 'redeem_request|rejected|shipping'],
		color: '#CC1A1A',
		label: 'Solicitud rechazada',
	},
	
	// withdrawal
	{
		id: ['redeem_request|pending|withdrawal|no', 'redeem_request|pending|shipping|no'],
		color: '#FFE500',
		label: {
			[Role.CONSTRUCTOR]: {
				label: 'Solicitud enviada',
			},
			[Role.SELLER]: {
				label: 'Solicitud recibida',
			},
		},
	},
	{
		id: ['redeem_request|rejected|withdrawal|no', 'redeem_request|rejected|shipping|no'],
		color: '#CC1A1A',
		label: 'Solicitud rechazada',
	},
	{
		id: 'redeem_request|accepted|withdrawal|no',
		color: '#2250F2',
		label: 'Retiros pendientes',
	},
	{
		id: 'redeem_request|accepted|shipping|no',
		color: '#2250F2',
		label: 'Envíos pendientes',
	},
	{
		id: ['redeem_request|ended|withdrawal|no', 'redeem_request|burned|withdrawal|no', 'redeem_request|ended|shipping', 'redeem_request|ended|shipping|no'],
		color: '#09C210',
		label: 'Entregado',
	}
];