import axios, { AxiosResponse } from 'axios';
import { User } from '../../types';
import { IProjectDetailData } from './projectTypes';

const customAxios = (user: User) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_PROJECTS_BASE_URL,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            config.headers['Authorization'] = `Bearer ${user.token}`;

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const financingTypes = [
    { id: 1, value: 'Obra privada' },
    { id: 2, value: 'Pública' },
    { id: 3, value: 'Mixta' },
];

export enum ExternalComponents {
    Computation = 'computation',
    Supplies = 'supplies',
    Gantt = 'gantt',
}

const service = {
    getExternalUrl: (user: User, projectId: number, component: string): string => {
        let urlBase = process.env.REACT_APP_EMBED_PROJECTS_BASE_URL;
        let url = `${urlBase}/project/${projectId}/${component}?token=${user.token}`;

        return url;
    },

    getProjectTypes: async (user: User): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/v1/constructor/${user.id_external}/project-types`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },

    createConstructorAccount: async (user: User): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(
                `/v1/accounts/create`,
                {
                    ns_name: user.name,
                    ns_cuit: user.cuit_external,
                    ns_external_id: user.id_external,
                });
            return responseBody(response);
        } catch (error) {
            console.error('Error al crear cuenta:', error);
            throw error;
        }
    },

    getResponsibles: async (user: User): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/v1/constructor/${user.id_external}/available-responsibles`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    getFinancingTypes: (user: User): any[] => {
        try {
            return financingTypes;
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    getProjects: async (user: User): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/v1/constructor/${user.id_external}/projects`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    allProjects: async (user: User): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/v1/constructor/${user.id_external}/all-projects`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    getProjectById: async (user: User, projectId: number): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.get<any>(`/v1/constructor/${user.id_external}/project/${projectId}`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    createProject: async (user: User, newProject: IProjectDetailData): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(`/v1/constructor/${user.id_external}/projects`, newProject);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    editProject: async (user: User, projectId: number, project: IProjectDetailData): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(`/v1/constructor/${user.id_external}/project/${projectId}/edit`, project);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    },

    deleteProject: async (user: User, projectId: number): Promise<any> => {
        try {
            const client = customAxios(user);
            const response = await client.post<any>(`/v1/constructor/${user.id_external}/project/${projectId}/delete`);

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error:', error);
            throw error;
        }
    }
}

export default service;