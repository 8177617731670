import { TextField, Box, InputLabel, TextFieldProps } from '@mui/material';
import React from 'react';

const TextFieldWithPlaceholder: React.FC<TextFieldProps> = (props) => {
	return (
		<Box>
			<InputLabel variant="standard">{props.required ? props.label + '*' : props.label}</InputLabel>
			<TextField
				id={props.id}
				inputRef={props.inputRef}
				placeholder={props.placeholder}
				value={props.value}
				size="small"
				variant="outlined"
				sx={props.sx}
				required={props.required}
				error={props.error}
				onChange={props.onChange}
				disabled={props.disabled}
				multiline={props.multiline}
				rows={props.rows}
				maxRows={props.maxRows}
				fullWidth={props.fullWidth}
				InputProps={props.InputProps}
				type={props.type}
			></TextField>
		</Box>
	);
};

export default TextFieldWithPlaceholder;
