import { Alert, Grid, Link, Snackbar, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { User } from '../../../types';
import { useState } from 'react';

export interface Props {
	user: User;
	callback?: () => void;
}

const ProjectsEmptyState = (props: Props) => {
	const intl = useIntl();
	const [error, setError] = useState<string>('');

	const handleClick = async () => {
		try {
			if (props.callback) {
				props.callback();
			}
		} catch (error) {
			console.error(error);
			setError(intl.formatMessage({ id: 'common.error_message' }));
		}
	};
	return (
		<Grid container item>
			<Grid item xs={12}>
				<Stack direction="column" className="min-h-[50vh] items-center justify-center">
					<Typography className="pl-2" variant="h5">
						{intl.formatMessage({ id: `common.my_projects.empty_state` })}
					</Typography>
					<Link component="button" onClick={handleClick}>
						<Typography className="pl-2 text-[1.125rem] text-[primary.main]">
							{intl.formatMessage({ id: `common.my_projects.empty_state.link` })}
						</Typography>
					</Link>

					<Snackbar
						open={!!error}
						onClose={() => setError('')}
						autoHideDuration={6000}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Alert severity="error" variant="filled">
							{error}
						</Alert>
					</Snackbar>
				</Stack>
			</Grid>
		</Grid>
	);
};
export default ProjectsEmptyState;
