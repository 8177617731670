import { User, QuoteStatus, Role } from '../types';
import { Column } from '../components/table-paginatable';
import { Option } from '../components/select-with-custom-check';
import { ReactElement } from 'react';

export interface Row {
	id: string;
	product: string;
	proyect: string;
	seller: string;
	client: string;
	date: string;
	status: string;
	statusLabel: any;
	statusLabelString: string;
	statusDotColor: string;
	statusForRole: string;
	orderStatus: string;
	deliveryStatus: string;
	quoteStatus: string;
	quoteType: string;
	locality: ReactElement;
	requester: string;
	requesterLabel: any;
	comparativeExpDate: string;
	onClickUrl: string;
}

const colors = {
	lightGray: '#797979',
	gray: '#8D8D98',
	green: '#03C305',
	red: '#FF0005',
	yellow: '#FFC103',
};

const dropdownOptionsForConstructor: Option[] = [
	{ value: 'Todas tus cotizaciones', label: 'Todas tus cotizaciones' },
	{ value: 'En proceso de compra', label: 'En proceso de compra' },
	{ value: 'Finalizadas', label: 'Finalizadas' },
	{ value: 'No concretadas', label: 'No concretadas' },
];

const dropdownOptionsForSeller: Option[] = [
	{ value: 'Todas tus cotizaciones', label: 'Todas tus cotizaciones' },
	{ value: 'Pendientes de cotización', label: 'Pendientes de cotización' },
	{ value: 'Documentación pendiente', label: 'Documentación pendiente' },
	{ value: 'Pendientes de entrega', label: 'Pendientes de entrega' },
	{ value: 'Finalizadas', label: 'Finalizadas' },
	{ value: 'No concretadas', label: 'No concretadas' },
];

const getColumns = (user: User) => {
	let columns: Column[];

	if (user.type_external === 'constructor') {
		columns = [
			{ id: 'id', label: 'ID', width: '10%', mobile: false },
			{ id: 'product', label: 'Producto' },
			{ id: 'proyect', label: 'Proyecto' },
			{ id: 'seller', label: 'Vendedor', mobile: false },
			{ id: 'date', label: 'Fecha', width: '14%', mobile: false },
			{ id: 'statusLabel', label: 'Estado', width: '20%' },
			{ id: 'requesterLabel', label: 'Solicitada por', width: '12%', mobile: false },
		];
	} else if (user.type_external === 'seller') {
		columns = [
			{ id: 'id', label: 'ID', width: '8%', mobile: false },
			{ id: 'date', label: 'Fecha de Pedido', width: '15%', mobile: false },
			{ id: 'client', label: 'Cliente', width: '15%' },
			{ id: 'product', label: 'Producto', width: '15%' },
			{ id: 'locality', label: 'Localidad', width: '15%', mobile: false },
			{ id: 'statusLabel', label: 'Estado', width: '15%' },
		];
	} else {
		columns = [];
	}

	return columns;
};

const getDropDownOptions = (user: User) => {
	return user.type_external === 'constructor' ? dropdownOptionsForConstructor : dropdownOptionsForSeller;
};

const getSearcherPlaceholderText = (user: User): string => {
	return user.type_external === 'constructor'
		? 'Buscá por proyecto, producto, vendedor, estado, usuario o ID'
		: 'Buscá por cliente, producto, estado o ID';
};

const searchFilterLists = (text: string, rows: Row[], user: User): Row[] => {
	let filtered: Row[];

	if (user.type_external === Role.CONSTRUCTOR) {
		filtered = rows.filter((row) => {
			return (
				row.id?.toLowerCase().includes(text.toLowerCase()) ||
				row.requester.toLowerCase().includes(text.toLowerCase()) ||
				row.statusLabelString?.toLowerCase().includes(text.toLowerCase()) ||
				row.seller?.toLowerCase().includes(text.toLowerCase()) ||
				row.product?.toLowerCase().includes(text.toLowerCase()) ||
				row.proyect?.toLowerCase().includes(text.toLowerCase())
			);
		});
	} else if (user.type_external === Role.SELLER) {
		filtered = rows.filter((row) => {
			return (
				row.id?.toLowerCase().includes(text.toLowerCase()) ||
				row.client?.toLowerCase().includes(text.toLowerCase()) ||
				row.statusLabelString?.toLowerCase().includes(text.toLowerCase()) ||
				row.product?.toLowerCase().includes(text.toLowerCase())
			);
		});
	} else {
		filtered = [];
	}

	return filtered;
};

const dropDownFilterLists = (status: string, rows: Row[], user: User): Row[] => {
	if (user.type_external === Role.CONSTRUCTOR) {
		switch (status) {
			case 'En proceso de compra': {
				return rows.filter((row) => {
					return (
						[
							QuoteStatus.EN_PROCESO_DE_COTIZACION.toString(),
							QuoteStatus.COTIZACION_RECIBIDA.toString(),
							QuoteStatus.EN_PROCESO_DE_COMPRA.toString(),
						].includes(row.statusForRole) || row.status === 'Primera Carga'
					);
				});
			}

			case 'Finalizadas': {
				return rows.filter((row) => {
					return row.status === 'Convertida' && row.orderStatus === 'Pagada' && row.deliveryStatus === 'Entrega total';
				});
			}

			case 'No concretadas': {
				return rows.filter((row) => {
					return row.statusForRole === QuoteStatus.NO_CONCRETADA.toString();
				});
			}

			default:
				return [];
		}
	} else if (user.type_external === Role.SELLER) {
		switch (status) {
			case 'Pendientes de cotización': {
				return rows.filter((row) => {
					return ['Cotizar', 'Recotizar'].includes(row.statusLabelString);
				});
			}

			case 'Pendientes de entrega': {
				return rows.filter((row) => row.statusLabelString === 'Entrega pendiente');
			}

			case 'Documentación pendiente': {
				return rows.filter((row) => row.statusForRole === 'En proceso de venta');
			}

			case 'Finalizadas': {
				return rows.filter((row) => row.statusLabelString === 'Finalizada');
			}

			case 'No concretadas': {
				return rows.filter((row) => row.statusLabelString === 'No concretada');
			}

			default:
				return [];
		}
	}
	return [];
};

const setStatusAndColorForConstructor = (row: Row) => {
	let statusLabel: string = '';
	let dotColor: string = '';

	if (row.statusForRole === QuoteStatus.EN_PROCESO_DE_COTIZACION.toString() || row.status === 'Primera Carga') {
		dotColor = colors.yellow;
		statusLabel = 'En cotización';
	}

	if (row.status === 'Esperando Respuesta') {
		if (new Date(row.comparativeExpDate) >= new Date()) {
			dotColor = colors.red;
			statusLabel = 'Cotización disponible';
		} else {
			dotColor = colors.yellow;
			statusLabel = 'Vencida';
		}
	}

	if (row.status === 'En Cierre') {
		if (row.orderStatus === 'Aguardando OC') {
			dotColor = colors.red;
			statusLabel = 'Cargar OC';
		} else if (row.orderStatus === 'Aguardando Factura') {
			dotColor = colors.yellow;
			statusLabel = 'Factura pendiente';
		} else if (row.orderStatus === 'Aguardando Comprobante de pago') {
			dotColor = colors.red;
			statusLabel = 'Aguardando pago';
		}
	}

	if ((row.status === 'Convertida' || row.status === 'En Cierre') && row.orderStatus === 'Pagada') {
		if (row.deliveryStatus === 'Entrega parcial' || row.deliveryStatus === 'Pendiente de entrega') {
			dotColor = colors.yellow;
			statusLabel = 'Entrega pendiente';
		}

		if (row.deliveryStatus === 'Entrega total') {
			dotColor = colors.green;
			statusLabel = 'Finalizada';
		}
	}

	if (row.status === 'Perdida' || row.status === 'Desestimada') {
		dotColor = colors.gray;
		statusLabel = 'No concretada';
	}

	row.statusLabelString = statusLabel;
	row.statusDotColor = dotColor;
};

const setStatusAndColorForSeller = (row: Row) => {
	let statusLabel: string = '';
	let dotColor: string = '';

	if (row.statusForRole === 'A cotizar/Recotizar' && row.quoteStatus === 'En cotización') {
		dotColor = colors.red;
		statusLabel = row.quoteType === 'Recotización' ? 'Recotizar' : 'Cotizar';
	}

	if (row.statusForRole === 'Aguardando respuesta') {
		dotColor = colors.gray;
		statusLabel = 'Cotización compartida';
	}

	if (row.statusForRole === 'En proceso de venta') {
		if (row.orderStatus === 'Aguardando OC') {
			dotColor = colors.yellow;
			statusLabel = 'Aguardando OC';
		}

		if (row.orderStatus === 'Aguardando Factura') {
			dotColor = colors.red;
			statusLabel = 'Cargar factura';
		}

		if (row.orderStatus === 'Aguardando Comprobante de pago') {
			dotColor = colors.yellow;
			statusLabel = 'Aguardando pago';
		}
	}

	if (row.statusForRole === 'En proceso de entrega') {
		dotColor = colors.red;
		statusLabel = 'Entrega pendiente';
	}

	if (row.statusForRole === 'Venta finalizada') {
		dotColor = colors.green;
		statusLabel = 'Finalizada';
	}

	if (row.statusForRole === 'No concretada') {
		dotColor = colors.gray;
		statusLabel = 'No concretada';
	}

	row.statusLabelString = statusLabel;
	row.statusDotColor = dotColor;
};

export {
	getColumns,
	searchFilterLists,
	setStatusAndColorForSeller,
	setStatusAndColorForConstructor,
	dropDownFilterLists,
	getDropDownOptions,
	getSearcherPlaceholderText,
};
