import styled from '@emotion/styled';
import { Card, debounce, Snackbar, Alert } from '@mui/material';
import {
	activeTokensSeller,
	exchangeProcessConstructor,
	exchangeProcessSeller,
	exchangedTokensConstructor,
	exchangedTokensSeller,
	purchaseProcessConstructor,
	purchasedTokensConstructor,
	salesProcessSeller,
} from './columns';
import BaseTable, { DataRow } from '../../baseTable';
import { useNavigate, createSearchParams, useSearchParams, useLocation } from 'react-router-dom';
import Tabs from './Tabs';
import { useState, useEffect, useMemo } from 'react';
import client from '../../../clients/client';
import { RedeemRequestTypes, RedeemTypesMapper, Role, User } from '../../../types';
import { useIntl } from 'react-intl';
import RejectedRedeemTokensDialog from './dialogs/RejectedRedeemTokensDialog';
import { useQuery } from '../../../hooks/use-query';

type Props = {
	user: User;
	type: Role;
	agreementId?: string;
	loadingChange?: (value: boolean) => void;
	isLoadingUserState?: boolean;
};

export const RoundedCard = styled(Card)`
	border-radius: 30px;
	height: 100%;
	position: relative;
	display: flex;
	flex-flow: column;
`;

const tabMapper = {
	'purchase': 0,
	'exchange-process': 1,
	'active-tokens': 2,
	'ended': 3,
}

const MyTokensTable = (props: Props) => {
	const navigate = useNavigate();
	// const [searchParams, setSearchParams] = useSearchParams();
	let query = useQuery();
	//@ts-ignore
	const [activeTab, setActiveTab] = useState(tabMapper[query.get('tab') || 'purchase'] || 0);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState([true, true, true, true]);
	const [error, setError] = useState<string>('');
	const [redeemRequestId, setRedeemRequestId] = useState('');
	const [rejectRedeemTokensDialog, setRejectRedeemTokensDialog] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [loadedComment, setLoadedComment] = useState(undefined);
	
	const intl = useIntl();
	const { type, user, agreementId, loadingChange } = props;

	const handleLoading = (position: number, value: boolean) => {
		setLoading(
			loading.map((item, i) => {
				return position === i ? value : true;
			})
		);
		loadingChange && loadingChange(value);
	};

	const formatItemWithRedeemType = (item: any) => {
		let mapItemType: RedeemRequestTypes | null;
		switch (item.type) {
			case 'shipping':
				mapItemType = RedeemRequestTypes.SHIPPING;
				break;
			case 'withdrawal':
				mapItemType = RedeemRequestTypes.WITHDRAWAL;
				break;
		
			default:
				mapItemType = null;
				break;
		}
		
		return { ...item, formattedType: mapItemType ? RedeemTypesMapper[mapItemType] : RedeemTypesMapper[RedeemRequestTypes.SHIPPING] }
	}

	const fetchSellerSalesProcess = debounce(() => {
		client
			.getSellerSalesProcess({ token: user.token })
			.then((data) => {
				setTableData(data.data);
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchSellerExchangeProcess = debounce(() => {
		client
			.getSellerExchangeProcess({ token: user.token })
			.then((data) => setTableData(data.data.map((item: any) => formatItemWithRedeemType(item))))
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchSellerSold = debounce((agreementId?: string) => {
		client
			.getSellerSold({ token: user.token, agreementId })
			.then((data) => {
				setTableData(data.data);
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchSellerExchanged = debounce(() => {
		client
			.getSellerExchanged({ token: user.token })
			.then((data) => setTableData(data.data.map((item: any) => formatItemWithRedeemType(item))))
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchConstructorPurchaseProcess = debounce((agreementId?: string) => {
		client
			.getConstructorPurchaseProcess({ token: user.token, agreementId })
			.then((data) => {
				setTableData(data.data);
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchConstructorExchangeProcess = debounce((agreementId?: string) => {
		client
			.getConstructorExchangeProcess({ token: user.token, agreementId })
			.then((data) => setTableData(data.data.map((item: any) => formatItemWithRedeemType(item))))
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchConstructorPurchased = debounce((agreementId?: string) => {
		client
			.getConstructorPurchased({ token: user.token, agreementId })
			.then((data) => {
				setTableData(data.data);
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const fetchConstructorExchanged = debounce((agreementId?: string) => {
		client
			.getConstructorExchanged({ token: user.token, agreementId })
			.then((data) => setTableData(data.data.map((item: any) => formatItemWithRedeemType(item))))
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
				setTableData([]);
			})
			.finally(() => handleLoading(activeTab, false));
	}, 250);

	const typeMapper = {
		[Role.SELLER]: [fetchSellerSalesProcess, fetchSellerExchangeProcess, fetchSellerSold, fetchSellerExchanged],
		[Role.CONSTRUCTOR]: [
			fetchConstructorPurchaseProcess,
			fetchConstructorExchangeProcess,
			fetchConstructorPurchased,
			fetchConstructorExchanged,
		],
	};

	const tableMapper = {
		[Role.SELLER]: [
			{
				columns: salesProcessSeller(intl),
				onRowClick: (row: DataRow) => {
					navigate(`/my-tokens/${row.id}`, { state: { tableData } });
				},
			},
			{
				columns: exchangeProcessSeller(intl),
				onRowClick: (row: DataRow) => {
					if (row.status === 'pending') {
						navigate({
							pathname: `/my-tokens/redeem/${row.id}`,
							search: createSearchParams({
								agreementId: agreementId ?? '',
							}).toString(),
						});
					} else if (row.status === 'rejected') {
						setReadOnly(true);
						setLoadedComment(row.comment);
						setRejectRedeemTokensDialog(true);
					} else {
						navigate(`/my-tokens/redeem-request/${row.id}`);
					}
				},
			},
			{ columns: activeTokensSeller(intl) },
			{
				columns: exchangedTokensSeller(intl),
				onRowClick: (row: DataRow) => {
					navigate(`/my-tokens/redeem-request/${row.id}`);
				},
			},
		],
		[Role.CONSTRUCTOR]: [
			{
				columns: purchaseProcessConstructor(intl),
				onRowClick: (row: DataRow) => {
					navigate(`/my-tokens/${row.id}`);
				},
			},
			{
				columns: exchangeProcessConstructor(intl),
				onRowClick: (row: DataRow) => {
					if (row.status !== 'rejected') {
						navigate(`/my-tokens/redeem-request/${row.id}`);
					} else if (row.status === 'rejected') {
						setReadOnly(true);
						setLoadedComment(row.comment);
						setRejectRedeemTokensDialog(true);
					}
				},
			},
			{
				columns: purchasedTokensConstructor(intl),
				onRowClick: (row: DataRow) => {
					navigate(`/my-tokens/${row.id}`);
				},
			},
			{
				columns: exchangedTokensConstructor(intl),
				onRowClick: (row: DataRow) => {
					navigate(`/my-tokens/redeem-request/${row.id}`);
				},
			},
		],
	};

	useEffect(() => {
		typeMapper[type][activeTab](agreementId);
	}, [activeTab, agreementId, type]);

	useEffect(() => {
		const tab = query.get('tab');
		const redeem = query.get('redeem');

		if (!tab && !redeem) {
			navigate({
				pathname: `/my-tokens`,
				search: createSearchParams(
					Role.CONSTRUCTOR === type
						? {
								tab: 'purchase',
								agreement: agreementId || '',
						  }
						: {
								tab: 'purchase',
						  }
				).toString(),
			});
		} else if(tab && !redeem) {
			if (tab === 'purchase') {
				setActiveTab(0);
				handleLoading(0, true);
			} else if (tab === 'exchange-process') {
				setActiveTab(1);
				handleLoading(1, true);
			} else if (tab === 'active-tokens') {
				setActiveTab(2);
				handleLoading(2, true);
			} else if (tab === 'ended') {
				setActiveTab(3);
				handleLoading(3, true);
			}
		}
	}, [query]);

	useEffect(() => {
		if (props.isLoadingUserState) {
			setLoading([true, true, true, true]);
		}
	}, [props.isLoadingUserState])
	

	const handleAcceptance = (id: string) => {
		setLoadingDialog(true);
		client
			.redeemAction({
				fields: {
					redeem_request_id: id,
					action_type: 1,
					comments: null,
					result: 1,
				},
				token: user.token,
			})
			.then((data) => {
				setRejectRedeemTokensDialog(false);
				setLoadingDialog(false);
				navigate(`/my-tokens/redeem-request/${id}`);
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
			});
	};

	const handleRejection = (id: string, comments: string) => {
		setLoadingDialog(true);
		client
			.redeemAction({
				fields: {
					redeem_request_id: id,
					action_type: 1,
					comments,
					result: 2,
				},
				token: user.token,
			})
			.then((data) => {
				setRejectRedeemTokensDialog(false);
				setLoadingDialog(false);
				fetchSellerExchangeProcess();
			})
			.catch((err) => {
				setError(intl.formatMessage({ id: 'common.error_message' }));
			});
	};

	return (
		<>
			<RejectedRedeemTokensDialog
				redeemRequestId={redeemRequestId}
				open={rejectRedeemTokensDialog}
				loading={loadingDialog}
				readOnly={readOnly}
				loadedComment={loadedComment}
				onReject={(comments: string) => handleRejection(redeemRequestId, comments)}
				onBack={() => {
					setRejectRedeemTokensDialog(false);
				}}
				onClose={() => setRejectRedeemTokensDialog(false)}
			/>
			<Tabs
				type={type}
				activeTab={activeTab}
				changeActiveTab={(tab: any) => {
					if (activeTab !== tab.index  && !loading[activeTab]) {
						navigate({
							pathname: `/my-tokens`,
							search: createSearchParams({
								tab: tab.name, 
								...(type === Role.CONSTRUCTOR
									? {agreement: agreementId || ''}
									: {}
								) 
							}).toString(),
						});
						handleLoading(activeTab, true);
					}
				}}
			/>
			<BaseTable
				loading={loading[activeTab]}
				empty={tableData.length === 0}
				columns={tableMapper[type][activeTab].columns}
				data={tableData}
				stickyHeader={true}
				onRowClick={tableMapper[type][activeTab].onRowClick}
				orderByDefault={
					// find the first column with sort function property and used this column to sort by default
					tableMapper[type][activeTab].columns.find((column) => column.sort)?.displayName
				}
				maxHeightOnPx={1125}
				TableContainerOverflowX='auto'
			/>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};

export default MyTokensTable;
