import { Box } from "@mui/system";

const Help = () => {
    const defaultSrc = "https://nuqlea.com/ayuda";
    return(
        <Box
            sx={{
                height: '100%',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <iframe
                id='help'
                src={defaultSrc}
                title="Ayuda"
                style={{
                    borderStyle: 'none',
                    width: '100%',
                    height: `95vh`,
                    marginTop: '64px',
                    overflow: 'hidden',
                    marginBottom: '-25px'
                }}
            >
            </iframe>
        </Box>
    )
}

export default Help;

