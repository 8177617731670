import { Button, Card, CardActions, CardContent, CardHeader, Stack, Typography, styled } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlaceIcon from '@mui/icons-material/Place';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { IProjectData } from './projectTypes';
import { IntlShape } from 'react-intl';

interface ProjectCardProps {
	intl: IntlShape;
	projectData: IProjectData;
	onEditClick?: () => void;
	onViewClick?: () => void;
}

const ProjectCard = ({ intl, projectData, onViewClick }: ProjectCardProps) => {
	const maxWidth = '100%';
	const minWidth = '315px';
	const height = '162px';

	const ProjectCardStyled = styled(Card)(({ theme }) => ({
		maxWidth: maxWidth,
		minWidth: minWidth,
		height: height,
		position: 'relative',
		paddingLeft: '0px',
		'::before': {
			content: '""',
			position: 'absolute',
			bottom: '17px',
			left: '0',
			width: '3px',
			height: '128px',
			backgroundColor: '#406aff',
			borderTopRightRadius: '5px',
			borderBottomRightRadius: '5px',
		},
	}));

	const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
		display: 'flex',
		overflow: 'hidden',
		'& .MuiCardHeader-content': {
			overflow: 'hidden',
		},
	}));

	return (
		<ProjectCardStyled>
			<CardHeaderStyled
				className="pl-[2.5rem]"
				titleTypographyProps={{ className: 'font-semibold truncate' }}
				title={projectData.name}
			/>
			<CardContent className="pt-0 pb-0 pl-[2.5rem] max-h-[3.8rem]">
				<Stack direction="row" alignItems="center" gap={1}>
					<Typography color={'grey.600'} className="text-[0.75rem]">
						<PlaceIcon />
					</Typography>
					<Typography color={'grey.600'} className="font-medium text-[0.75rem] pr-2 truncate">
						{projectData.address}
					</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" gap={1}>
					<Typography color={'grey.600'} className="text-[0.75rem]">
						<HomeWorkIcon />
					</Typography>
					<Typography color={'grey.600'} className="text-[0.75rem]" noWrap>
						<strong>{intl.formatMessage({ id: `common.my_projects.project_info.type` })}:</strong>
						{' ' + projectData.type}
					</Typography>
				</Stack>
			</CardContent>
			<CardActions>
				<Stack direction="row" width={'100%'} alignItems="center" justifyContent="flex-end">
					<Button endIcon={<ArrowForwardIcon />} onClick={onViewClick}>
						{intl.formatMessage({ id: `common.my_projects.project_info.button_view` })}
					</Button>
				</Stack>
			</CardActions>
		</ProjectCardStyled>
	);
};

export default ProjectCard;
