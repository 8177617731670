import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';

type BudgetAcceptanceDialogProps = {
	open: boolean;
	onConfirm: () => void;
	onClose: () => void;
	loading: boolean;
};

const BudgetAcceptanceDialog = ({ open, onConfirm, onClose, loading }: BudgetAcceptanceDialogProps) => {
	const intl = useIntl();
	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			sx={{
				'& .MuiPaper-root': { borderRadius: 1, pb: 0 },
			}}
			maxWidth="md"
		>
			<DialogTitle sx={{ textAlign: 'center', borderBottom: 'none' }}>
				{intl.formatMessage({ id: 'mytokens.constructor.budget.dialog.acceptance.title' })}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
				{
					loading &&
					<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open>
						<CircularProgress color="inherit" />
					</Backdrop>
				}
				{intl.formatMessage({ id: 'mytokens.constructor.budget.dialog.acceptance.question' })}
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, marginTop: 4 }}>
				<Button
					size="large"
					onClick={onClose}
					sx={{ background: 'white', color: 'black', border: '1px solid #000', borderRadius: 10 }}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button
					size="large"
					onClick={onConfirm}
					sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10 }}
					variant="contained"
				>
					{intl.formatMessage({ id: `common.confirm` })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default BudgetAcceptanceDialog;
