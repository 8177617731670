import { Box, Grid, debounce, Snackbar, Alert, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import TokenQuantityStatus from './TokenQuantityStatus';
import client from '../../../clients/client';
import { useEffect, useRef, useState } from 'react';
import { Role } from '../../../types';
import { useUpdateTokensContext } from '../../../contexts/updateTokensContext';

interface Props {
	type: string;
	token: string;
	agreementId?: string;
	loading: boolean;
	stopLoading: (value: boolean) => void;
	setCanExchange?: (value: boolean) => void;
}

const TokensQuantity = (props: Props) => {
	const intl = useIntl();
	const theme = useTheme();
	const { type, token, agreementId, loading, stopLoading, setCanExchange } = props;
	const { refresh, setRefresh } = useUpdateTokensContext();

	const [unities, setUnities] = useState({ available: 0, pending: 0, exchangeProcess: 0 });
	// const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>('');

	const fetchConstructorTokens = debounce(() => {
		// setLoading(true);
		stopLoading(true)
		client
			.getConstructorTokens({ token, agreementId })
			.then((data) => {
				setUnities(data.data);
				setCanExchange && setCanExchange(data.data.available > 0);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => stopLoading(false));
	});

	const fetchSellerTokens = debounce(() => {
		stopLoading(true);
		client
			.getSellerTokens({ token, agreementId })
			.then((data) => {
				if (data.emptyCollections) throw data.message;
				setUnities(data.data);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => stopLoading(false));
	});

	useEffect(() => {
		// if (refresh) {
			if (!!agreementId && type === Role.CONSTRUCTOR) fetchConstructorTokens();
			if (!!agreementId && type === Role.SELLER) fetchSellerTokens();
			if (!agreementId) stopLoading(false);
			setRefresh(false);
		// }
	}, [agreementId, type, refresh]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				gap: 6,
			}}
		>
			<Grid
				container
				rowSpacing={0.5}
				columnSpacing={2}
			>
				<Grid item xs={12} sm={4}>
					<TokenQuantityStatus
						loading={loading}
						quantity={unities?.available}
						color="green"
						label={intl.formatMessage({ id: `mytokens.${type}.dashboard.available` }, { unityName: 'Tokens' })}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TokenQuantityStatus
						loading={loading}
						quantity={unities?.pending}
						color="yellow"
						label={intl.formatMessage({ id: `mytokens.${type}.dashboard.pending` }, { unityName: 'Tokens' })}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TokenQuantityStatus
						loading={loading}
						quantity={unities?.exchangeProcess}
						color="orange"
						label={intl.formatMessage({ id: `mytokens.${type}.dashboard.exchange_process` }, { unityName: 'Tokens' })}
					/>
				</Grid>
			</Grid>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default TokensQuantity;
