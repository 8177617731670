import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getRemoteConfig, getValue, fetchAndActivate, getAll } from "firebase/remote-config";

const useFirebase = (): [any | null] => {
	const [remoteConfigObject, setRemoteConfigObject] = useState<any | null>(null);
	const [remoteConfig, setRemoteConfig] = useState<any | null>(null);

	useEffect(() => {
		if(!remoteConfigObject) {

			const firebaseConfig = {
				apiKey: "AIzaSyAVgBhkEPJkfO2pFI8ER6AvnhaP_34GKxo",
				authDomain: "nuqlea-362002.firebaseapp.com",
				databaseURL: "https://nuqlea-362002-default-rtdb.firebaseio.com",
				projectId: "nuqlea-362002",
				storageBucket: "nuqlea-362002.appspot.com",
				messagingSenderId: "832475153802",
				appId: "1:832475153802:web:58c92f7f8224daa5833473",
				measurementId: "G-DHQQNMCMYJ"
			};
			
			const app = initializeApp(firebaseConfig);
			const remoteConf = getRemoteConfig(app);
			remoteConf.settings.minimumFetchIntervalMillis = 2000;

			setRemoteConfigObject(remoteConf);
	
			fetchAndActivate(remoteConf)
			.then(activated => {				
				return getAll(remoteConf);
			})
			.then(remoteFlags => {
				if(remoteFlags) {
					let newFlags = {} as any;
		
					for (const [key, config] of Object.entries(remoteFlags)) {
						newFlags[key] = JSON.parse(getValue(remoteConf, key).asString())
					}
		
					setRemoteConfig(newFlags);
				}
			})
			.catch(error => console.error(error));
		}
	}, []);

	return [remoteConfig];
};

export default useFirebase;
