import { useState } from 'react';
import { Button, Typography, CircularProgress, IconButton } from '@mui/material';
import client from '../clients/client';
import { Download } from '@mui/icons-material';
import { User } from '../types';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
	label?: string;
	driveFileId: string;
	fileId: string;
	listId?: string;
	user: User;
	gcsFilePath?: string;
}

const FileDownloadButtonText = (props: Props) => {
	const { label, driveFileId, fileId, listId, user, gcsFilePath } = props;

	const intl = useIntl();
	const baseTheme = useTheme();
	const matchesXs = useMediaQuery(baseTheme.breakpoints.down('md'));

	const [loading, setLoading] = useState(false);

	if (loading) {
		return <CircularProgress sx={{ color: '#000', mb: -0.75, mr: 3 }} size={20} />;
	}

	if (!driveFileId && !gcsFilePath) {
		return <Typography className='text-xs'>{intl.formatMessage({ id: 'common.not_available' })}</Typography>;
	}

	if (matchesXs) {
		return (
			<IconButton
				onClick={() => {
					setLoading(true);

					if (driveFileId) {
						Promise.all([
							client.getDriveFileName({ driveFileId, user }),
							listId
								? client.getDriveFile({ driveFileId, fileId, listId, user })
								: client.getDriveFileOnly({ driveFileId, user }),
						])
							.then(([nameData, fileData]) => {
								const blob = new Blob([fileData], { type: 'application/octet-stream' });
								const blobURL = URL.createObjectURL(blob);
								const a = document.createElement('a');
	
								a.setAttribute('target', '_blank');
								a.setAttribute('download', nameData.filename);
								a.setAttribute('href', blobURL);
								a.click();
							})
							.finally(() => setLoading(false));
					}
					
					if (gcsFilePath) {
						Promise.all([
							client.downloadGCSFile(gcsFilePath, "base64", user.id_external, user.token),
							client.downloadGCSBinaryFile(gcsFilePath,  user.id_external, user.token)
						])
						.then(([nameData, fileData]) => {
							const blob = new Blob([fileData], { type: 'application/octet-stream' });
							const blobURL = URL.createObjectURL(blob);
							const a = document.createElement('a');
							a.setAttribute('target', '_blank');
							a.setAttribute('download', nameData.filename);
							a.setAttribute('href', blobURL);
							a.click();
						})
						.finally(() => setLoading(false));
					}
				}}
			>
				<Download />
			</IconButton>
		);
	}

	return (
		<Button
			variant="text"
			size='small'
			onClick={() => {
				setLoading(true);
				if (driveFileId) {
					Promise.all([
						client.getDriveFileName({ driveFileId, user }),
						listId
							? client.getDriveFile({ driveFileId, fileId, listId, user })
							: client.getDriveFileOnly({ driveFileId, user }),
					])
						.then(([nameData, fileData]) => {
							const blob = new Blob([fileData], { type: 'application/octet-stream' });
							const blobURL = URL.createObjectURL(blob);
							const a = document.createElement('a');
	
							a.setAttribute('target', '_blank');
							a.setAttribute('download', nameData.filename);
							a.setAttribute('href', blobURL);
							a.click();
						})
						.finally(() => setLoading(false));
				}
				
				if (gcsFilePath) {
					Promise.all([
						client.downloadGCSFile(gcsFilePath, "base64", user.id_external, user.token),
						client.downloadGCSBinaryFile(gcsFilePath,  user.id_external, user.token)
					])
					.then(([nameData, fileData]) => {
						const blob = new Blob([fileData], { type: 'application/octet-stream' });
						const blobURL = URL.createObjectURL(blob);
						const a = document.createElement('a');
						a.setAttribute('target', '_blank');
						a.setAttribute('download', nameData.filename);
						a.setAttribute('href', blobURL);
						a.click();
					})
					.finally(() => setLoading(false));
				}
			}}
		>
			{label || 'Descargar'}
		</Button>
	);
};

export default FileDownloadButtonText;
