import React, { Fragment, useMemo } from 'react';
import emotionStyled from '@emotion/styled';
import { Alert, Box, Grid, LinearProgress, MenuItem, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { SelectInputConfirmation, TooltipQuestionMarkIcon } from './confirmation-step';
import { AgreementContent } from '../dashboard/tokens';
import { SET_SELECTED_AGREEMENT_ID } from './reducer/actionNames';
import giftSVG from '../../../assets/green-gift.svg';
import { AgreementCondition } from './reducer';
import { Agreement } from '../../../types';
import { useMobile } from '../../../hooks/use-mobile';
import { useRecoilValue } from 'recoil';
import agreementsOptionsState from '../../../atoms/agreements-options-state';

const GiftIcon = emotionStyled.img`
    width: 15px;
    height: 15px;
    margin: 0px 12px;
`;

interface Props {
	benefit?: AgreementCondition | null;
	dispatch: React.Dispatch<any>;
	isLoading?: boolean;
	noAvailableTokensToBuy?: boolean;
	selectedAgreementId: string;
	selectedAgreement?: Agreement;
	timeValidity: string;
}

const AgreementSelect = (props: Props) => {
	const {
		benefit,
		dispatch,
		isLoading,
		noAvailableTokensToBuy,
		selectedAgreement,
		selectedAgreementId,
		timeValidity,
	} = props;
	const { agreementsOptions } = useRecoilValue<{
		agreementsOptions: AgreementContent[];
		isLoading: boolean;
	}>(agreementsOptionsState)

	const intl = useIntl();
	const isMobile = useMobile();

	return (
		<Grid
			container
			rowSpacing={1}
			columnSpacing={2}
			sx={{
				...(!selectedAgreement ? { marginBottom: '1rem' } : {}),
			}}
		>
			<Grid item xs={12} sx={{ color: 'grey.600' }}>
				{intl.formatMessage({ id: 'buy_tokens.select_agreement.title' })}
			</Grid>
			<Grid
				item
				xs={12}
				sm={7}
				sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }}
			>
				<SelectInputConfirmation
					value={selectedAgreementId}
					onChange={(event) => dispatch({ type: SET_SELECTED_AGREEMENT_ID, payload: event.target.value })}
					sx={{ width: '100%', color: 'grey.600' }}
				>
					{agreementsOptions.map((agreement) => (
						<MenuItem key={agreement.id} value={agreement.id} sx={{ color: 'grey.600' }}>
							{agreement.product_label}
						</MenuItem>
					))}
				</SelectInputConfirmation>
				{selectedAgreement && !isLoading && (
					<Typography sx={{ color: '#72727E', fontSize: '12px', margin: '5px 0px 0px 2px' }}>
						{timeValidity}
						{!isMobile && (
							<Tooltip
								title={
									<Typography sx={{ color: '#FFFFFF' }}>
										{intl.formatMessage({ id: 'buy_tokens.select_agreement.tooltip_collection_duration' })}
									</Typography>
								}
							>
								<TooltipQuestionMarkIcon sx={{ width: '10px' }} />
							</Tooltip>
						)}
					</Typography>
				)}
			</Grid>
			{benefit && !isLoading && (
				<Grid item xs={12} sm={5}>
					<Box
						sx={{
							height: { xs: 'auto', sm: 'calc(100% - 23px)' },
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							backgroundColor: '#EFF8F3',
							color: '#069C54',
							borderLeft: '4px solid #069C54',
							borderRadius: '5px',
						}}
					>
						<GiftIcon src={giftSVG} alt="gift-icon" />
						<Typography
							sx={{ flexGrow: 1, color: 'inherit', fontWeight: '500', lineHeight: '19px', marginRight: '15px' }}
						>
							{/* {
                                intl.formatMessage({ id: 'buy_tokens.select_agreement.gift_condition_line' }, { minUnits: 40, unitName: selectedAgreement.unity, indicativeLetter: 'o' })
                                .split('\n').map((line, index) => {
                                    if (index + 1 < intl.formatMessage({ id: 'buy_tokens.select_agreement.gift_condition_line' }).split('\n').length) {
                                        return <Fragment key={index + 'buy_tokens.select_agreement.gift_condition_line'}>{line}<br/></Fragment>
                                    } else {
                                        return <Fragment key={index + 'buy_tokens.select_agreement.gift_condition_line'}>{line}</Fragment>
                                    }
                                })
                            } */}
							{benefit.description}
						</Typography>
					</Box>
				</Grid>
			)}
			{noAvailableTokensToBuy && (
				<Grid item xs={12} sm={5}>
					<Alert
						severity="error"
						sx={{
							height: '100%',
							borderRadius: '5px',
						}}
					>
						<Typography sx={{ color: 'inherit', fontWeight: '500' }}>
							{intl.formatMessage({ id: 'mytokens.constructor.dashboard.dialog.redeem.considerations_three' })}
						</Typography>
					</Alert>
				</Grid>
			)}
			{isLoading && (
				<Grid item xs={12}>
					<Box sx={{ width: '100%', marginTop: 5 }}>
						<LinearProgress color="inherit" />
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default AgreementSelect;
