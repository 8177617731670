import { Box, Typography, CircularProgress, useTheme, useMediaQuery, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatNumberLocale } from '../../../utils/data-utils';

interface TokenQuantityStatusProps {
	quantity?: number;
	color: 'green' | 'yellow' | 'orange';
	label: string;
	loading: boolean;
}

const hexaColors = {
	green: '#15D763',
	yellow: '#FFDB1C',
	orange: '#FF551F',
};

const TokenQuantityStatus = ({ quantity = 0, color, label, loading }: TokenQuantityStatusProps) => {
	const theme = useTheme();

	const [loadingState, setLoadingState] = useState(loading);

	useEffect(() => {
		setLoadingState(loading);
	}, [loading]);

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: loadingState ? 'center' : 'space-between',
				alignItems: 'center',
				gap: 2,
				margin: '1rem 0',
				padding: '1rem',
				borderRadius: '5px',
				backgroundColor: '#F7F7F7',
				opacity: 0.8,
				minHeight: { xs: 'auto', sm: '116px', lg: 'auto' },
			}}
		>
			{!loadingState ? (
				<>
					<Divider flexItem orientation="vertical" sx={{ borderColor: hexaColors[color], borderRightWidth: 'medium', borderRadius: 2 }} />
					<Typography sx={{ flex: 3, color: '#484850' }}>
						{label}
					</Typography>
					<Typography 
						sx={{ 
							flex: 2,
							color: '#000000',
							textAlign: 'right',
							fontSize: '24px',
							fontWeight: '500',
						}}
					>
						{formatNumberLocale(quantity)}
					</Typography>
				</>
			) : (
				<Box sx={{ display: 'flex', mb: '10px', color: 'grey.500' }}>
					<CircularProgress color="inherit" />
				</Box>
			)}
		</Box>
	);
};

export default TokenQuantityStatus;
