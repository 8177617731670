import React, { useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Snackbar,
	Stack,
	ThemeProvider,
	Typography,
} from '@mui/material';
import { useMobile } from '../../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import TextFieldWithPlaceholder from '../../../text-field-with-placeholder';
import SelectWithPlaceholder from '../../../select-with-placeholder';
import SelectMultiWithPlaceholder from '../../../select-multiple-with-placeholder';
import NuqleaFileUpload from '../../../nuqlea-file-uploader';
import { BusinessFile, BusinessName, DocumentationTypes, User } from '../../../../types';
import { fileToBase64 } from '../../../../utils/data-utils';
import client from '../../../../clients/client';
import { LoadingButton } from '@mui/lab';

import InformationBar from '../../../information-bar';
import GenericTabFrame from '../../../base-components/generic-tab-frame';
import StackDataDisplay from '../../../base-components/stack-data-display';

interface TaxProfileFormProps {
	callback: (isEditionMode: boolean, changed?: boolean) => void;
	showRequiredError?: (show: boolean, message?: string) => void;
	updateBusinessNamesList?: () => void;
	businessNameToEdit?: BusinessName;
	organizationId: string;
	token: string;
	creator: User;
	isModal?: boolean;
}

const ivaOptions = [
	{ value: 'Monotributista' },
	{ value: 'Consumidor final' },
	{ value: 'Responsable inscripto' },
	{ value: 'Exento' },
];

const iibbOptions = [{ value: 'Convenio local' }, { value: 'Convenio multilateral' }, { value: 'Exento' }];

const perceptionOptions = [{ value: 'IIGG' }, { value: 'IVA' }, { value: 'IIBB' }, { value: 'SUSS' }];

const TaxProfileForm: React.FC<TaxProfileFormProps> = ({
	callback,
	showRequiredError,
	updateBusinessNamesList,
	businessNameToEdit,
	organizationId,
	token,
	creator,
	isModal = false,
}) => {
	const isMobile = useMobile();
	const intl = useIntl();

	const [error, setError] = useState('');
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(intl.formatMessage({ id: 'common.error_required_fields' }));

	const [cuit, setCuit] = useState(businessNameToEdit?.cuit ?? '');
	const [organizationName, setOrganizationName] = useState(businessNameToEdit?.business_social ?? '');
	const [taxResidence, setTaxResidence] = useState(businessNameToEdit?.invoice_address ?? '');
	const [ivaStatus, setIvaStatus] = useState(businessNameToEdit?.tax_status ?? '');
	const [iibbStatus, setIibbStatus] = useState(businessNameToEdit?.iibb_status ?? '');
	const [perceptionStatus, setPerceptionStatus] = useState<string[]>(businessNameToEdit?.perceptions?.split(',') ?? []);
	const [firstSubmit, setFirstSubmit] = useState(false);

	const [miPymeCertificate, setMiPymeCertificate] = useState<File[]>([]);
	const [ivaCertificate, setIvaCertificate] = useState<File[]>([]);
	const [ivaExemptionCertificate, setIvaExemptionCertificate] = useState<File[]>([]);
	const [iibbLocalCertificate, setIibbLocalCertificate] = useState<File[]>([]);
	const [iibbMultilateral01Certificate, setIibbMultilateral01Certificate] = useState<File[]>([]);
	const [iibbMultilateral05Certificate, setIibbMultilateral05Certificate] = useState<File[]>([]);
	const [iibbExemptionCertificate, setIibbExemptionCertificate] = useState<File[]>([]);
	const [iiggPerceptionCertificate, setIiggPerceptionCertificate] = useState<File[]>([]);
	const [ivaPerceptionCertificate, setIvaPerceptionCertificate] = useState<File[]>([]);
	const [iibbPerceptionCertificate, setIibbPerceptionCertificate] = useState<File[]>([]);
	const [sussPerceptionCertificate, setSussPerceptionCertificate] = useState<File[]>([]);

	const [isChecking, setIsChecking] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	let allFilesPresent = false;

	const showRequiredErrorDefault = (show: boolean, message?: string) => {
		message ? setErrorMessage(message) : setErrorMessage(intl.formatMessage({ id: 'common.error_required_fields' }));
		setShowError(show);
	};

	const handleShowError = (show: boolean, message?: string) => {
		if (showRequiredError) {
			showRequiredError(show, message);
		} else {
			showRequiredErrorDefault(show, message);
		}
	};

	const handleSave = async () => {
		allFilesPresent = checkRequiredFilesPresent();
		setFirstSubmit(true);
		let items = document.querySelectorAll(
			'.MuiInputBase-input, .MuiSelect-nativeInput'
		) as NodeListOf<HTMLInputElement>;
		let arrayItems = Array.from(items);
		//A little delay to ensure the state changes before evaluate it's aria-invalid attribute
		await new Promise((resolve) => setTimeout(resolve, 0));
		const anyInvalid = arrayItems?.some((item) => item.getAttribute('aria-invalid') === 'true');

		if (anyInvalid || !allFilesPresent) {
			handleShowError(true);
		} else {
			setIsChecking(true);
			let exists = businessNameToEdit ? false : await checkAlreadyExists(Number(cuit), organizationName);
			setIsChecking(false);
			if (!exists) {
				handleShowError(false);
				businessNameToEdit ? updateBusinessName() : createBusinessName();
			}
		}
	};

	const createBusinessName = async () => {
		const newBusinessName: BusinessName = {
			id_constructora: organizationId,
			business_social: organizationName,
			cuit: Number(cuit),
			invoice_address: taxResidence,
			perceptions: perceptionStatus.join(','),
			tax_status: ivaStatus,
			iibb_status: iibbStatus,
			files: await makeFilesArray(),
			created_by: creator.email,
		};
		try {
			const data = newBusinessName as any;
			data.userId = creator.id;
			setIsSaving(true);
			await client.createBusinessName({ data: data, token: token });
			updateBusinessNamesList && updateBusinessNamesList();
			callback(false, true);
		} catch (error) {
			console.error(error);
		} finally {
			setIsSaving(false);
		}
	};

	const updateBusinessName = async () => {
		//Get current files loaded in front
		const unfilteredFiles = await makeFilesArray();
		let filesToDelete: string[] = [];

		//Check if original files are present in current files, if not, mark to delete
		businessNameToEdit?.files?.forEach((bf: BusinessFile) => {
			let keep = unfilteredFiles?.find((uf) => uf.format === bf.id);
			if (keep == undefined) {
				filesToDelete.push(String(bf.id));
			}
		});

		//Remove files that don't need to be updated
		const filteredFiles = unfilteredFiles?.filter((item) => item.doc !== '');

		const updatedBusinessName: BusinessName = {
			id: businessNameToEdit?.id,
			id_constructora: organizationId,
			business_social: organizationName,
			cuit: Number(cuit),
			invoice_address: taxResidence,
			perceptions: perceptionStatus.join(','),
			tax_status: ivaStatus,
			iibb_status: iibbStatus,
			files: filteredFiles,
			created_by: creator.email,
			default_business_name: businessNameToEdit?.default_business_name,
			main: businessNameToEdit?.main,
			removedFilesIds: filesToDelete,
			validInvoiceProfile: businessNameToEdit?.validInvoiceProfile,
		};

		try {
			const data = updatedBusinessName as any;
			data.userId = creator.id;
			setIsSaving(true);
			await client.updateBusinessName({ data: updatedBusinessName, token: token });
			updateBusinessNamesList && updateBusinessNamesList();
			callback(false, true);
		} catch (error: any) {
			console.error(error);
			if (error.response?.status === 413) {
				setError(intl.formatMessage({ id: 'common.payloadTooLarge' }));
			}
		} finally {
			setIsSaving(false);
		}
	};

	const checkAlreadyExists = async (cuit: Number, businessName: string) => {
		const data = { cuit: cuit, name: businessName };

		try {
			const result = await client.checkBusinessNameExists({ data: data, token: token });
			if (result) {
				if (result.data.cuit && result.data.name) {
					handleShowError(true, intl.formatMessage({ id: 'settings.company.tax_profile_tab.existent_cuit' }));
					return true;
				}
				if (result.data.name) {
					handleShowError(true, intl.formatMessage({ id: 'settings.company.tax_profile_tab.existent_business_name' }));
					return true;
				}
				handleShowError(true, intl.formatMessage({ id: 'settings.company.tax_profile_tab.existent_cuit' }));
				return true;
			} else {
				return false;
			}
		} catch (error) {}
	};

	const isShowFileRequired = (filePicker: File[]): boolean => {
		if (filePicker.length === 0 && firstSubmit) {
			allFilesPresent = true;
			return true;
		}
		return false;
	};

	const makeFilesArray = async () => {
		let files: { type: number; doc: string; format: string; name: string }[] = [];
		miPymeCertificate.length &&
			miPymeCertificate[0] &&
			files.push({
				type: DocumentationTypes.MI_PYME_CERT,
				doc: (await fileToBase64(miPymeCertificate[0])).base64 ?? '',
				format: miPymeCertificate[0].type,
				name: getFileName(DocumentationTypes.MI_PYME_CERT),
			});

		ivaCertificate.length &&
			ivaCertificate[0] &&
			files.push({
				type: DocumentationTypes.CUIT_CERT,
				doc: (await fileToBase64(ivaCertificate[0])).base64 ?? '',
				format: ivaCertificate[0].type,
				name: getFileName(DocumentationTypes.CUIT_CERT),
			});

		ivaExemptionCertificate.length &&
			ivaExemptionCertificate[0] &&
			files.push({
				type: DocumentationTypes.IVA_EXCL_CERT,
				doc: (await fileToBase64(ivaExemptionCertificate[0])).base64 ?? '',
				format: ivaExemptionCertificate[0].type,
				name: getFileName(DocumentationTypes.IVA_EXCL_CERT),
			});

		iibbLocalCertificate.length &&
			iibbLocalCertificate[0] &&
			files.push({
				type: DocumentationTypes.IIBB_LOCAL_CERT,
				doc: (await fileToBase64(iibbLocalCertificate[0])).base64 ?? '',
				format: iibbLocalCertificate[0].type,
				name: getFileName(DocumentationTypes.IIBB_LOCAL_CERT),
			});

		iibbMultilateral01Certificate.length &&
			iibbMultilateral01Certificate[0] &&
			files.push({
				type: DocumentationTypes.IIBB_MULTILATERAL_01_CERT,
				doc: (await fileToBase64(iibbMultilateral01Certificate[0])).base64 ?? '',
				format: iibbMultilateral01Certificate[0].type,
				name: getFileName(DocumentationTypes.IIBB_MULTILATERAL_01_CERT),
			});

		iibbMultilateral05Certificate.length &&
			iibbMultilateral05Certificate[0] &&
			files.push({
				type: DocumentationTypes.IIBB_MULTILATERAL_05_CERT,
				doc: (await fileToBase64(iibbMultilateral05Certificate[0])).base64 ?? '',
				format: iibbMultilateral05Certificate[0].type,
				name: getFileName(DocumentationTypes.IIBB_MULTILATERAL_05_CERT),
			});

		iibbExemptionCertificate.length &&
			iibbExemptionCertificate[0] &&
			files.push({
				type: DocumentationTypes.IIBB_EXCL_CERT,
				doc: (await fileToBase64(iibbExemptionCertificate[0])).base64 ?? '',
				format: iibbExemptionCertificate[0].type,
				name: getFileName(DocumentationTypes.IIBB_EXCL_CERT),
			});

		iiggPerceptionCertificate.length &&
			iiggPerceptionCertificate[0] &&
			files.push({
				type: DocumentationTypes.IIGG_PERC_CERT,
				doc: (await fileToBase64(iiggPerceptionCertificate[0])).base64 ?? '',
				format: iiggPerceptionCertificate[0].type,
				name: getFileName(DocumentationTypes.IIGG_PERC_CERT),
			});

		ivaPerceptionCertificate.length &&
			ivaPerceptionCertificate[0] &&
			files.push({
				type: DocumentationTypes.IVA_PERC_CERT,
				doc: (await fileToBase64(ivaPerceptionCertificate[0])).base64 ?? '',
				format: ivaPerceptionCertificate[0].type,
				name: getFileName(DocumentationTypes.IVA_PERC_CERT),
			});

		iibbPerceptionCertificate.length &&
			iibbPerceptionCertificate[0] &&
			files.push({
				type: DocumentationTypes.IIBB_PERC_CERT,
				doc: (await fileToBase64(iibbPerceptionCertificate[0])).base64 ?? '',
				format: iibbPerceptionCertificate[0].type,
				name: getFileName(DocumentationTypes.IIBB_PERC_CERT),
			});

		sussPerceptionCertificate.length &&
			sussPerceptionCertificate[0] &&
			files.push({
				type: DocumentationTypes.SUSS_PERC_CERT,
				doc: (await fileToBase64(sussPerceptionCertificate[0])).base64 ?? '',
				format: sussPerceptionCertificate[0].type,
				name: getFileName(DocumentationTypes.SUSS_PERC_CERT),
			});

		return files.length ? files : null;
	};

	const checkRequiredFilesPresent = () => {
		if (ivaStatus !== '') {
			if (ivaCertificate.length === 0) return false;
			if (ivaStatus === ivaOptions[3].value && ivaExemptionCertificate.length === 0) return false;
		}

		if (iibbStatus === iibbOptions[0].value && iibbLocalCertificate.length === 0) return false;

		if (
			iibbStatus === iibbOptions[1].value &&
			(iibbMultilateral01Certificate.length === 0 || iibbMultilateral05Certificate.length === 0)
		)
			return false;
		if (iibbStatus === iibbOptions[2].value && iibbExemptionCertificate.length === 0) return false;

		if (perceptionStatus.includes(perceptionOptions[0].value) && iiggPerceptionCertificate.length === 0) return false;
		if (perceptionStatus.includes(perceptionOptions[1].value) && ivaPerceptionCertificate.length === 0) return false;
		if (perceptionStatus.includes(perceptionOptions[2].value) && iibbPerceptionCertificate.length === 0) return false;
		if (perceptionStatus.includes(perceptionOptions[3].value) && sussPerceptionCertificate.length === 0) return false;

		return true;
	};

	const getFileName = (type: Number, index?: Number): string => {
		let name = Object.keys(DocumentationTypes).find(
			(item) => DocumentationTypes[item as keyof typeof DocumentationTypes] === type
		);

		return index ? String(name) + `_${index}` : String(name);
	};

	useEffect(() => {
		const getStoragedFiles = async () => {
			if (businessNameToEdit?.files?.length > 0) {
				for (const file of businessNameToEdit?.files) {
					const blob = new Blob();
					const filename = file.path.substring(file.path.lastIndexOf('/') + 1);
					const newFile = new File([blob], filename, { type: file.id });

					switch (Number(file.type)) {
						case DocumentationTypes.MI_PYME_CERT:
							setMiPymeCertificate([newFile]);
							break;
						case DocumentationTypes.CUIT_CERT:
							setIvaCertificate([newFile]);
							break;
						case DocumentationTypes.IVA_EXCL_CERT:
							setIvaExemptionCertificate([newFile]);
							break;
						case DocumentationTypes.IIBB_LOCAL_CERT:
							setIibbLocalCertificate([newFile]);
							break;
						case DocumentationTypes.IIBB_MULTILATERAL_01_CERT:
							setIibbMultilateral01Certificate([newFile]);
							break;
						case DocumentationTypes.IIBB_MULTILATERAL_05_CERT:
							setIibbMultilateral05Certificate([newFile]);
							break;
						case DocumentationTypes.IIBB_EXCL_CERT:
							setIibbExemptionCertificate([newFile]);
							break;
						case DocumentationTypes.IIGG_PERC_CERT:
							setIiggPerceptionCertificate([newFile]);
							break;
						case DocumentationTypes.IVA_PERC_CERT:
							setIvaPerceptionCertificate([newFile]);
							break;
						case DocumentationTypes.IIBB_PERC_CERT:
							setIibbPerceptionCertificate([newFile]);
							break;
						case DocumentationTypes.SUSS_PERC_CERT:
							setSussPerceptionCertificate([newFile]);
							break;
						default:
							break;
					}
				}
			}
		};
		getStoragedFiles();
	}, []);

	return (
		<>
			<Grid container px={isModal ? '2rem' : 0}>
				{showError && (
					<Grid item xs={12}>
						<InformationBar className="mt-4" icon="info" message={errorMessage}></InformationBar>
					</Grid>
				)}

				<Grid item minHeight={'28rem'} xs={12}>
					<Stack spacing={2}>
						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'header.tax_id_label' })}
							placeholder={intl.formatMessage({ id: 'settings.company.tax_profile_tab.cuit_placeholder' })}
							required
							disabled={businessNameToEdit?.main ? true : false}
							fullWidth
							onChange={(event) => {
								const input = event.target.value;
								const numericInput = input.replace(/\D/g, '');
								setCuit(numericInput);
							}}
							value={cuit}
							error={firstSubmit && cuit === '' ? true : false}
						></TextFieldWithPlaceholder>

						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.organization_name_input' })}
							placeholder={intl.formatMessage({
								id: 'settings.company.tax_profile_tab.organization_name_placeholder',
							})}
							required
							fullWidth
							onChange={(event) => setOrganizationName(event.target.value as string)}
							value={organizationName}
							error={firstSubmit && organizationName === '' ? true : false}
						></TextFieldWithPlaceholder>

						<TextFieldWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.tax_residence_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.tax_profile_tab.tax_residence_placeholder' })}
							fullWidth
							onChange={(event) => setTaxResidence(event.target.value as string)}
							value={taxResidence}
							required
							error={firstSubmit && taxResidence === '' ? true : false}
						></TextFieldWithPlaceholder>

						<Box>
							<InputLabel>
								{intl.formatMessage({ id: 'settings.company.tax_profile_tab.mipyme_certificate_input' })}
							</InputLabel>
							<NuqleaFileUpload value={miPymeCertificate} onChange={setMiPymeCertificate} />
						</Box>

						<SelectWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_status_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
							onChange={(event) => setIvaStatus(event.target.value as string)}
							fullWidth
							value={ivaStatus}
							required
							error={ivaStatus === '' && firstSubmit}
						>
							{ivaOptions.map((item) => (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							))}
						</SelectWithPlaceholder>

						{ivaStatus !== '' && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(ivaCertificate) ? true : false}
									value={ivaCertificate}
									onChange={setIvaCertificate}
								/>
							</Box>
						)}

						{ivaStatus === ivaOptions[3].value && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_exemption_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(ivaExemptionCertificate)}
									value={ivaExemptionCertificate}
									onChange={setIvaExemptionCertificate}
								/>
							</Box>
						)}

						<SelectWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_status_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
							onChange={(event) => setIibbStatus(event.target.value as string)}
							fullWidth
							value={iibbStatus}
							required
							error={iibbStatus === '' && firstSubmit}
						>
							{iibbOptions.map((item) => (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							))}
						</SelectWithPlaceholder>

						{iibbStatus === iibbOptions[0].value && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_local_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iibbLocalCertificate)}
									value={iibbLocalCertificate}
									onChange={setIibbLocalCertificate}
								/>
							</Box>
						)}

						{iibbStatus === iibbOptions[1].value && (
							<>
								<Box>
									<InputLabel>
										{intl.formatMessage({
											id: 'settings.company.tax_profile_tab.iibb_multilateral01_certificate_input',
										})}
									</InputLabel>
									<NuqleaFileUpload
										showRequired={isShowFileRequired(iibbMultilateral01Certificate)}
										value={iibbMultilateral01Certificate}
										onChange={setIibbMultilateral01Certificate}
									/>
								</Box>
								<Box>
									<InputLabel>
										{intl.formatMessage({
											id: 'settings.company.tax_profile_tab.iibb_multilateral05_certificate_input',
										})}
									</InputLabel>
									<NuqleaFileUpload
										showRequired={isShowFileRequired(iibbMultilateral05Certificate)}
										value={iibbMultilateral05Certificate}
										onChange={setIibbMultilateral05Certificate}
									/>
								</Box>
							</>
						)}

						{iibbStatus === iibbOptions[2].value && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_exemption_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iibbExemptionCertificate)}
									value={iibbExemptionCertificate}
									onChange={setIibbExemptionCertificate}
								/>
							</Box>
						)}

						<SelectMultiWithPlaceholder
							label={intl.formatMessage({ id: 'settings.company.tax_profile_tab.perception_agent_input' })}
							placeholder={intl.formatMessage({ id: 'settings.company.select_multi_placeholder' })}
							onValueChangeCallback={setPerceptionStatus}
							value={perceptionStatus}
							fullWidth
						>
							{perceptionOptions.map((item) => (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							))}
						</SelectMultiWithPlaceholder>

						{perceptionStatus.includes(perceptionOptions[0].value) && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iigg_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iiggPerceptionCertificate)}
									value={iiggPerceptionCertificate}
									onChange={setIiggPerceptionCertificate}
								/>
							</Box>
						)}

						{perceptionStatus.includes(perceptionOptions[1].value) && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(ivaPerceptionCertificate)}
									value={ivaPerceptionCertificate}
									onChange={setIvaPerceptionCertificate}
								/>
							</Box>
						)}

						{perceptionStatus.includes(perceptionOptions[2].value) && (
							<Box>
								<InputLabel>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(iibbPerceptionCertificate)}
									value={iibbPerceptionCertificate}
									onChange={setIibbPerceptionCertificate}
								/>
							</Box>
						)}

						{perceptionStatus.includes(perceptionOptions[3].value) && (
							<Box pb={'10px'}>
								<InputLabel sx={{ fontFamily: 'inherit', fontWeight: 500, color: '#4B4B4B' }}>
									{intl.formatMessage({ id: 'settings.company.tax_profile_tab.suss_perception_certificate_input' })}
								</InputLabel>
								<NuqleaFileUpload
									showRequired={isShowFileRequired(sussPerceptionCertificate)}
									value={sussPerceptionCertificate}
									onChange={setSussPerceptionCertificate}
								/>
							</Box>
						)}
					</Stack>
					{isSaving && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
				</Grid>

				<Grid item xs={12} sm={isMobile || isModal ? 12 : 6}>
					<Stack
						pt={'1.5rem'}
						direction="row"
						width={'100%'}
						justifyContent={isModal ? 'space-around' : 'left'}
						spacing={2}
					>
						<Button disabled={isSaving} onClick={() => callback(false, false)} variant="outlined">
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<LoadingButton
							loading={isChecking}
							disabled={isSaving}
							onClick={handleSave}
							variant="contained"
							sx={{ ml: '10px' }}
						>
							{intl.formatMessage({ id: 'common.save' })}
						</LoadingButton>
					</Stack>
				</Grid>
			</Grid>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={8000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};

export default TaxProfileForm;
