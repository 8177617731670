import { Container } from '@mui/material';
import { useEffect } from 'react';

const PageContainer = ({ children }: { children: React.ReactNode }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<Container
			maxWidth='lg'
			className='flex pt-16 pb-16 px-4'
		>
			{children}
		</Container>
	);
};

export default PageContainer;
