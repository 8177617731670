import axios, { AxiosResponse } from 'axios';
import { User } from '../types';

const customAxios = (user?: User) => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Accept'] = 'application/json';
            if (user) {
                config.headers['Authorization'] = `Bearer ${user.token}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export interface SubmitQuotationRequest {
    project: any;
    date?: Date;
    shipping?: string;
    address?: string;
    financing?: string;
    comments?: string;
    files: any;
    listId: string;
    user: User;
}

interface SubmitQuotationResponse {
    id_lista: string;
}

const client = {
    prepareUpload: async (user: User, requestedQuotationFiles?: File[], referenceQuotationFiles?: File[], deliveryScheduleFiles?: File[], quotationPlanFiles?: File[]): Promise<any> => {
        const getFileData = (file: File): { name: string; type: string; } => ({
            name: file.name,
            type: file.type,
        });

        try {
            const filesData = {
                cotizacion: {
                    columnName: 'Archivo de Pedido Original',
                    fileTypeId: 1,
                    hasFile: requestedQuotationFiles && requestedQuotationFiles.length > 0,
                    files: (requestedQuotationFiles || []).map((file) => getFileData(file)),
                },
                presupuesto: {
                    columnName: 'Presupuesto de referencia',
                    fileTypeId: 4,
                    hasFile: referenceQuotationFiles && referenceQuotationFiles.length > 0,
                    files: (referenceQuotationFiles || []).map((file) => getFileData(file)),
                },
                cronograma: {
                    columnName: 'Cronograma de entrega',
                    fileTypeId: 3,
                    hasFile: deliveryScheduleFiles && deliveryScheduleFiles.length > 0,
                    files: (deliveryScheduleFiles || []).map((file) => getFileData(file)),
                },
                planos: {
                    columnName: 'Planos para cotizar',
                    fileTypeId: 2,
                    hasFile: quotationPlanFiles && quotationPlanFiles.length > 0,
                    files: (quotationPlanFiles || []).map((file) => getFileData(file)),
                },
            };

            const client = customAxios(user);
            const response = await client.post<any>('/prepare-upload', { files: filesData, user });
            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    },
    submitQuotation: async (params: SubmitQuotationRequest) => {

        const { project, date, shipping, address, financing, comments, listId, files, user } = params;
        const client = customAxios(user);

        try {
            const response = await client.post<any>('/send-cotizacion',
                {
                    user: JSON.stringify(user),
                    form: {
                        proyecto: project.id_proyecto,
                        nombreProyecto: project.nombre_proyecto,
                        fechaRequeridaEntrega: date?.toLocaleDateString('es-AR'),
                        requiereEntrega: shipping,
                        direccionEntrega: address,
                        locationExtras: { place: address },
                        requiereFinanciacion: financing,
                        comments,
                    },
                    files: files,
                    listId
                }
            );

            return responseBody(response);
        } catch (error) {
            console.error('Ocurrió un error: ', error);
            throw error;
        }
    }
};

export default client;