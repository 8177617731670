import styled from '@emotion/styled';
import { Room } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Map from './map';
import MapMarker from './map-marker';
import marketIcon from '../assets/nuqlea-map-marker.png';
import { Location } from '../types';
import { ThemeProvider } from '@emotion/react';

const InputWithIcon = styled('div')`
	position: relative;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
`;

const Icon = styled(Room)`
	position: absolute;

	right: 0.5rem;
	top: 0.7rem;
	width: 1.5rem;
	height: 1.5rem;

	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
`;

const Label = styled('label')`
	position: relative;
	z-index: 1001;
	top: -65px;
	background-color: white;
	left: 20px;
	padding: 0 5px;
	font-weight: 400;
	font-size: 13px;
`;

const AddressSearch = styled('input')((props: { variant?: 'filter' | 'form' | 'compact'; errorColor?: string }) => ({
	height: '100%',
	padding: '10px 14px',
	borderRadius: props.variant === 'filter' ? '30px' : '4px',
	border:
		props.variant === 'filter'
			? '1px solid rgba(0, 0, 0, 0.87)'
			: `1px solid ${!!props.errorColor ? props.errorColor : 'rgba(0, 0, 0, 0.23)'}`,
	fontWeight: '400',
	fontSize: '14px',
	width: props.variant === 'filter' ? 'calc(100% - 33px)' : '100%',
	paddingRight: '3.65rem',
	boxSizing: 'border-box',
}));

const DialogAddressSearchContainer = styled('div')`
	padding: 1rem 2rem;
`;

const DialogAddressSearch = styled('input')(() => ({
	padding: '10px 14px',
	borderRadius: '4px',
	border: '1px solid rgba(0, 0, 0, 0.23)',
	fontWeight: '400',
	width: '100%',
	boxSizing: 'border-box',
}));

const StyledDialogContent = styled(DialogContent)`
	padding: 0;
`;

interface Props {
	label?: string;
	value?: Location;
	variant?: 'filter' | 'form' | 'compact';
	required?: boolean;
	disabled?: boolean;
	country?: string;
	onChange: (value?: Location) => void;
	errorColor?: string;
}

const defaultCenter = { lat: -34.6094281, lng: -58.4496156 };
const defaultZoom = 15;

const LocationPicker = (props: Props) => {
	// const addressSearchref = useRef();
	const dialogAddressSearchref = useRef();
	const intl = useIntl();

	const { label, value, variant, required, disabled, country, onChange, errorColor } = props;

	const [dialogOpen, setDialogOpen] = useState(false);
	const [markerPosition, setMarkerPosition] = useState<Location | undefined>(value);

	useEffect(() => {
		// @ts-ignore
		// const addressSearch = new google.maps.places.Autocomplete(addressSearchref.current, {
		// 	types: ['address'],
		// 	componentRestrictions: country && {
		// 		country: [country],
		// 	},
		// });

		// addressSearch?.addListener('place_changed', () => {
		// 	const coordinates = addressSearch.getPlace().geometry?.location;
		// 	const address = addressSearch.getPlace().formatted_address;

		// 	if (address && coordinates) {
		// 		onChange({ address, coordinates: { lat: coordinates.lat(), lng: coordinates.lng() } });
		// 	} else {
		// 		onChange(undefined);
		// 	}
		// });

		// @ts-ignore
		const dialogAddressSearch = new google.maps.places.Autocomplete(dialogAddressSearchref.current, {
			types: ['address'],
			componentRestrictions: country && {
				country: [country],
			},
		});

		dialogAddressSearch?.addListener('place_changed', () => {
			const coordinates = dialogAddressSearch.getPlace().geometry?.location;
			const address = dialogAddressSearch.getPlace().formatted_address;

			if (address && coordinates) {
				setMarkerPosition({
					google_place_id: dialogAddressSearch.getPlace().place_id,
					address,
					coordinates: { lat: coordinates.lat(), lng: coordinates.lng() },
					full_response: dialogAddressSearch.getPlace(),
				});
			} else {
				setMarkerPosition(undefined);
			}
		});
	});

	useEffect(() => {
		if (value) {
			setMarkerPosition(value);
		}
	}, [value]);

	const handleMarkerChanged = (coordinates: google.maps.LatLng | null) => {
		if (!coordinates) {
			setMarkerPosition(undefined);
			return;
		}

		const geocoder = new google.maps.Geocoder();

		geocoder
			.geocode({
				location: coordinates,
			})
			.then((res) => {
				const address = res.results[0].formatted_address;
				const location = {
					google_place_id: res.results[0].place_id,
					address,
					coordinates: {
						lat: coordinates.lat(),
						lng: coordinates.lng(),
					},
					full_response: res.results[0],
				};

				setMarkerPosition(location);
			});
	};

	const handleBack = () => {
		setDialogOpen(false);
		setMarkerPosition(value);
	};

	const handleConfirm = () => {
		setDialogOpen(false);
		onChange(markerPosition);
	};

	return (
		<>
			<Box height={50} onClick={() => setDialogOpen(true)} sx={{ '& input': { cursor: 'pointer' } }}>
				<InputWithIcon>
					<Icon />
					<AddressSearch
						id="address-search"
						// @ts-ignore
						// ref={addressSearchref}
						placeholder="Seleccioná una ubicación"
						type="search"
						value={value?.address}
						onChange={(event) => onChange({ ...value, address: event.target.value })}
						readOnly={disabled}
						variant={variant}
						errorColor={errorColor}
					/>
				</InputWithIcon>
			</Box>

			{label && (
				<Label>
					{label}
					{required ? '*' : ''}
				</Label>
			)}

			{variant === 'filter' && value?.coordinates && (
				<Map
					mini={true}
					center={value?.coordinates}
					zoom={defaultZoom}
					height="200px"
					gestureHandling="none"
					zoomControl={false}
					fullscreenControl={false}
					mapTypeControl={false}
					streetViewControl={false}
				>
					<MapMarker
						position={value?.coordinates}
						icon={{
							url: marketIcon,
							scaledSize: new google.maps.Size(50, 58),
						}}
					/>
				</Map>
			)}

			<Dialog sx={{ zIndex: 2000 }} open={dialogOpen} onClose={handleBack} fullWidth maxWidth="sm">
				<DialogTitle>
					<Typography variant="h4" textAlign="center">
						{intl.formatMessage({ id: 'address_picker.dialog.title' })}
					</Typography>
				</DialogTitle>
				<StyledDialogContent>
					<Typography textAlign="center">{intl.formatMessage({ id: 'address_picker.dialog.subtitle' })}</Typography>
					<DialogAddressSearchContainer>
						<DialogAddressSearch
							id="dialog-address-search"
							// @ts-ignore
							ref={dialogAddressSearchref}
							type="search"
							value={markerPosition?.address}
							onChange={(event) => setMarkerPosition({ ...markerPosition, address: event.target.value })}
						/>
					</DialogAddressSearchContainer>
					<Map
						mini={false}
						center={markerPosition?.coordinates || defaultCenter}
						zoom={defaultZoom}
						height="50vh"
						onClick={(event) => handleMarkerChanged(event.latLng)}
					>
						{markerPosition?.coordinates && (
							<MapMarker
								position={markerPosition?.coordinates}
								icon={{
									url: marketIcon,
									scaledSize: new google.maps.Size(50, 58),
								}}
								draggable={true}
								onDragend={(event) => handleMarkerChanged(event.latLng)}
							/>
						)}
					</Map>
				</StyledDialogContent>
				<DialogActions sx={{ justifyContent: 'center', gap: 1, mb: 1 }}>
					<Button onClick={handleBack} variant="outlined" className="w-[7.5rem]">
						{intl.formatMessage({ id: 'common.back' })}
					</Button>
					<Button onClick={handleConfirm} className="w-[7.5rem]" variant="contained">
						{intl.formatMessage({ id: 'common.confirm' })}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default LocationPicker;
