import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import {
  useKnockFeed,
} from "@knocklabs/react";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

const NotificationsToast = () => {
  const { feedClient } = useKnockFeed();

  const onNotificationsReceived = ({ items }:any) => {
    // Whenever we receive a new notification from our real-time stream, show a toast
    // (note here that we can receive > 1 items in a batch)
    items.forEach((notification:any) => {
      toast(<Box>
          <IconButton
            size="small"
            aria-label="close"
            onClick={t => toast.dismiss(notification.id)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div dangerouslySetInnerHTML={{ __html: notification.blocks[0].rendered }} />
        </Box>, {
        id: notification.id
      });
    });

    // Optionally, you may want to mark them as "seen" as well
    feedClient.markAsSeen(items);
  };

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient.on("items.received.realtime", onNotificationsReceived);

    // Cleanup
    return () =>
      feedClient.off("items.received.realtime", onNotificationsReceived);
  }, [feedClient]);

  return <Toaster position="bottom-right" toastOptions={{
      style: {
        background: '#222',
        color: '#fff',
      }
    }} />;
};

export default NotificationsToast;