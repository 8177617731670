import { Box, Container, Grid, Paper, Stack, Typography, debounce } from '@mui/material';
import { Document, FilesList, Response, TokenDetailStatus, StockSafekeeping, Role, BusinessName, AgreementConditionTypes, SellerWithDocuments } from '../../../types';
import StatusCard, { Status } from '../../status-card';
import TableCardTreeView from '../../table-card-tree-view';
import { Upload } from '@mui/icons-material';
import { relatedFiles } from '../../details/columns';
import FileUploadDialog from '../../details/dialogs/file-upload-dialog';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import FullScreenLoading from '../../full-screen-loading';
import client from '../../../clients/client';
import { useIntl } from 'react-intl';
import Error from '../../error';
import InfoMarker from '../../info-marker';
import NuqleaAlert from '../../nuqlea-alert';
import SideModalContainer from '../../modals/side-modal-container';
import { useMobile } from '../../../hooks/use-mobile';
import TaxProfileForm from '../../settings/organization/shared-components/tax-profile-form';
import { formatNumberLocale } from '../../../utils/data-utils';
import userState from '../../../atoms/user-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SellerPaymentInfo from '../../details/dialogs/seller-payment-info';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';

const allowedFileTypes = [
	{ id: 7, name: 'Orden de compra' },
	{ id: 9, name: 'Comprobante de pago' },
	{ id: 10, name: 'Comprobante de retenciones' },
	{ id: 11, name: 'Órden de pago' },
	{ id: 22, name: 'Cronograma de pago' },
];

const getDelivery = (conditions: []) => {
	const delivery: any = conditions.find((item: any) => item.condition_type_id === AgreementConditionTypes.SHIPPING || item.condition_type_id === AgreementConditionTypes.WITHDRAWAL);
	return delivery ?? { agreement_condition_type_label: '-', agreement_condition_zone_label: '-'};
};

const getShippingCost = (conditions: [], total: number): number => {
	const shipping: any = conditions.find((item: any) => item.condition_type_id === AgreementConditionTypes.SHIPPING);
	if (shipping) {
		return shipping.value_type === 'percentage'
			? ((total * shipping.value) / 100).toFixed(2)
			: total + shipping.value;
	}

	return 0;
};

const getFinancingCondition = (conditions: []) => {
	const financing: any = conditions.find((item: any) => item.condition_type_id === AgreementConditionTypes.FINANCING);
	return financing ? financing.name : 'Sin financiación';
};

const getPriceBonus = (conditions: []) => {
	const priceBonus: any = conditions.find((item: any) => item.condition_type_id === AgreementConditionTypes.PRICE);

	return priceBonus?.name;
};


const TokenDetailViewSeller = () => {
	const baseTheme = useTheme();
	const isMobile: boolean = useMobile();

	const { id } = useParams();
	const user = useRecoilValue(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const intl = useIntl();

	const [stockSafekeepingResponse, setStockSafekeepingResponse] = useState<Response<StockSafekeeping>>();
	const [agreementConditions, setAgreementConditions] = useState<any>([]);
	const [isLoadingAgreementConditions, setIsLoadingAgreementConditions] = useState(true); // because agreement conditions are fetched after stock safekeeping
	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [allDocuments, setAllDocuments] = useState<Document[]>([]);
	const [filesResponse, setFilesResponse] = useState<Response<FilesList>>();
	const [stockSafekeepingDetails, setStockSafekeepingDetails] = useState<any>([]);
	const [counts, setCounts] = useState<Status[]>();
	const [taxProfileForm, setTaxProfileForm] = useState<boolean>(false);
	const [businessName, setBusinessName] = useState<BusinessName>();
	const [isSellerInfoOpen, setIsSellerInfoOpen] = useState<boolean>(false);
	const [sellerInfo, setSellerInfo] = useState<SellerWithDocuments>();

	const fetchStockSafekeeping = debounce((fetchDocs: Boolean) => {
		setStockSafekeepingResponse(undefined);

		client
			.getStockSafekeeping({ token: user.token, sSkId: id, role: Role.CONSTRUCTOR })
			.then((data) => {
				setStockSafekeepingResponse({ data });
				getAgreementConditionsForSsk(data?.sSks.id);
				fetchSellerDocuments(data.sSks.seller);

				if (fetchDocs) fetchListDocuments(data?.sSks?.order_id, id);

				checkStatus(data?.sSks);

				if (!data?.sSks?.valid_invoice_profile) {
					client.getBusinessName({ token: user.token, id: data.sSks.company_id }).then((response) => {
						if (response.data && response.data.length > 0) {
							setBusinessName(response.data[0]);
						}
					});
				}
			})
			.catch(() => setStockSafekeepingResponse({ error: true }));
	}, 250);

	const getAgreementConditionsForSsk = async (sskid: string) => {
		try {
			const response = await client.getAgreementConditionForSsk({
				token: user.token,
				sSkId: sskid,
			});
			setAgreementConditions(response.conditions);
			setIsLoadingAgreementConditions(false);
		} catch (error) {}
	};

	const fetchListDocuments = debounce((orderId, sSkId) => {
		setFilesResponse(undefined);

		client
			.getTokenDocuments({ orderId, sSkId, user })
			.then((data) => {
				setFilesResponse({ data });
				const filteredDocs = data?.map((file: Document) => file);
				setAllDocuments(filteredDocs ?? []);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	const fetchSellerDocuments = debounce((sellerId: string) => {
		client.
			getSellerWithDocuments({ id: sellerId, user })
			.then((data) => {
				setSellerInfo(data);
			}
			);
	}, 250);

	useEffect(() => {
		fetchStockSafekeeping(true);
	}, []);

	useEffect(() => {
			setDisableRootElementScroll(taxProfileForm || isSellerInfoOpen || fileUploadDialogOpen);
	}, [taxProfileForm, isSellerInfoOpen, fileUploadDialogOpen]);

	const checkStatus = (sSk: any) => {
		setCounts([
			{ label: TokenDetailStatus.REQUEST_SENT, active: false, completed: sSk?.Estado_orden > 0 },
			{ label: TokenDetailStatus.INVOICE_AWAIT, active: sSk?.Estado_orden === 1, completed: sSk?.Estado_orden > 1 },
			{ label: TokenDetailStatus.PAYMENT_SENT, active: sSk?.Estado_orden === 2, completed: sSk?.Estado_orden > 2 },
			{ label: TokenDetailStatus.FINISHED, active: sSk?.Estado_orden === 3, completed: sSk?.Estado_orden > 3 },
		]);
	};

	useEffect(() => {
		if (stockSafekeepingResponse?.data) {
			const { sSks } = stockSafekeepingResponse?.data as any;
			
			const delivery = getDelivery(agreementConditions);
			const sskDetails = [
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.date' }),
					value: intl.formatDate(sSks?.created_at, { timeZone: 'UTC' }),
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.amount' }),
					value: `${formatNumberLocale(sSks?.quantity ?? 0)} tokens`,
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.total_cost' }),
					value: intl.formatNumber(sSks?.Monto_final, {
						style: 'currency',
						currency: 'ARS',
						currencyDisplay: 'narrowSymbol',
					}),
					detail: agreementConditions?.length ?
						getPriceBonus(agreementConditions) ? (
							<InfoMarker sx={{ '&MuiPaper-root': { justifyContent: 'start'}}} colorScheme="success" icon="gift" message={getPriceBonus(agreementConditions)}></InfoMarker>
						) : undefined : '-',
				},
				{
					key: delivery.condition_type_id === AgreementConditionTypes.SHIPPING
						? intl.formatMessage({ id: 'mytokens.acquisition_details.shipping_zone' })
						: delivery.agreement_condition_type_label,
					value: delivery.agreement_condition_zone_label
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.financing' }),
					value: agreementConditions?.length ? getFinancingCondition(agreementConditions) : '-',
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.business_name' }),
					value: `${sSks?.business_social} | ${sSks?.the_cuit}`,
					showInvalidInvoiceProfile: !sSks?.valid_invoice_profile,
				},
			];

			setStockSafekeepingDetails(sskDetails.filter((item: any) => item.value !== undefined)); 
		}
	}, [stockSafekeepingResponse, agreementConditions]);

	if (!stockSafekeepingResponse) {
		return <FullScreenLoading />;
	}

	const { data, error } = stockSafekeepingResponse as any;
	const sSks = data?.sSks;

	if (error || !sSks) {
		return <Error />;
	}

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = allDocuments;

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setAllDocuments(allNewDocs);
		} else {
			setAllDocuments([...allDocuments, newFile]);
		}
		if (newFile.file_type_id === 9 && sSks?.Estado_orden === 2) {
			setCounts([
				{ label: TokenDetailStatus.REQUEST_SENT, active: false, completed: true },
				{ label: TokenDetailStatus.INVOICE_AWAIT, active: false, completed: true },
				{ label: TokenDetailStatus.PAYMENT_SENT, active: false, completed: true },
				{ label: TokenDetailStatus.FINISHED, active: true, completed: false },
			]);
		}
	};

	return (
		<>
			<Container
				sx={{
					py: { xs: '100px', md: '130px', xl: '100px' },
				}}
				maxWidth="lg"
			>
				<FileUploadDialog
					tokenization={true}
					dataTokenization={sSks}
					open={fileUploadDialogOpen}
					types={allowedFileTypes.filter((fileType) => {
						if ([13, 14, 15].includes(fileType.id)) {
							return (
								allDocuments?.filter(
									(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
								).length > 0
							);
						}

						return true;
					})}
					documents={
						allDocuments?.filter(
							(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
						) || []
					}
					// dataSeller={data}
					user={user}
					onClose={(event, reason, newFile) => {
						setFileUploadDialogOpen(false);

						if (newFile) forceInsertNewFile(newFile);

						if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile)
							fetchListDocuments(sSks?.order_id, id);
					}}
				/>

				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
					<StatusCard
						title={`Solicitud de compra ${id}`}
						titleLocation="center"
						statuses={counts || []}
						actions={[]}
						backUrl={`/my-tokens?agreement=${sSks.agreement_id}`}
					/>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<Paper sx={{ padding: '20px', borderRadius: '30px' }}>
								<Grid container>
									<Grid mb={'20px'} mt={'10px'} item xs={12}>
										<Typography variant={'h5'} fontWeight={500}>
											{intl.formatMessage({ id: 'mytokens.redeem_request_details' })}
										</Typography>
									</Grid>
								</Grid>

								<Box
									sx={{
										display: 'flex',
										flexDirection: { xs: 'column', md: 'row' },
										minHeight: { xs: '400px' },
									}}
								>
									{!isLoadingAgreementConditions  
										? (
											<Stack justifyContent={'space-between'} width={'100%'} pt={'15px'}>
												{stockSafekeepingDetails.map((item: any) => (
													<Box key={item.key}>
														<Grid container spacing={1}>
															<Grid display={'flex'} flexDirection={'column'} item xs={12} md={12} lg={6}>
																<Typography variant="subtitle1" fontWeight={600}>
																	{item.key}
																</Typography>
																<Typography color="#7E7E82">{item.value}</Typography>
															</Grid>
															<Grid item xs={8} md={12} lg={6}>
																{item.detail && item.detail}
																{item.showInvalidInvoiceProfile && (
																	<NuqleaAlert
																		action={() => {
																			setTaxProfileForm(true);
																		}}
																		actionTitle={intl.formatMessage({ id: 'common.complete_action' })}
																	>
																		{intl.formatMessage({ id: 'mytokens.acquisition_details.invalid_invoice_profile' })}
																	</NuqleaAlert>
																)}
															</Grid>
														</Grid>
													</Box>
												))}
											</Stack>
										)
										: (
											<Box
												sx={{
													width: '100%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'start',
													paddingTop: '12%'
												}}
											>
												<Typography color="#7E7E82">{intl.formatMessage({ id: 'common.loading' })}</Typography>
											</Box>
										)
									}
								</Box>
							</Paper>
						</Grid>
						<Grid item xs={12} md={6}>
							<TableCardTreeView
								loading={!filesResponse}
								title={intl.formatMessage({ id: 'list.seller.details.related_files.title' })}
								subtitle={
									<Typography onClick={() => setIsSellerInfoOpen(true)} sx={{ mt: -.25, cursor: 'pointer', color: '#2f5dff', fontSize: '.88em' }}>
										{intl.formatMessage({ id: 'list.seller.details.related_files.payment_info' })}
									</Typography>
								}
								layoutColumns={6}
								columns={relatedFiles}
								relatedColumns={relatedFiles}
								rows={allDocuments?.map((document) => ({ ...document, user, list_id: '2bfc5999' }))}
								actions={
									sSks?.Estado_orden === 1
										? []
										: [
												{
													displayName: intl.formatMessage({ id: 'list.seller.details.upload_file.action' }),
													icon: <Upload />,
													type: 'Button',
													onClick: () => setFileUploadDialogOpen(true),
												},
										  ]
								}
							></TableCardTreeView>
						</Grid>
					</Grid>
				</Box>
			</Container>

			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={taxProfileForm}
				onClose={() => setTaxProfileForm(false)}
			>
				<TaxProfileForm
					callback={() => {setTaxProfileForm(false); fetchStockSafekeeping(false);}}
					businessNameToEdit={businessName}
					organizationId={user.id_external}
					token={user.token}
					creator={user}
					isModal={true}
				></TaxProfileForm>
			</SideModalContainer>

			{sellerInfo && 
				<SellerPaymentInfo
					open={isSellerInfoOpen}
					sellerData={{ name: sellerInfo.seller.seller, taxId: sellerInfo.seller.cuit, image: sellerInfo.seller.logo }}
					files={sellerInfo.documents?.filter((item: any) => item.visibility === 'all' || item.visibility === 'token').reduce((acc: any, file: any) => {
						const key = file.type;
						const curGroup = acc[key] ?? [];

						return { ...acc, [key]: [...curGroup, file] };
					}, {})}
					user={user}
					onClose={() => setIsSellerInfoOpen(false)}
				/>
			}
		</>
	);
};

export default TokenDetailViewSeller;
