import { createContext, useContext } from 'react';
import { Delivery } from '../types';

export type DeliveryContextType = {
  baseInfo?: any;
  deliveriesCount?: number;
  message?: any;
  setMessage:(message: any) => void;
  setDeliveryModal?: (deliveryModal: { data: Delivery, modalType: 'new' | 'edit' | 'view' } | null) => void;
}

export const DeliveryContext = createContext<DeliveryContextType>({
    baseInfo: {},
    deliveriesCount: 0,
    message: {},
    setMessage: (message:any) => {},
    setDeliveryModal: (deliveryModal: { data: Delivery, modalType: 'new' | 'edit' | 'view' } | null) => {},
});

export const useDeliveryContext = () => useContext(DeliveryContext)