import { DataRow } from '../table-card';
import { defaultFieldSort, defaultSort } from '../../utils/data-utils';
import { IntlShape } from 'react-intl';
import { Typography, Box, styled } from '@mui/material';
import { DeliveryStatus } from '../../constants';
import { Role, User } from '../../types';
import SetDeliveredButton from '../details/deliveries/set-delivered-button'
import SetTakeAwayButton from '../details/deliveries/set-takeaway-button'
import SetExpresoButton from '../details/deliveries/set-expreso-button'
import LoadRemitoButton from '../details/deliveries/load-remito-button'
import FileViewer from '../file-viewer';
import EditDeliveryButton from '../details/deliveries/edit-delivery-button';
import filesClient from '../../clients/filesClient';

const DeliveredStatus = styled(Typography)`
	color: #49C000;
	font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.43;
`;

const PendingDeliveryStatus = styled(Typography)`
	color: #556AC3;
	font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.43;
`;

const OnTheWayDeliveryStatus = styled(Typography)`
	color: #0E2160;
	font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.43;
`;

const getDeliveryStatusCell = (row: DataRow, intl: IntlShape, user: User) => {
	if (row.status === 1) {
		return <PendingDeliveryStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][row.status] }) }</PendingDeliveryStatus>;
	} else if(row.status === 2) {
		return <DeliveredStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][row.status] }) }</DeliveredStatus>;
	}

	return <OnTheWayDeliveryStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][row.status] }) }</OnTheWayDeliveryStatus>;
};

const getDeliveryStatus = (row: DataRow) => (row.delivery_date ? 'Entregado' : 'Pendiente de entrega');

export const relatedFiles = [
	{
		displayName: 'ID',
		headerStyle: { width: 'auto' },
		supportsMobile: true,
		render: (row: DataRow) => (row.id_doc ? row.id_doc : '-'),
	},
	{
		displayName: 'Tipo',
		supportsMobile: true,
		sort: defaultFieldSort('label_file_type'),
		render: (row: DataRow) => row.label_file_type,
	},

	{
		displayName: 'Fecha',
		sort: defaultFieldSort('timestamp'),
		render: (row: DataRow, intl: IntlShape) => row.timestamp && intl.formatDate(row.timestamp, { timeZone: 'UTC' }),
	},
	{
		displayName: 'Adjunto por',
		sort: (a: DataRow, b: DataRow) => defaultSort(a.created_by_name ?? '-', b.created_by_name ?? '-'),
		render: (row: DataRow) => row.created_by_name ?? '-',
	},
	{
		headerStyle: { width: '90px' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape, user?: User, listId?: string) =>
			row.drive_file_id || row.gcs_file_path ? (
				<Box sx={{ display:'flex', justifyContent: 'center', textAlign: 'center'}}>
					<FileViewer 
						driveFileId={row.drive_file_id}
						fileId={row.label_file_type}
						listId={row.list_id ?? listId}
						user={row.user ?? user}
						buttonType="icon"
						gcsFilePath={row.gcs_file_path}
						filePath={row.file_path}
						directDownload={row.direct_download}
						downloadFileMetadataHandler={row.direct_download ? filesClient.downloadMetadataFile : undefined}
						downloadFileBinaryHandler={row.direct_download ? filesClient.downloadBinaryFile : undefined}
					/>
					{/* <FileDownloadButton
						driveFileId={row.drive_file_id}
						fileId={row.label_file_type}
						listId={row.list_id ?? listId}
						user={row.user ?? user}
					/> */}
				</Box>
			) : (
				intl.formatMessage({ id: 'common.not_available' })
			),
	},
];

export const relatedDeliveries = [
	{
		displayName: 'Tipo de entrega',
		supportsMobile: true,
		sort: defaultFieldSort('type_label'),
		render: (row: DataRow, intl: IntlShape, user?: User) => <Box sx={{ py: row.type_label === 'Sin definir' ? 1 : 'inherit' }}>{row.type_label ?? '-'}</Box>,
	},
	{
		displayName: 'Estado',
		supportsMobile: true,
		sort: (a: DataRow, b: DataRow) => defaultSort(getDeliveryStatus(a), getDeliveryStatus(b)),
		render: (row: DataRow, intl: IntlShape, user?: User) => getDeliveryStatusCell(row, intl, row.user ?? user),
	},
	{
		displayName: 'Entrega pactada',
		sort: defaultFieldSort('estimated_delivery_date'),
		render: (row: DataRow, intl: IntlShape) =>
			row.estimated_delivery_date && intl.formatDate(row.estimated_delivery_date, { timeZone: 'UTC' }),
	},
	{
		displayName: 'Entrega realizada',
		sort: defaultFieldSort('delivery_date'),
		render: (row: DataRow, intl: IntlShape) => row.delivery_date && intl.formatDate(row.delivery_date, { timeZone: 'UTC' }),
	},
	{
		displayName: 'Completitud',
		sort: defaultFieldSort('completeness'),
		render: (row: DataRow, intl: IntlShape) => row.completeness && <Box sx={{ textTransform: 'capitalize' }}>{row.completeness}</Box>
	},
	{
		displayName: 'Comentario',
		sort: defaultFieldSort('comment'),
		render: (row: DataRow) => row.comment,
	},
	{
		displayName: 'Acción',
		headerStyle: { textAlign: 'center' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape, user?: User,  listId?: string) => {
			return (<Box sx={{ display: 'flex', justifyContent: 'center'}}>
				{	
					row.user.type_external === Role.SELLER && row.delivery_action_type === 'delivery' &&
					<SetDeliveredButton
						label={intl.formatMessage({ id: 'list.seller.details.deliveries.action_button_label.set_delivered' })}
						delivery={row}
						user={row.user ?? user}
					/>
				}
				{
					row.user.type_external === Role.SELLER && row.delivery_action_type === 'pickup' &&
					<SetTakeAwayButton
						label={intl.formatMessage({ id: 'list.seller.details.deliveries.action_button_label.set_retired' })}
						delivery={row}
						user={row.user ?? user}
					/>
				}
				{
					row.user.type_external === Role.SELLER && row.delivery_action_type === 'express' &&
					<SetExpresoButton
						label={intl.formatMessage({ id: 'list.seller.details.deliveries.action_button_label.set_expreso' })}
						delivery={row}
						user={row.user ?? user}
					/>
				}
				{	
					row.user.type_external === Role.SELLER && row.delivery_action_type === 'invoice' &&
					<LoadRemitoButton
						label={intl.formatMessage({ id: 'list.seller.details.deliveries.action_button_label.load_remito' })}
						delivery={row}
						user={row.user ?? user}
					/>
				}
			</Box>)
		}
	},
	{
		displayName: '',
		headerStyle: { width: '50px' },
		supportsMobile: true,
		render: (row: DataRow) => {
			return (
				row.status === 1 ? <Box sx={{ display: 'flex', justifyContent: 'center'}}>
					<EditDeliveryButton
						delivery={row}
						user={row.user}
					/>
				</Box> : <Box sx={{ display: 'flex', justifyContent: 'center'}}></Box>
			)
		},
		editDeliveryColumn: true
	},
];

export const relatedDeliveryFiles = [
	{
		displayName: 'ID',
		headerStyle: { width: 'auto' },
		supportsMobile: true,
		render: (row: DataRow) => (row.document_id ? 'Remito #' + row.document_id : 'Remito'),
	},
	{
		headerStyle: { width: '90px' },
		supportsMobile: true,
		render: (row: DataRow, intl: IntlShape, user?: User, listId?: string) =>
		(row.document_file_id || row.document_gcs_path) ? (
			<Box sx={{ display:'flex', justifyContent: 'center', textAlign: 'center'}}>
				<FileViewer 
					driveFileId={row.document_file_id}
					fileId={'Remito'}
					listId={row.list_id ?? listId}
					user={row.user ?? user}
					buttonType="icon"
					gcsFilePath={row.document_gcs_path}
				/>
				{/* <FileDownloadButton
					driveFileId={row.document_file_id}
					fileId={'Remito'}
					listId={row.list_id ?? listId}
					user={row.user ?? user}
				/> */}
			</Box>
		) : (
			intl.formatMessage({ id: 'common.not_available' })
		)
	},
];
