import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { InputLabel, MenuItem, Typography, IconButton, styled, Alert, Autocomplete, Chip, Checkbox, FormControlLabel } from '@mui/material';
import DatePicker from '../../date-picker';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DeliveryCompleteness, DeliveryType, SellerListDetails, User } from '../../../types';
import FullScreenLoading from '../../full-screen-loading';
import client from '../../../clients/client';
import { useDeliveryContext } from '../../../contexts/deliveryContext';

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: 'transparent',
	borderRadius: '10px',
	color: '#0288d1',
}));

const icon = <CheckBoxOutlineBlankIcon color="inherit" />;
const checkedIcon = <CheckBoxIcon sx={{ color: '#000' }} />;

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

interface Props {
	open: boolean;
	orderId?: string;
	user?: User;
	onClose: (event?: object | null, reason?: string, newFile?: any) => void;
}

const SellerNewDeliveryDialog = (props: Props) => {
	const intl = useIntl();
	const { open, orderId, user, onClose } = props;

	const [date, setDate] = useState<Date>();
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>('');
	const [noDate, setNoDate] = useState<boolean>(false);
	const [deliveryOption, setDeliveryOption] = useState<string>();
	const [comments, setComments] = useState<string>()
	const [expresoName, setExpresoName] = useState<string>()

	const deliveryTypeOptions = [
		{ id: 6, label: 'Retiro en comercio', value: 'pickup' },
		{ id: 1, label: 'Envío a la dirección pactada', value: 'delivery' },
		{ id: 3, label: 'Envío por expreso', value: 'express' },
		{ id: 7, label: 'Sin definir', value: 'unknown' },
	];

	const handleSubmit = () => {
		setLoading(true);
		setError('');
		
		date?.setHours(24)
		client.createDelivery({
			fields: {
				order_id: orderId,
				delivery_type: deliveryOption,
				proposed_delivery_date: noDate ? null : date?.toISOString().split('T')[0],
				express_name: expresoName ?? null,
				comment: comments
			},
			user
		}).then((response) => {
			onClose(null, 'success')
			
			// Reset form
			setDate(undefined)
			setComments(undefined)
			setExpresoName(undefined)
			setDeliveryOption(undefined)
			setNoDate(false)
		})
		.catch((error) => setError(error.message))
		.finally(() => setLoading(false));
	};

	const getTitle = (type: string) => {
		return 'Cargar entrega'
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
			sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3, borderRadius: 4 } }}
			onClick={(e) => {
				if (e.target instanceof HTMLElement) {
					if(!['checkbox', 'checkbox-label', 'datepicker'].includes(e.target.id)) {
						e.preventDefault()
					}
				}
				e.stopPropagation()
			}}
		>
			{loading && <FullScreenLoading />}
			<DialogTitle>	
				<Typography variant="h5">{ getTitle('') }</Typography>
			</DialogTitle>
			<DialogContent>
				
				<SeparatedLabel sx={{ fontWeight: 900 }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.new_delivery_dialog.delivery_type_field' })}
				</SeparatedLabel>
				<TextField
					select
					fullWidth
					required
					size="small"
					value={deliveryOption}
					onChange={(event) => setDeliveryOption(event.target.value)}
				>
					{deliveryTypeOptions.map((dop) => (
						<MenuItem key={dop.id} value={dop.value}>
							{/* {intl.formatMessage({ id: dop.label })} */}
							{dop.label}
						</MenuItem>
					))}
				</TextField>

				{
					deliveryOption === 'express' && <>
						<SeparatedLabel sx={{ fontWeight: 900 }}>
							{intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.expreso.expreso_name_field' })}
						</SeparatedLabel>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={expresoName}
							onChange={(event) => setExpresoName(event.target.value)}
						/>
					</>
				}

				<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.new_delivery_dialog.delivery_date_field' })}
				</SeparatedLabel>
				<StyledAlert severity="info" sx={{ mt:-2, ml:-1.25, mb:-1, p:0, '& .MuiAlert-icon': { fontSize: '1.4em', marginRight: '5px' } }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.new_delivery_dialog.delivery_date_field_hint' })}
				</StyledAlert>
				<DatePicker
					value={date}
					onChange={(value) => setDate(value)}
					variant="form"
					disabled={noDate}
				/>
				<FormControlLabel control={
						<Checkbox
							id="checkbox"
							icon={icon}
							checkedIcon={checkedIcon}
							checked={noDate}
							size="small"
						/>
					}
					onChange={() => setNoDate(!noDate)}
					label={<div id="checkbox-label">{intl.formatMessage({ id: 'list.seller.details.deliveries.new_delivery_dialog.delivery_date_field_no_date' })}</div>}
				/>

				<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
					{intl.formatMessage({ id: 'list.seller.details.deliveries.new_delivery_dialog.delivery_comments_field' })}
				</SeparatedLabel>
				<TextField
					fullWidth
					multiline
					size="small"
					rows={3}
					placeholder={intl.formatMessage({
						id: 'list.seller.details.deliveries.new_delivery_dialog.delivery_comments_field_placeholder',
					})}
					value={comments}
					onChange={(event) => setComments(event.target.value)}
				/>

				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error ?? 'Ocurrió un error.'}
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
				<Button
					onClick={() => onClose(null, 'cancelButtonClick')}
					variant="contained"
					sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					disabled={
						!deliveryOption ||
						(!date && !noDate) ||
						(deliveryOption === 'express' && !expresoName)
					}
					sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.confirm' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SellerNewDeliveryDialog;
