import { Avatar, Box, Button, Typography } from '@mui/material';
import { DataColumn, DataRow } from '../../table-card';
import { Circle } from '@mui/icons-material';
import { Role } from '../../../types';
import { StateColors } from './statuses';
import { IntlShape } from 'react-intl';
import { formatNumberLocale } from '../../../utils/data-utils';

const statusRender = (content: string, userType: Role = Role.CONSTRUCTOR) => {
	const foundStatus = StateColors.find((state) => state.id.includes(content));

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Circle
				sx={{
					marginRight: '1rem',
					height: 10,
					width: 10,
					color: foundStatus?.color || '#000',
				}}
			/>
			<Typography sx={{ color: 'black' }}>
				{(typeof foundStatus?.label === 'object' ? foundStatus?.label[userType]?.label : foundStatus?.label) || content}
			</Typography>
		</Box>
	);
};

const basicRenderWrapper = (content: any, weight?: number) => (
	<Typography variant="body1" sx={{ fontWeight: weight ?? 400, py: '15px', color: 'black' }}>
		{typeof content === 'number' ? formatNumberLocale(content) : content}
	</Typography>
);

const currencyDisplay = (intl: IntlShape, content: any, weight?: number) => (
	<Typography variant="body1" sx={{ color: 'black' }}>
		{intl.formatNumber(content, {
			style: 'currency',
			currency: 'ARS',
			currencyDisplay: 'narrowSymbol',
		})}
	</Typography>
);

const numberDisplay = (intl: IntlShape, content: any, weight?: number) => (
	<Typography variant="body1" sx={{ color: 'black', fontWeight: weight ?? 400, }}>
		{intl.formatNumber(content, {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 3
		})}
	</Typography>
);

const dateDisplay = (intl: IntlShape, content: any) => (
	<Typography variant="body1" sx={{ color: 'black' }}>
		{intl.formatDate(content.split(' ')[0], { timeZone: 'UTC' })}
	</Typography>
);

const sortByDateDDMMYYYY = (a: DataRow, b: DataRow, fieldNameToSort: string) => {
	const formatDate= (row: DataRow) => {
		const [day, month, year] = row[fieldNameToSort]?.split('/') || [];
		return `${year}-${month}-${day}`;
	};
	return new Date(formatDate(b)).getTime() - new Date(formatDate(a)).getTime();
}

export const salesProcessSeller = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.request_date' }),
		render: (row: DataRow) => basicRenderWrapper(row.createdAt),
		sort: (a: DataRow, b: DataRow) => sortByDateDDMMYYYY(a, b, 'createdAt'),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.customer' }),
		render: (row: DataRow) => basicRenderWrapper(row.nameConstructora),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.quantity, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.amount' }),
		render: (row: DataRow, intl: IntlShape) => currencyDisplay(intl, row.total_amount),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.status' }),
		render: (row: DataRow) => statusRender(row.orderStatus, Role.SELLER),
		supportsMobile: true,
	},
];

export const exchangeProcessSeller = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.request_date' }),
		render: (row: DataRow, intl: IntlShape) => dateDisplay(intl, row.createdAt),
		sort: (a: DataRow, b: DataRow) => sortByDateDDMMYYYY(a, b, 'createdAt'),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.customer' }),
		render: (row: DataRow) => basicRenderWrapper(row.nameConstructora),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.exchange_type' }),
		render: (row: DataRow) => basicRenderWrapper(row.formattedType),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.stock, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.exchange_status' }),
		render: (row: DataRow) => statusRender(`redeem_request|${row.status}|${row.type}${!!row.shipping_price ? '' : '|no'}`, Role.SELLER),
		supportsMobile: true,
	},
];

export const activeTokensSeller = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.customer' }),
		render: (row: DataRow) => basicRenderWrapper(row.nameConstructora),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.active_tokens' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.activeTokens, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.in_process' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.blocked, 600),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.next_expiration' }),
		render: (row: DataRow) => basicRenderWrapper(row.smallestValidity),
	},
];

export const exchangedTokensSeller = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.request_date' }),
		render: (row: DataRow, intl: IntlShape) => dateDisplay(intl, row.createdAt),
		supportsMobile: true,
		sort: (a: DataRow, b: DataRow) => sortByDateDDMMYYYY(a, b, 'createdAt'),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.customer' }),
		render: (row: DataRow) => basicRenderWrapper(row.nameConstructora),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.stock, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.seller.dashboard.exchange_type' }),
		render: (row: DataRow) => basicRenderWrapper(row.formattedType),
	},
];

export const purchaseProcessConstructor = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.request_date' }),
		render: (row: DataRow) => basicRenderWrapper(row.createdAt),
		supportsMobile: true,
		sort: (a: DataRow, b: DataRow) => sortByDateDDMMYYYY(a, b, 'createdAt'),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.total_value' }),
		render: (row: DataRow, intl: IntlShape) => currencyDisplay(intl, row.totalValue),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.quantity, 600),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.expired_date' }),
		render: (row: DataRow) => basicRenderWrapper(row.validity),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.status_purchase' }),
		render: (row: DataRow) => statusRender(row.orderStatus, Role.CONSTRUCTOR),
		supportsMobile: true,
	},
];

export const exchangeProcessConstructor = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.request_date' }),
		render: (row: DataRow) => dateDisplay(intl, row.createdAt),
		supportsMobile: true,
		sort: (a: DataRow, b: DataRow) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.exchange_type' }),
		render: (row: DataRow) => basicRenderWrapper(row.formattedType),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.stock, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.shipping_request' }),
		render: (row: DataRow, intl: IntlShape) => (row.date
			? dateDisplay(intl, row.date)
			:  intl.formatMessage({ id: 'mytokens.constructor.dashboard.according_to_schedule' })
		),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.status_delivery' }),
		render: (row: DataRow) => statusRender(`redeem_request|${row.status}|${row.type}${!!row.shipping_price ? '' : '|no'}`, Role.CONSTRUCTOR),
		supportsMobile: true,
	},
];

export const purchasedTokensConstructor = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.purchase_date' }),
		render: (row: DataRow) => basicRenderWrapper(row.createdAt),
		supportsMobile: true,
		sort: (a: DataRow, b: DataRow) => sortByDateDDMMYYYY(a, b, 'createdAt')
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.total_value' }),
		render: (row: DataRow, intl: IntlShape) => currencyDisplay(intl, row.total_amount),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.quantity, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.expired_date' }),
		render: (row: DataRow) => basicRenderWrapper(row.validity),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.remainder_to_exchange' }),
		render: (row: DataRow) => basicRenderWrapper(row.netAmount),
	},
];

export const exchangedTokensConstructor = (intl: IntlShape): DataColumn[] => [
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.exchange_type' }),
		render: (row: DataRow) => basicRenderWrapper(row.formattedType),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.tokens_quantity' }),
		render: (row: DataRow, intl: IntlShape) => numberDisplay(intl, row.stock, 600),
		supportsMobile: true,
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.shipping_quantity' }),
		render: (row: DataRow) => basicRenderWrapper(row.shippings),
	},
	{
		displayName: intl.formatMessage({ id: 'mytokens.constructor.dashboard.last_shipping' }),
		render: (row: DataRow) => basicRenderWrapper(row.date),
		supportsMobile: true,
	},
];

export const historyDialogColumns = (actionButton?: (row: DataRow) => void): DataColumn[] => [
	{
		displayName: 'Fecha',
		render: (row: DataRow) => basicRenderWrapper(row.createdAt),
		supportsMobile: true,
	},
	{
		displayName: 'Tokens generados',
		render: (row: DataRow) => (
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			  {basicRenderWrapper(row.stock)}
			</Box>
		  ),
		supportsMobile: true,
	},
	{
		displayName: 'Precio unitario',
		render: (row: DataRow, intl: IntlShape) => currencyDisplay(intl, row.pricing),
		supportsMobile: true,
	},
	{
		displayName: 'Validez',
		render: (row: DataRow) => row.validity,
		supportsMobile: true,
	},
	{
		displayName: 'Estado',
		render: (row: DataRow) => statusRender(row.status, Role.CONSTRUCTOR),
		supportsMobile: true,
	},
	{
		render: (row: DataRow) => (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{!!row.statusAction && actionButton && (
					<Button variant="text" sx={{ color: 'blue', fontSize: '14px' }} onClick={() => actionButton(row)}>
						{row.statusAction}
					</Button>
				)}
			</Box>
		),
		supportsMobile: true,
	},
];
