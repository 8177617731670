import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { Alert, MenuItem, Typography, styled } from '@mui/material';
import { CancelationReason, User } from '../../../types';
import client from '../../../clients/client';
import FullScreenLoading from '../../full-screen-loading';

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	backgroundColor: theme.palette.background.default,
	padding: '10px',
	borderRadius: '10px',
	color: theme.palette.text.secondary,
}));

const SeparatedTextField = styled(TextField)``;

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

interface Props {
	open: boolean;
	reasons: CancelationReason[];
	quotationId?: string;
	listId: string;
	environment?: string;
	user: User;
	onClose: (event?: object | null, reason?: string) => void;
}

enum Step {
	INTRODUCTION,
	FORM,
}

const SellerRejectionDialog = (props: Props) => {
	const intl = useIntl();
	const { open, reasons, quotationId, listId, environment, user, onClose } = props;

	const [step, setStep] = useState(Step.INTRODUCTION);
	const [reason, setReason] = useState<number>(-1);
	const [comments, setComments] = useState<string>();
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();

	const handleSubmit = () => {
		setLoading(true);
		setError(undefined);

		client
			.rejectQuotation({ listId, quotationId, environment, reason, comments, user })
			.then((response) => onClose(null, 'success'))
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" sx={{
			'& .MuiPaper-root': { maxWidth: '500px', p:3, borderRadius: 4 }
		}}>
			{loading && <FullScreenLoading />}
			<DialogTitle>
				<Typography variant="h5" textAlign="center">
					{intl.formatMessage({ id: 'list.seller.details.reject_quotation_dialog.title' })}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{step === Step.INTRODUCTION && (
					<StyledAlert severity="info">
						{intl.formatMessage({ id: 'list.seller.details.reject_quotation_dialog.details' })}
					</StyledAlert>
				)}
				{step === Step.FORM && (
					<>
						{error && (
							<Alert severity="error">{intl.formatMessage({ id: 'common.loading_error_message.details' })}</Alert>
						)}
						<SeparatedLabel sx={{ fontWeight: 900 }}>{intl.formatMessage({ id: 'list.seller.details.reject_quotation_dialog.reason_field_name' })}</SeparatedLabel>
						<SeparatedTextField
							select
							fullWidth
							size="small"
							value={reason}
							onChange={(event) => setReason(parseInt(event.target.value))}
						>
							<MenuItem disabled value={-1}>
								<Typography variant="inherit" sx={{ opacity: .4 }}>
									{intl.formatMessage({ id: 'list.seller.details.reject_quotation_dialog.reason_field_placeholder' })}
								</Typography>
							</MenuItem>
							{reasons.map((reason) => (
								<MenuItem key={reason.cancellation_id} value={reason.cancellation_id}>
									{reason.cancellation_type}
								</MenuItem>
							))}
						</SeparatedTextField>

						<SeparatedLabel sx={{ fontWeight: 900 }}>{intl.formatMessage({ id: 'list.seller.details.reject_quotation_dialog.comments_field_name' })}{reason === 4 ? '*' : ''}</SeparatedLabel>
						<SeparatedTextField
							fullWidth
							multiline
							rows={4}
							placeholder={intl.formatMessage({
								id: 'list.seller.details.reject_quotation_dialog.comments_field_placeholder',
							})}
							value={comments}
							onChange={(event) => setComments(event.target.value)}
							required={reason === 4}
						/>
					</>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent:'center', gap:1, mb:1 }}>
				{step === Step.INTRODUCTION && (
					<>
						<Button onClick={() => onClose(null, 'cancelButtonClick')} variant="contained" sx={{ border: '1px solid #000', borderRadius:10, width: '120px' }}>
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<Button onClick={() => setStep(Step.FORM)} variant="contained" sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius:10, width: '120px' }}>
							{intl.formatMessage({ id: 'common.continue' })}
						</Button>
					</>
				)}
				{step === Step.FORM && (
					<>
						<Button onClick={() => setStep(Step.INTRODUCTION)} variant="contained" sx={{ border: '1px solid #000', borderRadius:10, width: '120px' }}>
							{intl.formatMessage({ id: 'common.back' })}
						</Button>
						<Button onClick={handleSubmit} variant="contained" disabled={!reason || reason < 0 || (reason === 4 && !comments)} sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius:10, width: '120px' }}>
							{intl.formatMessage({ id: 'common.confirm' })}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default SellerRejectionDialog;
