import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface ModalContainerProps extends BoxProps {
	children: ReactNode;
}

const ModalContainer: React.FC<ModalContainerProps> = ({ children, ...props }) => {
	return (
		<Box
			{...props}
			sx={{
				position: 'absolute',
				top: '50%',
				transform: 'translate(-50%, -50%)',
				left: '50%',
				overflow: 'auto',
				padding: '50px',
				...props.sx,
			}}
		>
			{children}
		</Box>
	);
};

export default ModalContainer;
