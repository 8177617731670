export const INITIALIZE_STATE_WITH_AGREEMENT_DATA = 'INITIALIZE_STATE_WITH_AGREEMENT_DATA';
export const RESET_ERROR_ON_FETCH = 'RESET_ERROR_ON_FETCH';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_EQUIVALENCE_PRODUCTS_MAP = 'SET_EQUIVALENCE_PRODUCTS_MAP';
export const SET_ERROR_FROM_FETCH_TRUE = 'SET_ERROR_FROM_FETCH_TRUE';
export const SET_ERROR_FROM_PURCHASE = 'SET_ERROR_FROM_PURCHASE';
export const SET_NO_TOKENS_AVAILABLE_TO_BUY = 'SET_NO_TOKENS_AVAILABLE_TO_BUY';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_SELECTED_AGREEMENT_ID = 'SET_SELECTED_AGREEMENT_ID';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_DELIVERY = 'SET_SELECTED_DELIVERY';
export const SET_SELECTED_FINANCING_METHOD = 'SET_SELECTED_FINANCING_METHOD';
export const SET_SELLER = 'SET_SELLER';
export const SET_STOCK_SAFEKEEPING_ID = 'SET_STOCK_SAFEKEEPING_ID';
export const TOGGLE_IS_LOADING_RESPONSE = 'TOGGLE_IS_LOADING_RESPONSE';
export const TOGGLE_IS_HELP_DIALOG_OPEN = 'TOGGLE_IS_HELP_DIALOG_OPEN';