import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import { GridBox, NextButton, SeparatedLabel, StyledTextField, TypographyTitle } from '../styles';
import { useIntl } from 'react-intl';
import InformationBar from '../../information-bar';
import { SignUpStep } from '../interfaces';
import { areErrorsPresent } from '../utils';
import { workAreaTypes } from '../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import AcceptTermsDialog from '../../details/dialogs/accept-terms-dialog';

const ConstructorCreationComponent = ({
	formData,
	formErrors,
	handleInputChange,
	loading,
	handleRestartFlow,
	handleChangeStep,
	hasCuit,
}: any) => {
	const intl = useIntl();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false);
	const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const togglePasswordVisibilityRepeat = () => {
		setShowPasswordRepeat(!showPasswordRepeat);
	};

	return (
		<>
			<TypographyTitle variant="h5">
				{intl.formatMessage({ id: 'auth.signup.constructor.user_creation' })}
			</TypographyTitle>
			{areErrorsPresent(SignUpStep.CONSTRUCTOR_CREATION, formErrors) && (
				<InformationBar
					sx={{ mb: '15px' }}
					icon="error"
					message={intl.formatMessage({ id: 'common.generic_error_fields' })}
					color="#FF3D00"
				></InformationBar>
			)}
			<GridBox>
				<Grid container spacing={16}>
					<Grid item xs={12} md={5}>
						<Box>
							<Typography color={'black'} fontWeight={700} fontSize={'16px'} marginBottom={1}>
								{intl.formatMessage({ id: 'auth.signup.user.title' })}
							</Typography>
							<Typography fontWeight={400} fontSize={'14px'} color={'#72727E'}>
								{intl.formatMessage({ id: 'common.required_fields' })}
							</Typography>
						</Box>
					</Grid>
					<Grid id="noPaddingGrid" item xs={12} md={7}>
						<SeparatedLabel sx={{ marginTop: 0 }}>{intl.formatMessage({ id: 'auth.signup.full_name' })}</SeparatedLabel>
						<StyledTextField
							name="fullName"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.full_name.placeholder',
							})}
							value={formData.fullName}
							onChange={handleInputChange}
							required
							error={!!formErrors.fullName}
							helperText={formErrors.fullName}
						/>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.phone' })}</SeparatedLabel>
						<StyledTextField
							name="phone"
							size="small"
							type="text"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.phone.placeholder',
							})}
							value={formData.phone}
							onChange={handleInputChange}
							required
							error={!!formErrors.phone}
							helperText={formErrors.phone}
						/>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.work_area' })}</SeparatedLabel>
						<StyledTextField
							select
							name="workArea"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({ id: 'auth.signup.work_area.placeholder' })}
							InputLabelProps={{
								style: { color: '#AAA' },
							}}
							value={formData.workArea}
							onChange={handleInputChange}
							required
							error={!!formErrors.workArea}
							helperText={formErrors.workArea}
						>
							{workAreaTypes.map((workArea) => (
								<MenuItem key={workArea.id} value={workArea.value}>
									{workArea.label}
								</MenuItem>
							))}
						</StyledTextField>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.mail' })}</SeparatedLabel>
						<StyledTextField
							name="mail"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.mail.placeholder',
							})}
							value={formData.mail}
							onChange={handleInputChange}
							required
							error={!!formErrors.mail}
							helperText={formErrors.mail}
						/>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.mail_confirmation.placeholder' })}</SeparatedLabel>
						<StyledTextField
							name="confirmedMail"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.mail_confirmation.placeholder',
							})}
							value={formData.confirmedMail}
							onChange={handleInputChange}
							onCut={(e) => e.preventDefault()}
							onCopy={(e) => e.preventDefault()}
							onPaste={(e) => e.preventDefault()}
							required
							error={!!formErrors.confirmedMail}
							helperText={formErrors.confirmedMail}
						/>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.password' })}</SeparatedLabel>
						<StyledTextField
							name="password"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.password.placeholder',
							})}
							value={formData.password}
							onChange={handleInputChange}
							required
							error={!!formErrors.password}
							helperText={formErrors.password}
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={togglePasswordVisibility} edge="end" aria-label="toggle password visibility">
											{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.password_repeat' })}</SeparatedLabel>
						<StyledTextField
							name="passwordRepeat"
							size="small"
							fullWidth
							placeholder={intl.formatMessage({
								id: 'auth.signup.password_repeat.placeholder',
							})}
							value={formData.passwordRepeat}
							onChange={handleInputChange}
							required
							error={!!formErrors.passwordRepeat}
							helperText={formErrors.passwordRepeat}
							type={showPasswordRepeat ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={togglePasswordVisibilityRepeat}
											edge="end"
											aria-label="toggle password visibility"
										>
											{showPasswordRepeat ? <VisibilityIcon /> : <VisibilityOffIcon />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{!hasCuit && (
							<Typography sx={{ marginTop: '20px' }}>
								{intl.formatMessage(
									{ id: 'auth.signup.terms_and_conditions' },
									{
										termsLink: (
											<Button
												sx={{
													padding: 0,
													textDecoration: 'underline',
													color: 'inherit',
													fontWeight: 400,
													textTransform: 'none',
													'&:hover': {
														backgroundColor: 'transparent',
													},
												}}
												onClick={() => setOpenTermsDialog(true)}
											>
												términos y condiciones
											</Button>
										),
									}
								)}
							</Typography>
						)}
					</Grid>
					<Grid id="noPaddingDownGrid" item xs={12}>
						<Box sx={{ borderTop: 'solid 1px #D9D9D9' }} />
						<Grid container spacing={16}>
							<Grid item xs={0} md={5}></Grid>
							<Grid id="noPaddingGrid" item xs={12} md={7}>
								<Box sx={{ paddingTop: { xs: '0px', md: '22px' } }}>
									<Button
										onClick={() => handleRestartFlow(SignUpStep.ACCOUNT_CHECK)}
										variant="outlined"
										sx={{
											width: { xs: '40%', md: '120px' },
											mr: '20px',
										}}
									>
										{intl.formatMessage({ id: 'common.back_to_login' })}
									</Button>
									<NextButton loading={loading} onClick={handleChangeStep} variant="contained">
										{intl.formatMessage({ id: 'common.continue' })}
									</NextButton>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</GridBox>
			<AcceptTermsDialog open={openTermsDialog} onClose={() => setOpenTermsDialog(false)} />
		</>
	);
};

export default ConstructorCreationComponent;
