import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import {
	Alert,
	FormControl,
	FormControlLabel,
	Box,
	Autocomplete,
	Checkbox,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Typography,
	styled,
} from '@mui/material';
import { Availability, Payment, User } from '../../../types';
import client from '../../../clients/client';
import FullScreenLoading from '../../full-screen-loading';
import FileUpload from 'react-material-file-upload';
import DatePicker from '../../date-picker';
import excelIcon from '../../../assets/excel-icon.svg';
import pdfIcon from '../../../assets/pdf-icon.svg';
import { humanFileSize } from '../../../utils/data-utils';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: 'transparent',
	borderRadius: '10px',
	color: '#0288d1',
}));

const FileUploadWrapper = styled('div')(({ theme }) => ({
	'& > *': {
		border: `2px dashed  ${theme.palette.divider} !important`,
		backgroundColor: theme.palette.background.default,
	},
	'& .MuiFormControl-root': {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
		'& .MuiTypography-root': {
			fontSize: '1em',
			paddingTop: 20,
			paddingBottom: 20,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0d6efd',
			fontSize: 30,
			marginBottom: 4,
			marginLeft: 30,
		},
		'& .MuiButtonBase-root': {
			position: 'absolute',
			background: 'transparent',
			color: 'transparent',
			width: '100%',
			height: '10vh',
			marginBottom: -8,
			marginLeft: 0,
		},
	},
}));

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

const FileLogo = styled('img')`
	height: 60px;
	display: inline-block;
`;

interface Props {
	open: boolean;
	environment?: string;
	user: User;
	quotationId?: string;
	listId?: string;
	onClose: (event?: object | null, reason?: string) => void;
}

enum Step {
	FORM,
	CONFIRMATION,
}

const availabilities = [
	{
		id: Availability.TOTAL,
		label: 'list.seller.details.quotation_dialog.availability.total',
	},
	{
		id: Availability.PARTIAL,
		label: 'list.seller.details.quotation_dialog.availability.partial',
	},
	{
		id: Availability.MANUFACTURNING_REQUIRED,
		label: 'list.seller.details.quotation_dialog.availability.manufacturing_required',
	},
	{
		id: Availability.OTHER,
		label: 'list.seller.details.quotation_dialog.availability.other',
	},
];

const payments = [
	{
		id: Payment.BEFORE_DELIVERY,
		label: 'list.seller.details.quotation_dialog.payment.before_delivery',
	},
	{
		id: Payment.IN_0_DAYS,
		label: 'list.seller.details.quotation_dialog.payment.in_0_days',
	},
	{
		id: Payment.IN_15_DAYS,
		label: 'list.seller.details.quotation_dialog.payment.in_15_days',
	},
	{
		id: Payment.IN_30_DAYS,
		label: 'list.seller.details.quotation_dialog.payment.in_30_days',
	},
	{
		id: Payment.IN_45_DAYS,
		label: 'list.seller.details.quotation_dialog.payment.in_45_days',
	},
	{
		id: Payment.IN_60_DAYS,
		label: 'list.seller.details.quotation_dialog.payment.in_60_days',
	},
	{
		id: Payment.IN_90_DAYS,
		label: 'list.seller.details.quotation_dialog.payment.in_90_days',
	},
	{
		id: Payment.OTHER,
		label: 'list.seller.details.quotation_dialog.payment.other',
	},
];

const SellerQuotationDialog = (props: Props) => {
	const intl = useIntl();
	const { open, environment, quotationId, listId, user, onClose } = props;

	const [files, setFiles] = useState<File[]>([]);
	const [date, setDate] = useState<Date>();
	const [includesShipping, setIncludesShipping] = useState<Boolean>(true);
	const [payment, setPayment] = useState<Payment[]>();
	const [availability, setAvailability] = useState<Availability>(Availability.TOTAL);
	const [comments, setComments] = useState<string>();

	const [step, setStep] = useState(Step.FORM);
	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();

	const handleSubmit = () => {
		setLoading(true);
		setError(undefined);

		const file = files[0];

		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve((reader.result as string).split(',').pop());
			reader.onerror = (error) => reject(error);
		})
			.then((data) => {
				date?.setHours(24);
				return client.sendQuotation({
					form: { date, availability, payment, comments, includesShipping },
					file: { base64: data, name: file.name, type: file.type, size: file.size },
					environment,
					user,
					quotationId,
					listId,
				});
			})
			.then((response) => onClose(null, 'success'))
			.catch((error) => {
				if (error.response?.status === 413) {
					setError(intl.formatMessage({ id: 'common.payloadTooLarge' }));
				} else {
					setError(error.message);
				}
				setStep(Step.FORM);
			})
			.finally(() => setLoading(false));
	};

	const handleFileLoad = (files: File[]) => {
		setError('');

		if (files.length) {
			const FILES_MAX_SIZE = 4;

			if (files[0].size / 1024 / 1024 >= FILES_MAX_SIZE) {
				setError(`El archivo es demasiado grande y supera el máximo de 4MB`);
				return;
			}

			if (
				files[0].type !== 'application/pdf' &&
				files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
				files[0].type !== 'application/vnd.ms-excel'
			) {
				setError('Solamente se aceptan los tipos de archivos PDF o MS Excel');
				return;
			}
		}

		setFiles(files);
	};

	const icon = <CheckBoxOutlineBlankIcon color="inherit" />;
	const checkedIcon = <CheckBoxIcon sx={{ color: '#000' }} />;

	const handleSetShipping = (event: any) => {
		setIncludesShipping(event.target.value === 'true');
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth={step === Step.FORM ? 'md' : 'xs'}>
			{loading && <FullScreenLoading />}
			<DialogTitle>
				<Typography variant="h5" textAlign={step === Step.FORM ? 'start' : 'center'}>
					{step === Step.FORM && intl.formatMessage({ id: 'list.seller.details.quotation_dialog.form_title' })}
					{step === Step.CONFIRMATION &&
						intl.formatMessage({ id: 'list.seller.details.quotation_dialog.confirmation_title' })}
				</Typography>
			</DialogTitle>
			<DialogContent sx={{ padding: { md: '18px 30px' }, maxHeight: { md: '55vh' } }}>
				{step === Step.FORM && (
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.file_field_name' })}
							</SeparatedLabel>
							<FileUploadWrapper>
								<FileUpload
									value={files}
									onChange={(files: File[]) => {
										handleFileLoad(files);
									}}
									multiple={false}
									buttonText={intl.formatMessage({ id: 'common.upload' })}
									title={intl.formatMessage({ id: 'list.seller.details.quotation_dialog.file_field_placeholder' })}
									sx={{
										borderRadius: 4,
										mt: 1,
										'& .MuiFormControl-root': {
											display: files && files.length ? 'none' : 'flex',
										},
									}}
								/>
							</FileUploadWrapper>
							<small style={{ fontSize: '.74em' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.file_restrictions' })}
							</small>
						</Grid>
						<Grid item xs={12} md={6}>
							<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.date_field_name' })}
							</SeparatedLabel>
							<DatePicker value={date} onChange={(value) => setDate(value)} variant="form" />

							<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.includes_shipping_field_name' })}
							</SeparatedLabel>
							<FormControl required>
								<RadioGroup
									value={includesShipping}
									onChange={handleSetShipping}
									row
									sx={{
										'& .MuiButtonBase-root.Mui-checked': {
											color: '#000',
											'& .MuiSvgIcon-root:nth-child(2)': {
												fill: '#3B74FB',
											},
										},
									}}
								>
									<FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: 'common.yes' })} />
									<FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: 'common.no' })} />
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.payment_field_name' })}
							</SeparatedLabel>
							<Autocomplete
								multiple
								disableCloseOnSelect
								limitTags={2}
								onChange={(event, newValue) => {
									setPayment(newValue.map((opt) => opt.id) as Payment[]);
								}}
								options={payments}
								getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
								renderOption={(props, option, { selected }) => (
									<li {...props}>
										<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} size="small" />
										{intl.formatMessage({ id: option.label })}
									</li>
								)}
								size="small"
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder={
											payment?.length === 0
												? intl.formatMessage({
														id: 'list.seller.details.quotation_dialog.payment_field_placeholder',
												  })
												: undefined
										}
										fullWidth
										variant="outlined"
										size="small"
									/>
								)}
								ListboxProps={{
									style: {
										maxHeight: '220px',
									},
								}}
							></Autocomplete>
						</Grid>
						<Grid item xs={12} md={6}>
							<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.availability_field_name' })}
							</SeparatedLabel>
							<TextField
								select
								size="small"
								fullWidth
								placeholder={intl.formatMessage({
									id: 'list.seller.details.quotation_dialog.availability_field_placeholder',
								})}
								value={availability}
								onChange={(event) => setAvailability(event.target.value as Availability)}
								required
							>
								{availabilities.map((availability) => (
									<MenuItem key={availability.id} value={availability.id}>
										{intl.formatMessage({ id: availability.label })}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12}>
							<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
								{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.comments_field_name' })}
								{(availability === Availability.OTHER ||
									availability === Availability.PARTIAL ||
									availability === Availability.MANUFACTURNING_REQUIRED ||
									includesShipping === false ||
									payment?.includes(Payment.OTHER)) &&
									'*'}
							</SeparatedLabel>
							{!includesShipping && (
								<StyledAlert
									severity="info"
									sx={{ p: 0, '& .MuiAlert-icon': { fontSize: '1.4em', marginRight: '5px' } }}
								>
									{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.comments_restrictions_1' })}
								</StyledAlert>
							)}
							{payment?.includes(Payment.OTHER) && (
								<StyledAlert
									severity="info"
									sx={{ p: 0, mt: '-8px', '& .MuiAlert-icon': { fontSize: '1.4em', marginRight: '5px' } }}
								>
									{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.comments_restrictions_2' })}
								</StyledAlert>
							)}
							{(availability === Availability.OTHER ||
								availability === Availability.PARTIAL ||
								availability === Availability.MANUFACTURNING_REQUIRED) && (
								<StyledAlert
									severity="info"
									sx={{ p: 0, mt: '-8px', mb: 1, '& .MuiAlert-icon': { fontSize: '1.4em', marginRight: '5px' } }}
								>
									{intl.formatMessage({ id: 'list.seller.details.quotation_dialog.comments_restrictions_3' })}
								</StyledAlert>
							)}
							<TextField
								fullWidth
								multiline
								size="small"
								rows={3}
								placeholder={intl.formatMessage({
									id: 'list.seller.details.quotation_dialog.comments_field_placeholder',
								})}
								value={comments}
								onChange={(event) => setComments(event.target.value)}
								required={
									payment?.includes(Payment.OTHER) ||
									availability === Availability.OTHER ||
									availability === Availability.PARTIAL ||
									availability === Availability.MANUFACTURNING_REQUIRED ||
									includesShipping === false
								}
							/>
						</Grid>
					</Grid>
				)}
				{step === Step.CONFIRMATION && (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="subtitle1" textAlign="center">
								{intl.formatMessage(
									{ id: 'list.seller.details.quotation_dialog.confirmation_text' },
									{ date: intl.formatDate(date, { timeZone: 'UTC' }) }
								)}
							</Typography>
							<Box sx={{ pt: 4 }}>
								<Box
									sx={{
										display: 'flex',
										gap: 1,
									}}
								>
									<FileLogo
										src={files[0].type === 'application/pdf' ? pdfIcon : excelIcon}
										sx={{
											maxWidth: '48px',
											ml: 4,
										}}
									/>
									<Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'start' }}>
										<Typography
											variant="subtitle1"
											sx={{ fontSize: '.91em', maxWidth: '296px', wordBreak: 'break-word', textOverflow: 'ellipsis' }}
										>
											{files[0].name}
										</Typography>
										<Typography variant="subtitle2" sx={{ color: '#777' }}>
											{intl.formatMessage({
												id: files[0].type === 'application/pdf' ? 'common.pdf_file' : 'common.excel_file',
											})}
										</Typography>
										<Typography
											variant="subtitle2"
											sx={{
												fontSize: '.8em',
												color: '#777',
											}}
										>
											{humanFileSize(files[0].size)}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12}></Grid>
					</Grid>
				)}
				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error ?? 'Ocurrió un error.'}
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1, mb: 1 }}>
				{step === Step.FORM && (
					<>
						<Button
							onClick={() => onClose(null, 'cancelButtonClick')}
							variant="contained"
							sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
						>
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<Button
							onClick={() => setStep(Step.CONFIRMATION)}
							sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
							variant="contained"
							disabled={
								files.length === 0 ||
								!date ||
								!payment ||
								!availability ||
								payment?.length === 0 ||
								((payment.includes(Payment.OTHER) || availability === Availability.OTHER) && !comments) ||
								((availability === Availability.OTHER ||
									availability === Availability.PARTIAL ||
									availability === Availability.MANUFACTURNING_REQUIRED) &&
									!comments) ||
								(includesShipping === false && !comments)
							}
						>
							{intl.formatMessage({ id: 'common.continue' })}
						</Button>
					</>
				)}
				{step === Step.CONFIRMATION && (
					<>
						<Button
							onClick={() => setStep(Step.FORM)}
							sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
							variant="contained"
						>
							{intl.formatMessage({ id: 'common.back' })}
						</Button>
						<Button
							onClick={handleSubmit}
							sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
							variant="contained"
						>
							{intl.formatMessage({ id: 'common.send' })}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default SellerQuotationDialog;
