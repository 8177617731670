import { Typography, styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { User } from '../types';
import SellerPaymentInfo from './details/dialogs/seller-payment-info';

const Wrapper = styled('div')`
	& > *:not(:first-child) {
		margin-left: 10px;
	}
`;

const SellectPicture = styled('img')`
	height: 45px;
	display: inline-block;
`;

const TextWrapper = styled('div')`
	display: inline-block;
	vertical-align: top;
`;

const TaxID = styled(Typography)`
	color: #2f5dff;
	font-size: .75em;
	margin-top:-2px;
`;

interface Props {
	name: string;
	taxId: number;
	image?: string;
	files?: any;
	listId?: string;
	user: User;
}

const Seller = (props: Props) => {
	const intl = useIntl();
	const { name, taxId, image, files, listId, user } = props;

	const [open, setOpen] = useState<boolean>(false)

	return (
		<Wrapper>
			{image && <SellectPicture src={image} />}
			<TextWrapper>
				<Typography variant="subtitle1">{name}</Typography>
				{	(!files || files?.length === 0) &&
					<TaxID>{intl.formatMessage({ id: 'common.tax_id' }, { taxId })}</TaxID>
				}
				{	files && files?.length > 0 &&
					<Typography onClick={() => setOpen(true)} sx={{ mt:-.25, cursor: 'pointer', color: '#2f5dff', fontSize:'.88em' }}>
						{intl.formatMessage({ id: 'list.details.payment_info.activator_label'})}
					</Typography>
				}
			</TextWrapper>
			{	
				files && files?.length > 0 &&
				<SellerPaymentInfo open={open} sellerData={{
						name, taxId, image
					}} files={files?.reduce((acc:any, obj:any) => {
						const key = obj.file_type;
						const curGroup = acc[key] ?? [];
						return { ...acc, [key]: [...curGroup, obj] };
					}, {})} listId={listId} user={user}
					onClose={() => setOpen(false)}
				/>
			}
		</Wrapper>
	);
};

export default Seller;
