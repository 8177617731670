import { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, Typography, IconButton, Stack, InputLabel, MenuItem } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { BusinessName, Company, User } from '../../../types';
import { formatCUIT } from '../../../utils/data-utils';
import { SelectInputConfirmation, TextFieldConfirmation } from './confirmation-step';
import NuqleaAlert from '../../nuqlea-alert';
import SideModalContainer from '../../modals/side-modal-container';
import TaxProfileForm from '../../settings/organization/shared-components/tax-profile-form';
import { useMobile } from '../../../hooks/use-mobile';
import { useSetRecoilState } from 'recoil';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';

interface Props {
	user: User;
	confirm: () => void;
	companies: BusinessName[];
	open: boolean;
	onClose: () => void;
	selectedCompany: BusinessName | undefined;
	handleSetSelectedCompany: (newCompany: BusinessName | undefined) => void;
	reloadCompanies?: (reload: boolean) => void;
}

const PurchaseConfirmDialog = (props: Props) => {
	const intl = useIntl();
	const isMobile: boolean = useMobile();
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const { user, confirm, companies, open, onClose, selectedCompany, handleSetSelectedCompany, reloadCompanies } = props;
	const [taxProfileForm, setTaxProfileForm] = useState<boolean>(false);

	const companiesMap = useMemo<Map<string | null | undefined, BusinessName>>(
		() => new Map(companies.map((company: BusinessName) => [company.id, company])),
		[companies]
	);

	useEffect(() => {
		setDisableRootElementScroll(taxProfileForm);
	}, [taxProfileForm]);

	return (
		<>
			<Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="sm" PaperProps={{ sx: { maxWidth: '450px' } }}>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent sx={{ display: 'flex', padding: { xs: 'unset', sm: '1rem' }, justifyContent: 'center' }}>
					<Stack spacing={4} width={'100%'}>
						<Typography variant="h5" sx={{ textAlign: 'center' }}>
							{intl.formatMessage({ id: 'buy_tokens.purchase_confirm.title' })}
						</Typography>
						<Stack>
							<InputLabel shrink htmlFor="company" sx={{ fontSize: 18, color: '#000', fontWeight: 500 }}>
								{intl.formatMessage({ id: 'common.organization_name' })}
							</InputLabel>
							<SelectInputConfirmation
								labelId="company"
								value={selectedCompany?.id ?? ''}
								onChange={(event) => handleSetSelectedCompany(companiesMap.get(event.target.value as string))}
							>
								{companies.map((company: BusinessName) => (
									<MenuItem key={company.id} value={company.id ?? ''} sx={{ color: 'grey.600' }}>
										{company.business_social}
									</MenuItem>
								))}
							</SelectInputConfirmation>
						</Stack>
						<Stack>
							<InputLabel
								shrink
								htmlFor="sent-location"
								sx={{ fontSize: 18, color: '#000', fontWeight: 500, position: 'relative' }}
							>
								{intl.formatMessage({ id: 'header.tax_id_label' })}
							</InputLabel>
							<TextFieldConfirmation id="sent-location" value={formatCUIT(selectedCompany?.cuit ?? '')} disabled />
						</Stack>
						{selectedCompany?.validInvoiceProfile === false && (
							<NuqleaAlert
								action={() => {
									setTaxProfileForm(true);
								}}
								actionTitle={intl.formatMessage({ id: 'common.complete_action' })}
								minHeight="60px"
							>
								{intl.formatMessage({ id: 'buy_tokens.purchase_confirm.invalid_invoice_profile' })}
							</NuqleaAlert>
						)}
						<Button
							disabled={!selectedCompany}
							onClick={confirm}
							variant="contained"
							sx={{
								color: '#FFFFFF',
								backgroundColor: '#406AFF',
								borderRadius: '4px',
								'&:hover': { backgroundColor: '#406AFF' },
							}}
						>
							{intl.formatMessage({ id: 'buy_tokens.purchase_confirm.confirm_button' })}
						</Button>
					</Stack>
				</DialogContent>
			</Dialog>
			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={taxProfileForm}
				onClose={() => setTaxProfileForm(false)}
			>
				<TaxProfileForm
					callback={(_, changed) => {
						setTaxProfileForm(false);
						if (!changed) {
							return;
						}
						reloadCompanies?.(true);
					}}
					businessNameToEdit={selectedCompany}
					organizationId={user.id_external}
					token={user.token}
					creator={user}
					isModal={true}
				></TaxProfileForm>
			</SideModalContainer>
		</>
	);
};

export default PurchaseConfirmDialog;
