import styled from '@emotion/styled';
import {
	Box,
	CircularProgress,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Theme,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

const CellContent = styled('div')`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ClickeableRow = styled(TableRow)`
	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
`;

const CollapsedHeader = styled(Typography)`
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #000000;
`;

export interface DataColumn {
	displayName?: string;
	supportsMobile?: boolean;
	headerStyle?: SxProps<Theme>;
	sort?: (a: DataRow, b: DataRow) => number;
	backgroundColor?: (row: DataRow) => any;
	render: (row: DataRow, intl: IntlShape) => any;
	showWarning?: (row: DataRow, intl: IntlShape) => boolean;
}

export interface DataRow {
	[key: string]: any;
}

interface BaseTableProps {
	loading?: boolean;
	empty?: boolean;
	stickyHeader?: boolean;
	data: DataRow[];
	columns: DataColumn[];
	onRowClick?: (row: DataRow) => void;
	orderByDefault?: string;
	maxHeightOnPx: number;
	TableContainerOverflowX?:  "auto" | "clip" | "hidden" | "scroll" | "visible";
}

const BaseTable = ({ data, columns, onRowClick, loading, empty, maxHeightOnPx, orderByDefault, stickyHeader, TableContainerOverflowX = 'hidden' }: BaseTableProps) => {
	const intl = useIntl();
	let ref = useRef<HTMLDivElement | null>(null);

	const [orderBy, setOrderBy] = useState<string>(orderByDefault ?? '');
	const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
	const [columnAverageWidth, setColumnAverageWidth] = useState(100);

	useEffect(() => {
		if (ref.current) {
			setColumnAverageWidth(ref.current.offsetWidth / columns.length);
		}
	}, [ref, columns.length]);

	const handleChangeSorting = useCallback(
		(column?: string) => {
			if (!column) {
				return;
			}

			if (orderBy !== column) {
				setOrderBy(column);
				setDirection('asc');
			} else if (direction === 'asc') {
				setDirection('desc');
			} else {
				setDirection('asc');
			}
		},
		[direction, orderBy]
	);

	const onRowClickHandler = (row: DataRow) => {
		if (onRowClick) onRowClick(row);
	};

	const getSortedRows = useCallback(
		(columns: DataColumn[], direction: 'asc' | 'desc', rows?: DataRow[], orderBy?: string) => {
			if (!rows) {
				return rows;
			}

			if (!orderBy) {
				return rows;
			}

			const column = columns.filter((column) => column.displayName === orderBy)[0];
			if (!column) {
				return rows;
			}

			const sort = column.sort;
			if (!sort) {
				return rows;
			}

			return rows.sort((a, b) => (direction === 'asc' ? sort(a, b) : sort(b, a)));
		},
		[]
	);

	const sortedRows = getSortedRows(columns, direction, data, orderBy);
	return (
		<>
			{!!loading && (
				<Box
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '300px', color: 'grey.500' }}
				>
					<CircularProgress color="inherit" />
				</Box>
			)}
			{!!empty && !loading && (
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '300px' }}>
					<Typography>{intl.formatMessage({ id: 'common.table.no_data' })}</Typography>
				</Box>
			)}
			{!loading && !empty && (
				<TableContainer
					ref={(el) => {
						ref.current = el;
					}}
					sx={{ overflowX: TableContainerOverflowX, maxHeight: `${maxHeightOnPx}px`, }}
				>
					<Table {...{ stickyHeader }}>
						<TableHead>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								{!!data?.length &&
									columns.map((column, index) => (
										<TableCell
											key={index}
											colSpan={1}
											sx={{
												...column.headerStyle,
												display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
											}}
										>
											{column.displayName && (
												<CellContent>
													{column.sort ? (
														<TableSortLabel
															active={orderBy === column.displayName}
															direction={orderBy === column.displayName ? direction : 'asc'}
															onClick={() => handleChangeSorting(column.displayName)}
														>
															<Tooltip title={column.displayName} placement="top">
																<CollapsedHeader
																	variant="body1"
																	width={[
																		`${columnAverageWidth}px`,
																		`${columnAverageWidth}px`,
																		`${columnAverageWidth}px`,
																		'100%',
																	]}
																>
																	{column.displayName}
																</CollapsedHeader>
															</Tooltip>
														</TableSortLabel>
													) : (
														<Tooltip title={column.displayName} placement="top">
															<CollapsedHeader
																variant="body1"
																width={[
																	`${columnAverageWidth}px`,
																	`${columnAverageWidth}px`,
																	`${columnAverageWidth}px`,
																	'100%',
																]}
															>
																{column.displayName}
															</CollapsedHeader>
														</Tooltip>
													)}
												</CellContent>
											)}
										</TableCell>
									))}
							</TableRow>
						</TableHead>

						<TableBody>
							{!!sortedRows?.length &&
								sortedRows?.map((row, i) => (
									<ClickeableRow
										key={i}
										onClick={() => onRowClickHandler(row)}
									>
										{columns.map((column, ind) => (
											<TableCell
												key={ind}
												sx={{
													display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
													background: column.backgroundColor ? column.backgroundColor(row) : 'inherit',
												}}
											>
												<CellContent>
													{column.render(row, intl) != null ? <span>{column.render(row, intl)}</span> : '-'}
												</CellContent>
											</TableCell>
										))}
									</ClickeableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	);
};

export default BaseTable;
