import { Typography, Box, Button } from '@mui/material';

const Error = () => {
	return <Box sx={{ display: 'flex', flexDirection:'column', width: '100%', height: '100vh', justifyContent: 'center', justifyItems: 'center', alignItems: 'center', gap: 2 }}>
		<img width="64" height="64" src="https://img.icons8.com/cotton/64/error--v5.png" alt="error--v5"/>
		<Typography>Ocurrió un error inesperado al intentar obtener los datos.</Typography>
		<Button
			onClick={() => window.location.href = '/' }
			variant="contained"
			sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
		>
			Ir al inicio
		</Button>
	</Box>;
};

export default Error;
