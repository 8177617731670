import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import axios from 'axios';

interface Props {
	open: boolean;
	onClose: (event?: object | null, reason?: string) => void;
	agreement?: string;
}

const termsByAgreement = {
	'/assets/terms_tokens.md': ['d9ac3a27', 'd9ac3a278', '14'],
	'/acopio/hierro/terms.md': ['15', '9', 'acd6c2c4'],
} as any;

const AcceptTokenTermsDialog = (props: Props) => {
	const { open, onClose, agreement } = props;
	const intl = useIntl();
	const baseTheme = useTheme();
	const matchesSm = useMediaQuery(baseTheme.breakpoints.down('sm'));

	const [content, setContent] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		(async () => {
			const termsUrl = Object.keys(termsByAgreement)
				.map((key) => (termsByAgreement[key].includes(agreement) ? key : null))
				.filter((el) => el)[0];

			if (!termsUrl) {
				return;
			}

			await axios.get(`${window.location.origin}${termsUrl}`).then((result) => {
				setContent(result.data);
			});
		})();
	}, [agreement]);

	return (
		<Box>
			<Dialog aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
				<DialogTitle className="text-[1.12rem] font-bold pb-[0.9rem] mb-[0.9rem] border-b border-[#C6C6C6]">
					{intl.formatMessage({ id: 'terms_dialog.title' })}
				</DialogTitle>
				<DialogContent>
					<div className="px-12 content" dangerouslySetInnerHTML={{ __html: content }}></div>
				</DialogContent>
				<DialogActions
					sx={{
						justifyContent: 'center',
					}}
				>
					<Button onClick={onClose} sx={{ width: '10rem' }} variant="contained">
						{intl.formatMessage({ id: 'common.close' })}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default AcceptTokenTermsDialog;
