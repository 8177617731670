import { Paper, Grid, Box, Typography, Button } from '@mui/material';
import { CtaType, HomeItem } from '../domain/HomeItem';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';

const Banner = (props: HomeItem) => {
	const navigate = useNavigate();

	const onClickCta = () => {
		const user = props.user;
		const { type, link, gtm_variable } = props.cta;

		if(gtm_variable && globalThis.location.hostname === 'studio.nuqlea.com') {
			(globalThis as any).dataLayer?.push({
				"event": gtm_variable,
				"value": {
					"user": {
                        "user_id": user?.id,
                        "user_email": user?.email,
                        "type_external": user?.type_external,
                        "id_external": user?.id_external,
                        "name_external": user?.name_external,
                        "role": user?.role,
                        "environment": user?.enviroment
                    }
				}
			})
		}

		if (type === CtaType.navigation) {
			navigate(link);
		}
		if (type === CtaType.redirection) {
			navigate(link, { replace: true });
		}
	};
	return (
		<Grid item xs={12} sx={{
			pl: {
				md: 'auto',
				xs: '0!important'
			}
		}}>
			<Paper
				elevation={3}
				onClick={onClickCta}
				sx={{
					':hover': {
						cursor: 'pointer',
						transform: 'translateY(-6px)',
					},
					transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
					px: {
						md:'2.8em',
						xs:'2em'
					},
					py: {
						md: '2.8em',
						xs: '1em'
					},
					backgroundColor: 'white',
					borderRadius: 2.8,
			}}>
				<Box sx={{
					display: 'flex',
					flexDirection: { md: 'row', xs: 'column' },
					justifyContent: {
						md: 'space-between',
						xs: 'start'
					}
				}}>
					<Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
						<Box sx={{ width: { sx: '100%', md: '75%'} }}>
							<Typography
								sx={{
									fontSize: '1.2em',
									fontWeight: 600,
									lineHeight: '19px',
									letterSpacing: '-0.02em',
									color: '#343434',
									opacity: '70%',
								}}
							>
								{props.title}
							</Typography>
							<Typography
								variant="body1"
								sx={{
									fontSize: { lg: '2.4em', xs: '22px'},
									lineHeight: {md: '1.2em', xs: '1.2em' },
									fontWeight: 600,
									letterSpacing: '-0.02em',
									color: '#343434',
									mt: 2
								}}
							>
								{props.description}
							</Typography>
							<Button
								endIcon={<EastIcon />}
								sx={{
									marginTop: {xs: 1, md: 3},
									marginBottom: {xs: 2, md: 0},
									width: 'fit-content',
									'&:hover': {
										backgroundColor: 'transparent'
									},
									padding: 0,
									color: '#406AFF',
									fontSize: '17px',
									lineHeight: {sm: '1px', md: '34px' },
									letterSpacing: '-0.02em',
									textTransform: 'none',
									textAlign: 'left'
								}}
								size="large"
								onClick={onClickCta}
							>
								{props.cta.label}
							</Button>
						</Box>
					</Box>
					<Box sx={{
						display: 'flex',
						justifyContent: {
							xs:'center'
						},
						flex: 3,
					}}>
						<img style={{ objectFit: 'contain', width: '90%' }} src={props.img} alt="" />
					</Box>
				</Box>
			</Paper>
		</Grid>
	);
};

export default Banner;
