import React from 'react';
import { BusinessName, User } from '../../../types';
import TaxProfileForm from './shared-components/tax-profile-form';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import { useIntl } from 'react-intl';

interface TaxProfileEditionTabProps {
	callback: (isEditionMode: boolean) => void;
	showRequiredError: (show: boolean, message?: string) => void;
	updateBusinessNamesList: () => void;
	businessNameToEdit?: BusinessName;
	organizationId: string;
	token: string;
	creator: User;
}

const TaxProfileEditionTab: React.FC<TaxProfileEditionTabProps> = (props: TaxProfileEditionTabProps) => {
	const intl = useIntl();

	return (
		<GenericTabFrame
			title={intl.formatMessage({ id: 'settings.company.tax_profile_tab.title' })}
			subtitle={intl.formatMessage({ id: 'settings.company.tax_profile_tab.subtitle' })}
		>
			<TaxProfileForm {...props}></TaxProfileForm>
		</GenericTabFrame>
	);
};

export default TaxProfileEditionTab;
