import React from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { DollarValueInPesos } from '../../types';
import { formatNumberWithCurrency } from '../../utils/data-utils';

interface Props {
	dollarType: string;
	dollarsMap: Map<string, DollarValueInPesos>;
	isFirstDollarCardWithDate: boolean;
	lastUpdateDate: Date;
}

const DollarValueCard = (props: Props) => {
	const { dollarType, dollarsMap, isFirstDollarCardWithDate, lastUpdateDate } = props;
	const intl = useIntl();

	return (
		<Card elevation={0}>
			<CardContent
				sx={{
					display: 'flex',
					minHeight: 89,
					maxHeight: 89,
					'&: last-child': { paddingBottom: '16px' },
					...(isFirstDollarCardWithDate ? { flexDirection: 'column' } : {}),
				}}
			>
				{isFirstDollarCardWithDate ? (
					<>
						<Typography sx={{ pl: 2, fontSize: 18, fontWeight: 700 }} variant="h6" gutterBottom>
							{intl.formatMessage({ id: 'common.reference_prices.currency_value' })}
						</Typography>
						<Typography sx={{ pl: 2, fontSize: 12 }}>
							{`${intl.formatMessage({ id: `common.reference_prices.dollar_source` })}
							${intl.formatDate(lastUpdateDate, { day: 'numeric', month: 'numeric', year: 'numeric' }) /*always render date DD/MM/YYYY*/}
							${dollarsMap.size > 0 ? ' - ' + intl.formatTime(lastUpdateDate, { hour: 'numeric', minute: 'numeric' }) : '' /*render time only if dollarsMap is loaded HH:MM am/pm */}`}
						</Typography>
					</>
				) : (
					<Grid container>
						<Grid item xs={6} sx={{ fontSize: 18, display: 'flex', alignItems: 'center' }}>
							{intl.formatMessage({ id: `common.reference_prices.${dollarType}_dollar` })}
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
							<Grid container>
								<Grid item xs={6}>
									{intl.formatMessage({ id: 'common.reference_prices.buy' })}
								</Grid>
								<Grid item xs={6} sx={{ fontSize: 16, fontWeight: 500 }}>
									{formatNumberWithCurrency(dollarsMap.get(dollarType)?.compra, 2)}
								</Grid>
							</Grid>
							<Divider />
							<Grid container sx={{ marginBottom: '-4px' }}>
								<Grid item xs={6}>
									{intl.formatMessage({ id: 'common.reference_prices.sell' })}
								</Grid>
								<Grid item xs={6} sx={{ fontSize: 16, fontWeight: 500 }}>
									{formatNumberWithCurrency(dollarsMap.get(dollarType)?.venta, 2)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};

export default DollarValueCard;
