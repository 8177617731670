import { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CircularProgress } from '@mui/material';

interface Props {
	url: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = (props: Props) => {
  const { url } = props;

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPdfData = async () => {
        setPdfUrl(url)
        const pdf = await pdfjs.getDocument(url).promise
        setNumPages(pdf.numPages)
        setLoading(false)
    };

    fetchPdfData()
  }, [url])

  return (
    <div>
      {loading && <CircularProgress color="info" size={30} />}
      {pdfUrl && (
        <Document file={ pdfUrl } onLoadSuccess={() => {}}>
            {Array.from(new Array(numPages), (el, index) => (
                <Page
                  loading={loading}
                  key={index}
                  scale={1.6}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
            ))}
        </Document>
       )}
    </div>
  );
};

export default PdfViewer;