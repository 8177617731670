import { features } from 'process';
import { FeatureNames, Features, User, UserFeature } from '../types';
import client from './client';

const EmptyStatesClient = {
	pageHasAlreadyViewed(identifier: FeatureNames, user: User): boolean {
		if (user.feature_names && user.feature_names.length > 0) {
			return user.feature_names.includes(identifier);
		}
		return false;
	},
	markAsviewed(featureId: Features, user: User, token: string) {
		try {
			let featureName = '';
			for (const key in Features) {
				if (Features[key as keyof typeof Features] === featureId) {
					featureName = key;
				}
			}
			if (!user.feature_names?.includes(featureName)) {
				const feat: UserFeature = { feature_id: featureId, user_id: user.id, active: false };
				client.updateUserFeature({ token: token, data: feat });
				user.feature_names.push(String(featureName));
				let localUser = JSON.parse(localStorage.getItem('user') ?? '');
				localUser.user = user;
				localStorage.setItem('user', JSON.stringify(localUser));
			}
		} catch (error) {}
		return Promise.resolve();
	},
};

export default EmptyStatesClient;
