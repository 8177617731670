import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import PageContainer from '../page-container';
import { KeyboardBackspace } from '@mui/icons-material';
import { useIntl } from 'react-intl';

interface basePageFrameProps {
	title: string | ReactNode;
	subtitle?: string;
	image?: ReactNode;
	children: ReactNode;
	endAdornment?: ReactNode;
	transparent?: boolean;
	titleContainerStyles?: string;
	titleStyles?: string;
	childContainerStyles?: string;
	returnAction?: () => void;
	returnActionTitle?: string;
}

const BasePageFrame: React.FC<basePageFrameProps> = ({
	title,
	subtitle,
	children,
	image,
	endAdornment,
	transparent,
	titleContainerStyles,
	titleStyles,
	childContainerStyles,
	returnAction,
	returnActionTitle,
}) => {
	const intl = useIntl();
	return (
		<PageContainer>
			<Grid container>
				{returnAction && (
					<Grid item xs={12}>
						<Button onClick={returnAction} startIcon={<KeyboardBackspace />}>
							{returnActionTitle ?? intl.formatMessage({ id: 'common.back' })}
						</Button>
					</Grid>
				)}
				{image && (
					<Grid item xs={3} md={1} lg={1} xl={1}>
						{image}
					</Grid>
				)}
				<Grid item xs={image ? 9 : 12}>
					<Stack>
						<Box className={titleContainerStyles || 'justify-between items-baseline flex'}>
							<Typography className={titleStyles} lineHeight={1} variant="h2" gutterBottom>
								{title}
							</Typography>
							{endAdornment}
						</Box>
						<Typography variant="h5">{subtitle}</Typography>
					</Stack>
				</Grid>
				<Grid className={`mt-[2.2rem]`} item xs={12}>
					<Paper
						className={`${transparent ?? 'p-[2.2rem]'} w-full rounded-md shadow-none ${
							transparent && 'bg-transparent'
						}` + (childContainerStyles ? ` ${childContainerStyles}` : '')}
					>
						{children}
					</Paper>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default BasePageFrame;
