import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogActions, Grid, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import notificationState from '../atoms/one-time-notification-state';
import client from '../clients/client';
import userState from '../atoms/user-state';

interface Props {
	// open: boolean;
	// onClose: () => void;
	// redeemRequestId: string;
    // refresh: () => void;
	// unassignedProducts: boolean;
}

const OneTimeDialog = (props: Props) => {
    const [notifications, setNotifications] = useRecoilState(notificationState);
    const user = useRecoilValue(userState);
    const totalNotifications = useRef(notifications.length);
    const currentNotification = useRef(1);
	const intl = useIntl();

    const handleClose = () => {
        if(notifications.length !== 1){
            currentNotification.current = currentNotification.current + 1;
        }
        setNotifications([...notifications.slice(1)]);
        client.updateOneTimeNotification({ id: notifications[0].id, token: user.token })
    }

	return (
		<Dialog
			aria-labelledby="customized-dialog-title"
			open={notifications.length > 0}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle
				sx={{
					fontSize: '18px',
					fontWeight: 700,
					padding: '0 0 14px 0',
					marginBottom: '14px',
					borderBottom: '1px solid #C6C6C6',
				}}
			>
				{intl.formatMessage({ id: 'common.message' }, { quantity: `${currentNotification.current}/${totalNotifications.current}` })}
			</DialogTitle>

			<DialogContent className='flex flex-col items-center py-2'>			
                <>
                    <Grid container spacing={1} paddingX={1}>
                        <Grid item xs={12}>
                            <Typography>{notifications[0]?.message}</Typography>
                        </Grid>
                    </Grid>

                </>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: '10px 16px!important' }}>
				<Button
					onClick={handleClose}
					sx={{ width: '160px' }}
					variant="outlined"
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default OneTimeDialog;