import Card, { Action } from './card';
import DataList, { Entry } from './data-list';

interface Props {
	title: string;
	subtitle?: string;
	entries: Entry[];
	actions?: Action[];
	loading?: boolean;
	error?: boolean;
	onRetry?: () => void;
}

const DataCard = (props: Props) => {
	const { title, subtitle, entries, actions, loading, error, onRetry } = props;

	return (
		<Card title={title} subtitle={subtitle} loading={loading} error={error} onRetry={onRetry} actions={actions}>
			<DataList entries={entries} />
		</Card>
	);
};

export default DataCard;
