import { Box, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Tooltip } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { MoreVert } from '@mui/icons-material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { BaseFile, User } from '../types';
import FileViewer from './file-viewer';
import { blue } from '@mui/material/colors';

interface FileFormPlaceholderProps {
	file: BaseFile;
	previewCallback?: (filePath: string, user: User) => Promise<any>;
	downloadCallback?: (filePath: string, user: User) => Promise<any>;
	googleDriveId?: string;
	user: User;
}

const FileFormPlaceholder: React.FC<FileFormPlaceholderProps> = ({
	file,
	previewCallback,
	downloadCallback,
	user,
	googleDriveId = '',
}) => {
	const [anchorEl, setAnchorEl] = useState<any>();
	const open = Boolean(anchorEl);

	const intl = useIntl();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const kebabMenu = (
		<Box display="flex" alignItems="end">
			<IconButton onClick={handleClick}>
				<MoreVert color="action" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				container={document.body}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={() => handleOptionClick('preview')}>{intl.formatMessage({ id: 'common.view' })}</MenuItem>
				<MenuItem onClick={() => handleOptionClick('download')}>
					{intl.formatMessage({ id: 'common.download' })}
				</MenuItem>
			</Menu>
		</Box>
	);

	const eyeViewer = (
		<FileViewer
			buttonType={'icon'}
			user={user}
			gcsFilePath={file.path}
			downloadFileMetadataHandler={previewCallback}
			downloadFileBinaryHandler={downloadCallback}
		></FileViewer>
	);

	const handleOptionClick = (option: string) => {
		switch (option) {
			case 'preview':
				setAnchorEl(null);
				previewCallback && previewCallback(file.path ?? '', user);
				break;

			default:
				setAnchorEl(null);
				downloadCallback && downloadCallback(file.path ?? '', user);
				break;
		}
	};

	return (
		<OutlinedInput
			fullWidth
			readOnly
			size="small"
			startAdornment={
				<InputAdornment sx={{ '& :hover': { cursor: 'default' } }} position="start">
					<InsertDriveFileIcon color="primary" />
				</InputAdornment>
			}
			value={file.name}
			endAdornment={
				<InputAdornment position="end">
					<Box display="flex" alignItems="end">
						{eyeViewer}
					</Box>
				</InputAdornment>
			}
			sx={{
				bgcolor: blue[50],
				border: `1px solid primary.main`,
			}}
		/>
	);
};

export default FileFormPlaceholder;
