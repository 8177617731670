import { Box, Grid, debounce, Paper, Typography, Stack } from '@mui/material';
import {
	Document,
	FilesList,
	Response,
	TokenDetailStatus,
	Role,
	StockSafekeeping,
	AgreementConditionTypes,
} from '../../../types';
import StatusCard, { Status } from '../../status-card';
import TableCardTreeView from '../../table-card-tree-view';
import { Upload } from '@mui/icons-material';
import { relatedFiles } from '../../details/columns';
import FileUploadDialog from '../../details/dialogs/file-upload-dialog';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FullScreenLoading from '../../full-screen-loading';
import client from '../../../clients/client';
import { useIntl } from 'react-intl';
import Error from '../../error';
import PageContainer from '../../page-container';
import { formatNumberLocale } from '../../../utils/data-utils';
import userState from '../../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import ClientInfoComponent from '../../client-info';

const allowedFileTypes = [
	{ id: 6, name: 'Factura' },
	{ id: 13, name: 'Nota de crédito' },
	{ id: 15, name: 'Nota de débito' },
	{ id: 14, name: 'Recibo de pago' },
];

const TokenDetailView = () => {
	const { id } = useParams();
	const user = useRecoilValue(userState);
	const intl = useIntl();

	const [stockSafekeepingResponse, setStockSafekeepingResponse] = useState<Response<StockSafekeeping>>();
	const [agreementConditions, setAgreementConditions] = useState<any>([]);
	const [isLoadingAgreementConditions, setIsLoadingAgreementConditions] = useState(true); // because agreement conditions are fetched after stock safekeeping
	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [allDocuments, setAllDocuments] = useState<Document[]>([]);
	const [filesResponse, setFilesResponse] = useState<Response<FilesList>>();
	const [stockSafekeepingDetails, setStockSafekeepingDetails] = useState<any>([]);
	const [counts, setCounts] = useState<Status[]>();

	const fetchStockSafekeeping = debounce((fetchDocs: Boolean) => {
		setStockSafekeepingResponse(undefined);

		client
			.getStockSafekeeping({ token: user.token, sSkId: id, role: Role.SELLER })
			.then((data) => {
				setStockSafekeepingResponse({ data });
				getAgreementConditionsForSsk(data?.sSks.id);

				if (fetchDocs) fetchListDocuments(data?.sSks?.order_id, id);

				checkStatus(data?.sSks);
			})
			.catch(() => setStockSafekeepingResponse({ error: true }));
	}, 250);

	const getAgreementConditionsForSsk = async (sskid: string) => {
		try {
			const response = await client.getAgreementConditionForSsk({
				token: user.token,
				sSkId: sskid,
			});
			setAgreementConditions(response.conditions);
			setIsLoadingAgreementConditions(false);
		} catch (error) {

		}
	};

	const getDelivery = (conditions: []) => {
		const delivery: any = conditions?.find((item: any) => item.condition_type_id === AgreementConditionTypes.SHIPPING || item.condition_type_id === AgreementConditionTypes.WITHDRAWAL);
		return delivery ?? { agreement_condition_type_label: '-', agreement_condition_zone_label: '-'};
	};

	const getFinancingCondition = (conditions: []) => {
		const financing: any = conditions.find((item: any) => item.condition_type_id && (item.condition_type_id === AgreementConditionTypes.FINANCING));
		return financing ? financing.name : 'Sin financiación';
	};

	const fetchListDocuments = debounce((orderId, sSkId) => {
		setFilesResponse(undefined);

		client
			.getTokenDocuments({ orderId, sSkId, user })
			.then((data) => {
				setFilesResponse({ data });
				const filteredDocs = data?.map((file: Document) => file);
				setAllDocuments(filteredDocs ?? []);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	useEffect(() => {
		fetchStockSafekeeping(true);
	}, []);

	const checkStatus = (sSk: any) => {
		setCounts([
			{ label: TokenDetailStatus.REQUEST_RECEIVED, active: false, completed: true },
			{ label: TokenDetailStatus.INVOICE_SENT, active: sSk?.Estado_orden === 1, completed: sSk?.Estado_orden > 1 },
			{ label: TokenDetailStatus.PAID, active: sSk?.Estado_orden === 2, completed: sSk?.Estado_orden > 2 },
			{ label: TokenDetailStatus.ACCREDITED, active: sSk?.Estado_orden === 3, completed: sSk?.Estado_orden > 3 },
		]);
	};

	useEffect(() => {
		if (stockSafekeepingResponse?.data) {
			const { sSks } = stockSafekeepingResponse?.data as any;
			const delivery = getDelivery(agreementConditions);
			setStockSafekeepingDetails([
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.date' }),
					value: intl.formatDate(sSks?.created_at, { timeZone: 'UTC' }),
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.amount' }),
					value: `${formatNumberLocale(sSks?.quantity ?? 0)} tokens`,
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.total_cost' }),
					value: intl.formatNumber(sSks?.Monto_final, {
						style: 'currency',
						currency: 'ARS',
						currencyDisplay: 'narrowSymbol',
					}),
				},
				{
					key: delivery?.condition_type_id && delivery?.condition_type_id === AgreementConditionTypes.SHIPPING
						? intl.formatMessage({ id: 'mytokens.acquisition_details.shipping_zone' })
						: delivery?.agreement_condition_type_label,
					value: delivery?.agreement_condition_zone_label
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.financing' }),
					value: agreementConditions?.length ? getFinancingCondition(agreementConditions) : '-',
				},
				{
					key: 'Cuit:',
					value: sSks?.the_cuit,
				},
				{
					key: intl.formatMessage({ id: 'mytokens.acquisition_details.business_name' }),
					value: sSks?.business_social,
				},
			]);
		}
	}, [stockSafekeepingResponse, agreementConditions]);

	if (!stockSafekeepingResponse) {
		return <FullScreenLoading />;
	}

	const { data, error } = stockSafekeepingResponse as any;
	const sSks = data?.sSks;

	if (error || !sSks) {
		return <Error />;
	}

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = allDocuments;

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setAllDocuments(allNewDocs);
		} else {
			setAllDocuments([...allDocuments, newFile]);
		}
		if (newFile.file_type_id === 6 && sSks?.Estado_orden === 1) {
			setCounts([
				{ label: TokenDetailStatus.REQUEST_RECEIVED, active: false, completed: true },
				{ label: TokenDetailStatus.INVOICE_SENT, active: false, completed: true },
				{ label: TokenDetailStatus.PAID, active: true, completed: false },
				{ label: TokenDetailStatus.ACCREDITED, active: false, completed: false },
			]);
		}
	};

	return (
		<PageContainer>
			<FileUploadDialog
				tokenization={true}
				dataTokenization={sSks}
				open={fileUploadDialogOpen}
				types={allowedFileTypes.filter((fileType) => {
					if ([13, 14, 15].includes(fileType.id)) {
						return (
							allDocuments?.filter(
								(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
							).length > 0
						);
					}

					return true;
				})}
				documents={
					allDocuments?.filter(
						(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
					) || []
				}
				// dataSeller={data}
				user={user}
				onClose={(event, reason, newFile) => {
					setFileUploadDialogOpen(false);
					if (newFile) forceInsertNewFile(newFile);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile)
						fetchListDocuments(sSks?.order_id, id);
				}}
			/>

			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
				<StatusCard
					title={`Solicitud de compra ${id}`}
					titleLocation="center"
					statuses={counts || []}
					actions={[]}
					backUrl={`/my-tokens?agreement=${sSks.agreement_id}`}
				/>
				<Grid container spacing={4}>
					<Grid item xs={12} md={6}>
						<Paper sx={{ padding: '20px', borderRadius: '30px' }}>
							<Grid container justifyContent="space-between">
								<Grid item xs={8}>
									<Typography variant={'h5'} fontWeight={500}>
										{intl.formatMessage({ id: 'mytokens.redeem_request_details' })}
									</Typography>
								</Grid>
								<Grid container item xs={4} alignItems={'flex-end'} className='pb-[1px]'>
									<ClientInfoComponent customMessage={intl.formatMessage({ id: 'mytokens.client_billing.action' })} businessName={sSks} showButton={false} />
								</Grid>
							</Grid>

							<Box
								sx={{
									display: 'flex',
									flexDirection: { xs: 'column', md: 'row' },
									minHeight: { xs: '400px' },
								}}
							>
								{!isLoadingAgreementConditions
									? (
										<Stack justifyContent={'space-between'} width={'100%'} pt={'15px'}>
											{stockSafekeepingDetails.map((item: any) => (
												<Box key={item.key}>
													<Grid container spacing={1}>
														<Grid display={'flex'} flexDirection={'column'} item xs={12} md={12} lg={6}>
															<Typography variant="subtitle1" fontWeight={600}>
																{item.key}
															</Typography>
															<Typography color="#7E7E82">{item.value}</Typography>
														</Grid>
														<Grid item xs={8} md={12} lg={6}>
															{item.detail && item.detail}
														</Grid>
													</Grid>
												</Box>
											))}
										</Stack>
									)
									: (
										<Box
											sx={{
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'start',
												paddingTop: '12%'
											}}
										>
											<Typography color="#7E7E82">{intl.formatMessage({ id: 'common.loading' })}</Typography>
										</Box>
									)
								}
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<TableCardTreeView
							loading={!filesResponse}
							title={intl.formatMessage({ id: 'list.seller.details.related_files.title' })}
							layoutColumns={6}
							columns={relatedFiles}
							relatedColumns={relatedFiles}
							rows={allDocuments?.map((document) => ({ ...document, user, list_id: '2bfc5999' }))}
							actions={[
								{
									displayName: intl.formatMessage({ id: 'list.seller.details.upload_file.action' }),
									icon: <Upload />,
									type: 'Button',
									onClick: () => setFileUploadDialogOpen(true),
								},
							]}
						></TableCardTreeView>
					</Grid>
				</Grid>
			</Box>
		</PageContainer>
	);
};

export default TokenDetailView;
