import { Grid, Paper, Typography, debounce, styled, Divider, Box } from '@mui/material';
import { Collaborator, CollaboratorRole, CollaboratorStatus, Response } from '../../types';
import { IntlShape, useIntl } from 'react-intl';
import { Check as ConfirmIcon, Close as CancelIcon, Delete as DeleteIcon } from '@mui/icons-material';
import TableCard, { DataRow } from '../table-card';
import { ACTION_COLUMN_STYLE } from '../../constants';
import ActionButton from '../action-button';
import { useEffect, useMemo, useState } from 'react';
import client from '../../clients/client';
import NewCollaboratorDialog from './new-collaborator-dialog';
import CollaboratorInvitationConfirm from './collaborator-invitation-confirm';
import userState from '../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import BasePageFrame from '../base-components/base-page-frame';

const AdminTag = styled('span')`
	color: blue;
`;

const Collaborators = () => {
	const [showAddNewCollaboratorDialog, setShowAddNewColaboratorDialog] = useState(false);
	const [showInvitationConfirmDialog, setShowInvitationConfirmDialog] = useState(false);

	const intl = useIntl();

	const user = useRecoilValue(userState);

	const requestsColumns = [
		{
			displayName: 'Nombre',
			supportsMobile: true,
			render: (row: DataRow, intl: IntlShape) => row.user_name,
		},
		{
			displayName: 'Mail',
			supportsMobile: true,
			render: (row: DataRow) => row.user_email,
		},
		{
			displayName: 'Área',
			render: (row: DataRow, intl: IntlShape) =>
				row.area ? intl.formatMessage({ id: `collaborators.area_column.${row.area}` }) : '-',
		},
		{
			headerStyle: ACTION_COLUMN_STYLE,
			supportsMobile: true,
			render: (row: DataRow, intl: IntlShape) => (
				<ActionButton
					icon={<ConfirmIcon />}
					color="success"
					label={intl.formatMessage({ id: 'collaborators.accept_request_dialog.title' })}
					confirmation={{
						title: intl.formatMessage({ id: 'collaborators.accept_request_dialog.title' }),
						text: intl.formatMessage({ id: 'collaborators.accept_request_dialog.text' }),
					}}
					action={() =>
						client
							.updateCollaborator({ id: row.user_id, status: CollaboratorStatus.CONFIRMED, user })
							.then(() => fetchAll())
					}
				/>
			),
		},
		{
			headerStyle: ACTION_COLUMN_STYLE,
			supportsMobile: true,
			render: (row: DataRow, intl: IntlShape) => (
				<ActionButton
					icon={<CancelIcon />}
					color="error"
					label={intl.formatMessage({ id: 'collaborators.reject_request_dialog.title' })}
					confirmation={{
						title: intl.formatMessage({ id: 'collaborators.reject_request_dialog.title' }),
						text: intl.formatMessage({ id: 'collaborators.reject_request_dialog.text' }),
					}}
					action={() =>
						client
							.updateCollaborator({ id: row.user_id, status: CollaboratorStatus.DELETED, user })
							.then(() => fetchAll())
					}
				/>
			),
		},
	];

	const currentColumns = [
		{
			displayName: 'Nombre',
			supportsMobile: true,
			render: (row: DataRow, intl: IntlShape) =>
				!!row.user_id ? (
					<span>
						{row.user_name}
						{row.role !== CollaboratorRole.USER && <AdminTag> - Admin</AdminTag>}
					</span>
				) : (
					'-'
				),
		},
		{
			displayName: 'Mail',
			supportsMobile: true,
			render: (row: DataRow) => row.user_email,
		},
		{
			displayName: 'Área',
			render: (row: DataRow, intl: IntlShape) =>
				row.area ? intl.formatMessage({ id: `collaborators.area_column.${row.area}` }) : '-',
		},
		{
			headerStyle: { width: '125px' },
			supportsMobile: true,
			render: (row: DataRow, intl: IntlShape) => (
				<>
					{row.role === CollaboratorRole.USER && (
						<ActionButton
							label={intl.formatMessage({ id: 'collaborators.current.convert_into_admin_button' })}
							confirmation={{
								title: intl.formatMessage({ id: 'collaborators.convert_into_admin.confirmation_dialog.title' }),
								text: intl.formatMessage({ id: 'collaborators.convert_into_admin.confirmation_dialog.text' }),
							}}
							// securityCheck={{
							// 	title: intl.formatMessage({ id: 'collaborators.convert_into_admin.security_check_dialog.title' }),
							// 	text: intl.formatMessage({ id: 'collaborators.convert_into_admin.security_check_dialog.text' }),
							// }}
							// success={{
							// 	title: intl.formatMessage({ id: 'collaborators.convert_into_admin.success_dialog.title' }),
							// 	text: intl.formatMessage({ id: 'collaborators.convert_into_admin.success_dialog.text' }),
							// }}
							action={() =>
								client.updateCollaborator({
									id: row.user_id,
									status: CollaboratorStatus.CONFIRMED,
									role: CollaboratorRole.ADMIN,
									user,
								})
							}
							onSuccess={() => {
								localStorage.setItem(
									'user',
									JSON.stringify({ user: { ...user, role: CollaboratorRole.USER }, error: false })
								);
								window.location.href = window.location.origin;
								// window.location.reload()
							}}
						/>
					)}
					{!row.user_id && (
						<div style={{ textAlign: 'center' }}>
							<span style={{ color: 'orange' }}>Esperando confirmación</span>
						</div>
					)}
				</>
			),
		},
		{
			headerStyle: ACTION_COLUMN_STYLE,
			supportsMobile: true,
			render: (row: DataRow, intl: IntlShape) => (
				<>
					{row.role === CollaboratorRole.USER && (
						<ActionButton
							icon={<DeleteIcon />}
							label={intl.formatMessage({ id: 'collaborators.revoke_access_dialog.title' })}
							confirmation={{
								title: intl.formatMessage({ id: 'collaborators.revoke_access_dialog.title' }),
								text: intl.formatMessage({ id: 'collaborators.revoke_access_dialog.text' }),
							}}
							success={{
								title: intl.formatMessage({ id: 'collaborators.revoke_access_dialog.title' }),
								text: intl.formatMessage({ id: 'collaborators.revoke_access_dialog.success_text' }),
							}}
							action={() => client.updateCollaborator({ id: row.user_id, status: CollaboratorStatus.DELETED, user })}
							onSuccess={() => fetchAll()}
						/>
					)}
					{!row.user_id && (
						<ActionButton
							icon={<DeleteIcon />}
							label={intl.formatMessage({ id: 'collaborators.revoke_invitation_dialog.title' })}
							confirmation={{
								title: intl.formatMessage({ id: 'collaborators.revoke_invitation_dialog.title' }),
								text: intl.formatMessage({ id: 'collaborators.revoke_invitation_dialog.text' }),
							}}
							success={{
								title: intl.formatMessage({ id: 'collaborators.revoke_invitation_dialog.title' }),
								text: intl.formatMessage({ id: 'collaborators.revoke_invitation_dialog.success_text' }),
							}}
							action={() => client.revokeInvitation({ email: row.user_email, user })}
							onSuccess={() => fetchAll()}
						/>
					)}
				</>
			),
		},
	];

	const [requestsResponse, setRequestsResponse] = useState<Response<Collaborator[]>>();
	const [currentResponse, setCurrentResponse] = useState<Response<Collaborator[]>>();

	const hasRequests = useMemo(() => {
		return requestsResponse?.data && requestsResponse?.data.length > 0;
	}, [requestsResponse?.data]);

	const fetchRequests = debounce(() => {
		setRequestsResponse(undefined);

		client
			.listCollaborators({ status: CollaboratorStatus.PENDING, user })
			.then((response) => response.items)
			.then((data) => setRequestsResponse({ data }))
			.catch(() => setRequestsResponse({ error: true }));
	}, 2000);

	const fetchCurrent = debounce(() => {
		setCurrentResponse(undefined);
		Promise.all([
			client.listCollaborators({ status: CollaboratorStatus.CONFIRMED, user }),
			client.invitedCollaborators({ user }),
		])
			.then((response) => {
				const [confirmed, invited] = response;
				const data = [...confirmed.items, ...invited];
				setCurrentResponse({ data });
			})
			.catch(() => setCurrentResponse({ error: true }));
	}, 2000);

	const fetchAll = () => {
		fetchRequests();
		fetchCurrent();
	};

	useEffect(fetchAll, []);
						
	return (
		<>
			<BasePageFrame
				title={intl.formatMessage({ id: 'common.collaborators.title' })}
				transparent
			>
				<Paper
					elevation={0}
					sx={{
						padding: '25px',
						marginTop: '15px',
						width: '100%',
						borderRadius: 2,
						height: '100%',
					}}
				>
					<Grid container spacing={2}>
						{hasRequests && (
							<Grid item>
								<TableCard
									title={intl.formatMessage({ id: 'collaborators.requests.title' })}
									columns={requestsColumns}
									rows={requestsResponse?.data}
									loading={!requestsResponse}
									error={currentResponse?.error}
									emptyMessage={intl.formatMessage({ id: 'collaborators.request.empty_message' })}
									elevation={0}
								/>
								<Divider />
							</Grid>
						)}
						<Grid item>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Typography
									sx={{
										textAlign: 'right',
										color: 'primary.main',
										'&:hover': {
											cursor: 'pointer',
										},
									}}
									component="a"
									onClick={() => setShowAddNewColaboratorDialog(true)}
								>
									{intl.formatMessage({ id: 'collaborators.invite_link' })}
								</Typography>
							</Box>
							<TableCard
								title={''}
								columns={currentColumns}
								rows={currentResponse?.data}
								loading={!currentResponse}
								error={currentResponse?.error}
								emptyMessage={intl.formatMessage({ id: 'collaborators.current.empty_message' })}
								elevation={0}
								infiniteScroll
							/>
						</Grid>
					</Grid>
				</Paper>
			</BasePageFrame>
			<NewCollaboratorDialog
				open={showAddNewCollaboratorDialog}
				onClose={() => setShowAddNewColaboratorDialog(false)}
				collaborators={currentResponse?.data || []}
				user={user}
				openConfirmationDialog={() => setShowInvitationConfirmDialog(true)}
			/>
			<CollaboratorInvitationConfirm
				open={showInvitationConfirmDialog}
				onClose={() => setShowInvitationConfirmDialog(false)}
				onClick={() => fetchAll()}
			/>
		</>
	);
};

export default Collaborators;
