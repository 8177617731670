import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';

interface GenerateTokensButtonProps {
	disabled?: boolean;
	onClick: () => void;
}

//Separar
const GenerateTokensButton = ({ onClick, disabled }: GenerateTokensButtonProps) => {
	const intl = useIntl();

	return (
		<Button
			variant="contained"
			sx={{ py: '15px', borderRadius: '30px', textTransform: 'none', marginTop: {xs: '1rem', md: 0} }}
			startIcon={<AddIcon />}
			onClick={onClick}
			disabled={disabled}
		>
			{intl.formatMessage({ id: 'mytokens.seller.dashboard.generate_tokens_button' })}
		</Button>
	);
};

export default GenerateTokensButton;
