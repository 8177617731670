import React, { Fragment } from 'react'
import { Box, InputLabel, Stack, styled, TextField, Typography } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl';
import DatePicker from '../../../date-picker';
import NuqleaFileUpload from '../../../nuqlea-file-uploader';
import { Delivery, Role } from '../../../../types';
import FileFormPlaceholder from '../../../file-form-placeholder';
import { useRecoilValue } from 'recoil';
import userState from '../../../../atoms/user-state';

interface Props {
  delivery: Delivery;
  modalType: 'new' | 'view' | 'edit';
  error: string;
  setError: (error: string) => void;
	newDeliveryData: Delivery;
	setNewDeliveryData: (data: Delivery) => void;
  addFileToDelete?: (file: any) => void;
}

const DateFilesComments = (props: Props) => {
  const intl = useIntl();
	const user = useRecoilValue(userState);

  const { delivery, modalType, error, setError, newDeliveryData, setNewDeliveryData, addFileToDelete } = props;
  const isEditing = modalType !== 'view';

	const handleFileLoad = (file: File, fileId: string | undefined, id_doc: string, fileIndex: number) => {
		if (file) {
			const FILES_MAX_SIZE = 4;

			if (file.size / 1024 / 1024 >= FILES_MAX_SIZE) {
        setError(intl.formatMessage({ id: 'mytokens.error_message.invalid_size' }, { maxFileSize: '4' }));
				return;
			}

			if (
				file.type !== 'application/pdf' &&
				!file.type.startsWith('image/')
			) {
        setError(intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.file_type' }));
				return;
			}
      const newFiles = newDeliveryData?.files?.map((element, index) => {
        if (index === fileIndex) {
          return { ...element, file, updated: true };
        }
        return element;
      }) ?? [{ file, id_doc, updated: false }];
      setNewDeliveryData({ ...newDeliveryData, files: newFiles });
		} else {
      const newFiles = newDeliveryData?.files?.map((element, index) => {
        if (index === fileIndex) {
          // set as undefined to remove the file, but keep the id_doc
          return { ...element, file: undefined, updated: false };
        }
        return element;
      }) ?? [{ file: undefined, id_doc, updated: false }];

      setNewDeliveryData({ ...newDeliveryData, files: newFiles });
      addFileToDelete?.({ id: fileId, file: null }); // to remove row from redeem_request_files
    }
	};

  if (newDeliveryData?.status === 1) {
    // If the delivery has status pending, dont show this fields
    return null
  }

  return (
    <Stack>
      <InputLabel className='font-black flex mt-5 mb-2'>
        {intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.delivery_date' })}
      </InputLabel>
      <DatePicker
        identifier="delivery-date"
        disabled={!isEditing}
        value={newDeliveryData?.delivery_date ? new Date(newDeliveryData?.delivery_date) : undefined}
        onChange={(value) => {
          setError('');
          setNewDeliveryData({ ...newDeliveryData, delivery_date: value?.toISOString() ?? '' });
        }}
        error={error === intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.delivery_date' })}
      />
      {
        user.type_external === Role.CONSTRUCTOR && !newDeliveryData?.comment ? null :
          <>
            <InputLabel className='font-black flex mt-5 mb-2'>
              {intl.formatMessage({ id: 'list.seller.details.quotation_dialog.comments_field_name' })}
            </InputLabel>
            <TextField
              disabled={!isEditing}
              fullWidth
              multiline
              size="small"
              rows={3}
              placeholder={intl.formatMessage({
                id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.comment_placeholder',
              })}
              value={newDeliveryData?.comment}
              onChange={(event) => {
                setError('');
                setNewDeliveryData({ ...newDeliveryData, comment: event.target.value });
              }}
            />
          </>
      }
      {!isEditing && newDeliveryData?.files && newDeliveryData?.files.length > 0 ?
          newDeliveryData.files.map((file: any) => {
              let adaptedFile = { ...file };
              adaptedFile.path = file.gcs_file_path;
              adaptedFile.name = file.file_name;
              
              return (
                  <Box key={file.id}>
                      <InputLabel className='font-black flex mt-5'>
                        {intl.formatMessage({ id: 'list.seller.details.quotation_dialog.remito_field_name' })}
                      </InputLabel>
                      <TextField
                        className='my-2'
                        disabled={true}
                        fullWidth
                        size="small"
                        value={adaptedFile?.id_doc ?? ""}
                      />
                      <FileFormPlaceholder user={user} file={adaptedFile}></FileFormPlaceholder>
                  </Box>
              );
          })
      : null}
      {!!isEditing && newDeliveryData?.files?.map((element, index: number) => (
        <Fragment key={`file-index:${index}`}>
          <InputLabel className='font-black flex mt-5 mb-2'>
            {intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.file' })}
            {index > 0 && (
              <CloseIcon
                className='cursor-pointer absolute -top-0.5 right-0'
                onClick={() => {
                  setError('');
                  setNewDeliveryData({ ...newDeliveryData, files: newDeliveryData.files?.filter((_, i) => i !== index) });
                  addFileToDelete?.({ ...element, file: null });
                }}
              />
            )}
          </InputLabel>
          <TextField
            className='mb-2'
            disabled={!isEditing}
            fullWidth
            variant="outlined"
            size="small"
            value={element.id_doc}
            onChange={(event) => setNewDeliveryData({
              ...newDeliveryData,
              files: newDeliveryData.files?.map((file, i) => i === index ? { ...file, id_doc: event.target.value } : file)
            })}
            placeholder={intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.enter_file_number_field_name' })}
          />
          <NuqleaFileUpload
            disabled={!isEditing}
            value={element.file ? [element.file] : []}
            onChange={(files) => {
              setError('')
              handleFileLoad(files[0], element.id, element.id_doc, index )}
            }
            buttonText={intl.formatMessage({ id: 'common.upload' })}
            title={intl.formatMessage({ id: 'list.seller.details.deliveries.delivery_dialog.delivered.file_field_placeholder' })}
          />
          {index + 1 === newDeliveryData?.files?.length && (
            <Typography
              color={'primary.main'}
              fontWeight={400}
              className='cursor-pointer mt-2 w-fit'
              onClick={() => setNewDeliveryData({ ...newDeliveryData, files: [...(newDeliveryData.files ?? []), { file: undefined, id_doc: '', updated: false }] })}
            >
              {intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.upload_another_file'})}
            </Typography>
          )}
        </Fragment>
      ))}
    </Stack>
  )
}

export default DateFilesComments