import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	TextField,
	ThemeProvider,
	Typography,
	debounce,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import PageContainer from '../../page-container';
import { FileError, ErrorCode } from 'react-dropzone';
import { useIntl } from 'react-intl';
import InputContainer from './InputContainer';
import FilterTextField from '../../FormTextField';
import { RedeemTokenViewData, Role, ConstructionAddress, RedeemRequestTypes } from '../../../types';
import client from '../../../clients/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
//import DatePicker from '../../date-picker';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EquivalenceDialog from './dialogs/EquivalenceDialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileUpload from 'react-material-file-upload';
import LocationPicker from '../../location-picker';
import { fileToBase64, formatNumberLocale, truncToDecimals } from '../../../utils/data-utils';
import RedeemTokensViewCTADialog from './dialogs/RedeemTokensViewCTADialog';
import RedeemShippingQuotationDialog from '../RedeemShippingQuotationDialog';
import FileViewer from '../../file-viewer';
import SideModalContainer from '../../modals/side-modal-container';
import AddressForm from '../../address-form';
import { useMobile } from '../../../hooks/use-mobile';
import SelectWithPlaceholder from '../../select-with-placeholder';
import TokenRedeemButton from '../tokenRedeemButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import InfoMarker from '../../info-marker';
import { useQuery } from '../../../hooks/use-query';
import { blue } from '@mui/material/colors';
import userState from '../../../atoms/user-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import BasePageFrame from '../../base-components/base-page-frame';

enum DateTypeOptions {
	uniqueDate = 'uniqueDate',
	deliveryCalendar = 'deliveryCalendar',
}

const GoBackBox = styled(Box)`
	position: absolute;
	top: -37px;
	left: 10px;
	cursor: pointer;
	color: grey.600;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	align-items: center;
`;

const FILES_MAX_SIZE = 4;

const FileUploadWrapper = styled('div')<{ errorColor?: string }>(({ theme, errorColor }) => ({
	width: '100%',
	'& > *': {
		border: `2px dashed ${!!errorColor ? errorColor : theme.palette.divider} !important`,
		backgroundColor: theme.palette.background.default,
		borderRadius: 4,
	},
	'& .MuiFormControl-root': {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
		'& .MuiTypography-root': {
			fontSize: '1em',
			paddingTop: 20,
			paddingBottom: 20,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0d6efd',
			fontSize: 30,
			marginBottom: 4,
			marginLeft: 30,
		},
		'& .MuiButtonBase-root': {
			position: 'absolute',
			background: 'transparent',
			color: 'transparent',
			width: '100%',
			height: '12vh',
			borderRadius: 0,
			marginBottom: -8,
			marginLeft: 0,
		},
	},
}));

const downloadFileByAgreementMapper = (aId: string) => {
	const mapper: { [key: string]: string } = {
		'9': '9',
		d9ac3a27: 'd9ac3a278',
		d9ac3a278: 'd9ac3a278',
		'14': 'd9ac3a278',
		'15': '9',
	};
	return mapper[aId];
};

const filesAllowedTypes = [
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
];

const RedeemTokensView = () => {
	const user = useRecoilValue(userState);
	const intl = useIntl();
	const theme = useTheme();
	const lessThanTablet = useMediaQuery(theme.breakpoints.down('md'));
	const isMobile: boolean = useMobile();
	const navigate = useNavigate();
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const { id: redeemRequestId } = useParams();
	let query = useQuery();
	const agreementId = query.get('agreementId');

	const [date, setDate] = useState<Dayjs>(dayjs());
	const [loadingData, setLoadingData] = useState<boolean>(true);
	const [data, setData] = useState<RedeemTokenViewData>();
	const [dateType, setDateType] = useState<string>(DateTypeOptions.uniqueDate);
	const [equivalenceDialog, setEquivalenceDialog] = useState(false);

	const [error, setError] = useState<any[]>([]);
	const [deliveryCalendarFiles, setDeliveryCalendarFiles] = useState<File[]>([]);

	const [showRedeemCTADialog, setShowRedeemCTADialog] = useState(false);
	const [showRedeemCTADialogType, setShowRedeemCTADialogType] = useState<'Accept' | 'Reject'>('Accept');
	const [redeemTokensShippingDialog, setRedeemTokensShippingDialog] = useState(false);

	const [showSideModal, setShowSideModal] = useState(false);
	const [addresses, setAddresses] = useState<{ key: string; label: string }[]>([]);
	//for constructor address key in addresses array, for seller, string
	const [address, setAddress] = useState<{ key: string; label: string } | null>(null);
	const [sellerAddresses, setSellerAddresses] = useState<{ key: string; label: string }[]>([]);
	const [sellerAddress, setSellerAddress] = useState<any>();
	const [loadingAddress, setLoadingAddress] = useState(false);

	const [reedeemableOptions, setReedeemableOptions] = useState<any[]>([]);
	const [selectedReedeemableOptions, setSelectedReedeemableOptions] = useState<any>();

	const tokensToRedeem = useMemo(
		() =>
			!!data
				? data?.products.reduce(
						(prev, curr) =>
							prev + truncToDecimals((curr.quantity || 0) * Number(curr.equivalence), data?.token_decimals),
						0
				  )
				: 0,
		[data]
	);
	const decimalsFromRedeemStep = useMemo(() => {
		if (!data?.redeem_step || Math.floor(data?.redeem_step) === data?.redeem_step) {
			return 0;
		} else {
			return data.redeem_step.toString().split('.')[1].length || 0;
		}
	}, [data]);

	const minDatetoShip = useCallback(
		(minTimeToShip: number | undefined) => {
			if (!!minTimeToShip) {
				return dayjs().add(minTimeToShip + 1, 'day');
			} else return dayjs();
		},
		[data]
	);

	const fetchCreateRedeem = debounce((fields) => {
		client
			.createRedeem({ fields, token: user.token })
			.then((data) => {
				navigate(`/my-tokens/redeem-request/${data.rRId}`);
			})
			.catch((err) => {
				setLoadingData(false);
				console.log({ err, error: true, message: 'Failed to create redeem request' });
			});
	}, 250);

	const validateFilesLoad = (file: File): FileError | null => {
		if (!filesAllowedTypes.includes(file.type)) {
			const message = intl.formatMessage({ id: 'mytokens.error_message.invalid_file_type' });
			return {
				message,
				code: ErrorCode.FileInvalidType,
			};
		}

		if (file.size / 1024 / 1024 >= FILES_MAX_SIZE) {
			const message = intl.formatMessage(
				{ id: 'mytokens.error_message.invalid_size' },
				{ maxFileSize: FILES_MAX_SIZE }
			);
			return {
				message,
				code: ErrorCode.FileTooLarge,
			};
		}

		return null;
	};

	const validateData = () => {
		setError([]);
		let result = true;
		if (selectedReedeemableOptions?.redeemCode === RedeemRequestTypes.SHIPPING && !address?.key) {
			setError((prev) => [
				...prev,
				{ type: `${RedeemRequestTypes.SHIPPING}`, msg: intl.formatMessage({ id: 'common.required_fields' }) },
			]);
			result = false;
		}
		if (dateType === DateTypeOptions.deliveryCalendar && !deliveryCalendarFiles.length) {
			setError((prev) => [
				...prev,
				{ type: `${DateTypeOptions.deliveryCalendar}`, msg: intl.formatMessage({ id: 'common.required_fields' }) },
			]);
			result = false;
		}

		if (
			dateType === DateTypeOptions.uniqueDate &&
			date.isBefore(minDatetoShip(data?.minTimeToShip))
		) {
			setError((prev) => [
				...prev,
				{
					type: `${DateTypeOptions.uniqueDate}`,
					msg: `Por favor, elige una fecha para el canje que sea al menos ${data?.minTimeToShip} días posterior a la fecha de hoy`,
				},
			]);
			result = false;
		}
		return result;
	};

	const handleConstructorClick = () => {
		const validated = validateData();
		if (validated) {
			setError([]);
			const formattedData = {
				token_ids: selectedReedeemableOptions?.token_id,
				products: data?.products,
				stock: tokensToRedeem,
				agreement_condition_id: selectedReedeemableOptions?.condition_id,
				agreement_condition_zone_id: selectedReedeemableOptions?.zone_id,
				address: selectedReedeemableOptions?.redeemCode === RedeemRequestTypes.SHIPPING ? address?.key : null,
				type: selectedReedeemableOptions?.redeemCode,
				agreement_id: agreementId,
				date: dateType === DateTypeOptions.uniqueDate ? date?.toISOString()?.substring(0, 10) : null,
			};

			if (dateType === DateTypeOptions.deliveryCalendar && deliveryCalendarFiles.length) {
				fileToBase64(deliveryCalendarFiles[0])
					.then((file) => {
						const formattedDataWithFile = {
							...formattedData,
							fileName: file.name,
							fileType: file.type,
							fileData: file.base64,
							where: 'Cronograma de entrega',
							// filesFolderId viene implicito en la creacion
							// redeemRequestId viene implicito en la creacion
						};
						fetchCreateRedeem(formattedDataWithFile);
					})
					.catch((error) => console.log(error));
			} else {
				fetchCreateRedeem(formattedData);
			}

			setLoadingData(true);
		} else {
			window.scrollTo(0, 0);
		}
	};

	const handleSellerPrimaryClick = () => {
		// if (data?.type === RedeemRequestTypes.SHIPPING) {
		// 	setRedeemTokensShippingDialog(true);
		// } else {
		setShowRedeemCTADialog(true);
		setShowRedeemCTADialogType('Accept');
		// }
	};

	const handleGoBack = () => {
		navigate('/my-tokens');
	};

	const handlePrimaryClick = () => {
		if (user.type_external === Role.CONSTRUCTOR) {
			handleConstructorClick();
		} else {
			handleSellerPrimaryClick();
		}
	};

	const handleSecondaryClick = () => {
		if (user.type_external === Role.CONSTRUCTOR) {
			navigate(`/my-tokens?tab=exchange-process?&agreement=${agreementId}`);
		} else {
			setShowRedeemCTADialog(true);
			setShowRedeemCTADialogType('Reject');
		}
	};

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDateType(event.target.value);
	};

	const handleCreateAddress = async (address: ConstructionAddress) => {
		if (address) {
			address.organizationId = user.id_external;
		}

		const saveAddress = async () => {
			try {
				setLoadingAddress(true);
				await client.createOrganizationAddress({ data: address, token: user.token });

				const constrAddresses = await client.getOrganizationAddresses({ token: user.token, id: user.id_external });
				const additionalAddresses = constrAddresses.data.map((item: any) => ({
					key: item.id,
					label: `${item.address}, ${item.city}, ${item.province}`,
				}));

				setAddress(additionalAddresses[0] || null);
				setAddresses(additionalAddresses);

				setLoadingAddress(false);
			} catch (error) {
				console.error(error);
			}
		};

		await saveAddress();
		setShowSideModal(false);
	};

	const handleOnCTAPressed = (content?: string) => {
		if (showRedeemCTADialogType === 'Accept') {
			setLoadingData(true);
			client
				.redeemAction({
					fields: {
						redeem_request_id: redeemRequestId,
						action_type: 1,
						comments: content,
						result: 1,
					},
					token: user.token,
				})
				.then(() => {
					setShowRedeemCTADialog(false);
					setLoadingData(false);
					navigate(`/my-tokens/redeem-request/${redeemRequestId}`);
				})
				.catch((err) => {
					setError([intl.formatMessage({ id: 'common.error_message' })]);
				});
		} else {
			setLoadingData(true);
			client
				.redeemAction({
					fields: {
						redeem_request_id: redeemRequestId,
						action_type: 1,
						comments: content,
						result: 2,
					},
					token: user.token,
				})
				.then((data) => {
					navigate(`/my-tokens?tab=exchange-process`);
				})
				.catch((err) => {
					setError([intl.formatMessage({ id: 'common.error_message' })]);
				});
		}
	};

	useEffect(() => {
		// if agreementId is not present, redirect to my-tokens
		if (!agreementId) navigate('/my-tokens');

		const init = async () => {
			if (user.type_external === Role.CONSTRUCTOR) {
				debounce(() => {
					client.fetchAgreementInfo(user.token, agreementId ?? '').then((agreementResponse) => {
						if (agreementResponse.data.length === 0) {
							navigate(`/my-tokens?tab=purchase&agreement=${agreementId}`, {
								state: { message: 'Hubo un error. Intente nuevamente más tarde' },
							});
						} else {
							Promise.all([
								client.fetchAgreementAvailableTokens(user.token, agreementId ?? ''),
								client.fetchAgreementSellerAddress(user.token, agreementId ?? ''),
								client.fetchAgreementProducts(user.token, agreementId ?? ''),
								client.getOrganizationAddresses({ token: user.token, id: user.id_external }),
								client.fetchReedeemableTokens(user.token, agreementId ?? '', user.id_external),
							]).then((responses: any) => {
								const response: RedeemTokenViewData = {
									products: responses[2].data.map((prod: any) => ({ ...prod, quantity: 0 })),
									...agreementResponse.data[0],
									address: [
										...responses[1].address.map((item: any) => ({
											key: item.id,
											label: `${item.Direccion}, ${item.Localidad}`,
										})),
									],
									addresses: [
										...responses[3].data.map((item: any) => ({
											key: item.id,
											label: `${item.address}, ${item.city}, ${item.province}`,
										})),
									],
								};

								const reedemableOptionsObject = responses[4].redeemableTokens;
								setData(response);
								setAddress(response.addresses[0] || null);
								setAddresses(response.addresses);
								setSellerAddresses(response.address);
								setSellerAddress(response.address[0]);
								setLoadingData(false);
								setReedeemableOptions(reedemableOptionsObject);
								reedemableOptionsObject.length && setSelectedReedeemableOptions(reedemableOptionsObject[0]);
								if (!!response.minTimeToShip) {
									const possibleDate = dayjs().add(response.minTimeToShip + 1, 'day');
									if (possibleDate.get('day') === 0) {
										setDate(possibleDate.add(1, 'day'));
									} else if (possibleDate.get('day') === 6) {
										setDate(possibleDate.add(2, 'day'));
									} else {
										setDate(possibleDate);
									}
								}
							});
						}
					});
				}, 250)();
			}

			if (user.type_external === Role.SELLER && redeemRequestId) {
				setLoadingData(true);

				const response: any = await client.fetchSellerRedeemInfo(user.token, redeemRequestId);
				setDate(dayjs(response.redeemRequest.date));

				!response.redeemRequest.date && setDateType(DateTypeOptions.deliveryCalendar);

				if (response.redeemRequest.address.includes('https:')) {
					const url = new URL(response.redeemRequest.address);
					const qParam = url.searchParams.get('q');

					if (!!qParam) {
						const [lat, lng] = qParam.split(',').map(Number);
						const geocoder = new google.maps.Geocoder();

						geocoder
							.geocode({
								location: { lat, lng },
							})
							.then((res) => {
								// setAddress(res.results[0].formatted_address);
							});
					}
				} else if (/^[a-z0-9]{8,10}$/.test(response.redeemRequest.address)) {
					const addressResponse = await client.getSellerAddress({
						token: user.token,
						id: response.redeemRequest.address,
					});
					const sellerAdd = addressResponse.data[0];
					//@ts-ignore
					setAddress(`${sellerAdd.Direccion}, ${sellerAdd.Localidad}`);
				} else {
					setAddress(response.redeemRequest.address);
				}

				const newData: RedeemTokenViewData = {
					products: response.products,
					address: response.redeemRequest.address,
					addresses: [],
					minPurchase: response.redeemRequest.minPurchase,
					unity: response.redeemRequest.unity,
					type: response.redeemRequest.type,
					files: response.files,
					token_decimals: response.redeemRequest.token_decimals,
					redeem_step: response.redeemRequest.redeem_step,
				};
				setData(newData);
				setLoadingData(false);
			}
		};
		init();

		// empty dependencies array to avoid calling get enpoints multiple times
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDisableRootElementScroll(
			showSideModal || equivalenceDialog || showRedeemCTADialog || redeemTokensShippingDialog
		);
	}, [showSideModal, equivalenceDialog, showRedeemCTADialog, redeemTokensShippingDialog]);

	if (loadingData) {
		return (
			<PageContainer>
				<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open>
					<CircularProgress color="inherit" />
				</Backdrop>
			</PageContainer>
		);
	}

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.title' })}
			subtitle={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.subtitle' })}
			returnAction={handleGoBack}
		>
			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={showSideModal}
				onClose={() => setShowSideModal(false)}
				loading={loadingAddress}
			>
				<AddressForm
					columnLayout
					retrieveAddress={handleCreateAddress}
					onCancel={() => setShowSideModal(false)}
				></AddressForm>
			</SideModalContainer>
			<Grid container spacing={2}>
				{error.length !== 0 && (
					<Box
						sx={{
							marginTop: 4,
							background: 'white',
							borderRadius: '8px',
							padding: 2,
							borderLeft: '6px solid red',
							color: 'red',
							display: 'flex',
							alignItems: 'center',
							gap: 2,
							width: '100%',
						}}
					>
						<ErrorOutlineIcon />
						{error[0].msg}
					</Box>
				)}
				<Paper
					elevation={0}
					sx={{
						padding: '30px 45px',
						width: '100%',
						paddingLeft: { xl: '50px' },
						paddingRight: { xl: '50px' },
					}}
				>
					<Grid container>
						<Grid item xs={12} md={6}>
							<Box sx={{ width: '60%' }}>
								<Typography fontSize={'1.4em'} fontWeight={700} variant="inherit" sx={{ marginBottom: '4px' }}>
									{intl.formatMessage({ id: 'mytokens.dashboard.dialog.redeem.request' })}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							{user.type_external !== Role.SELLER && (
								<Typography mb={'10px'} fontWeight={500} color={'grey.600'}>
									{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.select' })}
								</Typography>
							)}
							<Grid container xs={12} rowGap={2}>
								{reedeemableOptions.map((item) => {
									return (
										<>
											<Grid item xs={4}>
												<TokenRedeemButton
													redeemType={item?.redeemCode}
													redeemLocation={
														item?.redeemCode !== 'withdrawal'
															? item.redeemZone
															: intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.onSite' })
													}
													tokenAmount={item.cant}
													onClick={() => setSelectedReedeemableOptions(item)}
													selected={selectedReedeemableOptions === item ? true : false}
												></TokenRedeemButton>
											</Grid>
											<Grid item xs={1}></Grid>
										</>
									);
								})}
								<Grid item xs={12} sx={{ mt: '20px' }}>
									<InputContainer
										titleExtraStyles={{ fontSize: '14px', color: 'grey.600', fontWeight: 500 }}
										title={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.address' })}
									>
										{user.type_external === Role.CONSTRUCTOR &&
											selectedReedeemableOptions?.redeemCode === RedeemRequestTypes.WITHDRAWAL &&
											(sellerAddresses.length > 1 ? (
												<Select
													startAdornment={<LocationOnIcon />}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={sellerAddress?.key || sellerAddresses[0].key}
													onChange={(e) => {
														const foundAddress = sellerAddresses.find((addr) => addr.key === e.target.value);
														setSellerAddress(foundAddress || null);
													}}
												>
													{sellerAddresses.map((address) => {
														return (
															<MenuItem key={address.key} value={address.key}>
																{address.label}
															</MenuItem>
														);
													})}
												</Select>
											) : (
												sellerAddresses.length !== 0 && (
													<FilterTextField
														disabled
														InputProps={{ startAdornment: <LocationOnIcon /> }}
														value={selectedReedeemableOptions.redeemZone}
													/>
												)
											))}
										{user.type_external === Role.CONSTRUCTOR &&
											selectedReedeemableOptions?.redeemCode === RedeemRequestTypes.SHIPPING && (
												<SelectWithPlaceholder
													startAdornment={<LocationOnIcon />}
													fullWidth
													label=""
													sx={{
														py: '8px',
													}}
													value={address?.key || addresses[0]?.key || 0}
													placeholder="Ingrese su direccion"
													onChange={(e) => {
														const foundAddress = addresses.find((addr) => addr.key === e.target.value);
														setAddress(foundAddress || null);
													}}
												>
													{addresses.map((address) => {
														return (
															<MenuItem key={address.key} value={address.key}>
																{address.label}
															</MenuItem>
														);
													})}
													<MenuItem
														onClick={() => {
															setShowSideModal(true);
														}}
													>
														Nueva dirección
													</MenuItem>
												</SelectWithPlaceholder>
											)}
										{user.type_external === Role.SELLER && (
											<FilterTextField value={`${address}`} InputProps={{ readOnly: true }} />
										)}
									</InputContainer>
								</Grid>
								<Grid item xs={12}>
									{user.type_external !== Role.SELLER && selectedReedeemableOptions?.redeemCode !== 'withdrawal' && (
										<InfoMarker
											colorScheme="info"
											message={intl.formatMessage(
												{ id: 'mytokens.redeem.shipping.warning' },
												{ zone: selectedReedeemableOptions?.redeemZone }
											)}
										></InfoMarker>
									)}
								</Grid>
								<Grid item xs={12}>
									<FormControl>
										<Typography color={'grey.600'} fontWeight={500} mb="4px" id="demo-radio-buttons-group-label">
											{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.scheme' })}
										</Typography>

										<RadioGroup
											row
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue={dateType}
											name="radio-buttons-group"
											onChange={handleDateChange}
										>
											<FormControlLabel
												value={DateTypeOptions.uniqueDate}
												control={<Radio />}
												label={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.unique_day' })}
												sx={{
													display:
														user.type_external === Role.SELLER && dateType === DateTypeOptions.deliveryCalendar
															? 'none'
															: 'inline-flex',
												}}
											/>
											<FormControlLabel
												value={DateTypeOptions.deliveryCalendar}
												control={<Radio />}
												label={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.delivery_scheme' })}
												sx={{
													display:
														user.type_external === Role.SELLER && dateType === DateTypeOptions.uniqueDate
															? 'none'
															: 'inline-flex',
												}}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									{DateTypeOptions.uniqueDate === dateType && (
										<InputContainer
											titleExtraStyles={{ fontSize: '14px', color: 'grey.600', fontWeight: 500 }}
											title={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.date' }) + '*'}
										>
											<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
												<DatePicker
													defaultValue={dayjs(date)}
													onChange={(newDate: any) => {
														newDate && setDate(newDate);
													}}
													className="w-50"
													readOnly={user.type_external === Role.SELLER}
													minDate={minDatetoShip(data?.minTimeToShip)}
													shouldDisableDate={(day) => dayjs(day).get('day') === 0 || dayjs(day).get('day') === 6}
												></DatePicker>
											</LocalizationProvider>
										</InputContainer>
									)}
									{user.type_external === Role.CONSTRUCTOR && DateTypeOptions.deliveryCalendar === dateType && (
										<>
											<Box sx={{ display: 'flex', flexDirection: 'horizontal', justifyContent: 'space-between' }}>
												<Typography>Subí tu cronograma de entrega*</Typography>

												<a
													href={`https://storage.googleapis.com/nuqlea-studio-images/templates/Cronograma_entregas_ejemplo_${downloadFileByAgreementMapper(
														agreementId as string
													)}.xlsx`}
													download
												>
													{intl.formatMessage({ id: 'mytokens.dashboard.redeem_request.download_example' })}
												</a>
											</Box>
											<FileUploadWrapper
												errorColor={
													error.findIndex((elm) => elm.type === DateTypeOptions.deliveryCalendar) !== -1
														? 'red'
														: undefined
												}
											>
												<FileUpload
													value={deliveryCalendarFiles}
													onChange={setDeliveryCalendarFiles}
													validator={(file: File) => validateFilesLoad(file)}
													multiple={false}
													buttonText={intl.formatMessage({ id: 'common.upload' })}
													title={intl.formatMessage({ id: 'mytokens.dashboard.upload_file_field_placeholder' })}
													sx={{
														borderRadius: 4,
														mt: 1,
														mb: 0.5,
														width: '100%',
														'& .MuiFormControl-root': {
															display: deliveryCalendarFiles && deliveryCalendarFiles.length ? 'none' : 'flex',
														},
													}}
												/>
											</FileUploadWrapper>
											<Typography variant="caption" sx={{ fontSize: '13px' }}>
												{intl.formatMessage(
													{ id: 'mytokens.dashboard.restrictions' },
													{ minRedeem: data?.minTimeToShip }
												)}
											</Typography>
										</>
									)}
									{user.type_external === Role.SELLER && DateTypeOptions.deliveryCalendar === dateType && (
										<FileViewer
											gcsFilePath={data?.files.gcs_file_path}
											fileId={data?.files.label_tipo_archivo}
											user={user}
											buttonType="link"
											text={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.show_delivery_scheme' })}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Divider orientation="horizontal" sx={{ margin: '3rem 0' }} />
					<Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Grid item xs={12} md={6}>
							<Box>
								<Typography fontSize={'1.4em'} fontWeight={700} variant="inherit" sx={{ marginBottom: 1.4 }}>
									{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.products_title' })}
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Typography sx={{ marginBottom: '4px' }}>
									{intl.formatMessage(
										{ id: 'mytokens.redeem.minimum_units_amount' },
										{ unity: data?.unity, minPurchase: data?.minPurchase }
									)}
								</Typography>
								{!!selectedReedeemableOptions?.cant && (
									<Typography sx={{ marginBottom: '4px' }}>
										{intl.formatMessage(
											{ id: 'mytokens.redeem.available_tokens' },
											{ amount: formatNumberLocale(selectedReedeemableOptions.cant) }
										)}
									</Typography>
								)}
								{user.type_external === Role.CONSTRUCTOR && (
									<>
										<Typography sx={{ marginBottom: '4px' }}>
											{data?.unity === 'pallet'
												? intl.formatMessage({ id: 'mytokens.truck_pallet_capacity' })
												: intl.formatMessage({ id: 'mytokens.truck_weight_capacity' })}
										</Typography>
										<Typography
											sx={{
												cursor: 'pointer',
												marginBottom: '4px',
												color: '#3B74FB',
												fontWeight: 600,
												marginTop: '8px',
											}}
											onClick={() => setEquivalenceDialog(true)}
										>
											{intl.formatMessage({ id: 'mytokens.equivalence' })}
										</Typography>
									</>
								)}
							</Box>
							{user.type_external === Role.CONSTRUCTOR &&
								!!selectedReedeemableOptions?.cant &&
								tokensToRedeem > selectedReedeemableOptions.cant && (
									<Typography variant="body1" sx={{ color: '#ff3030' }}>
										{intl.formatMessage({
											id: 'mytokens.constructor.dashboard.redeem_request.errors.maximum_amount',
										})}
									</Typography>
								)}
							{user.type_external === Role.CONSTRUCTOR &&
								data?.minPurchase &&
								data.products.reduce((prev, curr) => prev + curr.quantity, 0) < data?.minPurchase && (
									<Typography variant="body1" sx={{ color: '#ff3030' }}>
										{intl.formatMessage(
											{ id: 'mytokens.constructor.dashboard.redeem_request.errors.min_amount' },
											{ unity: data?.unity }
										)}
									</Typography>
								)}
						</Grid>
						<Grid item xs={12} md={6}>
							<Grid
								container
								sx={{
									justifyContent: 'space-between',
									alignItems: 'center',
									marginBottom: '10px',
									textTransform: 'capitalize',
								}}
							>
								<Grid item xs={2} md={1}>
									{' '}
									{/** placeholder */}
								</Grid>
								<Grid item xs={5} md={5} sx={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
									<Typography fontSize={'16px'} fontWeight={600} variant="inherit">
										{intl.formatMessage({ id: 'mytokens.redeem.products' })}
									</Typography>
								</Grid>
								<Grid item xs={2} md={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
									<Typography fontSize={'16px'} fontWeight={600} variant="inherit">
										{data?.unity !== 'unidad' ? data?.unity + 's' : data?.unity + 'es'}
									</Typography>
								</Grid>
								<Grid item xs={2} md={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
									<Typography fontSize={'16px'} fontWeight={600} variant="inherit">
										{intl.formatMessage({ id: 'mytokens.redeem.tokens' })}
									</Typography>
								</Grid>
							</Grid>
							{data?.products.map((prod, i) => (
								<Grid
									key={i}
									container
									sx={{ marginBottom: `${!lessThanTablet && '24px'}` }}
									justifyContent="space-between"
									alignItems="center"
								>
									<Grid item xs={2} md={1}>
										<img src={prod.image} alt={prod.name} width="100%" />
									</Grid>
									<Grid item xs={5} md={5}>
										<Typography fontSize={'14px'} fontWeight={500} color={'grey.600'} sx={{ marginBottom: '4px' }}>
											{prod.name}
										</Typography>
									</Grid>
									<Grid item xs={2} md={2} alignItems="baseline">
										{user.type_external === Role.SELLER ? (
											<Typography variant="h5" sx={{ marginBottom: '4px', textAlign: 'center' }}>
												{prod.quantity}
											</Typography>
										) : (
											<Box>
												<TextField
													value={prod.quantity}
													type="number"
													placeholder="0"
													variant="outlined"
													sx={{
														'& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
															{
																'-webkit-appearance': 'none',
																margin: 0,
															},
														'& input[type=number]': {
															'-moz-appearance': 'textfield',
														},
													}}
													inputProps={{
														min: 0,
														step: data?.redeem_step || 1,
														style: { textAlign: 'center', padding: '8px' },
													}}
													onChange={(e) => {
														let updatedValue: number = !!e.target.value
															? truncToDecimals(parseFloat(e.target.value), decimalsFromRedeemStep)
															: 0;
														setData({
															...data,
															products: data?.products.map((prod, j) => ({
																...prod,
																quantity: j === i ? updatedValue : prod.quantity,
															})),
														});
													}}
												/>
											</Box>
										)}
									</Grid>
									<Grid item xs={2} md={2}>
										<Typography variant="body1" color="gray" textAlign="center">{`${
											prod.quantity
												? formatNumberLocale(
														truncToDecimals(Number(prod.equivalence) * prod.quantity, data?.token_decimals)
												  )
												: 0
										} tokens`}</Typography>
									</Grid>
								</Grid>
							))}
							<Grid item xs={12}>
								<Box
									sx={{
										gap: 1,
										alignItems: 'center',
										paddingTop: 2,
										paddingBottom: 2,
										background: blue[50],
										borderRadius: 2,
										margin: '1rem 0',
									}}
								>
									<Grid key={'pallets'} container justifyContent="space-between" alignItems="center" paddingBottom={1}>
										<Grid item xs={2} md={1}>
											<></>
										</Grid>
										<Grid item xs={5} md={5.5}>
											<Typography variant="h5" sx={{ color: '#406AFF', fontWeight: 500 }}>
												{intl.formatMessage(
													{ id: 'mytokens.seller.dashboard.dialog.redeem.total_product_redeem' },
													{ unityName: data?.unity }
												)}
											</Typography>
										</Grid>
										<Grid item xs={2} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
											<Typography variant="h5" sx={{ color: '#406AFF' }}>
												{formatNumberLocale(
													Number(
														data?.products.reduce(
															(prev, curr) => truncToDecimals(prev + curr.quantity, decimalsFromRedeemStep),
															0
														)
													)
												)}
											</Typography>
										</Grid>
										<Grid item xs={2} md={2.5}>
											<></>
										</Grid>
									</Grid>
									<Grid key={'tokens'} container justifyContent="space-between" alignItems="center">
										<Grid item xs={2} md={1}>
											<></>
										</Grid>
										<Grid item xs={5} md={5.5}>
											<Typography fontWeight={500} color={'grey.600'}>
												{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.redeem.total_tokens_redeem' })}
											</Typography>
										</Grid>
										<Grid item xs={2} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
											<Typography fontWeight={600} color={'grey.600'}>
												{formatNumberLocale(tokensToRedeem)}
											</Typography>
										</Grid>
										<Grid item xs={2} md={2.5}>
											<></>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Divider orientation="horizontal" sx={{ margin: '3rem 0' }} />
					<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
						<Button
							sx={{
								bgcolor: 'white',
								border: '1px solid #406AFF',
								borderRadius: '5px',
								color: '#406AFF',
								fontWeight: 500,
								width: '160px',
								justifyContent: 'center',
								'&:hover': {
									backgroundColor: '#3445F2',
									color: '#FFF',
								},
								'&:disabled': {
									color: '#FFF',
									backgroundColor: 'gray',
								},
							}}
							onClick={handleSecondaryClick}
						>
							{user.type_external === Role.CONSTRUCTOR ? 'Cancelar' : 'Rechazar'}
						</Button>
						<Button
							disabled={
								!!loadingData ||
								//@ts-ignore
								data.products.reduce((prev, curr) => prev + curr.quantity, 0) < data?.minPurchase ||
								//@ts-ignore
								(user.type_external === Role.CONSTRUCTOR && tokensToRedeem > selectedReedeemableOptions?.cant) ||
								tokensToRedeem === 0 ||
								!date
							}
							onClick={handlePrimaryClick}
							sx={{
								bgcolor: '#406AFF',
								borderRadius: '5px',
								color: 'white',
								fontWeight: 500,
								width: '160px',
								justifyContent: 'center',
								'&:hover': {
									backgroundColor: '#3445F2',
								},
								'&:disabled': {
									color: '#FFF',
									backgroundColor: 'gray',
								},
							}}
						>
							{user.type_external === Role.CONSTRUCTOR ? 'Canjear' : 'Aceptar'}
						</Button>
					</Box>
				</Paper>
			</Grid>
			<EquivalenceDialog
				open={equivalenceDialog}
				onClose={() => setEquivalenceDialog(false)}
				data={data?.products || []}
				loading={loadingData}
			/>
			<RedeemTokensViewCTADialog
				open={showRedeemCTADialog}
				type={showRedeemCTADialogType}
				tokensToRedeem={tokensToRedeem}
				onCTAPressed={handleOnCTAPressed}
				onClose={() => setShowRedeemCTADialog(false)}
			/>
			<RedeemShippingQuotationDialog
				agreementId={agreementId as string}
				redeemRequestId={redeemRequestId}
				user={user}
				open={redeemTokensShippingDialog}
				onReject={() => setRedeemTokensShippingDialog(false)}
				onClose={() => setRedeemTokensShippingDialog(false)}
			/>
		</BasePageFrame>
	);
};

export default RedeemTokensView;
