import React, { useEffect, useState } from 'react';
import { Modal, Box, Slide, BoxProps, CircularProgress, IconButton, Stack, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SideModalContainerProps extends BoxProps {
	isOpen: boolean;
	children?: React.ReactNode;
	slideDirection?: 'up' | 'right' | 'down' | 'left';
	modalPosition?: 'center' | 'right' | 'left';
	modalContentAlign?: 'top' | 'bottom' | 'center';
	loading?: boolean;
	showCloseIcon?: boolean;
	iconComponent?: React.ReactElement;
	onClose?: () => void;
}

const SideModalContainer: React.FC<SideModalContainerProps> = ({
	isOpen,
	children,
	slideDirection,
	modalPosition,
	modalContentAlign,
	loading,
	showCloseIcon = true,
	iconComponent,
	onClose,
	...props
}) => {
	const [open, setOpen] = useState(isOpen);

	const handleClose = onClose && onClose;

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	const getAlignItems = () => {
		switch (modalContentAlign) {
			case 'top':
				return 'flex-start';
			case 'bottom':
				return 'flex-end';
			case 'center':
				return 'center';
			default:
				return 'normal';
		}
	};

	return (
		<Modal
			sx={{ display: 'flex', justifyContent: modalPosition, alignItems: getAlignItems() }}
			open={open}
			onClose={handleClose}
		>
			<Slide direction={slideDirection} in={open} exit={!open} timeout={500} mountOnEnter unmountOnExit>
				<Paper
					sx={{
						outline: 'none',
						overflow: 'overlay',
						overflowY: 'auto',
						maxHeight: '100%',
						...props.sx,
					}}
				>
					{!!loading && (
						<Box
							sx={{
								position: 'absolute',
								backgroundColor: 'rgba(225, 225, 225, 0.5)',
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
								alignItems: 'center',
								height: '100%',
								zIndex: (theme) => theme.zIndex.drawer + 1000,
							}}
						>
							<CircularProgress color="inherit" />
						</Box>
					)}
					<Stack
						width={'100%'}
						direction={'row'}
						justifyContent={iconComponent && showCloseIcon ? 'space-between' : 'flex-end'}
						alignItems={'center'}
						p={2}
					>
						{iconComponent && <Box>{iconComponent}</Box>}
						{showCloseIcon && (
							<IconButton aria-label="close" onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						)}
					</Stack>
					<Stack pb={'30px'}>{children}</Stack>
				</Paper>
			</Slide>
		</Modal>
	);
};

export default SideModalContainer;
