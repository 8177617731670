import { Button, Grid, Paper, Box, Typography } from '@mui/material';
import { Cta, CtaType, HomeItem } from '../domain/HomeItem';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useMobile } from '../../../hooks/use-mobile';
import { User } from '../../../types';

interface Props {
	items: HomeItem[];
	user: User;
}
const ItemList = (props: Props) => {
	const isMobile = useMobile();
	const navigate = useNavigate();
	const onclickCta = (cta: Cta) => () => {
		const { type, link, gtm_variable } = cta;
		
		if(gtm_variable && globalThis.location.hostname === 'studio.nuqlea.com') {
			(globalThis as any).dataLayer?.push({
				"event": gtm_variable,
				"value": {
					"user": {
                        "user_id": props.user?.id,
						"user_email": props.user?.email,
						"type_external": props.user?.type_external,
						"id_external": props.user?.id_external,
						"name_external": props.user?.name_external,
						"role": props.user?.role,
						"environment": props.user?.enviroment
                    }
				}
			})
		}

		if (type === CtaType.navigation) {
			navigate(link);
		}
		if (type === CtaType.redirection) {
			navigate(link, { replace: true });
		}
	};
	return (
		<Grid item xs={12}>
			<Box sx={{ 
				display: 'flex', 
				justifyContent: 'space-between', 
				flexDirection: { xs: 'column', sm: 'row'},
				gap: { xs: 2, sm: 4 }
			}}>
				{props.items.map((item, index) => (
					<Grid item sm={5.8} xs={12} key={item.title}>
						<Paper
							elevation={3}
							onClick={onclickCta(item.cta)}
							sx={{
								':hover': {
									cursor: 'pointer',
									transform: 'translateY(-6px)',
								},
								transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
								px: '2em',
								py: '1em',
								height: '215px',
								borderRadius: 2.8,
								position: 'relative'
						}}>
							<Box sx={{ 
								display: 'flex',
								height: '100%',
								flexDirection: { md: 'row', xs: 'column' },
								justifyContent: {
									md: 'space-between',
									xs: 'start'
								}
							}}>
								<Box sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									flex: 3,
									pt: 1
								}}>
									<Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
										<Typography
											sx={{
												fontSize: '1.3em',
												fontWeight: 700,
												lineHeight: '1.3em',
												letterSpacing: '-0.02em',
												color: '#000',
												maxWidth: {
													md: '200px',
													xs: '100%',
												}
											}}
										>
											{item.title}
										</Typography>
										<Typography
											variant="body1"
											sx={{
												fontSize: { lg: '1.1em', xs: '1em'},
												lineHeight: { md: '1.3em', xs: '1.1em' },
												fontWeight: 400,
												letterSpacing: '-0.02em',
												color: '#72727E',
												opacity: '78%',
												maxWidth: {
													xl: '300px',
													xs: '100%',
												},
												mt: 1
											}}
										>
											{item.description}
										</Typography>
									</Box>

									<Button
										variant="text"
										endIcon={<EastIcon />}
										sx={{
											borderRadius: '30px',
											color: '#406AFF',
											width: {
												md:'fit-content',
												xs: '100%'
											},
											my: {
												md: 0,
												xs: '1em'
											},
											px: 1,
											ml: -1,
											fontSize: {
												md:'1.16em',
												xs: '1.3em'
											},
											'&:hover': {
												backgroundColor: 'transparent'
											},
											lineHeight: '1em',
											letterSpacing: '-0.02em',
											justifyContent: 'start',
											textTransform: 'none',
											textAlign: 'left'
										}}
										size="large"
										onClick={onclickCta(item.cta)}
									>
										{item.cta.label}
									</Button>
								</Box>
								{!isMobile && (
									<Box sx={{
										display: 'flex',
										flex: 2,
										justifyContent: 'end',
										maxWidth: {xs: '200px', md: 'none'},
										alignSelf: 'center'
									}}>
										<img style={{ objectFit: 'contain', width: index === 0 ? '78%' : '100%' }} src={item.img} alt="" />
									</Box>
								)}
							</Box>
						</Paper>
					</Grid>
				))}
			</Box>
		</Grid>
	);
};

export default ItemList;
