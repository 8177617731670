import { Divider, Grid, Stack, StackProps, Typography } from '@mui/material';
import { StackedData } from '../../types';

interface stackDataDisplayProps extends StackProps {
	title?: string;
	data?: StackedData[];
}

const StackDataDisplay: React.FC<stackDataDisplayProps> = ({ title, data, ...props }) => {
	return (
		<Stack {...props}>
			{title && <Typography className="pr-20 font-extrabold">{title}</Typography>}
			{data?.map((item, index) => (
				<Grid container flexGrow={1} key={item.title}>
					{(index > 0 || title) && (
						<Grid item xs={12}>
							<Divider sx={{ my: '0.9rem' }} orientation="horizontal"></Divider>
						</Grid>
					)}

					<Grid container>
						<Grid item xs={7}>
							{item.objectTitle ? (
								item.objectTitle
							) : (
								<Typography pr={'5rem'} color="grey.600" fontWeight={600}>
									{item.title}
								</Typography>
							)}
						</Grid>
						<Grid item xs={5}>
							{item.objectDesc ? (
								item.objectDesc
							) : (
								<Typography variant="body2" whiteSpace={'pre-line'}>
									{item.description}
								</Typography>
							)}
						</Grid>

						{item.extraFullRow && (
							<Grid pt={'0.7rem'} item xs={12}>
								{item.extraFullRow}
							</Grid>
						)}
					</Grid>
				</Grid>
			))}
		</Stack>
	);
};

export default StackDataDisplay;
