import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useIntl } from 'react-intl';
import { useEffect, useState, useRef } from 'react';

interface Props {
    open: boolean;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(1),
    borderRadius: '10px',
    minHeight: '80vh',
    width: '100%',
    overflow: 'hidden',
  },
  '& .MuiDialogContent-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 0,
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '100%',
    '& iframe': {
      position: 'absolute',
      width: '100%',
      height: '200vh',
      overflowX: 'hidden',
      top: '-800px',
    }
  },
  
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    marginRight: '10px'
  },
  '& .MuiTypography-root': {
    paddingTop: '0'
  },
}));

const container = (title: string, text: string, color: string) => (
  <Box>
    <Typography sx={{ color, fontSize: '1rem' }}>{title}</Typography>
    <Typography sx={{ fontSize: '0.8rem' }}>{text}</Typography>
  </Box>
);

const HelpDialogArchbee = (props: Props) => {
  const {open, onClose} = props
  const intl = useIntl();
  const helpRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (  
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {loading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="inherit" />
          </Box>
        )}
        <DialogTitle sx={{ alignSelf: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: 'bold', paddingBottom: 0, marginTop: '10px' }} >
            {intl.formatMessage({ id: "help_dialog.title" })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ height: '100vh', overflow: 'hidden', position: 'relative' }}>
            <iframe
              onLoad={handleIframeLoad}
              src="https://app.archbee.com/public/PREVIEW-jjWxVRQ0YKJnEvGltRhiV"
              width="100%"
              frameBorder={0}
              height="100%"
            />
          </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '25%' }} variant="contained">
                {intl.formatMessage({ id: 'common.understood' })}
            </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default HelpDialogArchbee;