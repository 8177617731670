import { Box, InputAdornment, Tooltip, TextField, Typography } from '@mui/material';
import { AudienceUser } from '../AudiencesService';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Column, PaginatableTable } from '../../table-paginatable';
import SearchIcon from '@mui/icons-material/Search';

export interface AudienceUserComponentProps {
    users: AudienceUser[]
    excludeds?: boolean | false
}

enum OriginBackgroundColor {
    seller = '#E26B2E1A',
    nuqlea = '#406AFF1A'
}

enum OriginColor {
    seller = '#E26B2E',
    nuqlea = '#406AFF'
}

const formatAudienceUser = (user: AudienceUser) => {
    return {
        name_constructora: <Tooltip placement="top" disableFocusListener title={user.name_constructora} arrow>
            <Box>{user.name_constructora}</Box>
        </Tooltip>,
        origin: <Box
            component="span"
            sx={{
                backgroundColor: OriginBackgroundColor[user.origin.toLowerCase() as keyof typeof OriginColor],
                color: OriginColor[user.origin.toLowerCase() as keyof typeof OriginColor],
                padding: '4px 36px',
                borderRadius: '4px',
                textTransform: 'capitalize'
            }}
        >{
            user?.origin?.toLowerCase() === 'seller' ?
                (user?.seller_name && user?.seller_name?.length > 20) ? 
                    <Tooltip placement="top" disableFocusListener title={user?.seller_name} arrow>
                        <span>{user?.seller_name}</span>
                    </Tooltip> :
                (user?.seller_name || 'Seller') :
            user?.origin
        }</Box>,
        cellBorder: false
    }
}

const formatAudienceUsers = (users: AudienceUser[]) => {
    return users.map((user) => formatAudienceUser(user));
}

const AudienceUsersComponent: React.FC<AudienceUserComponentProps> = ({ users, excludeds }) => {
    const intl = useIntl();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [formattedData, setFormattedData] = useState<any[]>(formatAudienceUsers(users));

    const filteredData = users.filter(user =>
        user.name_constructora.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.origin.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns: Column[] = [
        { id: 'name_constructora', label: intl.formatMessage({ id: 'audiences.details.users_table.columns.name' }), width: '50%', align: 'left' },
        { id: 'origin', label: intl.formatMessage({ id: 'audiences.details.users_table.columns.origin' }), width: '50%', align: 'center' },
    ];

    useEffect(() => {
        if (!searchTerm) {
            setFormattedData(formatAudienceUsers(users));
            return;
        }

        setFormattedData(formatAudienceUsers(filteredData));
    }, [searchTerm, users]);

    return (
        <Box>
            <Typography variant="h6">
                {intl.formatMessage({ id: excludeds ? 'audiences.details.excluded_users_title' : 'audiences.details.users_title' })} ({users.length})
            </Typography>
            <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder={intl.formatMessage({ id: 'audiences.details.search_placeholder' })}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Box maxHeight={'300px'} overflow={'auto'}>
                <PaginatableTable
                    columns={columns}
                    noResultsMessage={intl.formatMessage({ id: 'common.table.no_data' })}
                    isEmptyDataSource={filteredData.length === 0}
                    rows={formattedData}
                    emptyDataSourceMessage={intl.formatMessage({ id: 'common.table.no_data' })}
                    disablePagination={true}
                    rowsPerPage={users.length}
                    orderBy='name_constructora'
                    orderByDirection='asc'
                />
            </Box>
        </Box>
    );
}

export default AudienceUsersComponent;