import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { useEffect, useState, useRef } from 'react';
import welcomeVideo from '../../../assets/videos/comments_welcome_new.mp4';
import { User, Comment, Features, FeatureNames } from '../../../types';
import {
	Button,
	Alert,
	Popover,
	LinearProgress,
	Typography,
	Box,
	IconButton,
	CircularProgress,
	InputBase,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';
import EmojiPicker from 'emoji-picker-react';
import { EmojiStyle } from 'emoji-picker-react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import client from '../../../clients/client';
import EmptyStatesClient from '../../../clients/EmptyStatesClient';
import Drawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

interface Props {
	open: boolean;
	listId?: string;
	orderId?: string;
	user: User;
	onClose: (event?: object | null, reason?: string) => void;
	onReadComments: () => void;
}

enum Categories {
	SUGGESTED = 'suggested',
	SMILEYS_PEOPLE = 'smileys_people',
	ANIMALS_NATURE = 'animals_nature',
	FOOD_DRINK = 'food_drink',
	TRAVEL_PLACES = 'travel_places',
	ACTIVITIES = 'activities',
	OBJECTS = 'objects',
	SYMBOLS = 'symbols',
	FLAGS = 'flags',
}

const ListComments = (props: Props) => {
	const intl = useIntl();
	const { open, listId, orderId, user, onClose, onReadComments } = props;

	const videoEl = useRef<HTMLVideoElement>(null);
	const bottomEl = useRef<HTMLElement>(null);
	const textInput = useRef<HTMLInputElement>(null);
	const [firstTime, setFirstTime] = useState<boolean>();
	const [sendingMessage, setSendingMessage] = useState<boolean>(true);
	const [error, setError] = useState<string>();
	const [comments, setComments] = useState<any>();
	const [newComment, setNewComment] = useState<string>('');

	const scrollToBottom = () => {
		bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const handleSubmit = async () => {
		await EmptyStatesClient.markAsviewed(Features.EMPTY_STATE_QUOTATION_COMMENTS, props.user, props.user.token);
		setFirstTime(false);
		getComments();
	};

	const attemptPlay = () => {
		videoEl &&
			videoEl.current &&
			videoEl.current.play().catch((error) => {
				console.error('Error attempting to play', error);
			});
	};

	const sendMessage = () => {
		if (newComment) {
			setSendingMessage(true);
			client
				.sendListComment({ listId, orderId, message: newComment, user })
				.catch((error) => {
					setError(error.message);
					setSendingMessage(false);
				})
				.finally(() => getComments());
		}
	};

	const addEmojiToMessage = (emoji: any) => {
		if (newComment) {
			const withEmoji = newComment + ' ' + emoji.emoji;
			setNewComment(withEmoji);
		} else {
			setNewComment(emoji.emoji);
		}
	};

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			sendMessage();
		}
	};

	const getComments = () => {
		setSendingMessage(true);

		client
			.getListComments({ listId, orderId, user })
			.then((response) => setComments(response.comments))
			.catch((error) => setError(error.message))
			.finally(() => setSendingMessage(false));

		setNewComment('');
		if (textInput.current && textInput.current.value) textInput.current.value = '';
		textInput?.current?.focus();
	};

	const checkReads = () => {
		const allUnreads = comments
			?.filter((comm: Comment) => (comm.read_at ? null : comm.id))
			.map((comm: Comment) => comm.id);
		if (allUnreads?.length > 0) {
			client.setAsReadListComment({ comment_ids: allUnreads, user }).then((response) => onReadComments());
		}
	};

	useEffect(() => {
		if (open) {
			const pageViewed = EmptyStatesClient.pageHasAlreadyViewed(FeatureNames.QUOTATION_COMMENTS, props.user);

			if (!pageViewed) {
				EmptyStatesClient.markAsviewed(Features.EMPTY_STATE_QUOTATION_COMMENTS, props.user, props.user.token);
				setFirstTime(true);
				setSendingMessage(false);
			} else {
				setFirstTime(false);
				if (!comments) {
					getComments();
				} else {
					textInput?.current?.focus();
					setTimeout(() => scrollToBottom(), 10);
				}
			}

			setError(undefined);
		}
	}, [open]);

	useEffect(() => {
		if (firstTime) {
			attemptPlay();
		}
	}, [firstTime]);

	useEffect(() => {
		if (open) {
			checkReads();
			scrollToBottom();
		}
	}, [comments]);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					width: {
						xs: '100%',
						md: '429px',
					},
					maxWidth: 'md',
					borderRadius: '0px',
					p: 1,
				},
			}}
		>
			{sendingMessage && (
				<Box sx={{ width: '97%' }}>
					<LinearProgress color="inherit" />
				</Box>
			)}
			<DialogTitle
				sx={{
					px: 4,
					pt: 1.6,
					fontFamily: 'Inter',
					fontSize: '16px',
					fontWeight: 800,
					letterSpacing: '-0.01em',
					textAlign: 'left',
					borderBottom: 'none',
					color: 'black',
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
						<Typography
							variant="h4"
							textAlign="start"
							fontWeight="bold"
							color="black"
							sx={{
								fontSize: { md: '1.1em', xs: '1em' },
								mt: 1,
							}}
						>
							{intl.formatMessage({ id: 'list.details.list_comments.dialog.title' })}
						</Typography>
						{firstTime && (
							<Typography textAlign="start" sx={{ fontSize: '1em', mt: 1 }} fontWeight="bold" color="black">
								{intl.formatMessage({ id: 'list.details.list_comments.dialog.welcome_subtitle' })}
							</Typography>
						)}
					</Box>
					<IconButton
						aria-label="close"
						onClick={onClose}
						disabled={sendingMessage}
						sx={{
							color: 'black',
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '80vh',
					paddingBottom: 2,
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: comments && comments.length > 0 ? 'start' : 'center',
					}}
				>
					{firstTime && (
						<Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
							<video
								ref={videoEl}
								style={{
									width: '100%',
									margin: '0 auto',
									border: '1px solid #000',
									borderRadius: '0px',
									backgroundColor: '#FFF',
									background: '#FFF',
									maxHeight: '380px',
									clipPath: 'inset(1% 20%)',
								}}
								playsInline
								loop
								muted
								controls={false}
								src={welcomeVideo}
							/>
						</Box>
					)}
					{!firstTime && comments && comments.length > 0 && (
						<Timeline
							sx={{
								height: '100%',
								mt: 0,
								[`& .${timelineItemClasses.root}:before`]: {
									flex: 0,
									padding: 0,
								},
							}}
						>
							{comments?.map((comment: any) => {
								return (
									<Box key={comment.id}>
										<TimelineItem>
											<TimelineContent sx={{ pt: { md: 2.2, xs: 1.8 }, pb: 2 }}>
												<Typography
													fontSize={{ md: '1.1em', xs: '.88em' }}
													sx={{ color: (theme) => theme.palette.text.secondary, ml: 1 }}
												>
													{intl.formatMessage({ id: 'list.details.list_comments.dialog.from_line_text' })}
													<strong>{comment.created_by}</strong>{' '}
												</Typography>
												<Typography
													sx={{
														mt: 1,
														fontSize: '.9em',
														p: 1.5,
														border: '1px solid #ececec',
														borderRadius: 3,
														wordBreak: 'break-word',
														color: (theme) => theme.palette.text.secondary,
														backgroundColor: comment.origin === 'operation' ? '#ececfc' : 'transparent',
													}}
												>
													{comment.message}
												</Typography>
												<Typography
													fontSize={{ md: '1em', xs: '.88em' }}
													sx={{ display: 'flex', justifyContent: 'right', textAlign: 'right' }}
												>
													<Box component="span" sx={{ color: (theme) => theme.palette.text.secondary }}>
														<small>
															{dayjs(comment.created_at).locale('es').format('DD/MM/YYYY HH:mm[hs]')}
														</small>
													</Box>
												</Typography>
											</TimelineContent>
										</TimelineItem>
									</Box>
								);
							})}
							<Box ref={bottomEl}></Box>
						</Timeline>
					)}
					{!firstTime && sendingMessage && (!comments || comments.length === 0) && (
						<Box
							sx={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography>
								{intl.formatMessage({ id: 'list.details.list_comments.dialog.loading_comments' })}
							</Typography>
						</Box>
					)}
					{!firstTime && !sendingMessage && (!comments || comments.length === 0) && (
						<Box
							sx={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'start',
								p: 2,
							}}
						>
							<Typography
								variant="h5"
								sx={{
									fontFamily: 'Inter',
									fontSize: '16px',
									fontWeight: 700,
									letterSpacing: '-0.01em',
									maxWidth: '360px',
									textAlign: 'center',
									color: (theme) => theme.palette.text.secondary,
								}}
							>
								{intl.formatMessage({ id: 'list.details.list_comments.dialog.empty_state' })}
							</Typography>
						</Box>
					)}
				</Box>

				{!firstTime && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
							px: 4,
							pb: 2,
						}}
					>
						<Box
							sx={{
								border: (theme) => `1px solid ${theme.palette.text.secondary}`,
								px: { md: 2, xs: 1 },
								py: 0,
								borderRadius: '10px',
								width: '100%',
								display: 'flex',
							}}
						>
							<InputBase
								sx={{
									pr: { md: 2, xs: 1 },
									width: '100%',
								}}
								placeholder="Agregar un comentario..."
								fullWidth
								autoFocus={true}
								inputRef={textInput}
								onKeyDown={handleKeyDown}
								value={newComment}
								onChange={(e: any) => setNewComment(e.target.value)}
								disabled={sendingMessage}
							/>
							{!sendingMessage && (
								<>
									<PopupState variant="popover" popupId="emoji-popup">
										{(popupState: any) => (
											<Box>
												<IconButton
													{...bindTrigger(popupState)}
													sx={{
														width: { md: 38, xs: 24 },
														'& .MuiSvgIcon-root': {
															width: { md: '1em', xs: 18 },
														},
														color: (theme) => theme.palette.grey[500],
													}}
												>
													<InsertEmoticonIcon />
												</IconButton>
												<Popover
													{...bindPopover(popupState)}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'center',
													}}
													transformOrigin={{
														vertical: 'bottom',
														horizontal: 'center',
													}}
												>
													<EmojiPicker
														lazyLoadEmojis={true}
														emojiStyle={EmojiStyle.NATIVE}
														onEmojiClick={addEmojiToMessage}
														searchPlaceHolder="Buscar emoji..."
														skinTonesDisabled={true}
														previewConfig={{ showPreview: false }}
														categories={[
															{
																category: Categories.SUGGESTED,
																name: 'Más usados',
															},
															{
																category: Categories.SMILEYS_PEOPLE,
																name: 'Caras & Gente',
															},
															{
																category: Categories.ANIMALS_NATURE,
																name: 'Animales & Naturaleza',
															},
															{
																category: Categories.FOOD_DRINK,
																name: 'Comidas & Bebidas',
															},
															{
																category: Categories.TRAVEL_PLACES,
																name: 'Viajes & Lugares',
															},
															{
																category: Categories.ACTIVITIES,
																name: 'Actividades',
															},
															{
																category: Categories.OBJECTS,
																name: 'Objetos',
															},
															{
																category: Categories.SYMBOLS,
																name: 'Símbolos',
															},
															{
																category: Categories.FLAGS,
																name: 'Banderas',
															},
														]}
													/>
												</Popover>
											</Box>
										)}
									</PopupState>
									<IconButton
										onClick={sendMessage}
										sx={{
											width: { md: 38, xs: 24 },
											'& .MuiSvgIcon-root': {
												width: { md: '1em', xs: 18 },
											},
											color: '#0000FF',
										}}
									>
										<SendIcon />
									</IconButton>
								</>
							)}
							{sendingMessage && (
								<Box sx={{ py: 0.4 }}>
									<CircularProgress
										sx={{ color: (theme) => theme.palette.text.secondary, ml: 1.75, mr: 3 }}
										size={25}
									/>
								</Box>
							)}
						</Box>
					</Box>
				)}
				{firstTime && (
					<Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: '16px', marginRight: '20px' }}>
						<Button
							onClick={handleSubmit}
							variant="contained"
							sx={{
								background: '#000',
								color: '#FFF',
								border: (theme) => `1px solid ${theme.palette.text.secondary}`,
								borderRadius: 10,
								width: '120px',
							}}
						>
							{intl.formatMessage({ id: 'common.continue' })}
						</Button>
					</Box>
				)}
				{error && <Alert severity="error">{intl.formatMessage({ id: 'common.loading_error_message.details' })}</Alert>}
			</DialogContent>
		</Drawer>
	);
};

export default ListComments;
