import { Box, Button, Grid, MenuItem, Typography } from "@mui/material"
import { GridBox, NextButton, SeparatedLabel, StyledAlert2, StyledTextField, TypographyTitle } from "../styles"
import { useIntl } from 'react-intl';
import InformationBar from "../../information-bar";
import { SignUpStep } from "../interfaces";
import { areErrorsPresent } from "../utils";
import { categoryTypes } from "../constants";


const SellerCreationComponent = ({ formData, formErrors, handleInputChange, loading, handleRestartFlow, handleChangeStep }: any) => {
	const intl = useIntl();

    return (
        <>
            <TypographyTitle variant="h5">{intl.formatMessage({ id: 'auth.signup.user.title' })}</TypographyTitle>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', mb: 3 }}>
                <StyledAlert2
                    severity="info"
                    sx={{
                        textAlign: 'left',
                        fontSize: '14px',
                        px: 9,
                        color: '#222',
                        borderRadius: 3,
                        '& .MuiAlert-icon': { display: 'none' },
                    }}
                >
                    {intl.formatMessage({ id: 'auth.signup.seller.info_message' }, { br: <br /> })}
                </StyledAlert2>
            </Box>
            {areErrorsPresent(SignUpStep.SELLER_CREATION, formErrors) && (
                <InformationBar
                    sx={{ mb: '15px' }}
                    icon="error"
                    message={intl.formatMessage({ id: 'common.generic_error_fields' })}
                    color="#FF3D00"
                ></InformationBar>
            )}
            <GridBox>
                <Grid container spacing={16}>
                    <Grid item xs={12} md={5}>
                        <Box>
                            <Typography color={'black'} fontWeight={700} fontSize={'16px'} marginBottom={1}>
                                {intl.formatMessage({ id: 'auth.signup.creation.business_data' })}
                            </Typography>
                            <Typography fontWeight={400} fontSize={'14px'} color={'#72727E'}>
                                {intl.formatMessage({ id: 'common.required_fields' })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid id="noPaddingGrid" item xs={12} md={7}>
                        <SeparatedLabel sx={{ marginTop: 0 }}>
                            {intl.formatMessage({ id: 'auth.signup.category' })}
                        </SeparatedLabel>
                        <StyledTextField
                            select
                            name="category"
                            size="small"
                            fullWidth
                            label={intl.formatMessage({ id: 'auth.signup.category.placeholder' })}
                            InputLabelProps={{
                                style: { color: '#AAA' },
                            }}
                            value={formData.category}
                            onChange={handleInputChange}
                            required
                            error={!!formErrors.category}
                            helperText={formErrors.category}
                        >
                            {categoryTypes.map((catType) => (
                                <MenuItem key={catType.id} value={catType.value}>
                                    {catType.label}
                                </MenuItem>
                            ))}
                        </StyledTextField>
                    </Grid>
                    <Grid id="noPaddingDownGrid" item xs={12}>
                        <Box sx={{ borderTop: 'solid 1px #D9D9D9'}} />
                        <Grid container spacing={16} sx={{ paddingTop: '10px' }}>
                            <Grid item xs={12} md={5}>
                                <Box>
                                    <Typography color={'black'} fontWeight={700} fontSize={'16px'} marginBottom={1}>
                                        {intl.formatMessage({ id: 'auth.signup.user.title' })}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid id="noPaddingGrid" item xs={12} md={7}>
                                <SeparatedLabel sx={{ marginTop: 0 }}>
                                    {intl.formatMessage({ id: 'auth.signup.full_name' })}
                                </SeparatedLabel>
                                <StyledTextField
                                    name="fullName"
                                    size="small"
                                    fullWidth
                                    placeholder={intl.formatMessage({
                                        id: 'auth.signup.full_name.placeholder',
                                    })}
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    required
                                    error={!!formErrors.fullName}
                                    helperText={formErrors.fullName}
                                />
                                <SeparatedLabel>
                                    {intl.formatMessage({ id: 'auth.signup.phone' })}
                                </SeparatedLabel>
                                <StyledTextField
                                    name="phone"
                                    InputProps={{
                                        inputProps: { min: 0 },
                                    }}
                                    size="small"
                                    fullWidth
                                    placeholder={intl.formatMessage({
                                        id: 'auth.signup.phone.placeholder',
                                    })}
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                    error={!!formErrors.phone}
                                    helperText={formErrors.phone}
                                />
                                <SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.mail' })}</SeparatedLabel>
                                <StyledTextField
                                    name="mail"
                                    size="small"
                                    fullWidth
                                    placeholder={intl.formatMessage({
                                        id: 'auth.signup.mail.placeholder',
                                    })}
                                    value={formData.mail}
                                    onChange={handleInputChange}
                                    required
                                    error={!!formErrors.mail}
                                    helperText={formErrors.mail}
                                />
                                <SeparatedLabel>{intl.formatMessage({ id: 'auth.signup.role' })}</SeparatedLabel>
                                <StyledTextField
                                    name="role"
                                    size="small"
                                    fullWidth
                                    placeholder={intl.formatMessage({
                                        id: 'auth.signup.role.placeholder',
                                    })}
                                    value={formData.role}
                                    onChange={handleInputChange}
                                    required
                                    error={!!formErrors.role}
                                    helperText={formErrors.role}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid id="noPaddingDownGrid" item xs={12}>
                        <Box sx={{ borderTop: 'solid 1px #D9D9D9'}} />
                        <Grid container spacing={16}>
                            <Grid item xs={0} md={5}></Grid>
                            <Grid id="noPaddingGrid" item xs={12} md={7} >
                                <Box sx={{ paddingTop: {xs: '0px', md: '22px' } }}>
                                    <Button
                                        onClick={() => handleRestartFlow(SignUpStep.ACCOUNT_CHECK)}
                                        variant="contained"
                                        sx={{
                                            background: '#FFF',
                                            border: '1.5px solid #406AFF',
                                            color: '#406AFF',
                                            borderRadius: '4px',
                                            width: { xs: '40%', md: '120px' },
                                            mr: '10px',
                                            textTransform: 'none',
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'common.back_to_login' })}
                                    </Button>
                                    <NextButton loading={loading} loadingPosition="start" onClick={handleChangeStep} variant="contained">
                                        {intl.formatMessage({ id: 'common.continue' })}
                                    </NextButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </GridBox>
        </>
    )
    
}

export default SellerCreationComponent;
