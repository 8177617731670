import { ThemeOptions, createTheme } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#aaaaaa',
    },
  },
  typography: {
    fontSize: 13,
  },
};

export default createTheme(themeOptions);