import { Button, Dialog, DialogContent, DialogActions, IconButton, DialogTitle, Alert } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';

interface Props {
	errorMessage: string | null;
	open: boolean;
	onClose: () => void;
}

const PurchaseErrorDialog = (props: Props) => {
	const intl = useIntl();
	const { errorMessage, open, onClose } = props;

	return (
		<Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
			<IconButton aria-label="close" onClick={onClose} className="absolute right-12 top-12">
				<CloseIcon />
			</IconButton>
			<DialogTitle>{intl.formatMessage({ id: 'buy_tokens.purchase_error.title' })}</DialogTitle>
			<DialogContent sx={{ display: 'flex', padding: { xs: 'unset', sm: '3.5rem' } }}>
				<Alert severity="error">{errorMessage}</Alert>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: 'center',
				}}
			>
				<Button
					onClick={onClose}
					sx={{
						width: '10rem',
					}}
					variant="outlined"
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PurchaseErrorDialog;
