import React, { ReactNode } from 'react';
import { Box, Input, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

interface SelectWithPlaceholderProps extends SelectProps {
	label: string;
	children: ReactNode;
	placeholder: string;
}

const SelectWithPlaceholder: React.FC<SelectWithPlaceholderProps> = ({ label, children, placeholder, ...props }) => {
	return (
		<Box>
			<InputLabel>{props.required ? label + '*' : label}</InputLabel>
			<Select
				{...props}
				size={props.size ? props.size : 'small'}
				sx={{
					...props.sx,
					color: placeholder && props.value === '0' ? '#81818181' : 'inherit',
					...(props.error && {
						'& .MuiOutlinedInput-input': {
							border: '2px solid #FF3D00',
						},
					}),
				}}
				MenuProps={{
					sx: {
						'&& .Mui-selected': {
							backgroundColor: '#eaeaea',
						},
						'&& .Mui-selected:hover': {
							backgroundColor: '#a5a5a5',
						},
					},
				}}
			>
				<MenuItem value="0" disabled>
					{placeholder}
				</MenuItem>
				{children}
			</Select>
		</Box>
	);
};

export default SelectWithPlaceholder;
