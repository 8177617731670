import { useNavigate } from 'react-router-dom';
import { Button, Grid, Paper, Box, Typography } from '@mui/material';
import EmptyStatesClient from '../../clients/EmptyStatesClient';
import { FeatureNames, Features, User } from '../../types';

interface Props {
	pageIdentifier: Features;
	user: User;
	banner: {
		title: string;
		cta: {
			label: string;
			href?: string;
			onClick?: () => void;
		};
		video: string;
	};
	items: {
		title: string;
		subtitle: string;
	}[];
	token: string;
}

const EmptyState = (props: Props) => {
	const navigate = useNavigate();

	const onClickCta = async () => {
		await EmptyStatesClient.markAsviewed(props.pageIdentifier, props.user, props.token);

		if (props.banner.cta.onClick) {
			props.banner.cta.onClick();
		} else if (props.banner.cta.href) {
			navigate(props.banner.cta.href);
		}
	};

	return (
		<Grid>
			<Paper
				sx={{
					px: {
						md: '2.8em',
						xs: '2em',
					},
					py: {
						md: '1.8em',
						xs: '1em',
					},
					backgroundColor: '#EAEAEA',
					borderRadius: '0.7rem 0.7rem 0 0',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						flexDirection: { md: 'row', xs: 'column' },
						justifyContent: {
							md: 'space-between',
							xs: 'start',
						},
					}}
				>
					<Box
						className="flex flex-col max-w-[21rem] justify-end gap-8"
						sx={{
							py: { md: 2, xs: 1 },
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography variant="h2" className="leading-snug font-semibold ">
								{props.banner.title}
							</Typography>
						</Box>

						<Button
							variant="contained"
							className="text-base leading-8 max-w-60"
							sx={{
								maxWidth: {
									md: '240px',
									xs: '100%',
								},
							}}
							onClick={onClickCta}
							href={props.banner.cta.href}
						>
							{props.banner.cta.label}
						</Button>
					</Box>
					<Box className="flex mx-[6%] w-full justify-end">
						<iframe
							style={{ borderRadius: '2em', width: '100%', maxWidth: '500px' }}
							height="260"
							src={props.banner.video}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						></iframe>
					</Box>
				</Box>
			</Paper>
			<Paper
				sx={{
					py: '3rem',
					borderRadius: '0 0 11.2px 11.2px',
					px: {
						md: '2.8em',
						xs: '2em',
					},
				}}
				elevation={0}
			>
				<Grid container justifyContent={'space-between'} sx={{ textAlign: { md: 'initial', xs: 'center' } }}>
					{props.items.map((item) => (
						<Grid item md={3.33} sx={{ paddingBottom: { xs: '1em' } }}>
							<Typography variant="h6" className="font-bold">
								{item.title}
							</Typography>
							<Typography variant="body2" className="text-base mt-4">
								{item.subtitle}
							</Typography>
						</Grid>
					))}
				</Grid>
			</Paper>
		</Grid>
	);
};

export default EmptyState;
