import Language, { isValidLanguage } from '../i18n/language';
import useStorage from './use-storage';

const useLanguage = (defaultSupplier: () => Language): [Language, (language: Language) => void] => {
	const [language, setLanguage] = useStorage('language', defaultSupplier);

	if (isValidLanguage(language)) {
		return [language, setLanguage];
	}

	return [defaultSupplier(), setLanguage];
};

export default useLanguage;
