import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { formatNumberLocale } from '../../../../utils/data-utils';

type Props = {
	open: boolean;
	onCTAPressed: (content?: string) => void;
	type: 'Accept' | 'Reject';
	onClose: () => void;
	tokensToRedeem: number;
};

const Content = ({
	type,
	tokensToRedeem,
	rejectComment,
	setRejectComment,
}: {
	type: 'Accept' | 'Reject';
	rejectComment: string;
	tokensToRedeem?: number;
	setRejectComment: React.Dispatch<React.SetStateAction<string>>;
}) => {
	if (type === 'Accept') {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 4,
					marginBottom: 4,
				}}
			>
				<Typography variant="h5">Aceptar</Typography>
				<Typography variant="body1" color="#72727E">
					{`Estas a punto de aceptar un canje de ${!!tokensToRedeem ? formatNumberLocale(tokensToRedeem) : tokensToRedeem} tokens`}
				</Typography>
			</Box>
		);
	}
	return (
		<Grid container spacing={4}>
			<Grid item xs={6}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
					<Typography variant="h6">Rechazar</Typography>
					<Typography>
						Deja un comentario acerca del motivo de rechazo de la solicitud de entrega para que pueda verla el cliente
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
					<Typography variant="body1">Comentario</Typography>
					<TextField
						multiline
						minRows={10}
						onChange={(e) => setRejectComment(e.target.value)}
						value={rejectComment}
						placeholder="Escriba su comentario..."
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

const RedeemTokensViewCTADialog = ({ open, onCTAPressed, onClose, type, tokensToRedeem }: Props) => {
	const [rejectComment, setRejectComment] = useState('');

	const Actions = () => {
		if (type === 'Accept') {
			return (
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						onClick={onClose}
						sx={{
							bgcolor: 'white',
							border: '1px solid #406AFF',
							borderRadius: '5px',
							color: '#406AFF',
							fontWeight: 500,
							width: '160px',
							justifyContent: 'center',
							'&:hover': {
								backgroundColor: '#3445F2',
								color: '#FFF',
							},
							'&:disabled': {
								color: '#FFF',
								backgroundColor: 'gray',
							},
						}}
					>
						Cerrar
					</Button>
					<Button
						onClick={() => onCTAPressed()}
						sx={{
							bgcolor: '#406AFF',
							borderRadius: '5px',
							color: 'white',
							fontWeight: 500,
							width: '160px',
							justifyContent: 'center',
							'&:hover': {
								backgroundColor: '#3445F2',
							},
							'&:disabled': {
								color: '#FFF',
								backgroundColor: 'gray',
							},
						}}
					>
						Aceptar
					</Button>
				</DialogActions>
			);
		}
		return (
			<DialogActions sx={{ justifyContent: 'center' }}>
				<Button
					onClick={onClose}
					sx={{
						bgcolor: 'white',
						border: '1px solid #406AFF',
						borderRadius: '5px',
						color: '#406AFF',
						fontWeight: 500,
						width: '160px',
						justifyContent: 'center',
						'&:hover': {
							backgroundColor: '#3445F2',
							color: '#FFF',
						},
						'&:disabled': {
							color: '#FFF',
							backgroundColor: 'gray',
						},
					}}
				>
					Cerrar
				</Button>
				<Button
					onClick={() => onCTAPressed(rejectComment)}
					disabled={!rejectComment}
					sx={{
						bgcolor: '#406AFF',
						borderRadius: '5px',
						color: 'white',
						fontWeight: 500,
						width: '160px',
						justifyContent: 'center',
						'&:hover': {
							backgroundColor: '#3445F2',
						},
						'&:disabled': {
							color: '#FFF',
							backgroundColor: 'gray',
						},
					}}
				>
					Rechazar
				</Button>
			</DialogActions>
		);
	};
	return (
		<Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
			<DialogContent>
				<Content
					type={type}
					rejectComment={rejectComment}
					setRejectComment={setRejectComment}
					tokensToRedeem={tokensToRedeem}
				/>
			</DialogContent>
			<Actions />
		</Dialog>
	);
};

export default RedeemTokensViewCTADialog;
