import {
	Box,
	Dialog,
	DialogContent,
	Typography,
	Avatar,
	IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useMobile } from '../../../hooks/use-mobile';
import Qanimated from "../../../assets/animated_q.gif"

interface Props {
	open: boolean;
	onClose: () => void;
};

const PurchaseInProgressDialog = (props: Props) => {
	const intl = useIntl();
	const isMobile: boolean = useMobile();
	const { open, onClose } = props;

	return (
		<Dialog
			onClose={() => ({})}
			open={open}
			fullWidth={true}
			maxWidth="sm"
			PaperProps={{ sx: { maxWidth: '320px' } }}
		>
			<DialogContent sx={{ display: 'flex', padding: { xs: 'unset', sm: '1rem' }, justifyContent: 'center' }}>
				<Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', heigth: '320px' }}>
					<Avatar src={Qanimated} variant="square" sx={{ filter: 'grayscale(1)', width: '100px', height: '100px' }} />
					<Typography sx={{ textAlign: 'center', marginTop: '1rem', fontSize: '1.2em', fontWeight: 600, color: '#000' }}>
						{intl.formatMessage({ id: 'buy_tokens.in_progress' })}
					</Typography>
					<Typography sx={{ textAlign: 'center', fontSize: '.96em', fontWeight: 400, mt: 1.4 }}>
						{intl.formatMessage({ id: 'buy_tokens.in_progress.subtitle' })}
					</Typography>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default PurchaseInProgressDialog;
