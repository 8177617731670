import EmptyState from '../empty-state/empty-state';
import { FeatureNames, Features } from '../../types';
import NewQuotation from './new-quotation';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import EmptyStatesClient from '../../clients/EmptyStatesClient';
import BasePageFrame from '../base-components/base-page-frame';
import userState from '../../atoms/user-state';
import { useRecoilValue } from 'recoil';

interface Props {
	checkPageVisualization: boolean;
}

const NewQuotationsEmptyState = (props: Props) => {
	const intl = useIntl();
	const user = useRecoilValue(userState);
	const pageViewed = EmptyStatesClient.pageHasAlreadyViewed(FeatureNames.NEW_QUOTATION, user);
	const [showEmptyState, setShowEmptyState] = useState<boolean>(!pageViewed);

	return showEmptyState ? (
		<BasePageFrame
			title={intl.formatMessage({ id: 'new_quotation.title' })}
			subtitle={intl.formatMessage({ id: 'new_quotation.subtitle' })}
			transparent
		>
			<EmptyState
				user={user}
				pageIdentifier={Features.EMPTY_STATE_NEW_QUOTATION}
				banner={{
					title: '¿Cómo funciona el pedido de cotización?',
					cta: {
						label: 'Pedir cotización',
						onClick: () => setShowEmptyState(false),
					},
					video: 'https://www.youtube.com/embed/SY08XNtb5qA?si=acjpLwQ24oHRq2bS',
				}}
				items={[
					{
						title: 'Obtené las mejores condiciones de compra',
						subtitle:
							'Te acercamos un propuesta que contempla precio, disponibilidad, financiación y logística a medida.',
					},
					{
						title: 'Gestioná tus compras en nuestra plataforma digital',
						subtitle:
							'Hacé el seguimiento desde el pedido de presupuesto hasta los remitos. Tenés toda la documentación digitalizada',
					},
					{
						title: 'Cotizá los principales rubros de tu obra en un sólo lugar',
						subtitle:
							'Desde el inicio de obra cotizá materiales gruesos como acero y ladrillos cerámicos hasta aberturas  y terminaciones.',
					},
				]}
				token={user.token}
			/>
		</BasePageFrame>
	) : (
		<NewQuotation toggleEmptyState={setShowEmptyState} user={user} />
	);
};

export default NewQuotationsEmptyState;
