import React from 'react';
import { Button, IconButton } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';

interface InfoButtonProps {
	icon?: React.ReactNode;
	text: string;
	onClick: () => void;
}

const InfoButton: React.FC<InfoButtonProps> = ({ icon, text, onClick }) => {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));

	return isXs ? (
		<IconButton
			size="large"
			className="text-blue-600 border border-blue-600 hover:bg-blue-600 p-0 hover:text-white"
			onClick={onClick}
		>
			{icon ?? <HelpOutline className="w-[1.7rem] h-[1.7rem]" />}
		</IconButton>
	) : (
		<Button
			variant="outlined"
			size="small"
			startIcon={icon ?? <HelpOutline />}
			className="text-blue-600 border border-blue-600 hover:bg-blue-600 hover:text-white"
			onClick={onClick}
		>
			{text}
		</Button>
	);
};

export default InfoButton;
