import { Typography, Box, Button, styled } from '@mui/material';
import { IntlShape } from 'react-intl';
import React, { useState } from 'react';
import { FileType, Document, ListDetails, SellerListDetails, User } from '../../../types';
import SellerDeliveryDialog from '../deliveries/seller-delivery-dialog'

interface Props {
	label: string;
	delivery: any;
	user?: User;
}

const LoadRemitoButton = (props: Props, intl: IntlShape) => {
	const { label, delivery, user } = props;
	const [sellerDeliveryDialog, setSellerDeliveryDialogOpen] = useState<boolean>(false);

	const handleClick = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		e.nativeEvent.stopImmediatePropagation()
		if(!sellerDeliveryDialog) setSellerDeliveryDialogOpen(true)
	}

	return (
		<Box sx={{ justifyContent: 'center'}}>
			<Button
				onClick={handleClick}
				variant="contained"
				sx={{ background: '#FFF', border: '1px solid #000',color: '#000', px:2, py:1.25, borderRadius: 10, maxWidth: '132px', fontSize: '.82em', lineHeight: '1.1em', textTransform: 'none' }}
			>
				{label}
			</Button>
			<SellerDeliveryDialog deliveryType="remito" open={sellerDeliveryDialog} delivery={delivery} user={user} onClose={() => setSellerDeliveryDialogOpen(false)} />
		</Box>
	)
};

export default LoadRemitoButton;
