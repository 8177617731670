import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { User } from '../types';
import { Grid, Typography, Box, IconButton, styled, Dialog as MuiDialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadButtonText from './file-download-button-text';
import { useMobile } from '../hooks/use-mobile';
import { useRecoilValue } from 'recoil';
import userState from '../atoms/user-state';

const Dialog = styled(MuiDialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
		borderRadius: '8px',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
	},
}));

export interface ClientData {
	image?: string;
	name?: string;
	taxId?: number;
}

interface Props {
	open: boolean;
	clientData: ClientData;
	files?: any;
	listId?: string;
	user?: User;
	onClose: (event?: object | null, reason?: string) => void;
}

const Wrapper = styled('div')`
	& > *:not(:first-child) {
	margin-left: 10px;
	}
	
	width: fit-content;	
	margin-bottom: 20px;
`;

const Avatar = styled('img')`
	height: 60px;
	display: inline-block;
`;

const TextWrapper = styled('div')`
	display: inline-block;
	vertical-align: top;
`;

const TaxID = styled(Typography)`
	color: #0000ff;
`;

const FileRow = styled(Grid)`
	display: flex;
	&:not(:last-child) {
		border-bottom: 1px solid rgb(223 223 223);
	}
`;

const ClientBillingInfoDialog = (props: Props) => {
	const intl = useIntl();
	const isMobile = useMobile();
    const user = useRecoilValue(userState);
	const { open, clientData, files = [],  onClose } = props;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle className='border-none pl-4'>
				<Box>
					<Typography variant="h5" textAlign="start">
						{intl.formatMessage({ id: 'client_billing_dialog.title' })}
					</Typography>

					<IconButton
						aria-label="close"
						onClick={onClose}
						className='absolute right-2 top-2 text-gray-500'
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Wrapper>
					{clientData?.image && <Avatar src={clientData?.image} />}
					<TextWrapper>
						<Typography className='font-light mt-2 text-base'>{clientData?.name}</Typography>
						<TaxID className='font-light text-xs'>
							{intl.formatMessage({ id: 'common.tax_id' }, { taxId: clientData?.taxId })}
						</TaxID>
					</TextWrapper>
				</Wrapper>

				<Box maxHeight={'420px'} overflow={'auto'} display={'flex'} flexDirection={'column'} gap={isMobile ? 0 : 1} >
					{
						files?.map((file: any, index: number) => {
							return (
								<FileRow key={`${file.type}-${index}`} container>
									<Grid item xs={8}>
										<Typography variant="subtitle1">
											{intl.formatMessage({ id: `client_billing_dialog.files.${file.file_type}` })}
										</Typography>
									</Grid>
									<Grid item xs={4} className='flex flex-wrap justify-end content-center'>
										<FileDownloadButtonText
											driveFileId={''}
											fileId={file.id}
											user={user}
											gcsFilePath={file.path}
										/>
									</Grid>
								</FileRow>
							);
						})
					}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ClientBillingInfoDialog;
